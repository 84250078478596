import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Chip, TextField } from '@mui/material';
import Question from './Question';

interface MultiSelectQuestionProps {
  question: any;
  value: string[];
  onChange: (value: string[]) => void;
  onFollowUpChange?: (fieldId: string, value: string) => void;
  followUpValues?: Record<string, string>;
}

const MultiSelectQuestion: React.FC<MultiSelectQuestionProps> = ({
  question,
  value = [],
  onChange,
  onFollowUpChange,
  followUpValues = {}
}) => {
  return (
    <Question question={question}>
      <div>
        <FormControl fullWidth required={question.required}>
          <InputLabel>Select multiple</InputLabel>
          <Select
            multiple
            value={value}
            onChange={(e) => onChange(e.target.value as string[])}
            label="Select multiple"
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {question.options?.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Render follow-ups based on selected values */}
        {Array.isArray(value) && (question.followUps || [])
          .filter((followUp: {triggerOption: string; question: string; required?: boolean}) => 
            value.includes(followUp.triggerOption))
          .map((followUp: { question: string; triggerOption: string; required?: boolean }, index: number) => (
            <div key={index} className="mt-3 pl-4 border-l-4 border-purple-300">
              <p className="text-sm font-medium mb-2">
                {followUp.question}
                <Chip 
                  label={followUp.triggerOption} 
                  size="small" 
                  sx={{ ml: 1 }} 
                  variant="outlined" 
                />
              </p>
              <TextField 
                fullWidth
                size="small"
                multiline
                rows={2}
                value={followUpValues[`${question.id}-followup-${followUp.triggerOption}`] || ""}
                onChange={(e) => onFollowUpChange && onFollowUpChange(
                  `${question.id}-followup-${followUp.triggerOption}`, 
                  e.target.value
                )}
                required={followUp.required}
              />
            </div>
          ))
        }
        
        {/* Backward compatibility for old format */}
        {Array.isArray(value) && 
          value.includes(question.followUpTriggerOption) && 
          question.followUp?.question && (
          <div className="mt-3 pl-4 border-l-4 border-purple-300">
            <p className="text-sm font-medium mb-2">{question.followUp.question}</p>
            <TextField 
              fullWidth
              size="small"
              multiline
              rows={2}
              value={followUpValues[`${question.id}-followup`] || ""}
              onChange={(e) => onFollowUpChange && onFollowUpChange(
                `${question.id}-followup`, 
                e.target.value
              )}
              required={question.followUp.required}
            />
          </div>
        )}

        {/* Handle conditionalFollowUps format */}
        {Array.isArray(value) && question.conditionalFollowUps && 
          Object.keys(question.conditionalFollowUps)
            .filter(option => value.includes(option))
            .map((option, index) => {
              const followUp = question.conditionalFollowUps[option];
              return (
                <div key={`cond-${index}`} className="mt-3 pl-4 border-l-4 border-purple-300">
                  <p className="text-sm font-medium mb-2">
                    For "{option}": {followUp.question}
                    <Chip 
                      label={option} 
                      size="small" 
                      sx={{ ml: 1 }} 
                      variant="outlined" 
                    />
                  </p>
                  <TextField 
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    value={followUpValues[`${question.id}_${option}_followup`] || ""}
                    onChange={(e) => onFollowUpChange && onFollowUpChange(
                      `${question.id}_${option}_followup`, 
                      e.target.value
                    )}
                    required={followUp.required}
                  />
                </div>
              );
            })
        }
      </div>
    </Question>
  );
};

export default MultiSelectQuestion;