import { format } from 'date-fns';

interface PDFSignatureRendererProps {
  doc: any;
  questionsToInclude: any[];
  responses: { [key: string]: any };
  signatures: { [key: string]: string };
  currentY: number;
  primaryColorRgb: [number, number, number];
  blackTextColor: [number, number, number];
  safeText: (value: any) => string;
}

export const PDFSignatureRenderer = ({
  doc,
  questionsToInclude,
  responses,
  signatures,
  currentY,
  primaryColorRgb,
  blackTextColor,
  safeText
}: PDFSignatureRendererProps): number => {
  // Find all signature questions that have responses
  const signatureQuestions = questionsToInclude.filter(q => 
    q.type === "signature" && 
    (signatures[q.id] || (responses[q.id] && responses[q.id].startsWith('data:image')))
  );
  
  // If no signatures, just return current Y position
  if (signatureQuestions.length === 0) {
    return currentY;
  }
  
  // Check if we need a new page for signatures section
  if (currentY > doc.internal.pageSize.getHeight() - 100) {
    doc.addPage();
    currentY = 20;
  }
  
  // Add section header for signatures
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 10, 2, 2, "FD");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("SIGNATURES", doc.internal.pageSize.getWidth() / 2, currentY + 7, { align: "center" });
  currentY += 15;
  
  // Reset text color for content
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  
  // Loop through signatures and render each one
  signatureQuestions.forEach((q, index) => {
    // Check if we need a page break for this signature
    if (currentY > doc.internal.pageSize.getHeight() - 70) {
      doc.addPage();
      currentY = 20;
    }
    
    try {
      // Get signature data from either source (signatures or responses)
      let sigData = signatures[q.id];
      
      // If not in signatures, check responses
      if (!sigData && responses[q.id] && responses[q.id].startsWith('data:image')) {
        sigData = responses[q.id];
      }
      
      // If signature exists, display it
      if (sigData && sigData.startsWith('data:image')) {
        // Add signature label
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text(safeText(q.label || "Signature"), 15, currentY);
        currentY += 10;
        
        // Calculate width and height for signature (larger than thumbnails)
        const sigWidth = 120;
        const sigHeight = 60;
        
        // Add the signature image
        doc.addImage(
          sigData, 
          "PNG", 
          15, 
          currentY, 
          sigWidth, 
          sigHeight
        );
        
        // Add signature date and name if available
        currentY += sigHeight + 5;
        doc.setFontSize(9);
        doc.setFont("helvetica", "normal");
        
        // Add current date as signature date
        const signatureDate = format(new Date(), 'dd/MM/yyyy');
        doc.text(`Date: ${signatureDate}`, 15, currentY);
        
        // Add name if present in label or use default
        const nameMatch = q.label.match(/signature of (.*)/i);
        const signerName = nameMatch ? nameMatch[1] : "Signer";
        doc.text(`Name: ${signerName}`, 80, currentY);
        
        currentY += 20; // Add spacing after signature
      } else {
        // Handle missing signature
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text(`${safeText(q.label)}: No signature provided`, 15, currentY);
        currentY += 15;
      }
    } catch (error) {
      console.error("Error processing signature for PDF:", error);
      
      // Add error text
      doc.setFontSize(10);
      doc.setFont("helvetica", "italic");
      doc.text(`Error displaying signature for: ${safeText(q.label)}`, 15, currentY);
      currentY += 15;
    }
  });
  
  return currentY;
};