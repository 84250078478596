import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

/**
 * Sanitizes form data by replacing undefined values with null for Firebase
 * @param data Any data structure that might contain undefined values
 * @returns The same data structure with undefined values replaced with null
 */
export const sanitizeForFirebase = (data: any): any => {
  return JSON.parse(JSON.stringify(data, (key, value) => {
    return value === undefined ? null : value;
  }));
};

// In your existing save function where you call setDoc (don't replace your function)
// Replace this:
// await setDoc(doc(db, "forms", formData.id), formData);

// With this:
const formData = { id: "someUniqueId" }; // Define formData with appropriate structure
const sanitizedData = sanitizeForFirebase(formData);
await setDoc(doc(db, "forms", formData.id), sanitizedData);