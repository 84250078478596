import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Popover,
  Badge
} from '@mui/material';
import { 
  Search as SearchIcon,
  FilterList as FilterIcon,
  DateRange as DateRangeIcon,
  Close as CloseIcon,
  CheckBox as CheckBoxIcon,
  FilterAlt as FilterAltIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { normalizeTextForComparison } from '../services/responseProcessingService';
import { filterSubmission } from '../utils/filterUtils';

interface ResponseFiltersProps {
  onFilterChange: (filters: any) => void;
  questions: any[];
}

const ResponseFilters: React.FC<ResponseFiltersProps> = ({ onFilterChange, questions }) => {
  // Filter states
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dateRange, setDateRange] = useState<{ start: Date | null; end: Date | null }>({
    start: null, end: null
  });
  const [questionFilters, setQuestionFilters] = useState<{[key: string]: any}>({});
  
  // Advanced filter popover state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    applyFilters({ searchTerm: newSearchTerm });
  };
  
  // Clear all filters
  const handleClearFilters = () => {
    setSearchTerm('');
    setDateRange({ start: null, end: null });
    setQuestionFilters({});
    onFilterChange({});
  };
  
  // Apply filters to parent component
  const applyFilters = (updatedFilters?: any) => {
    const currentFilters = {
      searchTerm,
      dateRange,
      questionFilters,
      ...updatedFilters
    };
    
    // Remove empty filters
    Object.keys(currentFilters).forEach(key => {
      if (currentFilters[key] === '' || 
          currentFilters[key] === null || 
          (typeof currentFilters[key] === 'object' && 
           !Array.isArray(currentFilters[key]) && 
           Object.keys(currentFilters[key]).length === 0)) {
        delete currentFilters[key];
      }
    });
    
    onFilterChange(currentFilters);
  };

  // Handle date range changes
  const handleStartDateChange = (newDate: Date | null) => {
    const newDateRange = { ...dateRange, start: newDate };
    setDateRange(newDateRange);
    applyFilters({ dateRange: newDateRange });
  };
  
  const handleEndDateChange = (newDate: Date | null) => {
    const newDateRange = { ...dateRange, end: newDate };
    setDateRange(newDateRange);
    applyFilters({ dateRange: newDateRange });
  };

  // Handle advanced filter popover
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  
  // Update question filters
  const handleQuestionFilterChange = (questionId: string, value: any) => {
    // Use normalized values for text comparisons
    let normalizedValue = value;
    
    // Only normalize string values, not arrays/objects
    if (typeof value === 'string') {
      // Don't lowercase the value here - just trim it
      // The actual comparison will use normalizeTextForComparison
      normalizedValue = value.trim();
    }
    
    const newQuestionFilters = {
      ...questionFilters,
      [questionId]: normalizedValue
    };
    
    // Remove filter if value is empty
    if (value === '' || value === null || (Array.isArray(value) && value.length === 0)) {
      delete newQuestionFilters[questionId];
    }
    
    setQuestionFilters(newQuestionFilters);
    applyFilters({ questionFilters: newQuestionFilters });
  };

  // Get filterable questions (exclude instructions, sections, etc.)
  const filterableQuestions = questions.filter(q => 
    q.type !== 'instructions' && q.type !== 'section' && q.type !== 'signature'
  );
  
  // Count active filters
  const activeFilterCount = 
    (searchTerm ? 1 : 0) + 
    (dateRange.start || dateRange.end ? 1 : 0) + 
    Object.keys(questionFilters).length;

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 3, borderRadius: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" fontWeight="medium">
          Filters & Search
        </Typography>
        
        {activeFilterCount > 0 && (
          <Button 
            variant="outlined" 
            size="small" 
            color="primary"
            onClick={handleClearFilters}
            startIcon={<CloseIcon />}
          >
            Clear all ({activeFilterCount})
          </Button>
        )}
      </Box>
      
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        {/* Search field */}
        <TextField
          placeholder="Search by email or response content..."
          fullWidth
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => {
                  setSearchTerm('');
                  applyFilters({ searchTerm: '' });
                }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        
        {/* Date filter button */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={1} sx={{ minWidth: { sm: '400px' } }}>
            <DatePicker
              label="From date"
              value={dateRange.start}
              onChange={handleStartDateChange}
              slotProps={{ textField: { size: 'small' } }}
            />
            <DatePicker
              label="To date"
              value={dateRange.end}
              onChange={handleEndDateChange}
              slotProps={{ textField: { size: 'small' } }}
            />
          </Stack>
        </LocalizationProvider>
        
        {/* Advanced filter button */}
        <Button
          variant="outlined"
          startIcon={
            <Badge badgeContent={Object.keys(questionFilters).length} color="primary">
              <FilterAltIcon />
            </Badge>
          }
          onClick={handleFilterClick}
          size="small"
          sx={{ minWidth: { sm: '180px' } }}
        >
          Question Filters
        </Button>
      </Stack>
      
      {/* Display active filters */}
      {activeFilterCount > 0 && (
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {searchTerm && (
            <Chip 
              label={`Search: "${searchTerm}"`} 
              size="small" 
              onDelete={() => {
                setSearchTerm('');
                applyFilters({ searchTerm: '' });
              }} 
            />
          )}
          
          {(dateRange.start || dateRange.end) && (
            <Chip 
              icon={<DateRangeIcon />}
              label={`Date: ${dateRange.start ? new Date(dateRange.start).toLocaleDateString() : 'Any'} - ${dateRange.end ? new Date(dateRange.end).toLocaleDateString() : 'Any'}`}
              size="small" 
              onDelete={() => {
                setDateRange({ start: null, end: null });
                applyFilters({ dateRange: { start: null, end: null } });
              }} 
            />
          )}
          
          {Object.entries(questionFilters).map(([questionId, value]) => {
            const question = questions.find(q => q.id === questionId);
            if (!question) return null;
            
            let displayValue = '';
            
            if (Array.isArray(value)) {
              displayValue = value.join(', ');
            } else if (typeof value === 'boolean') {
              displayValue = value ? 'Yes' : 'No';
            } else if (value && typeof value === 'object') {
              if (value.min !== undefined || value.max !== undefined) {
                displayValue = `${value.min || 'Any'} - ${value.max || 'Any'}`;
              } else if (value.start || value.end) {
                displayValue = `${value.start ? new Date(value.start).toLocaleDateString() : 'Any'} - ${value.end ? new Date(value.end).toLocaleDateString() : 'Any'}`;
              } else {
                displayValue = JSON.stringify(value);
              }
            } else {
              displayValue = String(value);
            }
            
            return (
              <Chip 
                key={questionId}
                icon={<CheckBoxIcon />}
                label={`${question.label.substring(0, 15)}${question.label.length > 15 ? '...' : ''}: ${displayValue.substring(0, 15)}${displayValue.length > 15 ? '...' : ''}`}
                size="small" 
                onDelete={() => handleQuestionFilterChange(questionId, '')}
              />
            );
          })}
        </Box>
      )}
      
      {/* Advanced filters popover */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: { width: '400px', p: 2, maxHeight: '70vh', overflow: 'auto' }
        }}
      >
        <Typography variant="h6" fontWeight="medium" gutterBottom>
          Filter by Question Responses
        </Typography>
        <Divider sx={{ mb: 2 }} />
        
        {filterableQuestions.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No filterable questions available for this form.
          </Typography>
        ) : (
          filterableQuestions.map(question => (
            <Box key={question.id} mb={2}>
              <Typography variant="subtitle2" gutterBottom>
                {question.label}
              </Typography>
              
              {/* Render different filter inputs based on question type */}
              {(question.type === 'select' || 
                (question.type === 'checkbox' && question.checkboxStyle === 'yes-no')) && (
                <FormControl fullWidth size="small">
                  <InputLabel>Select a value</InputLabel>
                  <Select
                    value={questionFilters[question.id] || ''}
                    onChange={(e) => handleQuestionFilterChange(question.id, e.target.value)}
                    label="Select a value"
                  >
                    <MenuItem value="">
                      <em>Any</em>
                    </MenuItem>
                    {question.type === 'select' && question.options && 
                      question.options.map((option: string) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                      ))
                    }
                    {question.type === 'checkbox' && question.checkboxStyle === 'yes-no' && (
                      <>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </>
                    )}
                  </Select>
                </FormControl>
              )}
              
              {question.type === 'multi-select' && (
                <FormControl fullWidth size="small">
                  <InputLabel>Select value(s)</InputLabel>
                  <Select
                    multiple
                    value={questionFilters[question.id] || []}
                    onChange={(e) => handleQuestionFilterChange(
                      question.id, 
                      e.target.value as string[]
                    )}
                    label="Select value(s)"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {(selected as string[]).map((value) => (
                          <Chip key={value} label={value} size="small" />
                        ))}
                      </Box>
                    )}
                  >
                    {question.options && question.options.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              
              {question.type === 'text' && (
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Filter by text content..."
                  value={questionFilters[question.id] || ''}
                  onChange={(e) => handleQuestionFilterChange(question.id, e.target.value)}
                />
              )}
              
              {question.type === 'number' && (
                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    label="Min"
                    type="number"
                    value={questionFilters[question.id]?.min || ''}
                    onChange={(e) => handleQuestionFilterChange(
                      question.id, 
                      { 
                        ...questionFilters[question.id],
                        min: e.target.value !== '' ? Number(e.target.value) : undefined
                      }
                    )}
                  />
                  <TextField
                    size="small"
                    label="Max"
                    type="number"
                    value={questionFilters[question.id]?.max || ''}
                    onChange={(e) => handleQuestionFilterChange(
                      question.id, 
                      { 
                        ...questionFilters[question.id],
                        max: e.target.value !== '' ? Number(e.target.value) : undefined
                      }
                    )}
                  />
                </Stack>
              )}
              
              {/* For date questions */}
              {question.type === 'date' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label="From"
                      value={questionFilters[question.id]?.start || null}
                      onChange={(date) => handleQuestionFilterChange(
                        question.id, 
                        { 
                          ...questionFilters[question.id],
                          start: date
                        }
                      )}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                    <DatePicker
                      label="To"
                      value={questionFilters[question.id]?.end || null}
                      onChange={(date) => handleQuestionFilterChange(
                        question.id, 
                        { 
                          ...questionFilters[question.id],
                          end: date
                        }
                      )}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Stack>
                </LocalizationProvider>
              )}
            </Box>
          ))
        )}
        
        <Divider sx={{ my: 2 }} />
        
        <Box display="flex" justifyContent="space-between">
          <Button 
            variant="text" 
            onClick={() => {
              setQuestionFilters({});
              applyFilters({ questionFilters: {} });
            }}
          >
            Clear filters
          </Button>
          <Button onClick={handleFilterClose} variant="contained">
            Apply filters
          </Button>
        </Box>
      </Popover>
    </Paper>
  );
};

export default ResponseFilters;
