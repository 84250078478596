import React, { useState, ReactNode } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Divider, 
  IconButton, 
  Collapse,
  useTheme
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

interface SectionQuestionProps {
  question: {
    id: string;
    label: string;
    description?: string;
    sectionStyle?: string;
    sectionBgColor?: string;
    sectionBorderColor?: string;
    collapsible?: boolean;
    initiallyExpanded?: boolean;
    showSectionNumbers?: boolean;
  };
  sectionIndex?: number;
  children?: ReactNode;
}

const SectionQuestion: React.FC<SectionQuestionProps> = ({ 
  question, 
  sectionIndex = 1,
  children 
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(question.initiallyExpanded !== false);

  // Apply different styles based on the section style setting
  const getSectionStyles = () => {
    const baseStyles = {
      p: 3,
      mb: 4,
      backgroundColor: question.sectionBgColor || 'transparent',
      borderColor: question.sectionBorderColor || theme.palette.divider,
    };

    switch (question.sectionStyle) {
      case "card":
        return {
          ...baseStyles,
          borderRadius: 2,
          boxShadow: 3,
          border: 'none',
        };
      case "outlined":
        return {
          ...baseStyles,
          border: 1,
          borderRadius: 1,
          boxShadow: 0,
        };
      case "subtle":
        return {
          ...baseStyles,
          borderLeft: 4,
          borderColor: theme.palette.primary.main,
          borderRadius: 0,
          boxShadow: 0,
          bgcolor: 'rgba(0, 0, 0, 0.02)',
        };
      default: // "default"
        return {
          ...baseStyles,
          border: 1,
          borderRadius: 1,
          boxShadow: 1,
        };
    }
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper sx={getSectionStyles()} elevation={0}>
      {/* Section Header */}
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between"
        sx={{
          cursor: question.collapsible ? 'pointer' : 'default'
        }}
        onClick={question.collapsible ? handleToggleExpand : undefined}
      >
        <Box>
          <Typography 
            variant="h5" 
            fontWeight="500" 
            gutterBottom
            color="primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {question.showSectionNumbers && (
              <Box 
                component="span"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: 'white',
                  borderRadius: '50%',
                  width: 32,
                  height: 32,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1.5,
                  fontWeight: 'bold'
                }}
              >
                {sectionIndex}
              </Box>
            )}
            {question.label}
          </Typography>
          
          {question.description && (
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ 
                mt: 0.5, 
                mb: 2,
                maxWidth: question.collapsible ? 'calc(100% - 48px)' : '100%'
              }}
            >
              {question.description}
            </Typography>
          )}
        </Box>
        
        {question.collapsible && (
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              handleToggleExpand();
            }}
            size="large"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      
      {/* Divider */}
      {(question.description || question.showSectionNumbers) && (
        <Divider sx={{ mt: 1, mb: 3 }} />
      )}
      
      {/* Section Content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
        <Box sx={{ mt: 2, '& > *:not(:last-child)': { mb: 4 } }}>
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SectionQuestion;