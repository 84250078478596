import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { generateChartColors } from '../Chartutils';
import FilterablePieChart from './FilterablePieChart';

interface SelectVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
  onSegmentClick?: (index: number, value: any, label: string) => void;
  onFilterChange?: (value: any) => void;
  activeFilter?: any;
}

const SelectVisualization: React.FC<SelectVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = [],
  onSegmentClick,
  onFilterChange,
  activeFilter
}) => {
  const theme = useTheme();
  
  if (!summaryData || !summaryData.options) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No data available for this question
      </Typography>
    );
  }
  
  // Prepare data for chart
  const optionLabels = Object.keys(summaryData.options);
  const optionValues = optionLabels.map(label => summaryData.options[label]);
  
  // Generate colors based on theme
  const colors = generateChartColors(theme, optionLabels.length);
  
  const chartData = {
    labels: optionLabels,
    originalValues: optionLabels, // Keep original values for filtering
    datasets: [{
      data: optionValues,
      backgroundColor: colors,
      borderColor: colors.map((color: string) => theme.palette.mode === 'dark' ? color + '30' : color + '90'),
      borderWidth: 1,
    }]
  };
  
  const handleFilterClick = (value: string) => {
    if (onFilterChange) {
      // If clicked on already active filter, clear it
      if (activeFilter === value) {
        onFilterChange(null);
      } else {
        onFilterChange(value);
      }
    }
  };

  return (
    <FilterablePieChart
      title="Response Distribution"
      type={optionLabels.length > 6 ? 'doughnut' : 'pie'}
      data={chartData}
      activeFilter={activeFilter}
      onSegmentClick={onSegmentClick || ((_, value) => handleFilterClick(String(value)))}
      clearFilter={onFilterChange ? () => onFilterChange(null) : undefined}
      height={300}
      legendPosition={optionLabels.length > 4 ? 'right' : 'bottom'}
    />
  );
};

export default SelectVisualization;