import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import FollowUpDisplay from '../shared/FollowUpDisplay';

interface CheckboxResponseProps {
  question: any;
  submission: any;
}

const CheckboxResponse: React.FC<CheckboxResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // Debug logging
  console.log(`Checkbox response for ${question.id}:`, {
    response,
    type: typeof response,
    isArray: Array.isArray(response),
    checkboxStyle: question.checkboxStyle,
    hasFollowUp: !!question.followUp,
    relatedResponses: Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`))
      .reduce((obj, key) => ({ ...obj, [key]: responses[key] }), {})
  });

  // Helper to normalize yes/no values for consistent comparison
  const normalizeYesNo = (val: any): string => {
    if (val === true || val === 'true' || val === 'yes' || val === 'Yes' || val === 'YES' || val === 'y' || val === 'Y') {
      return 'yes';
    }
    if (val === false || val === 'false' || val === 'no' || val === 'No' || val === 'NO' || val === 'n' || val === 'N') {
      return 'no';
    }
    return String(val).toLowerCase();
  };

  // Handle yes/no style checkboxes
  if (question.checkboxStyle !== 'multi') {
    // For yes-no questions, check both boolean and string responses with case-insensitive comparison
    const normalizedResponse = normalizeYesNo(response);
    const isTrue = normalizedResponse === 'yes';
    const isFalse = normalizedResponse === 'no';
    
    const displayValue = isTrue ? 'Yes' : 
                        isFalse ? 'No' : 'Not provided';
                        
    return (
      <>
        <Typography variant="body1">{displayValue}</Typography>
        
        {/* For yes/no checkbox with follow-ups - check if the condition is met regardless of type */}
        {question.followUp && isTrue && responses[`${question.id}_followUp`] && (
          <FollowUpDisplay
            question={question.followUp.question}
            response={responses[`${question.id}_followUp`]}
          />
        )}
        
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // Handle multi-style checkboxes (similar to multi-select)
  // Handle different formats of multi-checkbox responses
  let checkboxItems: string[] = [];
  
  if (Array.isArray(response)) {
    checkboxItems = response;
  } else if (typeof response === 'object' && response !== null) {
    checkboxItems = Object.keys(response).filter(key => response[key] === true);
  } else {
    // Check for individual options stored as separate fields
    const relevantResponses = Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`) && 
              key !== `${question.id}_other` && 
              key !== `${question.id}_comment` &&
              !key.includes('followUp'))
      .filter(key => responses[key] === true);
      
    if (relevantResponses.length > 0) {
      checkboxItems = relevantResponses.map(key => key.replace(`${question.id}_`, ''));
    }
  }
  
  if (checkboxItems.length === 0) {
    return (
      <>
        <Typography variant="body1">Not provided</Typography>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  return (
    <>
      <Box>
        {checkboxItems.map((item, index) => (
          <Chip 
            key={index} 
            label={item} 
            size="small" 
            sx={{ mr: 0.5, mb: 0.5 }} 
          />
        ))}
      </Box>
      
      {/* Check for "other" option in checkboxes */}
      {question.allowOther && 
        (checkboxItems.includes('Other') || responses[`${question.id}_other`]) && (
        <FollowUpDisplay
          question="Other"
          response={responses[`${question.id}_other`] || '(No details provided)'}
        />
      )}
      
      {/* Render follow-up questions for selected options */}
      {question.checkboxStyle === "multi" && question.conditionalFollowUps && Array.isArray(checkboxItems) && (
        <Box sx={{ mt: 2 }}>
          {checkboxItems.map(option => {
            // Check for follow-up response using various possible key formats
            const followUpKeys = [
              `${question.id}_${option}_followup`,
              `${question.id}-${option}-followup`,
              `followUp_${question.id}_${option}`,
              `${question.id}_followUp_${option}`
            ];
            
            const followUpKey = followUpKeys.find(key => responses[key]);
            const followUpResponse = followUpKey ? responses[followUpKey] : null;
            
            if (followUpResponse && question.conditionalFollowUps[option]) {
              return (
                <Box 
                  key={`followup-${option}`} 
                  sx={{ 
                    mt: 1,
                    ml: 2,
                    pl: 2,
                    py: 1,
                    borderLeft: '3px solid',
                    borderColor: 'secondary.main',
                    bgcolor: 'rgba(156, 39, 176, 0.05)',
                    borderRadius: '0 4px 4px 0'
                  }}
                >
                  <Typography variant="subtitle2" color="secondary" fontSize="0.85rem">
                    Follow-up for "{option}":
                  </Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="0.85rem" gutterBottom>
                    {question.conditionalFollowUps[option].question}
                  </Typography>
                  <Typography variant="body1" sx={{ pl: 1, py: 0.5 }}>
                    {followUpResponse}
                  </Typography>
                </Box>
              );
            }
            return null;
          })}
        </Box>
      )}

      {/* Handle the general comment if present */}
      {question.allowComment && (
        responses[`${question.id}_comment`] || 
        responses[`comment_${question.id}`]
      ) && (
        <Box sx={{ mt: 2, pl: 2, borderLeft: '2px solid', borderColor: 'info.light' }}>
          <Typography variant="subtitle2" color="text.secondary">
            {question.commentLabel || "Additional comments:"}
          </Typography>
          <Typography variant="body1">
            {responses[`${question.id}_comment`] || responses[`comment_${question.id}`]}
          </Typography>
        </Box>
      )}
      
      <CommentDisplay comments={comment} />
    </>
  );
};

export default CheckboxResponse;