/**
 * Filter function to remove archived submissions unless explicitly requested
 * @param submissions Array of submissions
 * @param includeArchived Whether to include archived submissions
 * @returns Filtered array of submissions
 */
export const filterArchivedSubmissions = (
  submissions: any[], 
  includeArchived: boolean = false
): any[] => {
  if (includeArchived) {
    return submissions;
  }
  
  return submissions.filter(submission => submission.archived !== true);
};

/**
 * Count active (unarchived) submissions
 * @param submissions Array of submissions
 * @returns The count of active submissions
 */
export const countActiveSubmissions = (submissions: any[]): number => {
  return submissions.filter(submission => submission.archived !== true).length;
};