import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormStore } from "../store/store";
import { CircularProgress, Typography, Box, Button } from "@mui/material";
import FormBuilder from "./FormBuilder";

const EditForm: React.FC = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const { loadForm, setFormId } = useFormStore();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchForm = async () => {
      if (!formId) {
        setError("Invalid Form ID.");
        setLoading(false);
        return;
      }

      try {
        console.log("🔍 Attempting to load form with ID:", formId);
        await loadForm(formId);
        setFormId(formId); // ✅ Ensure the form ID is set correctly
        console.log("✅ Form loaded successfully");
        setLoading(false);
      } catch (err) {
        console.error("❌ Error loading form:", err);
        setError("Failed to load form. Please try again.");
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, loadForm, setFormId]);

  if (loading) {
    return (
      <Box className="flex flex-col items-center justify-center h-screen">
        <CircularProgress />
        <Typography className="mt-4">Loading Form...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="flex flex-col items-center justify-center h-screen">
        <Typography className="text-red-500">{error}</Typography>
        <Button
          variant="contained"
          color="primary"
          className="mt-4"
          onClick={() => navigate("/")}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  return <FormBuilder isEditing={true} />;
};

export default EditForm;
