import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ClerkProvider, useUser } from "@clerk/clerk-react";
import { db } from "./firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import "./clerkStyles.css"; // Import the Clerk styles

// Replace with your Clerk publishable key 
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

// ✅ This component ensures users are stored in Firebase when they sign up
const EnsureUserInFirestore = () => {
  const { user } = useUser();

  useEffect(() => {
    if (!user) return;

    const storeUser = async () => {
      const userRef = doc(db, "users", user.id);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // ✅ New User → Store in Firebase with default role
        await setDoc(userRef, {
          userId: user.id,
          email: user.primaryEmailAddress?.emailAddress || "",
          role: "User", // Default role for new users
        });
      }
    };

    storeUser();
  }, [user]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: undefined, // Use default theme
        elements: {
          // Fix modal positioning
          card: "mx-auto !important rounded-xl shadow-xl",
          modalBackdrop: "!items-center",
          modalContent: "!items-center !justify-center",
          rootBox: "!items-center !justify-center",
          // Ensure buttons match your app style
          formButtonPrimary: "bg-primary-600 hover:bg-primary-700",
          // Additional styling as needed
          formField: "rounded-md",
          formFieldInput: "rounded-md",
        },
      }}
    >
      <EnsureUserInFirestore /> {/* ✅ Ensures users are added to Firebase */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);
