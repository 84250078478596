import React from 'react';
import { TextField, Typography } from '@mui/material';
import Question from './Question';

interface LongTextQuestionProps {
  question: any;
  value: string;
  onChange: (value: string) => void;
}

const LongTextQuestion: React.FC<LongTextQuestionProps> = ({ 
  question, 
  value, 
  onChange 
}) => {
  // Generate character limit text if needed
  const charLimitText = (question.minChars || question.maxChars) ? 
    `${question.minChars ? `Min ${question.minChars} chars` : ""}${question.minChars && question.maxChars ? " | " : ""}${question.maxChars ? `Max ${question.maxChars} chars` : ""}` 
    : "";

  return (
    <Question question={question}>
      <div>
        <TextField 
          fullWidth 
          multiline
          minRows={4}
          maxRows={10}
          value={value} 
          onChange={(e) => onChange(e.target.value)} 
          required={question.required}
          //placeholder={question.helperText || ""} // Use helperText as placeholder inside the field
          helperText={charLimitText} // Only show character limits as helper text
          inputProps={{
            minLength: question.minChars,
            maxLength: question.maxChars,
          }}
        />
        {question.minChars && value && (
          <Typography variant="caption" color={value.length >= question.minChars ? "green" : "error"}>
            {value.length}/{question.minChars} minimum characters
          </Typography>
        )}
      </div>
    </Question>
  );
};

export default LongTextQuestion;