import { db } from '../../firebase';
import { getFormSubmissions } from './submissionReader';
import { normalizeResponseValue } from './responseProcessing';
import { 
  differenceInMinutes, 
  format, 
  differenceInCalendarDays, 
  differenceInBusinessDays 
} from 'date-fns';
import { collection, getDocs, query, where } from 'firebase/firestore';

/**
 * Gets summary statistics for all responses to a form
 */
export const getFormResponseSummary = async (formId: string) => {
  try {
    console.log(`Getting response summary for form ${formId}`);
    
    // Get all submissions with full details
    const allSubmissions = await getFormSubmissions(formId);
    
    // Filter to only include active (unarchived) submissions
    const activeSubmissions = allSubmissions.filter(sub => !sub.archived);
    
    console.log(`Processing ${activeSubmissions.length} active submissions for summary`);
    
    // Initialize summary object
    const summary: Record<string, any> = {};
    
    // Process each submission
    activeSubmissions.forEach(submission => {
      const responses = submission.responses || {};
      
      // Process each response
      for (const questionId in responses) {
        const rawResponse = responses[questionId];
        
        // Initialize summary for this question if it doesn't exist
        if (!summary[questionId]) {
          summary[questionId] = {
            responseCount: 0,
            responses: [],
            options: {},
            yesCount: 0,
            noCount: 0,
            sum: 0,
            min: Infinity,
            max: -Infinity,
            avg: 0,
            // Include the original submissions for reference
            submissions: []
          };
        }
        
        // Skip null/undefined responses
        if (rawResponse === null || rawResponse === undefined) {
          continue;
        }
        
        // Always increment the response count
        summary[questionId].responseCount++;
        
        // Store the submission for reference (useful for time-based analyses)
        summary[questionId].submissions.push(submission);
        
        // Store the raw response value in the responses array for text questions
        if (typeof rawResponse === 'string' || 
           (typeof rawResponse === 'number') ||
           !(rawResponse instanceof Object)) {
          summary[questionId].responses.push(rawResponse);
        }
        
        // Process Date Range responses
        if (typeof rawResponse === 'object' && rawResponse !== null &&
            ((rawResponse.startDate && rawResponse.endDate) ||
             (rawResponse.start && rawResponse.end))) {
          
          // Try to calculate days between dates
          try {
            let startDate, endDate;
            
            // Handle different property naming conventions
            if (rawResponse.startDate && rawResponse.endDate) {
              startDate = convertToDate(rawResponse.startDate);
              endDate = convertToDate(rawResponse.endDate);
            } else if (rawResponse.start && rawResponse.end) {
              startDate = convertToDate(rawResponse.start);
              endDate = convertToDate(rawResponse.end);
            }
            
            if (startDate && endDate) {
              const includeWeekends = rawResponse.includeWeekends !== false;
              let days = 0;
              
              if (includeWeekends) {
                days = differenceInCalendarDays(endDate, startDate) + 1;
              } else {
                days = differenceInBusinessDays(endDate, startDate) + 1;
              }
              
              // Track days for this question's statistics
              if (!summary[questionId].days) {
                summary[questionId].days = [];
              }
              
              summary[questionId].days.push(days);
              
              // Update min/max/avg days
              if (!summary[questionId].minDays || days < summary[questionId].minDays) {
                summary[questionId].minDays = days;
              }
              if (!summary[questionId].maxDays || days > summary[questionId].maxDays) {
                summary[questionId].maxDays = days;
              }
              
              // Calculate average days
              const sum = summary[questionId].days.reduce((a: number, b: number) => a + b, 0);
              summary[questionId].avgDays = sum / summary[questionId].days.length;
              summary[questionId].totalDays = sum;
            }
          } catch (err) {
            console.error("Error calculating days:", err);
          }
        }
        
        // Process Time Range responses
        if (typeof rawResponse === 'object' && rawResponse !== null &&
            ((rawResponse.startTime && rawResponse.endTime) ||
             (rawResponse.start && rawResponse.end))) {
          
          try {
            let startTime, endTime;
            
            if (rawResponse.startTime && rawResponse.endTime) {
              startTime = parseTimeToDate(rawResponse.startTime);
              endTime = parseTimeToDate(rawResponse.endTime);
            } else if (rawResponse.start && rawResponse.end) {
              startTime = parseTimeToDate(rawResponse.start);
              endTime = parseTimeToDate(rawResponse.end);
            }
            
            if (startTime && endTime) {
              // Calculate minutes or use provided minutes
              const minutes = rawResponse.minutes !== undefined ? 
                rawResponse.minutes : differenceInMinutes(endTime, startTime);
                
              // Store minutes for statistics
              if (!summary[questionId].minutes) {
                summary[questionId].minutes = [];
              }
              summary[questionId].minutes.push(minutes);
              
              // Update min/max/avg minutes
              if (!summary[questionId].minMinutes || minutes < summary[questionId].minMinutes) {
                summary[questionId].minMinutes = minutes;
              }
              if (!summary[questionId].maxMinutes || minutes > summary[questionId].maxMinutes) {
                summary[questionId].maxMinutes = minutes;
              }
              
              // Calculate average minutes
              const sum = summary[questionId].minutes.reduce((a: number, b: number) => a + b, 0);
              summary[questionId].avgMinutes = sum / summary[questionId].minutes.length;
              summary[questionId].totalMinutes = sum;
            }
          } catch (err) {
            console.error("Error calculating minutes:", err);
          }
        }
        
        // Normalize response value for consistent counting
        if (Array.isArray(rawResponse)) {
          // For multi-select responses, normalize each option
          rawResponse.forEach(option => {
            const normalizedOption = normalizeResponseValue(option);
            if (!summary[questionId].options[normalizedOption]) {
              summary[questionId].options[normalizedOption] = 1;
            } else {
              summary[questionId].options[normalizedOption]++;
            }
          });
        } else {
          // For single responses, normalize the value
          const normalizedValue = normalizeResponseValue(rawResponse);
          
          // Add to options with normalization
          if (!summary[questionId].options[normalizedValue]) {
            summary[questionId].options[normalizedValue] = 1;
          } else {
            summary[questionId].options[normalizedValue]++;
          }
        }
      }
    });
    
    // Return the summarized data
    return summary;
  } catch (error) {
    console.error("Error getting form response summary:", error);
    throw error;
  }
};

/**
 * Gets summary statistics for all responses to a form with normalized values
 */
export const getFormResponseSummaryNormalized = async (formId: string) => {
  try {
    console.log(`Getting normalized response summary for form ${formId}`);
    
    // Get all submissions with full details
    const submissions = await getFormSubmissions(formId);
    console.log(`Processing ${submissions.length} submissions for summary`);
    
    // Initialize summary object for all questions
    const summary: Record<string, any> = {};
    
    // Process each submission
    submissions.forEach(submission => {
      const responses = submission.responses || {};
      
      // Process each response
      for (const questionId in responses) {
        const rawResponse = responses[questionId];
        
        // Initialize summary for this question if it doesn't exist
        if (!summary[questionId]) {
          summary[questionId] = {
            responseCount: 0,
            responses: [],
            options: {},
            yesCount: 0,
            noCount: 0,
            sum: 0,
            min: Infinity,
            max: -Infinity,
            avg: 0,
            // Include the original submissions for reference
            submissions: []
          };
        }
        
        // Skip null/undefined responses
        if (rawResponse === null || rawResponse === undefined) {
          continue;
        }
        
        // Always increment the response count
        summary[questionId].responseCount++;
        
        // Store the submission for reference (useful for time-based analyses)
        summary[questionId].submissions.push(submission);
        
        // Process based on response type
        if (Array.isArray(rawResponse)) {
          // Handle multi-select responses
          rawResponse.forEach(option => {
            const normalizedOption = normalizeResponseValue(option);
            if (!summary[questionId].options[normalizedOption]) {
              summary[questionId].options[normalizedOption] = 1;
            } else {
              summary[questionId].options[normalizedOption]++;
            }
          });
        } else if (typeof rawResponse === 'boolean' || 
                  (typeof rawResponse === 'string' && 
                   ['yes', 'no', 'true', 'false'].includes(rawResponse.toLowerCase()))) {
          // Handle boolean/yes-no responses
          const isTrue = normalizeResponseValue(rawResponse) === 'yes';
          
          if (isTrue) {
            summary[questionId].yesCount++;
            if (!summary[questionId].options['yes']) {
              summary[questionId].options['yes'] = 1;
            } else {
              summary[questionId].options['yes']++;
            }
          } else {
            summary[questionId].noCount++;
            if (!summary[questionId].options['no']) {
              summary[questionId].options['no'] = 1;
            } else {
              summary[questionId].options['no']++;
            }
          }
        } else if (typeof rawResponse === 'string' && rawResponse.trim() !== '') {
          // Handle text responses
          summary[questionId].responses.push(rawResponse);
          
          // Also check if this is a select response that should be counted
          const normalizedResponse = normalizeResponseValue(rawResponse);
          if (!summary[questionId].options[normalizedResponse]) {
            summary[questionId].options[normalizedResponse] = 1;
          } else {
            summary[questionId].options[normalizedResponse]++;
          }
        } else if (typeof rawResponse === 'number' || 
                  (typeof rawResponse === 'string' && !isNaN(Number(rawResponse)))) {
          // Handle numeric responses - ensure we're working with a number
          const numValue = typeof rawResponse === 'number' ? 
                         rawResponse : Number(rawResponse);
          
          if (!isNaN(numValue)) {
            // Store the raw numeric response
            summary[questionId].responses.push(numValue);
            
            // Update running totals - be careful with Infinity values
            summary[questionId].sum += numValue;
            if (summary[questionId].min === Infinity || 
                numValue < summary[questionId].min) {
              summary[questionId].min = numValue;
            }
            if (summary[questionId].max === -Infinity || 
                numValue > summary[questionId].max) {
              summary[questionId].max = numValue;
            }
            
            // Recalculate average
            const validResponses = summary[questionId].responses.filter(
              (r: any) => !isNaN(Number(r))
            );
            if (validResponses.length > 0) {
              summary[questionId].avg = summary[questionId].sum / validResponses.length;
            }
          }
        } else if (rawResponse && typeof rawResponse === 'object') {
          // Handle special objects like dates, times, or time ranges
          if (rawResponse.startTime && rawResponse.endTime) {
            // Time range - calculate minutes
            try {
              const startTime = new Date(rawResponse.startTime);
              const endTime = new Date(rawResponse.endTime);
              
              if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                const minutes = differenceInMinutes(endTime, startTime);
                
                // Store the minutes for stats
                if (!summary[questionId].minutes) {
                  summary[questionId].minutes = [];
                }
                summary[questionId].minutes.push(minutes);
                
                // Update min/max/avg minutes
                if (!summary[questionId].minMinutes || minutes < summary[questionId].minMinutes) {
                  summary[questionId].minMinutes = minutes;
                }
                if (!summary[questionId].maxMinutes || minutes > summary[questionId].maxMinutes) {
                  summary[questionId].maxMinutes = minutes;
                }
                
                // Calculate average minutes
                const sum = summary[questionId].minutes.reduce((a: number, b: number) => a + b, 0);
                summary[questionId].avgMinutes = sum / summary[questionId].minutes.length;
                summary[questionId].totalMinutes = sum;
              }
            } catch (err) {
              console.error("Error calculating minutes for time range:", err);
            }
            
            // Store the response
            summary[questionId].responses.push(rawResponse);
          } else if (rawResponse.seconds !== undefined && rawResponse.nanoseconds !== undefined) {
            // Firestore timestamp
            summary[questionId].responses.push(rawResponse);
          } else {
            // Some other object, just store it
            summary[questionId].responses.push(JSON.stringify(rawResponse));
          }
        }
      }
    });
    
    // Post-process each question's summary
    for (const questionId in summary) {
      const questionSummary = summary[questionId];
      
      // Fix min/max if no numeric responses were found
      if (questionSummary.min === Infinity) {
        questionSummary.min = null;
      }
      if (questionSummary.max === -Infinity) {
        questionSummary.max = null;
      }
      
      // Process date distributions if we have date responses
      if (questionSummary.responses.some((r: any) => 
          (r && typeof r === 'object' && (r.seconds !== undefined || r.toDate)))) {
        processDatesForDistribution(questionSummary);
      }
      
      // Process time distributions if we have time responses
      if (questionSummary.responses.some((r: any) => 
          (r && typeof r === 'string' && r.includes(':')))) {
        processTimesForDistribution(questionSummary);
      }
      
      // Calculate distributions for numeric data
      processNumericDistribution(questionSummary);
    }
    
    console.log("Normalized summary data processed:", summary);
    return summary;
  } catch (error) {
    console.error("Error getting normalized form response summary:", error);
    throw error;
  }
};

// Helper functions for processing different data types
const processDatesForDistribution = (summary: any) => {
  // Implementation for date distribution processing
  // Group dates by month or day and create distribution data
};

const processTimesForDistribution = (summary: any) => {
  // Implementation for time distribution processing
  // Group times into time slots (morning, afternoon, etc.)
};

const processNumericDistribution = (summary: any) => {
  if (summary.responses.length > 0 && 
      typeof summary.responses[0] === 'number') {
    // Create distribution data for charts
    const min = summary.min;
    const max = summary.max;
    
    // Create reasonable distribution buckets
    if (min !== null && max !== null && max !== min) {
      const bucketCount = Math.min(10, summary.responses.length);
      const bucketSize = (max - min) / bucketCount;
      
      const ranges = [];
      const distribution = Array(bucketCount).fill(0);
      
      // Create bucket labels
      for (let i = 0; i < bucketCount; i++) {
        const start = min + i * bucketSize;
        const end = min + (i + 1) * bucketSize;
        ranges.push(`${start.toFixed(1)}-${end.toFixed(1)}`);
      }
      
      // Count responses in each bucket
      summary.responses.forEach((value: number) => {
        if (value >= min && value <= max) {
          const bucketIndex = Math.min(
            bucketCount - 1, 
            Math.floor((value - min) / bucketSize)
          );
          distribution[bucketIndex]++;
        }
      });
      
      // Add distribution data to summary
      summary.distribution = {
        ranges,
        counts: distribution
      };
    }
  }
};

/**
 * Helper to convert various timestamp formats to Date objects
 */
export const convertToDate = (dateValue: any): Date | null => {
  if (!dateValue) return null;
  
  try {
    // Handle Firestore Timestamp objects
    if (dateValue && typeof dateValue === 'object' && dateValue.toDate && typeof dateValue.toDate === 'function') {
      return dateValue.toDate();
    }
    
    // Handle Firestore timestamp objects with seconds/nanoseconds
    if (dateValue && typeof dateValue === 'object' && dateValue.seconds !== undefined) {
      return new Date(dateValue.seconds * 1000);
    }
    
    // Handle Date objects
    if (dateValue instanceof Date) {
      return dateValue;
    }
    
    // Handle ISO strings and other formats
    if (typeof dateValue === 'string' || typeof dateValue === 'number') {
      const date = new Date(dateValue);
      return !isNaN(date.getTime()) ? date : null;
    }
    
    return null;
  } catch (error) {
    console.error("Error converting to date:", error);
    return null;
  }
};

/**
 * Helper to parse time strings to Date objects
 */
export const parseTimeToDate = (timeValue: any): Date | null => {
  if (!timeValue) return null;
  
  try {
    // Handle Date objects
    if (timeValue instanceof Date) {
      return timeValue;
    }
    
    // If it's already a timestamp object
    if (typeof timeValue === 'object' && timeValue.toDate && typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    
    // If it's a seconds-based timestamp
    if (typeof timeValue === 'object' && timeValue.seconds !== undefined) {
      return new Date(timeValue.seconds * 1000);
    }
    
    // If it's a string like "HH:mm" or "HH:mm:ss"
    if (typeof timeValue === 'string') {
      // If it includes a colon, assume it's a time string
      if (timeValue.includes(':')) {
        // Create a date object for today with the given time
        const [hours, minutes] = timeValue.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        return date;
      } else {
        // Try parsing as a standard date string
        const date = new Date(timeValue);
        return !isNaN(date.getTime()) ? date : null;
      }
    }
    
    return null;
  } catch (error) {
    console.error("Error parsing time:", error);
    return null;
  }
};