import React, { useState, useEffect } from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useTheme } from '@mui/material/styles';
import { Line, Bar } from 'react-chartjs-2';
import { format, parseISO, isValid } from 'date-fns';
import { groupDatesByPeriod, formatGroupedDataForChart } from '../Chartutils';
import { useNormalizedData } from '../VisualizationHelper';

interface DateVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

// Define DateRangeVisualization component so it's available within the scope
interface DateRangeVisualizationProps {
  dateRanges: Array<{
    start: Date;
    end: Date;
    label?: string;
    email?: string;
    includeWeekends?: boolean;
    useHours?: boolean;
    hours?: number | null;
  }>;
  theme: any;
}

// Helper function to format the duration between two dates
const calculateDateDuration = (start: Date, end: Date) => {
  const durationMs = end.getTime() - start.getTime();
  const days = Math.ceil(durationMs / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end days
  
  if (days === 1) {
    return "1 day";
  } else if (days < 7) {
    return `${days} days`;
  } else if (days === 7) {
    return "1 week";
  } else if (days % 7 === 0) {
    return `${days / 7} weeks`;
  } else {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;
    return `${weeks} week${weeks > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
  }
};

// Component to visualize date ranges with overlap detection
const DateRangeVisualization: React.FC<DateRangeVisualizationProps> = ({ dateRanges, theme }) => {
  if (!dateRanges || dateRanges.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No date ranges available
      </Typography>
    );
  }
  
  // Sort ranges by start date (earliest first)
  const sortedRanges = [...dateRanges].sort((a, b) => a.start.getTime() - b.start.getTime());
  
  // Determine overlap type for each range
  const rangesWithOverlapInfo = sortedRanges.map((range, index) => {
    let overlapType: 'none' | 'full' | 'partial' = 'none';
    
    for (let i = 0; i < sortedRanges.length; i++) {
      if (i === index) continue; // Skip comparing with self
      
      const other = sortedRanges[i];
      
      // Check for full overlap (exact same dates)
      if (range.start.getTime() === other.start.getTime() && 
          range.end.getTime() === other.end.getTime()) {
        overlapType = 'full';
        break;
      }
      
      // Check for partial overlap
      if (range.start <= other.end && range.end >= other.start) {
        overlapType = 'partial';
        // Don't break, as we want to prioritize full overlap if it exists
      }
    }
    
    return { 
      ...range, 
      overlapType
    };
  });
  
  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Date Range Responses ({dateRanges.length})
      </Typography>
      
      <Box sx={{ mt: 2, maxHeight: 400, overflowY: 'auto' }}>
        {rangesWithOverlapInfo.map((range, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              mb: 1,
              borderRadius: 1,
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              '&:hover': {
                boxShadow: 1,
                backgroundColor: theme.palette.action.hover
              },
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            {/* Subtle overlap indicator */}
            {range.overlapType !== 'none' && (
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 8,
                  height: 8,
                  borderTopLeftRadius: 1,
                  bgcolor: range.overlapType === 'full' ? 'warning.main' : 'info.main',
                  zIndex: 1
                }}
                title={`${range.overlapType === 'full' ? 'Full' : 'Partial'} overlap with another date range`}
              />
            )}
            
            <Typography variant="subtitle1" color="text.primary">
              {format(range.start, 'MMM d, yyyy')} — {format(range.end, 'MMM d, yyyy')}
              {range.overlapType !== 'none' && (
                <Typography 
                  component="span" 
                  variant="caption" 
                  sx={{ 
                    ml: 1,
                    color: range.overlapType === 'full' ? 'warning.main' : 'info.main',
                    fontWeight: 'medium'
                  }}
                >
                  ({range.overlapType === 'full' ? 'Full' : 'Partial'} overlap)
                </Typography>
              )}
            </Typography>
            
            <Typography variant="body2" color="text.secondary">
              {calculateDateDuration(range.start, range.end)}
            </Typography>
            
            {/* Weekend info and hours if specified */}
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                Includes weekends: {range.includeWeekends !== false ? 'Yes' : 'No'}
              </Typography>
              
              {/* Only show hours if useHours is true */}
              {range.useHours && range.hours !== undefined && range.hours !== null && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  Hours specified: {range.hours} hour{range.hours !== 1 ? 's' : ''}
                </Typography>
              )}
            </Box>
            
            {/* Always display email and form ID for attribution */}
            <Box sx={{ mt: 1, pt: 1, borderTop: `1px dashed ${theme.palette.divider}` }}>
              {range.email && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  Submitted by: {range.email}
                </Typography>
              )}
              
              {range.label && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  Form ID: {range.label}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

// Main DateVisualization component
const DateVisualization: React.FC<DateVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  const [grouping, setGrouping] = useState<'day' | 'week' | 'month'>('day');
  const [chartType, setChartType] = useState<'bar' | 'line'>('line'); // Default to line chart
  
  // Use the normalized data hook to ensure consistent data format
  const normalizedData = useNormalizedData(question, summaryData, filteredSubmissions);
  
  // Check if this is a date range question
  const isDateRange = question.type === 'date-range';
  
  // Calculate ranges manually if necessary - ALWAYS call this hook regardless of conditions
  const manualRanges = React.useMemo(() => {
    if (!isDateRange) return [];
    
    if (normalizedData?.dateRanges && normalizedData.dateRanges.length > 0) {
      return normalizedData.dateRanges;
    }
    
    // Extract ranges manually from submissions
    return filteredSubmissions
      .map(sub => {
        const value = sub?.responses?.[question.id];
        if (!value || typeof value !== 'object') return null;
        
        try {
          // Extract start date
          let startDate: Date | null = null;
          if (value.start) {
            startDate = new Date(
              typeof value.start === 'object' && value.start.seconds 
                ? value.start.seconds * 1000 
                : value.start
            );
          }
          
          // Extract end date
          let endDate: Date | null = null;
          if (value.end) {
            endDate = new Date(
              typeof value.end === 'object' && value.end.seconds 
                ? value.end.seconds * 1000 
                : value.end
            );
          }
          
          // If we have both dates, return the range
          if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
            return {
              start: startDate,
              end: endDate,
              email: sub.email || 'Anonymous',
              label: sub.formId || sub.id || 'Unknown',
              includeWeekends: value.includeWeekends !== false,
              useHours: !!value.useHours,
              hours: value.hours || null
            };
          }
          
          return null;
        } catch (e) {
          console.error(`Error manually extracting date range:`, e);
          return null;
        }
      })
      .filter(range => range !== null);
  }, [isDateRange, normalizedData?.dateRanges, filteredSubmissions, question.id]);
  
  // Extract dates directly from the normalized data - ALWAYS call this hook
  const dates = React.useMemo(() => {
    if (isDateRange) return []; // Don't process regular dates for date range questions
    
    let extractedDates: Date[] = [];
    
    if (normalizedData?.dateResponses && normalizedData.dateResponses.length > 0) {
      // The normalized data should already have valid Date objects
      extractedDates = normalizedData.dateResponses;
    } else if (normalizedData?.options) {
      // Extract dates from the options format
      const fromOptions = Object.keys(normalizedData.options)
        .map(dateStr => {
          try {
            const date = new Date(dateStr);
            return isValid(date) ? date : null;
          } catch (e) {
            return null;
          }
        })
        .filter(date => date !== null) as Date[];
        
      // Repeat each date based on its count
      const expandedDates: Date[] = [];
      Object.entries(normalizedData.options).forEach(([dateStr, count]) => {
        try {
          const date = new Date(dateStr);
          if (isValid(date)) {
            for (let i = 0; i < Number(count); i++) {
              expandedDates.push(date);
            }
          }
        } catch (e) {}
      });
      
      if (expandedDates.length > 0) {
        extractedDates = expandedDates;
      } else {
        extractedDates = fromOptions;
      }
    }
    
    return extractedDates;
  }, [normalizedData, isDateRange]);
  
  // Group dates by selected period - ALWAYS call this hook
  const groupedDatesResult = React.useMemo(() => {
    if (dates.length === 0) return { labels: [], data: [] };
    
    const groupedDates = groupDatesByPeriod(dates, grouping);
    return formatGroupedDataForChart(groupedDates, grouping);
  }, [dates, grouping]);
  
  useEffect(() => {
    // Debug the incoming data
    console.log(`DateVisualization data for ${question.id}:`, {
      isDateRange: isDateRange,
      questionType: question.type,
      summaryDataExists: !!summaryData,
      filteredSubmissions: filteredSubmissions.length,
      normalizedDataExists: !!normalizedData
    });
    
    // If this is a date range question, extract the range data
    if (isDateRange) {
      console.log('Date range specific data:', {
        hasDateRanges: !!normalizedData?.dateRanges, 
        count: normalizedData?.dateRanges?.length || 0,
        manualRangesCount: manualRanges.length,
        sampleRange: normalizedData?.dateRanges?.[0] || manualRanges[0] || 'None'
      });
    }
  }, [question.id, isDateRange, summaryData, filteredSubmissions, normalizedData, manualRanges]);
  
  // Handle loading state while normalization occurs
  if (!normalizedData) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        Loading date visualization...
      </Typography>
    );
  }
  
  // For date-range questions, render the specialized date range view
  if (isDateRange) {
    // Render DateRangeVisualization if we have any ranges
    if (manualRanges.length > 0) {
      console.log(`Rendering DateRangeVisualization with ${manualRanges.length} ranges`);
      return (
        <DateRangeVisualization 
          dateRanges={manualRanges} 
          theme={theme}
        />
      );
    }
    
    // Show a more specific message for date range questions with no data
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No date ranges found in the current submissions
      </Typography>
    );
  }
  
  // For regular date questions
  if (dates.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No valid dates in the current filter selection
      </Typography>
    );
  }
  
  // Prepare chart data
  const chartData = {
    labels: groupedDatesResult.labels,
    datasets: [
      {
        label: 'Responses',
        data: groupedDatesResult.data,
        backgroundColor: theme.palette.primary.main + '60',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        fill: chartType === 'line' ? 'origin' : undefined,
        tension: 0.3, // Smooth the line a bit
        pointRadius: chartType === 'line' ? 3 : 0,
        pointBackgroundColor: theme.palette.primary.main
      }
    ]
  };
  
  // Common chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            return `Count: ${context.raw}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Count'
        },
        ticks: {
          precision: 0
        }
      },
      x: {
        title: {
          display: true,
          text: grouping.charAt(0).toUpperCase() + grouping.slice(1)
        }
      }
    }
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle2" color="text.secondary">
          Date Distribution ({dates.length} responses)
        </Typography>
        
        <Stack direction="row" spacing={1}>
          {/* Chart type toggle */}
          <ToggleButtonGroup
            value={chartType}
            exclusive
            onChange={(_, value) => value && setChartType(value)}
            size="small"
            aria-label="chart type"
          >
            <ToggleButton value="bar" aria-label="bar chart">
              <BarChartIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="line" aria-label="line chart">
              <ShowChartIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
          
          {/* Period grouping toggle */}
          <ToggleButtonGroup
            value={grouping}
            exclusive
            onChange={(_, value) => value && setGrouping(value)}
            size="small"
            aria-label="date grouping"
          >
            <ToggleButton value="day" aria-label="group by day">
              Day
            </ToggleButton>
            <ToggleButton value="week" aria-label="group by week">
              Week
            </ToggleButton>
            <ToggleButton value="month" aria-label="group by month">
              Month
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
      
      <Box sx={{ height: 300 }}>
        {chartType === 'bar' ? (
          <Bar data={chartData} options={chartOptions} />
        ) : (
          <Line data={chartData} options={chartOptions} />
        )}
      </Box>
      
      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, textAlign: 'center' }}>
        Based on {dates.length} date responses
      </Typography>
    </Box>
  );
};

export default DateVisualization;