import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  Typography, 
  Box, 
  SelectChangeEvent,
  CircularProgress,
  Avatar,
  Chip
} from '@mui/material';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';

interface AdminUserSelectProps {
  currentUserId: string;
  onUserChange: (userId: string, userEmail: string) => void;
}

interface User {
  id: string;
  email: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
}

const AdminUserSelect: React.FC<AdminUserSelectProps> = ({ currentUserId, onUserChange }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState(currentUserId);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const usersQuery = query(
          collection(db, 'users'),
          orderBy('email')
        );
        
        const snapshot = await getDocs(usersQuery);
        const usersList: User[] = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as User));
        
        setUsers(usersList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Failed to load users");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const newUserId = event.target.value;
    setSelectedUserId(newUserId);
    
    // Find the selected user's email
    const selectedUser = users.find(user => user.id === newUserId);
    if (selectedUser) {
      onUserChange(newUserId, selectedUser.email);
    }
  };

  // Show loading state
  if (loading) {
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <CircularProgress size={20} />
        <Typography variant="body2">Loading users...</Typography>
      </Box>
    );
  }

  // Show error state
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box mt={2}>
      <Typography variant="subtitle2" gutterBottom>
        Assign Form To User (Admin Only)
      </Typography>
      
      <FormControl fullWidth size="small">
        <InputLabel id="assign-user-label">Assign To</InputLabel>
        <Select
          labelId="assign-user-label"
          id="assign-user-select"
          value={selectedUserId}
          label="Assign To"
          onChange={handleChange}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Avatar 
                  src={user.imageUrl} 
                  alt={user.firstName || user.email} 
                  sx={{ width: 24, height: 24, mr: 1 }}
                >
                  {user.firstName?.[0] || user.email?.[0]?.toUpperCase() || 'U'}
                </Avatar>
                
                <Box sx={{ flexGrow: 1 }}>
                  {user.email}
                  {user.id === currentUserId && " (you)"}
                </Box>
                
                <Chip 
                  size="small" 
                  label={user.role || "User"} 
                  variant="outlined"
                  color={user.role === "Admin" ? "primary" : "default"}
                  sx={{ ml: 1, minWidth: 60, textAlign: 'center' }}
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {selectedUserId !== currentUserId && (
        <Typography 
          variant="caption" 
          color="warning.main"
          sx={{ display: 'block', mt: 1 }}
        >
          ⚠️ This form will be saved as if created by another user.
        </Typography>
      )}
    </Box>
  );
};

export default AdminUserSelect;