// Fix for src/components/AdminTools/FixApprovalData.tsx

import React, { useState } from 'react';
import { 
  Box, Button, CircularProgress, Alert, Dialog, 
  DialogTitle, DialogContent, DialogContentText, 
  DialogActions, Typography, Chip, LinearProgress,
  Paper
} from '@mui/material';
import { AdminPanelSettings, Build, BuildCircle } from '@mui/icons-material';
import { 
  collection, query, where, getDocs, writeBatch, 
  doc, getDoc, Timestamp
} from 'firebase/firestore';
import { db } from '../../firebase';

interface FixApprovalDataProps {
  isAdmin: boolean;
}

const FixApprovalData: React.FC<FixApprovalDataProps> = ({ isAdmin }) => {
  // Declare all hooks first, before any conditional logic
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [results, setResults] = useState<{
    formsProcessed: number;
    submissionsUpdated: number;
    errors: number;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [operationDetails, setOperationDetails] = useState<string[]>([]);
  const [showDetails, setShowDetails] = useState(false);

  // Only after all hooks are declared, we can use conditional return
  if (!isAdmin) return null;
  
  const addOperationDetail = (detail: string) => {
    setOperationDetails(prev => [...prev, detail]);
  };

  const fixApprovalData = async () => {
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);

    try {
      const stats = {
        formsProcessed: 0,
        submissionsUpdated: 0,
        errors: 0
      };

      // Log operation start
      addOperationDetail("🔍 Looking for forms with requiresApproval=true...");

      // 1. Find all forms that require approval
      const formsQuery = query(
        collection(db, "forms"),
        where("requiresApproval", "==", true)
      );
      
      const formsSnapshot = await getDocs(formsQuery);
      
      if (formsSnapshot.empty) {
        setError("No forms found that require approval.");
        addOperationDetail("❌ No forms found that require approval.");
        setLoading(false);
        return;
      }

      const formIds = formsSnapshot.docs.map(doc => doc.id);
      stats.formsProcessed = formIds.length;
      
      addOperationDetail(`✅ Found ${formIds.length} forms with requiresApproval=true.`);

      // 2. Process each form's submissions in batches
      for (let i = 0; i < formIds.length; i++) {
        const formId = formIds[i];
        await processFormSubmissions(formId, stats);
        
        // Update progress
        setProgress(((i + 1) / formIds.length) * 100);
      }

      setResults(stats);
      addOperationDetail(`🎉 Update completed successfully!`);
    } catch (err) {
      console.error("Error updating approval data:", err);
      setError("Failed to update approval data. See console for details.");
      addOperationDetail(`❌ Error: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  };

  // Process submissions for a single form
  const processFormSubmissions = async (formId: string, stats: any) => {
    try {
      // Get form data to include title in logs
      const formRef = doc(db, "forms", formId);
      const formSnap = await getDoc(formRef);
      const formTitle = formSnap.exists() ? formSnap.data().formTitle || 'Untitled Form' : formId;
      
      // Find submissions for this form
      const submissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "==", formId),
        where("archived", "==", false) // Only update non-archived submissions
      );
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      
      if (submissionsSnapshot.empty) {
        addOperationDetail(`ℹ️ No submissions found for form "${formTitle}" (${formId})`);
        return;
      }
      
      addOperationDetail(`📋 Found ${submissionsSnapshot.docs.length} submissions for form "${formTitle}" (${formId})`);
      
      // Get submissions that need updating
      const submissionsToUpdate = submissionsSnapshot.docs.filter(doc => {
        const data = doc.data();
        // Check if any approval fields are missing or have incorrect values
        return (
          data.approvalRequired !== true ||
          !data.approvalStatus ||
          !data.hasOwnProperty('approvalComments')
        );
      });
      
      addOperationDetail(`🔄 ${submissionsToUpdate.length} submissions need updating for form "${formTitle}"`);
      
      if (submissionsToUpdate.length === 0) return;
      
      // Process in batches of 450 to stay well under Firestore's 500 limit
      const BATCH_SIZE = 450;
      for (let i = 0; i < submissionsToUpdate.length; i += BATCH_SIZE) {
        const batch = writeBatch(db);
        const batchItems = submissionsToUpdate.slice(i, i + BATCH_SIZE);
        
        batchItems.forEach(submission => {
          const data = submission.data();
          
          // Prepare the update data - only include fields that need updating
          const updateData: Record<string, any> = {};
          
          // Set approvalRequired to true
          if (data.approvalRequired !== true) {
            updateData.approvalRequired = true;
          }
          
          // If no approvalStatus exists, set it to 'pending'
          if (!data.approvalStatus) {
            updateData.approvalStatus = 'pending';
          }
          
          // If approvalComments is missing, add an empty string
          if (!data.hasOwnProperty('approvalComments')) {
            updateData.approvalComments = '';
          }
          
          // Skip if no updates needed (shouldn't happen given our filter, but just to be safe)
          if (Object.keys(updateData).length === 0) return;
          
          // Update the main submission document
          batch.update(doc(db, "form_submissions", submission.id), updateData);
          
          // Also update corresponding details document if it exists
          getDoc(doc(db, "submission_details", submission.id))
            .then(detailsDoc => {
              if (detailsDoc.exists()) {
                const detailsBatch = writeBatch(db);
                detailsBatch.update(doc(db, "submission_details", submission.id), updateData);
                detailsBatch.commit()
                  .catch(err => console.error(`Error updating details for submission ${submission.id}:`, err));
              }
            })
            .catch(err => console.error(`Error checking details for submission ${submission.id}:`, err));
        });
        
        // Commit the batch
        await batch.commit();
        stats.submissionsUpdated += batchItems.length;
        
        addOperationDetail(`✓ Updated ${batchItems.length} submissions in batch ${Math.floor(i/BATCH_SIZE) + 1} for form "${formTitle}"`);
      }
      
    } catch (error) {
      addOperationDetail(`❌ Error processing form ${formId}: ${error}`);
      stats.errors++;
      console.error(`Error processing form ${formId}:`, error);
    }
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 2, sm: 3 }, 
        mb: { xs: 2, sm: 3 }, 
        borderRadius: 2,
        border: '1px dashed',
        borderColor: 'warning.main',
        bgcolor: 'rgba(255, 152, 0, 0.05)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <AdminPanelSettings color="warning" />
        <Typography variant="h6">Administrator Tools</Typography>
        <Chip 
          label="Maintenance" 
          color="warning" 
          size="small" 
          sx={{ height: 20, fontSize: '0.7rem' }} 
        />
      </Box>
      
      <Typography variant="subtitle1" gutterBottom>
        Fix Legacy Approval Data
      </Typography>
      
      <Typography variant="body2" color="text.secondary" paragraph>
        This tool ensures all submissions from forms with approval required have the necessary approval fields.
        It will add <code>approvalRequired: true</code>, <code>approvalStatus: "pending"</code>, and 
        <code>approvalComments: ""</code> to any submissions that are missing these fields.
      </Typography>
      
      <Button
        variant="contained"
        color="warning"
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Build />}
        disabled={loading}
        onClick={() => setConfirmOpen(true)}
        sx={{ mt: 1, mb: loading ? 2 : 0 }}
      >
        {loading ? 'Processing...' : 'Fix Approval Data'}
      </Button>
      
      {loading && (
        <Box sx={{ mt: 2, mb: 1 }}>
          <LinearProgress variant="determinate" value={progress} color="warning" />
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
            Processing... {Math.round(progress)}% complete
          </Typography>
        </Box>
      )}
      
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      
      {results && (
        <Alert severity="success" sx={{ mt: 2 }}>
          <Typography variant="body2">
            <strong>Update complete!</strong>
          </Typography>
          <Box component="ul" sx={{ mt: 1, mb: 0.5, pl: 2 }}>
            <li>Forms processed: {results.formsProcessed}</li>
            <li>Submissions updated: {results.submissionsUpdated}</li>
            {results.errors > 0 && (
              <li style={{ color: '#d32f2f' }}>Errors: {results.errors}</li>
            )}
          </Box>
          <Button 
            size="small"
            variant="outlined" 
            color="inherit"
            onClick={() => setShowDetails(!showDetails)}
            sx={{ mt: 1 }}
          >
            {showDetails ? "Hide Details" : "Show Details"}
          </Button>
        </Alert>
      )}
      
      {showDetails && operationDetails.length > 0 && (
        <Paper 
          variant="outlined" 
          sx={{ 
            mt: 2, 
            p: 2, 
            maxHeight: 300, 
            overflow: 'auto',
            bgcolor: 'background.default',
            fontFamily: 'monospace',
            fontSize: '0.8rem'
          }}
        >
          {operationDetails.map((detail, index) => (
            <Box key={index} sx={{ mb: 0.5 }}>{detail}</Box>
          ))}
        </Paper>
      )}
      
      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => !loading && setConfirmOpen(false)}>
        <DialogTitle sx={{ bgcolor: 'warning.main', color: 'warning.contrastText' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BuildCircle sx={{ mr: 1 }} />
            Confirm Database Update
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText>
            <Typography paragraph>
              This will update <strong>all</strong> submissions from forms with approval required to have the following fields:
            </Typography>
            
            <Box component="ul" sx={{ pl: 4 }}>
              <li><code>approvalRequired: true</code></li>
              <li><code>approvalStatus: "pending"</code> (if missing)</li>
              <li><code>approvalComments: ""</code> (if missing)</li>
            </Box>
            
            <Typography paragraph sx={{ mt: 2, fontWeight: 'bold' }}>
              This operation only affects submissions from forms with approval required setting enabled.
            </Typography>
            
            <Typography sx={{ color: 'warning.dark', mt: 1 }}>
              This maintenance operation affects your database directly.
              Are you sure you want to continue?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setConfirmOpen(false)} 
            color="inherit"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            onClick={fixApprovalData} 
            color="warning"
            variant="contained"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={16} color="inherit" /> : <Build />}
          >
            {loading ? 'Processing...' : 'Yes, Fix Approval Data'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default FixApprovalData;