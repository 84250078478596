import React, { useState } from "react";
import { 
  Box, 
  TextField, 
  Typography, 
  Button, 
  Chip, 
  Select, 
  MenuItem, 
  IconButton, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Switch,
  FormControlLabel,
  Divider
} from "@mui/material";
import { Close, Delete, ExpandMore, Add } from "@mui/icons-material";
import { Question } from "../../../store/store";

interface SelectQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const SelectQuestion: React.FC<SelectQuestionProps> = ({ 
  question, 
  onChange,
  questionTypes 
}) => {
  const [newOption, setNewOption] = useState("");
  const [selectedOptionForFollowUp, setSelectedOptionForFollowUp] = useState<string | null>(null);

  const handleAddOption = () => {
    if (!newOption.trim()) return;
    
    const options = [...(question.options || []), newOption.trim()];
    onChange({ options });
    setNewOption("");
  };

  const handleRemoveOption = (optionToRemove: string) => {
    const options = (question.options || []).filter(opt => opt !== optionToRemove);
    
    // Also remove any follow-ups for this option
    if (question.conditionalFollowUps && question.conditionalFollowUps[optionToRemove]) {
      const updatedFollowUps = { ...question.conditionalFollowUps };
      delete updatedFollowUps[optionToRemove];
      onChange({ options, conditionalFollowUps: updatedFollowUps });
    } else {
      onChange({ options });
    }
  };

  const addFollowUpQuestion = (option: string) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          question: "",
          required: false
        }
      }
    });
    
    setSelectedOptionForFollowUp(option);
  };

  const updateFollowUpQuestion = (option: string, questionText: string) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          ...currentFollowUps[option],
          question: questionText
        }
      }
    });
  };

  const updateFollowUpRequired = (option: string, required: boolean) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          ...currentFollowUps[option],
          required
        }
      }
    });
  };

  const removeFollowUpQuestion = (option: string) => {
    const currentFollowUps = { ...(question.conditionalFollowUps || {}) };
    delete currentFollowUps[option];
    
    onChange({
      conditionalFollowUps: Object.keys(currentFollowUps).length > 0 ? currentFollowUps : undefined
    });
    
    setSelectedOptionForFollowUp(null);
  };

  // Get options with follow-up questions
  const optionsWithFollowUps = Object.keys(question.conditionalFollowUps || {});

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Question Label"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle2">Options:</Typography>
        <Box display="flex" gap={2} mt={1}>
          <TextField
            fullWidth
            label="Add Option"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddOption();
              }
            }}
          />
          <Button variant="contained" onClick={handleAddOption}>
            Add
          </Button>
        </Box>

        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
          {(question.options || []).map((option) => (
            <Chip
              key={option}
              label={option}
              onDelete={() => handleRemoveOption(option)}
              deleteIcon={<Close />}
              color={optionsWithFollowUps.includes(option) ? "primary" : "default"}
              onClick={() => setSelectedOptionForFollowUp(option)}
              sx={{ 
                border: selectedOptionForFollowUp === option ? '2px solid' : 'none',
                cursor: 'pointer'
              }}
            />
          ))}
        </Box>
        
        {/* Conditional Follow-up Questions Section */}
        <Box mt={3}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="subtitle2">Conditional Follow-up Questions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="text.secondary" mb={2}>
                Add follow-up questions that appear when specific options are selected.
              </Typography>
              
              {selectedOptionForFollowUp && (
                <Box mt={2} p={2} bgcolor="#f5f8ff" borderRadius={1}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle2">
                      Follow-up for option: <strong>{selectedOptionForFollowUp}</strong>
                    </Typography>
                    
                    {!question.conditionalFollowUps?.[selectedOptionForFollowUp] ? (
                      <Button 
                        startIcon={<Add />}
                        onClick={() => addFollowUpQuestion(selectedOptionForFollowUp)}
                        size="small"
                      >
                        Add Follow-up
                      </Button>
                    ) : (
                      <IconButton 
                        color="error" 
                        size="small"
                        onClick={() => removeFollowUpQuestion(selectedOptionForFollowUp)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Box>
                  
                  {question.conditionalFollowUps?.[selectedOptionForFollowUp] && (
                    <Box mt={2}>
                      <TextField
                        fullWidth
                        label="Follow-up Question"
                        value={question.conditionalFollowUps[selectedOptionForFollowUp].question}
                        onChange={(e) => updateFollowUpQuestion(selectedOptionForFollowUp, e.target.value)}
                        placeholder="e.g., Please provide more details"
                        size="small"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={question.conditionalFollowUps[selectedOptionForFollowUp].required}
                            onChange={(e) => updateFollowUpRequired(selectedOptionForFollowUp, e.target.checked)}
                            size="small"
                          />
                        }
                        label="Required"
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  )}
                </Box>
              )}
              
              {!selectedOptionForFollowUp && question.options && question.options.length > 0 && (
                <Typography align="center" color="text.secondary" py={2}>
                  Click on an option chip above to add or edit a follow-up question
                </Typography>
              )}
              
              {(!question.options || question.options.length === 0) && (
                <Typography align="center" color="text.secondary" py={2}>
                  Add options first to create conditional follow-up questions
                </Typography>
              )}
              
              {/* List of options with follow-ups */}
              {optionsWithFollowUps.length > 0 && (
                <Box mt={3} pt={2} borderTop={1} borderColor="divider">
                  <Typography variant="subtitle2" gutterBottom>
                    Options with follow-up questions:
                  </Typography>
                  
                  {optionsWithFollowUps.map((option) => (
                    <Box 
                      key={option} 
                      p={1.5} 
                      mb={1.5} 
                      bgcolor="#f0f7ff" 
                      borderRadius={1}
                      border="1px solid #e3f2fd"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => setSelectedOptionForFollowUp(option)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box flex={1}>
                        <Typography variant="body2">
                          <strong>{option}</strong>
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {question.conditionalFollowUps![option].question || "(No question text)"}
                          {question.conditionalFollowUps![option].required && " • Required"}
                        </Typography>
                      </Box>
                      <IconButton 
                        size="small" 
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFollowUpQuestion(option);
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default SelectQuestion;