import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, Box, List, ListItem, 
  Divider, Paper, IconButton, CircularProgress,
  Tabs, Tab, Tooltip, Chip, Container, Button,
  Card, CardContent, Grid, Alert, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { 
  Visibility, VisibilityOff, Delete, Archive, Unarchive,
  Build, RestoreFromTrash, Refresh as RefreshIcon,
  KeyboardBackspace, Restore as RestoreIcon,
  Email, AccessTime, Close
} from '@mui/icons-material';
import { alpha, ThemeProvider } from '@mui/material/styles';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getFormSubmissions } from '../services/firebase/submissionReader';
import { useUser } from '@clerk/clerk-react';
import SharedNavBar from '../components/SharedNavBar';
import { 
  removeSubmissionResetStatus, 
  removeAllFormResetStatus,
  recalculateFormStats,
  toggleSubmissionArchiveStatus,
  ensureArchiveField,
  resetIndividualSubmission
} from '../services/firebase/submissionWriter';
import { 
  resetFormSubmissionCounts, 
  deleteAllArchivedSubmissions
} from '../services/firebase/submissionDeleter';
import { format } from 'date-fns';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { createAppTheme } from '../theme/themeOptions';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';

// Interface for tab panel props
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Interface for submission records
interface SubmissionRecord {
  id?: string;
  email?: string;
  submittedAt?: any;
  archived?: boolean;
  archivedAt?: any;
  resetAt?: any;
  approvalRequired?: boolean; // New field for approval status
  approvalStatus?: 'approved' | 'rejected' | 'pending'; // New field for approval status
  [key: string]: any;
}

/**
 * Maps submission data from the API to the SubmissionRecord type 
 * ensuring the approvalStatus is properly typed
 */
const mapToSubmissionRecord = (submissions: any[]): SubmissionRecord[] => {
  return submissions.map(submission => {
    // Make sure approvalStatus is one of the allowed enum values
    let typedApprovalStatus: 'approved' | 'rejected' | 'pending' | undefined;
    
    if (submission.approvalStatus === 'approved') {
      typedApprovalStatus = 'approved';
    } else if (submission.approvalStatus === 'rejected') {
      typedApprovalStatus = 'rejected';
    } else if (submission.approvalStatus === 'pending') {
      typedApprovalStatus = 'pending';
    } else {
      typedApprovalStatus = undefined;
    }
    
    return {
      id: submission.id,
      email: submission.email,
      formTitle: submission.formTitle,
      submittedAt: submission.submittedAt,
      archived: submission.archived,
      archivedAt: submission.archivedAt,
      resetAt: submission.resetAt,
      formId: submission.formId,
      approvalRequired: submission.approvalRequired || false,
      approvalStatus: typedApprovalStatus,
      approvalComments: submission.approvalComments,
      approvalDate: submission.approvalDate,
      // Include any other fields you need
      responses: submission.responses,
      metadata: submission.metadata
    };
  });
};

// TabPanel can stay outside the component
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`submissions-tabpanel-${index}`}
      aria-labelledby={`submissions-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const SubmissionsHistory: React.FC = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();
  
  // Get proper theme from user preferences
  const { theme: themeColor } = useUserPreferences();
  const theme = createAppTheme(themeColor || 'Blue');
  
  const [formTitle, setFormTitle] = useState('');
  const [submissions, setSubmissions] = useState<SubmissionRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState<string | null>(null);
  
  // State variables for tabs and operations
  const [tabValue, setTabValue] = useState(0);
  const [archiveInProgress, setArchiveInProgress] = useState<string | null>(null);
  const [restoreInProgress, setRestoreInProgress] = useState<string | null>(null);
  const [restoreAllDialogOpen, setRestoreAllDialogOpen] = useState(false);
  const [recalculating, setRecalculating] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [resettingInProgress, setResettingInProgress] = useState<string | null>(null);
  
  // Add state for the delete archived dialog
  const [confirmDeleteArchived, setConfirmDeleteArchived] = useState(false);
  const [deletingArchived, setDeletingArchived] = useState(false);

  // State for submission details
  const [selectedSubmission, setSelectedSubmission] = useState<string | null>(null);
  const [showSubmissionDetail, setShowSubmissionDetail] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);

  // Tab change handler
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Compute active and archived submissions
  const activeSubmissions = useMemo(() => {
    return submissions.filter(sub => {
      const isArchived = sub.archived === true;
      return !isArchived;
    });
  }, [submissions]);

  const archivedSubmissions = useMemo(() => {
    return submissions.filter(sub => sub.archived === true);
  }, [submissions]);

  // Format date helper function
  const formatDate = (dateValue: any): string => {
    try {
      if (!dateValue) return 'No date';
      
      // Handle Firestore timestamp
      if (typeof dateValue === 'object') {
        // Firestore timestamp with toDate method
        if (typeof dateValue.toDate === 'function') {
          return format(dateValue.toDate(), 'MMM d, yyyy HH:mm');
        }
        
        // Firestore timestamp with seconds
        if (dateValue.seconds !== undefined) {
          return format(new Date(dateValue.seconds * 1000), 'MMM d, yyyy HH:mm');
        }
      }
      
      // Handle string or Date object
      return format(new Date(dateValue), 'MMM d, yyyy HH:mm');
    } catch (error) {
      return 'Invalid date';
    }
  };
  
  // Handle submission detail view
  const handleViewSubmissionDetails = async (submissionId: string) => {
    if (!formId) return;
    
    try {
      // Find the submission in our local state
      const submission = submissions.find(sub => sub.id === submissionId);
      if (!submission) return;
      
      // Set the selected submission
      setSubmissionDetails(submission);
      setSelectedSubmission(submissionId);
      setShowSubmissionDetail(true);
    } catch (error) {
      console.error("Error fetching submission details:", error);
      alert("Failed to load submission details");
    }
  };

  const handleCloseSubmissionDetail = () => {
    setShowSubmissionDetail(false);
    setSelectedSubmission(null);
  };
  
  // Handle reset
  const handleReset = async () => {
    if (!formId) return;
    
    if (!confirmReset) {
      setConfirmReset(true);
      return;
    }
    
    try {
      await resetFormSubmissionCounts(formId);
      alert(`Response count for "${formTitle}" has been reset successfully.`);
      
      // Go back to the home page after resetting
      navigate('/');
    } catch (error) {
      console.error("Error resetting response count:", error);
      alert("Failed to reset response count. Please try again.");
    } finally {
      setConfirmReset(false);
    }
  };

  // Add handler for deleting archived submissions
  const handleDeleteAllArchived = async () => {
    if (!formId) return;
    
    if (!confirmDeleteArchived) {
      setConfirmDeleteArchived(true);
      return;
    }
    
    try {
      setDeletingArchived(true);
      
      const count = await deleteAllArchivedSubmissions(formId);
      
      // Refresh the submissions list
      const updatedSubmissions = await getFormSubmissions(formId);
      setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      
      setConfirmDeleteArchived(false);
      alert(`Successfully deleted ${count} archived submissions.`);
      
      // If we're on the archived tab and there are no more archived submissions,
      // switch to the active tab
      if (tabValue === 1 && archivedSubmissions.length === 0) {
        setTabValue(0);
      }
    } catch (error) {
      console.error("Error deleting archived submissions:", error);
      alert("Failed to delete archived submissions. Please try again.");
    } finally {
      setDeletingArchived(false);
      setConfirmDeleteArchived(false);
    }
  };

  // Handle archive status change
  const handleArchiveToggle = async (submissionId: string) => {
    if (!submissionId || archiveInProgress === submissionId) return;
    
    setArchiveInProgress(submissionId);
    try {
      const success = await toggleSubmissionArchiveStatus(submissionId);
      
      if (success) {
        // Update local state
        setSubmissions(prevSubmissions => 
          prevSubmissions.map(sub => {
            if (sub.id === submissionId) {
              const isNowArchived = !sub.archived;
              return {
                ...sub,
                archived: isNowArchived,
                archivedAt: isNowArchived ? new Date() : null
              };
            }
            return sub;
          })
        );
        
        // Refresh the submissions list
        const updatedSubmissions = await getFormSubmissions(formId || '');
        setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      }
    } catch (error) {
      console.error("Error toggling archive status:", error);
      alert("Failed to update archive status. Please try again.");
    } finally {
      setArchiveInProgress(null);
    }
  };
  
  // Handle remove reset status
  const handleRemoveResetStatus = async (submissionId: string) => {
    if (!submissionId || restoreInProgress === submissionId) return;
    
    setRestoreInProgress(submissionId);
    try {
      const success = await removeSubmissionResetStatus(submissionId);
      
      if (success) {
        // Update local state
        setSubmissions(prev => 
          prev.map(sub => 
            sub.id === submissionId 
              ? { ...sub, resetAt: null } 
              : sub
          )
        );
        
        // Refresh data by refetching submissions
        const updatedSubmissions = await getFormSubmissions(formId || '');
        setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      }
    } catch (error) {
      console.error("Error removing reset status:", error);
      alert("Failed to restore this submission. Please try again.");
    } finally {
      setRestoreInProgress(null);
    }
  };

  // Handle restore all reset submissions
  const handleRestoreAllResetSubmissions = async () => {
    try {
      const count = await removeAllFormResetStatus(formId || '');
      setRestoreAllDialogOpen(false);
      
      // Refresh the submissions list
      const updatedSubmissions = await getFormSubmissions(formId || '');
      setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      
      alert(`Successfully restored ${count} submissions to be counted.`);
    } catch (error) {
      console.error("Error restoring all submissions:", error);
      alert("Failed to restore submissions. Please try again.");
    }
  };

  // Handle recalculate stats
  const handleRecalculateStats = async () => {
    if (recalculating) return;
    
    setRecalculating(true);
    try {
      const updatedCount = await recalculateFormStats(formId || '');
      alert(`Form statistics recalculated successfully. Active responses: ${updatedCount}`);
      
      // Refresh the submissions list
      const updatedSubmissions = await getFormSubmissions(formId || '');
      setSubmissions(mapToSubmissionRecord(updatedSubmissions));
    } catch (error) {
      console.error("Error recalculating form statistics:", error);
      alert("Failed to recalculate form statistics. Please try again.");
    } finally {
      setRecalculating(false);
    }
  };

  // Handle fix archive fields
  const handleFixArchiveFields = async () => {
    const confirm = window.confirm("This will ensure all submissions have the archive field properly set. Continue?");
    if (!confirm) return;
    
    try {
      await ensureArchiveField(formId || '');
      
      // Refresh the submissions list
      const updatedSubmissions = await getFormSubmissions(formId || '');
      setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      
      alert("Archive fields updated successfully.");
    } catch (error) {
      console.error("Error fixing archive fields:", error);
      alert("An error occurred while updating archive fields.");
    }
  };
  
  // Handle reset individual submission
  const handleResetSubmission = async (submissionId: string) => {
    if (!submissionId || resettingInProgress === submissionId) return;
    
    const confirmed = window.confirm("Are you sure you want to remove this submission from the count? This submission will no longer be counted in statistics but will remain in the system.");
    if (!confirmed) return;
    
    setResettingInProgress(submissionId);
    try {
      const success = await resetIndividualSubmission(submissionId);
      
      if (success) {
        // Update local state
        setSubmissions(prev => 
          prev.map(sub => 
            sub.id === submissionId 
              ? { ...sub, resetAt: new Date() } 
              : sub
          )
        );
        
        // Refresh data by refetching submissions
        const updatedSubmissions = await getFormSubmissions(formId || '');
        setSubmissions(mapToSubmissionRecord(updatedSubmissions));
      }
    } catch (error) {
      console.error("Error resetting submission:", error);
      alert("Failed to reset this submission. Please try again.");
    } finally {
      setResettingInProgress(null);
    }
  };

  // Fetch user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user) {
        setUserRole(null);
        return;
      }
      
      try {
        const userDocRef = doc(db, "users", user.id);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          setUserRole(userDocSnap.data().role || null);
        } else {
          setUserRole(null);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole(null);
      }
    };
    
    fetchUserRole();
  }, [user]);

  // Fetch form data and submissions
  useEffect(() => {
    const loadData = async () => {
      if (!formId) {
        navigate('/');
        return;
      }
      
      try {
        setLoading(true);
        
        // Fetch form details
        const formDoc = await getDoc(doc(db, "forms", formId));
        if (!formDoc.exists()) {
          // Handle form not found
          navigate('/');
          return;
        }
        
        const formData = formDoc.data();
        setFormTitle(formData.title || formData.formTitle || 'Untitled Form');
        setQuestions(formData.questions || []);
        
        // Fetch submissions
        const submissionsData = await getFormSubmissions(formId);
        setSubmissions(mapToSubmissionRecord(submissionsData));
      } catch (error) {
        console.error("Error loading form data:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, [formId, navigate]);
  
  // Update the renderSubmissionList function for better mobile layout
  const renderSubmissionList = (submissions: SubmissionRecord[]) => {
    if (submissions.length === 0) {
      // Empty state remains the same
      return (
        <Box sx={{ py: 6, textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary">
            {tabValue === 0 
              ? "No active submissions for this form." 
              : "No archived submissions for this form."}
          </Typography>
        </Box>
      );
    }
    
    return (
      <Paper 
        variant="outlined" 
        sx={{ 
          overflow: 'hidden',
          borderRadius: '12px'
        }}
      >
        <List disablePadding>
          {submissions.map((submission, index) => (
            <React.Fragment key={submission.id || index}>
              {index > 0 && <Divider />}
              <ListItem
                disablePadding
                sx={{ 
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  p: { xs: 2, sm: 2 },
                  backgroundColor: submission.resetAt 
                    ? alpha('#f5f5f5', 0.7)
                    : 'inherit',
                  cursor: 'pointer', 
                  '&:hover': { 
                    backgroundColor: alpha(theme.palette.primary.main, 0.03)
                  }
                }}
                onClick={(e) => {
                  // Only trigger if not clicking a button
                  if (!(e.target as HTMLElement).closest('button')) {
                    handleViewSubmissionDetails(submission.id!);
                  }
                }}
              >
                {/* Main content - optimized for mobile */}
                <Box sx={{ 
                  width: '100%', 
                  pr: { xs: 0, sm: 10 },
                  mb: { xs: 2, sm: 0 } // Add bottom margin on mobile
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 1,
                    flexWrap: { xs: 'wrap', sm: 'nowrap' } // Wrap on small screens
                  }}>
                    <Email color="primary" fontSize="small" sx={{ mr: 1, mt: { xs: '2px', sm: 0 } }} />
                    <Typography 
                      variant="body1"
                      fontWeight="medium"
                      sx={{ 
                        wordBreak: 'break-word',
                        width: { xs: 'calc(100% - 30px)', sm: 'auto' }
                      }}
                    >
                      {submission.email || 'No email provided'}
                    </Typography>
                    
                    {/* Add approval status chip - NEW CODE */}
                    {submission.approvalRequired && (
                      <Chip 
                        label={
                          submission.approvalStatus === 'approved' ? 'Approved' :
                          submission.approvalStatus === 'rejected' ? 'Rejected' : 'Pending Approval'
                        }
                        size="small" 
                        color={
                          submission.approvalStatus === 'approved' ? 'success' :
                          submission.approvalStatus === 'rejected' ? 'error' : 'warning'
                        }
                        sx={{ 
                          ml: { xs: 0, sm: 1 }, 
                          mt: { xs: 1, sm: 0 },
                          height: 20, 
                          fontSize: '0.7rem',
                          cursor: 'pointer'
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/approval-manager');
                        }}
                      />
                    )}
                    
                    {/* Existing reset chip */}
                    {submission.resetAt && (
                      <Chip 
                        label="Reset" 
                        size="small" 
                        variant="outlined"
                        color="default"
                        sx={{ 
                          ml: { xs: 0, sm: 1 }, 
                          mt: { xs: 1, sm: 0 },
                          height: 20, 
                          fontSize: '0.7rem' 
                        }}
                      />
                    )}
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 0.5, sm: 2 }, 
                    ml: 0.5, 
                    color: 'text.secondary' 
                  }}>
                    <Typography variant="body2" component="span">
                      Submitted: {formatDate(submission.submittedAt)}
                    </Typography>
                    
                    {submission.archivedAt && (
                      <Typography variant="body2" component="span">
                        Archived: {formatDate(submission.archivedAt)}
                      </Typography>
                    )}
                    
                    {submission.resetAt && (
                      <Typography variant="body2" component="span">
                        Reset: {formatDate(submission.resetAt)}
                      </Typography>
                    )}
                  </Box>
                </Box>
                
                {/* Action buttons - better mobile layout */}
                <Box 
                  sx={{ 
                    display: 'flex',
                    width: '100%',
                    justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                    alignSelf: 'flex-end',
                    position: { xs: 'relative', sm: 'absolute' },
                    right: { sm: 16 },
                    gap: 1
                  }}
                >
                  {/* Reset button (only show if not already reset) */}
                  {!submission.resetAt && (
                    <Tooltip title="Remove this submission from count">
                      <IconButton 
                        color="error"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResetSubmission(submission.id!);
                        }}
                        disabled={resettingInProgress === submission.id}
                        sx={{ p: { xs: '4px', sm: '8px' } }} // Smaller padding on mobile
                      >
                        {resettingInProgress === submission.id ? (
                          <CircularProgress size={18} />
                        ) : (
                          <Delete fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* Restore button for reset submissions */}
                  {userRole === "Admin" && submission.resetAt && (
                    <Tooltip title="Restore this submission (remove reset status)">
                      <IconButton 
                        color="warning"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveResetStatus(submission.id!);
                        }}
                        disabled={restoreInProgress === submission.id}
                        sx={{ p: { xs: '4px', sm: '8px' } }}
                      >
                        {restoreInProgress === submission.id ? (
                          <CircularProgress size={18} />
                        ) : (
                          <RestoreFromTrash fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  
                  {/* Archive/Unarchive button */}
                  <Tooltip title={submission.archived ? "Unarchive" : "Archive"}>
                    <IconButton 
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleArchiveToggle(submission.id!);
                      }}
                      disabled={archiveInProgress === submission.id}
                      sx={{ p: { xs: '4px', sm: '8px' } }}
                    >
                      {archiveInProgress === submission.id ? (
                        <CircularProgress size={18} />
                      ) : submission.archived ? (
                        <Unarchive fontSize="small" />
                      ) : (
                        <Archive fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar />
        <Container maxWidth="lg" sx={{ mt: 2, px: { xs: 2, sm: 3 } }}>
          {/* Update the header with back button section */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: { xs: 'flex-start', sm: 'center' }, 
            mb: 3,
            flexDirection: { xs: 'column', sm: 'row' }
          }}>
            <IconButton onClick={() => navigate(-1)} sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}>
              <KeyboardBackspace />
            </IconButton>
            <Typography 
              variant="h4" 
              fontWeight="bold"
              sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }} // Smaller font on mobile
            >
              {formTitle || 'Untitled Form'}
            </Typography>
          </Box>
          
          {/* Stats summary box - improved for mobile */}
          <Box sx={{ 
            p: { xs: 2, sm: 3 }, 
            mb: 3, 
            borderRadius: 2, 
            backgroundColor: alpha(theme.palette.background.paper, 0.8), 
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
          }}>
            <Typography variant="h6" gutterBottom>Submission Statistics</Typography>
            <Grid container spacing={2}> {/* Reduced spacing on mobile */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  textAlign: 'center', 
                  p: { xs: 1.5, sm: 2 }, 
                  borderRadius: 1, 
                  bgcolor: alpha(theme.palette.primary.main, 0.1) 
                }}>
                  <Typography variant="h5" color="primary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    {submissions.length}
                  </Typography>
                  <Typography variant="body2">Total Submissions</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  textAlign: 'center', 
                  p: { xs: 1.5, sm: 2 }, 
                  borderRadius: 1, 
                  bgcolor: alpha(theme.palette.success.main, 0.1) 
                }}>
                  <Typography variant="h5" color="success.main" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    {activeSubmissions.length}
                  </Typography>
                  <Typography variant="body2">Active Submissions</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  textAlign: 'center', 
                  p: { xs: 1.5, sm: 2 }, 
                  borderRadius: 1, 
                  bgcolor: alpha(theme.palette.text.secondary, 0.1) 
                }}>
                  <Typography variant="h5" color="text.secondary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    {submissions.filter(sub => sub.resetAt).length}
                  </Typography>
                  <Typography variant="body2">Reset Submissions</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Admin Actions Toolbar with improved mobile layout */}
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: { xs: 1, sm: 2 }, // Smaller gap on mobile
            mb: 3
          }}>
            {/* Regular user actions */}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleRecalculateStats}
              disabled={recalculating}
              size="small" // Use small buttons on all devices for better mobile display
            >
              {recalculating ? 'Recalculating...' : 'Recalculate'}
            </Button>

            {/* Admin-only buttons with responsive layout */}
            {userRole === "Admin" && (
              <>
                {/* These buttons are always visible for admins */}
                {submissions.some(sub => sub.resetAt) && (
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<RestoreFromTrash />}
                    onClick={() => setRestoreAllDialogOpen(true)}
                    size="small"
                  >
                    Restore All Reset
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<Build />}
                  onClick={handleFixArchiveFields}
                  size="small"
                >
                  Fix Archive Fields
                </Button>
                <Button 
                  variant="outlined" 
                  color="error" 
                  startIcon={<Delete />}
                  onClick={handleReset}
                  size="small"
                >
                  Reset Count
                </Button>
                
                {/* This button only shows when viewing archived submissions */}
                {tabValue === 1 && archivedSubmissions.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => setConfirmDeleteArchived(true)}
                    disabled={deletingArchived}
                    size="small"
                    sx={{ mt: { xs: 1, sm: 0 } }} // Add top margin on mobile
                    fullWidth={window.innerWidth < 600} // Full width on very small screens
                  >
                    {deletingArchived ? 'Deleting...' : 'Delete All Archived'}
                  </Button>
                )}
              </>
            )}
          </Box>

          {/* Tabs for Active/Archived with better mobile labels */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              indicatorColor="primary" 
              textColor="primary"
              variant={window.innerWidth < 600 ? "fullWidth" : "standard"} // Full width tabs on mobile
            >
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Visibility sx={{ fontSize: 18, mr: 0.5 }} />
                    <Typography 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        fontSize: { xs: '0.75rem', sm: '0.875rem' } 
                      }}
                    >
                      Active ({activeSubmissions.length})
                    </Typography>
                  </Box>
                }
              />
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <VisibilityOff sx={{ fontSize: 18, mr: 0.5 }} />
                    <Typography 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        fontSize: { xs: '0.75rem', sm: '0.875rem' } 
                      }}
                    >
                      Archived ({archivedSubmissions.length})
                    </Typography>
                  </Box>
                }
              />
            </Tabs>
          </Box>

          {/* Tab content */}
          <TabPanel value={tabValue} index={0}>
            {loading ? (
              <Box sx={{ py: 6, textAlign: 'center' }}>
                <CircularProgress size={40} />
              </Box>
            ) : (
              renderSubmissionList(activeSubmissions)
            )}
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            {loading ? (
              <Box sx={{ py: 6, textAlign: 'center' }}>
                <CircularProgress size={40} />
              </Box>
            ) : (
              renderSubmissionList(archivedSubmissions)
            )}
          </TabPanel>

          {/* Confirmation Dialogs */}
          
          {/* Reset Confirmation Dialog */}
          <Dialog
            open={confirmReset}
            onClose={() => setConfirmReset(false)}
            PaperProps={{
              sx: { borderRadius: '12px', maxWidth: 500 }
            }}
          >
            <DialogTitle sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: 1
            }}>
              <Typography variant="h6" fontWeight="600">Reset Response Count</Typography>
              <IconButton 
                onClick={() => setConfirmReset(false)} 
                size="small" 
                aria-label="back"
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ py: 2 }}>
                {`Do you want to reset the response count for "${formTitle}"? This action will not delete submissions, but will reset the count to zero on the dashboard.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={() => setConfirmReset(false)} 
                variant="outlined"
              >
                Cancel
              </Button>
              <Button 
                onClick={handleReset} 
                color="error" 
                variant="contained"
                startIcon={<Delete />}
              >
                Reset All Submissions
              </Button>
            </DialogActions>
          </Dialog>

          {/* Restore All Dialog */}
          <Dialog
            open={restoreAllDialogOpen}
            onClose={() => setRestoreAllDialogOpen(false)}
            PaperProps={{
              sx: { borderRadius: '12px', maxWidth: 450 }
            }}
          >
            <DialogTitle>Restore All Reset Submissions</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`This will restore all reset submissions for "${formTitle}" to be counted. This action cannot be undone.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRestoreAllDialogOpen(false)} variant="outlined">
                Cancel
              </Button>
              <Button 
                onClick={handleRestoreAllResetSubmissions} 
                color="success" 
                variant="contained"
                startIcon={<RestoreFromTrash />}
              >
                Restore All
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* Delete Archived Confirmation Dialog */}
          <Dialog
            open={confirmDeleteArchived}
            onClose={() => setConfirmDeleteArchived(false)}
            PaperProps={{
              sx: { borderRadius: '12px', maxWidth: 500 }
            }}
          >
            <DialogTitle sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: 1
            }}>
              <Typography variant="h6" fontWeight="600">Delete All Archived Submissions</Typography>
              <IconButton 
                onClick={() => setConfirmDeleteArchived(false)} 
                size="small" 
                aria-label="close"
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ py: 2 }}>
                <Typography color="error.main" variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                  WARNING: This action cannot be undone!
                </Typography>
                {`You are about to permanently delete all ${archivedSubmissions.length} archived submissions for "${formTitle}". This will completely remove the data from the database and cannot be recovered.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={() => setConfirmDeleteArchived(false)} 
                variant="outlined"
              >
                Cancel
              </Button>
              <Button 
                onClick={handleDeleteAllArchived} 
                color="error" 
                variant="contained"
                startIcon={<Delete />}
                disabled={deletingArchived}
              >
                {deletingArchived ? 'Deleting...' : 'Delete All Archived'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Submission Detail Dialog */}
          <Dialog
            open={showSubmissionDetail}
            onClose={handleCloseSubmissionDetail}
            maxWidth="lg"
            fullWidth
            PaperProps={{
              sx: { 
                borderRadius: { xs: '8px', sm: '12px' }, 
                height: { xs: '95vh', sm: '90vh' },
                width: { xs: '95%', sm: 'auto' },
                margin: { xs: '8px', sm: 'auto' }
              }
            }}
          >
            <DialogTitle sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              pb: 2,
              pt: { xs: 1.5, sm: 2 },
              px: { xs: 1.5, sm: 2.5 }
            }}>
              <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Submission Details</Typography>
              <IconButton onClick={handleCloseSubmissionDetail} size="small">
                <Close fontSize="small" />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              {submissionDetails && (
                <SubmissionDetail
                  submission={submissionDetails}
                  questions={questions}
                  formId={formId || ''}
                  formTitle={formTitle}
                  theme={theme}
                  onArchiveChange={() => {
                    // Refresh submissions after archive change
                    handleCloseSubmissionDetail();
                    const getSubmissions = async () => {
                      if (formId) {
                        const updatedSubmissions = await getFormSubmissions(formId);
                        setSubmissions(mapToSubmissionRecord(updatedSubmissions));
                      }
                    };
                    getSubmissions();
                  }}
                />
              )}
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default SubmissionsHistory;
