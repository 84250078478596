import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem, TextField, Typography, Box } from '@mui/material';
import Question from './Question';

interface SelectQuestionProps {
  question: any;
  value: string;
  onChange: (value: string) => void;
  onFollowUpChange?: (fieldId: string, value: string) => void;
  followUpValues?: Record<string, string>;
}

// Simple debugging wrapper component
const DebugWrapper: React.FC<{id: string, value: string, children: React.ReactNode}> = ({
  id, value, children
}) => (
  <div className="debug-wrapper" data-question-id={id} data-value={value}>
    {children}
  </div>
);

const SelectQuestion: React.FC<SelectQuestionProps> = ({ 
  question, 
  value, 
  onChange,
  onFollowUpChange,
  followUpValues = {}
}) => {
  // Keep track of renders for debugging
  const [renderCount, setRenderCount] = useState(0);
  
  // Always use local state for safer rendering
  const [selectedValue, setSelectedValue] = useState(value || "");
  
  // Log essential info
  console.log(`SelectQuestion rendering [${renderCount}]:`, {
    questionId: question.id,
    value: value,
    localValue: selectedValue
  });
  
  // Update local state when prop changes
  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);
  
  // Increment render counter on each render
  useEffect(() => {
    setRenderCount(prev => prev + 1);
  });

  // Handle change event
  const handleSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    console.log(`SelectQuestion ${question.id} changing from ${selectedValue} to ${newValue}`);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <Question question={question}>
      <DebugWrapper id={question.id} value={selectedValue}>
        <FormControl fullWidth variant="outlined">
          <Select
            key={`select-${question.id}`}
            value={selectedValue}
            onChange={handleSelectionChange as any}
            displayEmpty
          >
            <MenuItem value="" disabled>
              <Typography variant="body2" color="text.secondary">
                Select an option
              </Typography>
            </MenuItem>
            {(question.options || []).map((option: any, index: number) => (
              <MenuItem 
                key={`${question.id}-option-${index}`} 
                value={typeof option === 'object' ? option.value : option}
              >
                {typeof option === 'object' ? option.label : option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Follow-up section */}
        {selectedValue && (
          <Box sx={{ mt: 3 }}>
            {/* Option 1: New format - Follow-ups array */}
            {question.followUps && question.followUps
              .filter((followUp: any) => followUp.triggerOption === selectedValue)
              .map((followUp: any, index: number) => (
                <Box 
                  key={`${question.id}-followup-${index}`} 
                  sx={{ pl: 2, borderLeft: '2px solid', borderColor: 'primary.light', mt: 2 }}
                >
                  <Typography variant="body2" fontWeight="medium" mb={1}>
                    {followUp.question}
                  </Typography>
                  <TextField 
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    value={followUpValues[`${question.id}-followup-${followUp.triggerOption}`] || ""}
                    onChange={(e) => onFollowUpChange && onFollowUpChange(
                      `${question.id}-followup-${followUp.triggerOption}`, 
                      e.target.value
                    )}
                    required={followUp.required}
                  />
                </Box>
              ))
            }

            {/* Option 2: Map-based conditional follow-ups */}
            {question.conditionalFollowUps && question.conditionalFollowUps[selectedValue] && (
              <Box sx={{ pl: 2, borderLeft: '2px solid', borderColor: 'primary.light', mt: 2 }}>
                <Typography variant="body2" fontWeight="medium" mb={1}>
                  {question.conditionalFollowUps[selectedValue].question}
                </Typography>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}-followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(
                    `${question.id}-followup`, 
                    e.target.value
                  )}
                  required={question.conditionalFollowUps[selectedValue].required}
                />
              </Box>
            )}

            {/* Option 3: Classic follow-up format */}
            {selectedValue === question.followUpTriggerOption && question.followUp?.question && (
              <Box sx={{ pl: 2, borderLeft: '2px solid', borderColor: 'primary.light', mt: 2 }}>
                <Typography variant="body2" fontWeight="medium" mb={1}>
                  {question.followUp.question}
                </Typography>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}-followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(
                    `${question.id}-followup`, 
                    e.target.value
                  )}
                  required={question.followUp?.required}
                />
              </Box>
            )}

            {/* Option 4: hasFollowUp property */}
            {question.options && question.options.some((o: any) => 
              (typeof o === 'object' && 
               (o.value === selectedValue || o === selectedValue) && 
               o.hasFollowUp)
            ) && (
              <Box sx={{ pl: 2, borderLeft: '2px solid', borderColor: 'primary.light', mt: 2 }}>
                <Typography variant="body2" fontWeight="medium" mb={1}>
                  Please provide more details:
                </Typography>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}-followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(
                    `${question.id}-followup`, 
                    e.target.value
                  )}
                  required
                />
              </Box>
            )}
          </Box>
        )}
      </DebugWrapper>
    </Question>
  );
};

export default SelectQuestion;