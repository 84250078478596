import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { useUser, useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { 
  AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem, IconButton, ListItemIcon,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, CircularProgress,
  ThemeProvider
} from "@mui/material";
import { People, ExitToApp, AccountCircle, Edit } from "@mui/icons-material";
import SharedNavBar from '../components/SharedNavBar';
import { createAppTheme } from "../theme/themeOptions";

const roles = ["User", "Editor", "Manager", "Admin"];

const UserManagement: React.FC = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const navigate = useNavigate();
  const [users, setUsers] = useState<{ id: string; email: string; role: string; firstName?: string; lastName?: string; imageUrl?: string }[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  // Profile editing states
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<any>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  // Add state for theme
  const [adminTheme, setAdminTheme] = useState<string>("Blue");

  useEffect(() => {
    if (!user) return;

    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const userList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...(doc.data() as any) }));
      
      // Fetch additional user data from Clerk
      try {
        // In a real implementation, you would use Clerk's Admin API here
        // This is a simplified version showing the concept
        setUsers(userList);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUsers(userList);
      }
    };

    fetchUsers();
  }, [user]);

  // Add effect to load admin's theme
  useEffect(() => {
    const loadAdminTheme = async () => {
      if (!user) return;
      
      try {
        const userDoc = await getDoc(doc(db, "users", user.id));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const themeName = userData.theme || "Blue";
          setAdminTheme(themeName);
        }
      } catch (error) {
        console.error("Error loading admin theme:", error);
      }
    };
    
    loadAdminTheme();
  }, [user]);

  const handleRoleChange = async (userId: string, newRole: string) => {
    await updateDoc(doc(db, "users", userId), { role: newRole });
    setUsers((prevUsers) => prevUsers.map((u) => (u.id === userId ? { ...u, role: newRole } : u)));
  };

  // ✅ Ensure only Admins can access this page
  const currentUser = users.find((u) => u.id === user?.id);
  if (!currentUser || currentUser.role !== "Admin") {
    return <Typography variant="h5" color="error">Access Denied</Typography>;
  }

  // ✅ Handle Menu Open/Close
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Profile editing functions
  const openProfileDialog = (user: any) => {
    setEditingUser(user);
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setImageUrl(user.imageUrl || "");
    setProfileDialogOpen(true);
    setUpdateSuccess(false);
  };

  const closeProfileDialog = () => {
    setProfileDialogOpen(false);
    setEditingUser(null);
  };

  const saveProfileChanges = async () => {
    if (!editingUser) return;
    
    setIsUpdating(true);
    try {
      // In a production app, you would use Clerk's Admin API to update user info
      // This requires server-side implementation with proper authentication
      // For demo purposes, we'll update our local state to simulate the change
      
      // Simulating API update delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update local state
      setUsers(prevUsers => prevUsers.map(u => 
        u.id === editingUser.id 
          ? { ...u, firstName, lastName, imageUrl } 
          : u
      ));
      
      setUpdateSuccess(true);
      
      // Close dialog after success
      setTimeout(() => {
        closeProfileDialog();
      }, 1500);
      
    } catch (error) {
      console.error("Error updating user profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  // Create theme using utility function
  const theme = createAppTheme(adminTheme);

  return (
    <ThemeProvider theme={theme}>
      <div className="max-w-5xl mx-auto">
        {/* ✅ Navigation Bar */}
        <SharedNavBar title="User Management" />

        {/* ✅ User Management Panel */}
        <div className="mt-6 p-6 bg-white shadow-lg rounded-lg">
          <Typography variant="h5" className="font-semibold mb-4">Manage User Roles</Typography>

          {/* ✅ Responsive Table */}
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-200 rounded-lg shadow-md">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border border-gray-300 px-4 py-2 text-left">User</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Email</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Role</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50 transition">
                    <td className="border border-gray-300 px-4 py-2">
                      <div className="flex items-center">
                        <Avatar 
                          src={user.imageUrl} 
                          alt={user.firstName || "User"} 
                          sx={{ width: 32, height: 32, marginRight: 1 }}
                        >
                          {(user.firstName?.[0] || "U")}
                        </Avatar>
                        <span>{user.firstName ? `${user.firstName} ${user.lastName || ""}` : "User"}</span>
                      </div>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{user.email}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      <select
                        className="border border-gray-300 rounded px-2 py-1"
                        value={user.role}
                        onChange={(e) => handleRoleChange(user.id, e.target.value)}
                      >
                        {roles.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <IconButton 
                        size="small" 
                        color="primary" 
                        onClick={() => openProfileDialog(user)}
                        title="Edit profile"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Profile Editing Dialog */}
        <Dialog open={profileDialogOpen} onClose={closeProfileDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            Edit User Profile
            {editingUser && <Typography variant="body2" color="text.secondary">{editingUser.email}</Typography>}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                variant="outlined"
                disabled={isUpdating}
              />
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                variant="outlined"
                disabled={isUpdating}
              />
              <TextField
                label="Profile Image URL"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                fullWidth
                variant="outlined"
                helperText="Enter a valid image URL"
                disabled={isUpdating}
              />
              
              {imageUrl && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Avatar 
                    src={imageUrl}
                    alt="Profile Preview"
                    sx={{ width: 100, height: 100 }}
                  >
                    {firstName?.[0] || "U"}
                  </Avatar>
                </Box>
              )}
              
              {updateSuccess && (
                <Box sx={{ 
                  bgcolor: 'success.light', 
                  color: 'success.contrastText',
                  p: 2,
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  Profile updated successfully!
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeProfileDialog} disabled={isUpdating}>
              Cancel
            </Button>
            <Button 
              onClick={saveProfileChanges} 
              color="primary" 
              variant="contained"
              disabled={isUpdating}
              startIcon={isUpdating ? <CircularProgress size={20} /> : null}
            >
              {isUpdating ? "Saving..." : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default UserManagement;
