import React from "react";
import { Box, Button, Typography, TextField, Tooltip, Alert } from "@mui/material";
import { SaveOutlined, HomeOutlined, PreviewOutlined, EditOutlined } from '@mui/icons-material';

interface FormActionsProps {
  isEditing: boolean;
  formId?: string;
  formSlug?: string;
  companySlug?: string | null;
  onSave: () => void;
  onSaveAndReturn: () => void;
  onSaveAndGoToLanding: () => void;
  onCopyUrl: () => void;
  hasSelectedFormsInLanding: boolean;
  requiresApproval?: boolean;
}

const FormActions: React.FC<FormActionsProps> = ({
  isEditing,
  formId,
  formSlug,
  companySlug,
  onSave,
  onSaveAndReturn,
  onSaveAndGoToLanding,
  onCopyUrl,
  hasSelectedFormsInLanding,
  requiresApproval
}) => {
  // Determine button text and icon based on context
  const landingPageButton = {
    text: hasSelectedFormsInLanding 
      ? "Save & View Landing Page" 
      : "Save & Setup Landing Page",
    icon: hasSelectedFormsInLanding ? <PreviewOutlined /> : <EditOutlined />,
    tooltip: hasSelectedFormsInLanding 
      ? "Save form and go to your landing page" 
      : "Save form and go to landing page builder to add it"
  };

  // Determine the form URL based on available slugs
  const getFormUrl = () => {
    const baseUrl = window.location.origin;
    
    // Always use the most current slugs if available
    if (companySlug && formSlug) {
      return `${baseUrl}/${companySlug}/${formSlug}`;
    } else {
      return `${baseUrl}/fill/${formId}`;
    }
  };

  return (
    <>
      {/* Add approval info alert if enabled */}
      {requiresApproval && (
        <Box mb={4}>
          <Alert severity="info">
            <Typography variant="subtitle2">Approval Required</Typography>
            <Typography variant="body2">
              You've enabled approval for this form. Form submissions will need your review before they're considered complete.
            </Typography>
          </Alert>
        </Box>
      )}
      
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap', mt: 6 }}>
        <Button 
          variant="contained" 
          color="primary" 
          size="large"
          onClick={onSave}
          startIcon={<SaveOutlined />}
        >
          {isEditing ? "Update Form" : "Save Form"}
        </Button>
        
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onSaveAndReturn}
          startIcon={<HomeOutlined />}
        >
          Save & Return Home
        </Button>
        
        <Tooltip title={landingPageButton.tooltip}>
          <span>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={onSaveAndGoToLanding}
              startIcon={landingPageButton.icon}
            >
              {landingPageButton.text}
            </Button>
          </span>
        </Tooltip>
      </Box>

      {formId && (
        <Box mt={4} p={3} border={1} borderColor="divider" borderRadius={1}>
          <Typography variant="subtitle1" gutterBottom>
            Share this form with others:
          </Typography>
          
          <Box display="flex" alignItems="center" mt={1}>
            <TextField
              fullWidth
              variant="outlined"
              value={getFormUrl()}
              InputProps={{
                readOnly: true,
              }}
            />
            
            <Button
              variant="contained"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={onCopyUrl}
            >
              Copy URL
            </Button>
          </Box>
          
          {companySlug && formSlug && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Using your custom URL format. Any changes to your company name in your profile will update this URL.
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default FormActions;