import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  IconButton,
  Switch,
  Divider
} from "@mui/material";
import { ExpandMore, Add, Delete } from "@mui/icons-material";

interface LikertQuestionProps {
  label: string;
  description?: string;
  onChange: (updates: any) => void;
  config?: {
    scaleSize?: number;
    lowLabel?: string;
    highLabel?: string;
    showNumbers?: boolean;
    scaleType?: 'agreement' | 'satisfaction' | 'frequency' | 'importance' | 'custom';
    conditionalFollowUps?: {
      [rating: string]: {
        question: string;
        required: boolean;
      };
    };
    allowComment?: boolean;
    commentLabel?: string;
    commentRequired?: boolean;
  };
}

const predefinedScales = {
  agreement: {
    lowLabel: 'Strongly Disagree',
    highLabel: 'Strongly Agree',
  },
  satisfaction: {
    lowLabel: 'Very Dissatisfied',
    highLabel: 'Very Satisfied',
  },
  frequency: {
    lowLabel: 'Never',
    highLabel: 'Always',
  },
  importance: {
    lowLabel: 'Not at all Important',
    highLabel: 'Extremely Important',
  }
};

const LikertQuestion: React.FC<LikertQuestionProps> = ({
  label,
  description,
  onChange,
  config = {}
}) => {
  const {
    scaleSize = 5,
    lowLabel = 'Strongly Disagree',
    highLabel = 'Strongly Agree',
    showNumbers = true,
    scaleType = 'agreement',
    conditionalFollowUps = {},
    allowComment = false,
    commentLabel = "",
    commentRequired = false
  } = config;

  // Add a state to track which rating is selected for follow-up
  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  const handleScaleTypeChange = (e: any) => {
    // Explicitly type the value as a string first
    const newScaleType = e.target.value;
    
    const updatedConfig = {
      ...config,
      scaleType: newScaleType
    };

    // Check if it's a key in the predefined scales
    if (newScaleType !== 'custom' && newScaleType in predefinedScales) {
      const scale = predefinedScales[newScaleType as keyof typeof predefinedScales];
      updatedConfig.lowLabel = scale.lowLabel;
      updatedConfig.highLabel = scale.highLabel;
    }

    // Update the config
    onChange({ config: updatedConfig });
  };

  // Add follow-up question for a specific rating
  const addFollowUpQuestion = (rating: string) => {
    const currentFollowUps = config.conditionalFollowUps || {};
    
    const updatedConfig = {
      ...config,
      conditionalFollowUps: {
        ...currentFollowUps,
        [rating]: {
          question: "",
          required: false
        }
      }
    };
    
    onChange({ config: updatedConfig });
  };

  // Update follow-up question text
  const updateFollowUpQuestion = (rating: string, questionText: string) => {
    const currentFollowUps = config.conditionalFollowUps || {};
    
    const updatedConfig = {
      ...config,
      conditionalFollowUps: {
        ...currentFollowUps,
        [rating]: {
          ...currentFollowUps[rating],
          question: questionText
        }
      }
    };
    
    onChange({ config: updatedConfig });
  };

  // Update follow-up required status
  const updateFollowUpRequired = (rating: string, required: boolean) => {
    const currentFollowUps = config.conditionalFollowUps || {};
    
    const updatedConfig = {
      ...config,
      conditionalFollowUps: {
        ...currentFollowUps,
        [rating]: {
          ...currentFollowUps[rating],
          required
        }
      }
    };
    
    onChange({ config: updatedConfig });
  };

  // Remove follow-up question
  const removeFollowUpQuestion = (rating: string) => {
    const currentFollowUps = { ...(config.conditionalFollowUps || {}) };
    delete currentFollowUps[rating];
    
    const updatedConfig = {
      ...config,
      conditionalFollowUps: Object.keys(currentFollowUps).length > 0 ? currentFollowUps : {}
    };
    
    onChange({ config: updatedConfig });
    
    if (selectedRating === rating) {
      setSelectedRating(null);
    }
  };

  // Get ratings with follow-up questions
  const ratingsWithFollowUps = Object.keys(config.conditionalFollowUps || {});

  return (
    <div>
      <Box mb={3}>
        <TextField
          fullWidth
          label="Question Text"
          variant="outlined"
          value={label}
          onChange={(e) => onChange({ label: e.target.value })}
          placeholder="Enter your question here"
        />
      </Box>

      <Box mb={3}>
        <TextField
          fullWidth
          label="Description (optional)"
          variant="outlined"
          value={description || ''}
          onChange={(e) => onChange({ description: e.target.value })}
          placeholder="Add a description to provide more context (optional)"
          multiline
          rows={2}
        />
      </Box>

      <Box mb={4}>
        <Typography variant="subtitle1" gutterBottom>
          Scale Configuration
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Scale Type</InputLabel>
              <Select
                value={scaleType}
                onChange={handleScaleTypeChange}
                label="Scale Type"
              >
                <MenuItem value="agreement">Agreement</MenuItem>
                <MenuItem value="satisfaction">Satisfaction</MenuItem>
                <MenuItem value="frequency">Frequency</MenuItem>
                <MenuItem value="importance">Importance</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Scale Size</InputLabel>
              <Select
                value={scaleSize}
                onChange={(e) => onChange({ 
                  config: {
                    ...config,
                    scaleSize: Number(e.target.value) 
                  }
                })}
                label="Scale Size"
              >
                <MenuItem value={3}>3-point scale</MenuItem>
                <MenuItem value={5}>5-point scale</MenuItem>
                <MenuItem value={7}>7-point scale</MenuItem>
                <MenuItem value={10}>10-point scale</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Low End Label"
              variant="outlined"
              value={lowLabel}
              onChange={(e) => onChange({ 
                config: {
                  ...config,
                  lowLabel: e.target.value
                }
              })}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="High End Label"
              variant="outlined"
              value={highLabel}
              onChange={(e) => onChange({ 
                config: {
                  ...config,
                  highLabel: e.target.value
                }
              })}
              margin="normal"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={showNumbers}
                  onChange={(e) => onChange({ 
                    config: {
                      ...config,
                      showNumbers: e.target.checked
                    }
                  })}
                />
              }
              label="Show numbers with labels"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Conditional Follow-up Questions Section */}
      <Box mb={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Conditional Follow-up Questions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Add follow-up questions that appear when specific ratings are selected.
            </Typography>
            
            <Box mt={2} display="flex" gap={1} flexWrap="wrap">
              {Array.from({ length: scaleSize }).map((_, index) => {
                const rating = String(index + 1);
                return (
                  <Chip
                    key={rating}
                    label={rating}
                    color={selectedRating === rating ? "primary" : 
                          ratingsWithFollowUps.includes(rating) ? "secondary" : "default"}
                    onClick={() => setSelectedRating(rating)}
                    sx={{ 
                      border: selectedRating === rating ? '2px solid' : 'none',
                      cursor: 'pointer'
                    }}
                  />
                );
              })}
            </Box>
            
            {selectedRating && (
              <Box mt={2} p={2} bgcolor="#f5f8ff" borderRadius={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">
                    Follow-up for rating: <strong>{selectedRating}</strong>
                  </Typography>
                  
                  {!conditionalFollowUps[selectedRating] ? (
                    <Button 
                      startIcon={<Add />}
                      onClick={() => addFollowUpQuestion(selectedRating)}
                      size="small"
                    >
                      Add Follow-up
                    </Button>
                  ) : (
                    <IconButton 
                      color="error" 
                      size="small"
                      onClick={() => removeFollowUpQuestion(selectedRating)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Box>
                
                {conditionalFollowUps[selectedRating] && (
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      label="Follow-up Question"
                      value={conditionalFollowUps[selectedRating].question}
                      onChange={(e) => updateFollowUpQuestion(selectedRating, e.target.value)}
                      placeholder="e.g., Please provide more details"
                      size="small"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={conditionalFollowUps[selectedRating].required}
                          onChange={(e) => updateFollowUpRequired(selectedRating, e.target.checked)}
                          size="small"
                        />
                      }
                      label="Required"
                      sx={{ mt: 1 }}
                    />
                  </Box>
                )}
              </Box>
            )}
            
            {/* List of ratings with follow-ups */}
            {ratingsWithFollowUps.length > 0 && (
              <Box mt={3} pt={2} borderTop={1} borderColor="divider">
                <Typography variant="subtitle2" gutterBottom>
                  Ratings with follow-up questions:
                </Typography>
                
                <Grid container spacing={2}>
                  {ratingsWithFollowUps.map((rating) => (
                    <Grid item xs={12} sm={6} key={rating}>
                      <Box 
                        p={1.5} 
                        bgcolor="#f0f7ff" 
                        borderRadius={1}
                        border="1px solid #e3f2fd"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => setSelectedRating(rating)}
                        sx={{ cursor: 'pointer', height: '100%' }}
                      >
                        <Box flex={1}>
                          <Typography variant="body2">
                            <strong>Rating {rating}</strong>
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {conditionalFollowUps[rating].question || "(No question text)"}
                            {conditionalFollowUps[rating].required && " • Required"}
                          </Typography>
                        </Box>
                        <IconButton 
                          size="small" 
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFollowUpQuestion(rating);
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle1" gutterBottom>
          Preview
        </Typography>
        <Box sx={{ p: 2, border: '1px dashed', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="body1" gutterBottom>
            {label || "Sample Question"}
          </Typography>
          
          {description && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {description}
            </Typography>
          )}
          
          <Box 
            sx={{ 
              mt: 2, 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ width: '100%', mr: 1 }}>
              <RadioGroup
                row
                sx={{
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                {Array.from({ length: scaleSize }).map((_, index) => {
                  const rating = String(index + 1);
                  const hasFollowUp = ratingsWithFollowUps.includes(rating);
                  
                  return (
                    <FormControlLabel
                      key={index}
                      value={rating}
                      control={
                        <Radio 
                          sx={hasFollowUp ? { 
                            color: 'secondary.main',
                            '&.Mui-checked': {
                              color: 'secondary.main',
                            }
                          } : {}}
                        />
                      }
                      label={
                        <Box sx={{ textAlign: 'center', mt: 1 }}>
                          {showNumbers && (
                            <Typography 
                              variant="caption" 
                              display="block"
                              sx={hasFollowUp ? { color: 'secondary.main' } : {}}
                            >
                              {index + 1}
                              {hasFollowUp && '*'}
                            </Typography>
                          )}
                          {index === 0 && (
                            <Typography variant="caption" sx={{ display: 'block', maxWidth: '80px', textAlign: 'center' }}>
                              {lowLabel}
                            </Typography>
                          )}
                          {index === scaleSize - 1 && (
                            <Typography variant="caption" sx={{ display: 'block', maxWidth: '80px', textAlign: 'center' }}>
                              {highLabel}
                            </Typography>
                          )}
                        </Box>
                      }
                      labelPlacement="bottom"
                      sx={{
                        margin: 0,
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          </Box>
          
          {ratingsWithFollowUps.length > 0 && (
            <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
              * This rating will show a follow-up question
            </Typography>
          )}
        </Box>
      </Box>
      
      {/* Add general comment field option like in CheckboxQuestion */}
      <Box mb={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Additional Comment Field</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlLabel
              control={
                <Switch
                  checked={config.allowComment || false}
                  onChange={(e) => onChange({ 
                    config: {
                      ...config,
                      allowComment: e.target.checked
                    }
                  })}
                  color="primary"
                />
              }
              label="Include general comment field"
            />
            
            {config.allowComment && (
              <Box mt={2}>
                <TextField
                  fullWidth
                  label="Comment Field Label"
                  size="small"
                  value={config.commentLabel || ""}
                  onChange={(e) => onChange({ 
                    config: {
                      ...config,
                      commentLabel: e.target.value
                    }
                  })}
                  placeholder="e.g., Additional comments"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={config.commentRequired || false}
                      onChange={(e) => onChange({ 
                        config: {
                          ...config,
                          commentRequired: e.target.checked
                        }
                      })}
                      size="small"
                    />
                  }
                  label="Comment required"
                />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default LikertQuestion;