import React from "react";
import { 
  Box, 
  TextField, 
  Typography, 
  Button,
  Select,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Tooltip
} from "@mui/material";
import { Add, Delete, ColorLens } from "@mui/icons-material";
import { Question, QuestionType } from "../../../store/store";
import QuestionFactory from "../QuestionFactory";

// Define an interface for section questions that extends Question with order property
interface SectionSubQuestion extends Question {
  order: number;
}

interface SectionQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const SectionQuestion: React.FC<SectionQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  // Filter out section from available question types to prevent nesting sections
  const availableQuestionTypes = questionTypes.filter(type => type !== "section");

  const handleAddQuestion = (type: string) => {
    const newSectionQuestions = [
      ...(question.sectionQuestions || []),
      {
        id: `section_q_${Date.now()}`,
        type: type as QuestionType,
        label: `Question ${(question.sectionQuestions || []).length + 1}`,
        required: false,
        order: (question.sectionQuestions || []).length,
        timeMode: "standard",
        instructionRows: 1,
        fullWidth: false
      }
    ];
    onChange({ sectionQuestions: newSectionQuestions });
    
    // Initialize specific question types with default values
    const newQuestion = newSectionQuestions[newSectionQuestions.length - 1];
    if (type === "select" || type === "multi-select") {
      updateSectionQuestion(newSectionQuestions.length - 1, { options: [] });
    } else if (type === "checkbox") {
      updateSectionQuestion(newSectionQuestions.length - 1, { options: [] });
    }
  };

  const updateSectionQuestion = (index: number, updates: Partial<Question>) => {
    const newSectionQuestions = [...(question.sectionQuestions || [])];
    newSectionQuestions[index] = { ...newSectionQuestions[index], ...updates };
    onChange({ sectionQuestions: newSectionQuestions });
  };

  const removeSectionQuestion = (index: number) => {
    const newSectionQuestions = (question.sectionQuestions || []).filter((_, i) => i !== index);
    onChange({ sectionQuestions: newSectionQuestions });
  };

  const handleMoveQuestion = (index: number, direction: "up" | "down") => {
    const newSectionQuestions = [...(question.sectionQuestions || [])];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < newSectionQuestions.length) {
      // Swap questions
      [newSectionQuestions[index], newSectionQuestions[targetIndex]] = 
      [newSectionQuestions[targetIndex], newSectionQuestions[index]];
      
      // Update order property - safely cast to any to avoid TypeScript errors
      // since we know these objects have an order property
      (newSectionQuestions[index] as any).order = index;
      (newSectionQuestions[targetIndex] as any).order = targetIndex;
      
      onChange({ sectionQuestions: newSectionQuestions });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Section Title"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
          placeholder="e.g., Personal Information, Employment History"
          InputProps={{
            style: { fontSize: "1.1rem", fontWeight: 500 }
          }}
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <TextField
          fullWidth
          label="Section Description"
          placeholder="Enter a description for this section (optional)"
          value={question.description || ""}
          onChange={(e) => onChange({ description: e.target.value })}
          multiline
          rows={2}
          sx={{ mb: 3 }}
        />
        
        <Box 
          border={1} 
          borderColor="divider" 
          borderRadius={1} 
          p={2} 
          mb={2} 
          sx={{ 
            bgcolor: question.sectionBgColor || 'transparent',
            borderColor: question.sectionBorderColor || 'divider'
          }}
        >
          <Box 
            display="flex" 
            justifyContent="space-between" 
            alignItems="center"
            mb={2}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Section Questions
            </Typography>
            
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title="Section Style">
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel>Style</InputLabel>
                  <Select
                    value={question.sectionStyle || "default"}
                    onChange={(e) => onChange({ sectionStyle: e.target.value })}
                    label="Style"
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="outlined">Outlined</MenuItem>
                    <MenuItem value="subtle">Subtle</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
              
              <Tooltip title="Background Color">
                <IconButton 
                  size="small"
                  onClick={() => {
                    const colors = ['#f5f5f5', '#e3f2fd', '#e8f5e9', '#fff8e1', '#fce4ec', 'transparent'];
                    const currentIndex = colors.indexOf(question.sectionBgColor || 'transparent');
                    const nextColor = colors[(currentIndex + 1) % colors.length];
                    onChange({ sectionBgColor: nextColor });
                  }}
                >
                  <ColorLens fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          
          <Divider sx={{ mb: 2 }} />
          
          {(question.sectionQuestions || []).length === 0 ? (
            <Typography variant="body2" color="text.secondary" align="center" py={3}>
              No questions added to this section yet. Add questions using the dropdown below.
            </Typography>
          ) : (
            (question.sectionQuestions || []).map((sectionQuestion, index) => (
              <Card key={sectionQuestion.id} sx={{ mb: 3, border: '1px solid #e0e0e0' }}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="subtitle2" fontWeight="medium">
                      Question {index + 1}
                    </Typography>
                    
                    <Box>
                      <IconButton 
                        size="small" 
                        disabled={index === 0}
                        onClick={() => handleMoveQuestion(index, "up")}
                      >
                        ↑
                      </IconButton>
                      
                      <IconButton 
                        size="small" 
                        disabled={index === (question.sectionQuestions || []).length - 1}
                        onClick={() => handleMoveQuestion(index, "down")}
                      >
                        ↓
                      </IconButton>
                      
                      <IconButton 
                        color="error"
                        size="small"
                        onClick={() => removeSectionQuestion(index)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                  
                  <Box mt={1}>
                    <QuestionFactory 
                      question={sectionQuestion}
                      onChange={(updates) => updateSectionQuestion(index, updates)}
                      questionTypes={availableQuestionTypes}
                    />
                  </Box>
                  <Box mt={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sectionQuestion.required || false}
                          onChange={(e) => updateSectionQuestion(index, { required: e.target.checked })}
                          color="primary"
                        />
                      }
                      label="Required"
                    />
                  </Box>
                </CardContent>
              </Card>
            ))
          )}
          
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="add-question-section-label">Add Question</InputLabel>
            <Select
              labelId="add-question-section-label"
              value=""
              onChange={(e) => {
                if (e.target.value) {
                  handleAddQuestion(e.target.value as string);
                }
              }}
              label="Add Question"
            >
              <MenuItem value="" disabled>
                <Typography color="text.secondary">Select a question type</Typography>
              </MenuItem>
              {availableQuestionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        {/* Section Display Options */}
        <Box mt={3}>
          <Typography variant="subtitle2" gutterBottom>
            Section Display Options
          </Typography>
          
          <Box display="flex" gap={2} flexWrap="wrap" mt={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={question.collapsible || false}
                  onChange={(e) => onChange({ collapsible: e.target.checked })}
                  color="primary"
                />
              }
              label="Collapsible Section"
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={question.initiallyExpanded !== false}
                  onChange={(e) => onChange({ initiallyExpanded: e.target.checked })}
                  color="primary"
                />
              }
              label="Initially Expanded"
              disabled={!question.collapsible}
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={question.showSectionNumbers || false}
                  onChange={(e) => onChange({ showSectionNumbers: e.target.checked })}
                  color="primary"
                />
              }
              label="Show Section Numbers"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionQuestion;