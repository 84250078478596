import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Typography, 
  Container, 
  Box, 
  Paper, 
  Grid, 
  ThemeProvider, 
  createTheme, 
  useMediaQuery,
  Divider
} from "@mui/material";
import { 
  Description, 
  Analytics, 
  Send, 
  Security, 
  Dashboard,
  DynamicForm
} from "@mui/icons-material";
// Update Clerk imports to include modal components
import { useAuth, useClerk } from "@clerk/clerk-react";

// Custom theme for the landing page
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
      light: '#6573c3',
      dark: '#2c387e'
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162'
    },
    background: {
      default: '#f9fafc'
    }
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.25rem',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: '10px 24px',
          fontSize: '1rem',
          fontWeight: 500
        },
        containedPrimary: {
          backgroundColor: '#3f51b5',
          '&:hover': {
            backgroundColor: '#2c387e',
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 6px 18px rgba(0,0,0,0.06)'
        }
      }
    }
  }
});

const FeatureItem = ({ icon, title, description }: { icon: React.ReactNode, title: string, description: string }) => (
  <Paper elevation={0} sx={{ p: 3, height: '100%', border: '1px solid', borderColor: 'rgba(0,0,0,0.08)' }}>
    <Box display="flex" alignItems="center" mb={2}>
      <Box color="primary.main" mr={1.5}>
        {icon}
      </Box>
      <Typography variant="h4" fontWeight={500}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body1" color="text.secondary">
      {description}
    </Typography>
  </Paper>
);

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // Get Clerk's authentication and modal utilities
  const { openSignIn } = useClerk();
  const { isSignedIn } = useAuth();
  
  // Handle sign in button click - open Clerk modal
  const handleSignIn = () => {
    openSignIn({
      afterSignInUrl: '/home',
      afterSignUpUrl: '/home'
    });
  };

  // Check if the user is already signed in and redirect if needed
  React.useEffect(() => {
    if (isSignedIn) {
      navigate('/home');
    }
  }, [isSignedIn, navigate]);
  
  return (
    <ThemeProvider theme={theme}>
      {/* We can remove the Clerk-specific style override since we're not using iframe */}
      
      <Box 
        sx={{ 
          bgcolor: 'background.default', 
          minHeight: '100vh'
        }}
      >
        {/* Header */}
        <Box 
          sx={{ 
            bgcolor: 'white', 
            boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
            position: 'sticky',
            top: 0,
            zIndex: 10
          }}
        >
          <Container maxWidth="lg">
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                py: 2
              }}
            >
              <Box display="flex" alignItems="center">
                <DynamicForm fontSize="large" color="primary" sx={{ mr: 1 }} />
                <Typography variant="h4" fontWeight={600} color="primary.main">
                  FormCraft
                </Typography>
              </Box>
              {/* Update sign in button to use the modal */}
              <Button 
                variant="outlined" 
                color="primary"
                onClick={handleSignIn}
              >
                Sign In
              </Button>
            </Box>
          </Container>
        </Box>

        {/* Hero Section */}
        <Box 
          sx={{ 
            pt: { xs: 6, md: 12 }, 
            pb: { xs: 8, md: 16 },
            bgcolor: 'white'
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: { xs: 4, md: 0 } }}>
                  <Typography 
                    variant="h1" 
                    color="primary.dark"
                    gutterBottom
                    sx={{ 
                      fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                      lineHeight: 1.2
                    }}
                  >
                    Create Professional Forms in Minutes
                  </Typography>
                  <Typography 
                    variant="h4" 
                    color="text.secondary" 
                    paragraph
                    sx={{ 
                      fontWeight: 400,
                      mb: 4,
                      maxWidth: '90%'
                    }}
                  >
                    Build, distribute, and analyze forms with our intuitive platform designed for businesses of all sizes.
                  </Typography>
                  {/* Update this button to use the modal */}
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="large"
                    onClick={handleSignIn}
                    sx={{ 
                      px: 4, 
                      py: 1.5,
                      fontSize: '1.1rem'
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box 
                  sx={{ 
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Paper
                    elevation={4}
                    sx={{
                      width: '100%',
                      maxWidth: 500,
                      overflow: 'hidden',
                      position: 'relative',
                      borderRadius: 4
                    }}
                  >
                    {/* This would be better with an actual screenshot of your app */}
                    <Box
                      sx={{
                        height: 400,
                        bgcolor: '#f5f7ff',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 3
                      }}
                    >
                      <DynamicForm 
                        sx={{ 
                          fontSize: 80, 
                          color: 'primary.main',
                          opacity: 0.8,
                          mb: 2
                        }} 
                      />
                      <Box
                        sx={{
                          width: '80%',
                          height: 12,
                          bgcolor: 'white',
                          borderRadius: 1,
                          mb: 2
                        }}
                      />
                      <Box
                        sx={{
                          width: '90%',
                          height: 48,
                          bgcolor: 'white',
                          borderRadius: 1,
                          mb: 2
                        }}
                      />
                      <Box
                        sx={{
                          width: '70%',
                          height: 36,
                          bgcolor: 'white',
                          borderRadius: 1,
                          mb: 2
                        }}
                      />
                      <Box
                        sx={{
                          width: '90%',
                          height: 48,
                          bgcolor: 'white',
                          borderRadius: 1,
                          mb: 2
                        }}
                      />
                      <Box
                        sx={{
                          width: '40%',
                          height: 36,
                          bgcolor: 'primary.main',
                          borderRadius: 1,
                        }}
                      />
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        
        {/* Features Section */}
        <Container maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
          <Box textAlign="center" mb={6}>
            <Typography 
              variant="h2" 
              gutterBottom
              sx={{ 
                fontSize: { xs: '1.75rem', md: '2rem' }
              }}
            >
              Everything You Need for Form Management
            </Typography>
            <Typography 
              variant="h5"
              color="text.secondary"
              sx={{ 
                maxWidth: 700,
                mx: 'auto',
                fontWeight: 400
              }}
            >
              Our platform simplifies the entire form lifecycle from creation to analysis
            </Typography>
          </Box>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<Description fontSize="large" />}
                title="Easy Form Creation"
                description="Drag and drop elements to create beautiful forms without any coding skills required."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<Send fontSize="large" />}
                title="Seamless Distribution"
                description="Share forms via link or embed them directly on your website for maximum reach."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<Analytics fontSize="large" />}
                title="Comprehensive Analytics"
                description="Track responses and analyze submission data with intuitive visualization tools."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<Security fontSize="large" />}
                title="Secure Data Handling"
                description="Your data is protected with enterprise-grade security and encryption standards."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<Dashboard fontSize="large" />}
                title="Customizable Workflows"
                description="Create conditional logic and custom paths based on user responses."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureItem
                icon={<DynamicForm fontSize="large" />}
                title="PDF Generation"
                description="Automatically generate professional PDF documents from form submissions."
              />
            </Grid>
          </Grid>
        </Container>
        
        {/* Replace the Sign In Section with a Call to Action */}
        <Box sx={{ bgcolor: 'white', py: { xs: 6, md: 10 } }}>
          <Container maxWidth="md">
            <Box textAlign="center" mb={5}>
              <Typography variant="h3" gutterBottom>
                Ready to get started?
              </Typography>
              <Typography 
                variant="body1" 
                color="text.secondary"
                sx={{ maxWidth: '600px', mx: 'auto', mb: 4 }}
              >
                Join thousands of professionals who trust FormCraft for their form management needs. Create your first form in minutes.
              </Typography>
              
              {/* Replace the iframe with a simple button */}
              <Button 
                variant="contained" 
                color="primary" 
                size="large"
                onClick={handleSignIn}
                sx={{ 
                  px: 5, 
                  py: 1.5,
                  fontSize: '1.1rem'
                }}
              >
                Sign In to Get Started
              </Button>
            </Box>
          </Container>
        </Box>
        
        {/* Footer */}
        <Box sx={{ bgcolor: '#f2f2f2', py: 4 }}>
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                  <DynamicForm color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1" fontWeight={500} color="primary.main">
                    FormCraft
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="text.secondary" align={isMobile ? "left" : "right"}>
                  &copy; {new Date().getFullYear()} FormCraft. All rights reserved.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Landing;
