import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { SignedIn, SignedOut, SignIn, SignUp, useAuth } from "@clerk/clerk-react"; // Remove ClerkProvider from imports
import FormBuilder from "./pages/FormBuilder";
import FillForm from "./pages/FillForm";
import Home from "./pages/Home";
import EditForm from "./pages/EditForm";
import Confirmation from "./pages/Confirmation";
import UserManagement from "./components/UserManagement";
import Landing from "./pages/Landing";
import UserProfile from "./components/UserProfile";
import ResponseDashboard from "./pages/ResponseDashboard";
import SubmissionsHistory from "./pages/SubmissionsHistory";
import CompanyLandingPage from "pages/CompanyLandingPage";
import LandingPageBuilder from "pages/LandingPageBuilder";
import FormSlugHandler from "pages/FormSlugHandler";
import './components/Visualizations/ChartSetup';
import ApprovalManager from "pages/ApprovalManager";
import ApprovalCalendarView from "pages/ApprovalCalendarView";
import "./clerkStyles.css"; // Import the styles for clerk positioning

// Create a wrapper component to handle authentication state
const AuthenticationWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Only run this check if Clerk has finished loading
    if (isLoaded) {
      // Public routes that don't require authentication
      const publicRoutes = [
        '/fill/', 
        '/confirmation',
        '/sign-in',
        '/sign-up',
        '/landing',
        '/:companySlug',
        '/:companySlug/:formSlug',
        '/landing-page-builder',
      ];
      
      // Check if current path is a public route
      const isPublicRoute = publicRoutes.some(route => 
        location.pathname.startsWith(route) || location.pathname === route
      );
      
      // If user is not signed in and trying to access a protected route
      if (!isSignedIn && !isPublicRoute) {
        console.log('User not authenticated, redirecting to landing page');
        navigate('/landing', { replace: true });
      }
    }
  }, [isSignedIn, isLoaded, location.pathname, navigate]);

  // Show nothing while Clerk is loading to prevent flashes
  if (!isLoaded) {
    return null;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <AuthenticationWrapper>
      <Routes>
        {/* Landing Page (Only for Non-Logged-In Users) */}
        <Route path="/landing" element={<SignedOut><Landing /></SignedOut>} />

        {/* Authentication Routes */}
        <Route path="/sign-in/*" element={<SignIn routing="path" path="/sign-in" />} />
        <Route path="/sign-up/*" element={<SignUp routing="path" path="/sign-up" />} />

        {/* Protected Routes - Only accessible when signed in */}
        <Route path="/home" element={<SignedIn><Home /></SignedIn>} />
        <Route path="/new-form" element={<SignedIn><FormBuilder /></SignedIn>} />
        <Route path="/edit/:formId" element={<SignedIn><EditForm /></SignedIn>} />
        <Route path="/manage-users" element={<SignedIn><UserManagement /></SignedIn>} />
        <Route path="/profile" element={<SignedIn><UserProfile /></SignedIn>} />
        <Route path="/responses/:formId" element={<SignedIn><ResponseDashboard /></SignedIn>} />
        <Route path="/responses" element={<SignedIn><ResponseDashboard /></SignedIn>} />
        <Route path="/forms/:formId/submissions" element={<SignedIn><SubmissionsHistory /></SignedIn>} />
        <Route path="/landing-page-builder" element={<SignedIn><LandingPageBuilder /></SignedIn>} />
        <Route path="/approval-manager" element={<SignedIn><ApprovalManager /></SignedIn>} />
        <Route path="/approval-manager/:formId" element={<SignedIn><ApprovalManager /></SignedIn>} />
        <Route path="/approval-manager/:formId/:submissionId" element={<SignedIn><ApprovalManager /></SignedIn>} />
        <Route path="/approval-manager/:formId/:submissionId/:status" element={<SignedIn><ApprovalManager /></SignedIn>} />
        <Route path="/approval-calendar/:formId" element={<SignedIn><ApprovalCalendarView /></SignedIn>} />
        <Route path="/approval-calendar/:formId/:submissionId" element={<SignedIn><ApprovalCalendarView /></SignedIn>} />

        {/* Public Routes (No sign-in required) */}
        <Route path="/fill/:formId" element={<FillForm />} /> {/* Keep this fully public */}
        <Route path="/confirmation" element={<Confirmation />} /> {/* Keep this fully public */}
        <Route path="/:companySlug/:formSlug" element={<FormSlugHandler />} />
        <Route path="/:companySlug" element={<CompanyLandingPage />} />
        {/* Default Route: Redirect to Home if Signed In, Otherwise Landing */}
        <Route path="*" element={
          <>
            <SignedIn>
              <Navigate to="/home" />
            </SignedIn>
            <SignedOut>
              <Navigate to="/landing" />
            </SignedOut>
          </>
        } />
      </Routes>
    </AuthenticationWrapper>
  );
};

export default App;