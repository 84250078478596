import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale'; // Import the UK locale
import Question from './Question';

interface DateQuestionProps {
  question: any;
  value: Date | null;
  onChange: (value: Date | null) => void;
}

const DateQuestion: React.FC<DateQuestionProps> = ({ 
  question, 
  value, 
  onChange 
}) => {
  // Track if the field has been touched to avoid showing validation immediately
  const [touched, setTouched] = useState(false);
  
  return (
    <Question question={question}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <DatePicker 
          value={value}
          format="dd/MM/yyyy" // Explicitly set UK date format
          onChange={(date) => {
            onChange(date);
            if (!touched) setTouched(true);
          }}
          slotProps={{ 
            textField: { 
              fullWidth: true,
              // Only show required validation if explicitly required AND the field was touched
              required: question.required,
              // Override the error state
              error: question.required && touched && !value,
              // Remove the red border when not in error state
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: (question.required && touched && !value) ? 'error.main' : 'rgba(0, 0, 0, 0.23)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: (question.required && touched && !value) ? 'error.main' : 'rgba(0, 0, 0, 0.87)',
                }
              }
            } 
          }}
          // Add onBlur to mark the field as touched
          onClose={() => setTouched(true)}
        />
      </LocalizationProvider>
    </Question>
  );
};

export default DateQuestion;