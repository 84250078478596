import { format, differenceInMinutes, parseISO } from 'date-fns';

// Generate theme-based colors for charts
export const generateChartColors = (theme: any, count: number) => {
  const baseColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];
  
  // For additional colors, create variations of the base colors
  const colors = [];
  for (let i = 0; i < count; i++) {
    if (i < baseColors.length) {
      colors.push(baseColors[i]);
    } else {
      // Create variations by adjusting opacity
      const baseIndex = i % baseColors.length;
      const opacity = 0.7 - (Math.floor(i / baseColors.length) * 0.15);
      colors.push(baseColors[baseIndex] + Math.floor(opacity * 255).toString(16).padStart(2, '0'));
    }
  }
  
  return colors;
};

// Safely handle date parsing for chart labels
export const formatSubmissionLabel = (submission: any, index: number) => {
  let label = `Submission ${index + 1}`;
  
  try {
    let submissionDate;
    if (submission.submittedAt && typeof submission.submittedAt === 'object' && 'toDate' in submission.submittedAt) {
      submissionDate = submission.submittedAt.toDate();
    } else if (submission.submittedAt && typeof submission.submittedAt === 'object' && 'seconds' in submission.submittedAt) {
      submissionDate = new Date(submission.submittedAt.seconds * 1000);
    } else if (submission.submittedAt) {
      submissionDate = new Date(submission.submittedAt);
    }
    
    if (submissionDate && !isNaN(submissionDate.getTime())) {
      const dateStr = format(submissionDate, 'MMM d');
      const respondent = submission.email || submission.name || '';
      label = respondent ? `${dateStr} - ${respondent.split('@')[0]}` : dateStr;
    }
  } catch (e) {
    // Use default label
  }
  
  return label;
};

// Sort submissions by date (newest first) with safe date handling
export const sortSubmissionsByDate = (submissions: any[]) => {
  return [...submissions].sort((a, b) => {
    let dateA, dateB;
    
    try {
      if (a.submittedAt && typeof a.submittedAt === 'object' && 'toDate' in a.submittedAt) {
        dateA = a.submittedAt.toDate();
      } else if (a.submittedAt && typeof a.submittedAt === 'object' && 'seconds' in a.submittedAt) {
        dateA = new Date(a.submittedAt.seconds * 1000);
      } else if (a.submittedAt) {
        dateA = new Date(a.submittedAt);
      } else {
        dateA = new Date(0);
      }
    } catch (e) {
      dateA = new Date(0);
    }
    
    try {
      if (b.submittedAt && typeof b.submittedAt === 'object' && 'toDate' in b.submittedAt) {
        dateB = b.submittedAt.toDate();
      } else if (b.submittedAt && typeof b.submittedAt === 'object' && 'seconds' in b.submittedAt) {
        dateB = new Date(b.submittedAt.seconds * 1000);
      } else if (b.submittedAt) {
        dateB = new Date(b.submittedAt);
      } else {
        dateB = new Date(0);
      }
    } catch (e) {
      dateB = new Date(0);
    }
    
    return dateB.getTime() - dateA.getTime();
  });
};

// Format numeric values for display
export const formatNumber = (value: number, precision: number = 1) => {
  if (Number.isInteger(value)) {
    return value.toString();
  }
  return value.toFixed(precision);
};

// Calculate statistics from an array of numbers
export const calculateStats = (values: number[]) => {
  if (!values || values.length === 0) {
    return { min: 0, max: 0, avg: 0, median: 0 };
  }
  
  const sortedValues = [...values].sort((a, b) => a - b);
  const min = sortedValues[0];
  const max = sortedValues[sortedValues.length - 1];
  const sum = sortedValues.reduce((a, b) => a + b, 0);
  const avg = sum / sortedValues.length;
  
  // Calculate median
  const mid = Math.floor(sortedValues.length / 2);
  const median = sortedValues.length % 2 === 0
    ? (sortedValues[mid - 1] + sortedValues[mid]) / 2
    : sortedValues[mid];
    
  return { min, max, avg, median };
};

// Group dates by period (day, week, month)
export const groupDatesByPeriod = (dates: Date[], period: 'day' | 'week' | 'month') => {
  const groups: Record<string, number> = {};
  
  dates.forEach(date => {
    let key: string;
    
    if (period === 'day') {
      key = format(date, 'yyyy-MM-dd');
    } else if (period === 'week') {
      // Use ISO week format (year-week)
      key = format(date, 'yyyy-ww');
    } else {
      // Month
      key = format(date, 'yyyy-MM');
    }
    
    groups[key] = (groups[key] || 0) + 1;
  });
  
  return groups;
};

// Convert groupedData to chart-friendly format
export const formatGroupedDataForChart = (
  groupedData: Record<string, number>, 
  period: 'day' | 'week' | 'month'
) => {
  const sortedKeys = Object.keys(groupedData).sort();
  
  // Format labels based on period
  const labels = sortedKeys.map(key => {
    const [year, periodValue] = key.split('-');
    
    if (period === 'day') {
      // For days, show the date in a more readable format
      const [_, month, day] = key.split('-');
      return `${month}/${day}`;
    } else if (period === 'week') {
      // For weeks, show "Week X"
      return `Week ${periodValue}`;
    } else {
      // For months, show the month name
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthIndex = parseInt(periodValue) - 1;
      return monthNames[monthIndex];
    }
  });
  
  // Get the corresponding values
  const data = sortedKeys.map(key => groupedData[key]);
  
  return { labels, data };
};

// Helper to create a Filter Chip component 
export interface FilterIndicatorProps {
  activeFilter: {value: string, label: string} | null;
  clearFilter: () => void;
}