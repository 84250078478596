import { format } from 'date-fns';
import { PDFQuestionRenderer } from './PDFQuestionRenderer';

interface PDFSectionRendererProps {
  doc: any;
  section: any;
  sectionCounter: number;
  questionCounter: number;
  currentY: number;
  responses: { [key: string]: any };
  repeatableItems: { [key: string]: Array<{ [key: string]: any }> };
  signatures: { [key: string]: string };
  imagePreview: { [key: string]: string };
  annotatedImages: { [key: string]: string };
  primaryColorRgb: [number, number, number];
  blackTextColor: [number, number, number];
  lightGrayColor: [number, number, number];
  safeText: (value: any) => string;
}

export const PDFSectionRenderer = ({
  doc,
  section,
  sectionCounter,
  questionCounter,
  currentY,
  responses,
  repeatableItems,
  signatures,
  imagePreview,
  annotatedImages,
  primaryColorRgb,
  blackTextColor,
  lightGrayColor,
  safeText
}: PDFSectionRendererProps): number => {
  // Check if we need a new page for section
  if (currentY > doc.internal.pageSize.getHeight() - 60) {
    doc.addPage();
    currentY = 20;
  }
  
  // Add section header with styling
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 10, 2, 2, "FD");
  
  // Add section title
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`${sectionCounter}. ${safeText(section.label || "Section")}`, 15, currentY + 7);
  
  // Reset text color for subsequent content
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  
  // Add description if available
  if (section.description) {
    currentY += 15;
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    
    // Split description into multiple lines if needed
    const splitDesc = doc.splitTextToSize(
      safeText(section.description), 
      doc.internal.pageSize.getWidth() - 30
    );
    
    doc.text(splitDesc, 15, currentY);
    
    // Calculate height based on number of lines
    const descHeight = splitDesc.length * 12; // Approximate line height
    currentY += descHeight;
  } else {
    currentY += 15; // Standard spacing after section header
  }
  
  // Now render all questions in this section
  if (section.sectionQuestions && Array.isArray(section.sectionQuestions)) {
    let sectionQuestionCounter = 1;
    
    section.sectionQuestions.forEach((sectionQuestion: any, index: number) => {
      // Skip instruction questions in PDF
      if (sectionQuestion.type === "instructions") return;
      
      // Check if we need a page break
      if (currentY > doc.internal.pageSize.getHeight() - 40) {
        doc.addPage();
        currentY = 20;
      }
      
      // Render the question using the question renderer
      currentY = PDFQuestionRenderer({
        doc,
        question: sectionQuestion,
        questionIndex: index,
        questionCounter: sectionQuestionCounter++,
        sectionCounter: sectionCounter,
        currentY,
        responses,
        repeatableItems,
        imagePreview,
        annotatedImages,
        signatures,
        blackTextColor,
        lightGrayColor,
        primaryColorRgb,
        safeText
      });
    });
  }
  
  return currentY;
};