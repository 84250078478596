import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, TextField, Typography, Box, 
  FormControl, InputLabel, Select, MenuItem,
  CircularProgress, Divider
} from '@mui/material';
import { duplicateForm } from '../services/firebase/submissionWriter';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useUser } from '@clerk/clerk-react';

interface DuplicateFormDialogProps {
  open: boolean;
  onClose: () => void;
  formId: string | null;
  formTitle: string;
  email: string;
  isAdmin: boolean;
  onSuccess: (newFormId: string) => void;
}

const DuplicateFormDialog: React.FC<DuplicateFormDialogProps> = ({
  open,
  onClose,
  formId,
  formTitle,
  email,
  isAdmin,
  onSuccess
}) => {
  const { user } = useUser();
  const [newTitle, setNewTitle] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  
  // Set initial values when dialog opens
  useEffect(() => {
    if (open) {
      setNewTitle(`${formTitle} (Copy)`);
      setNewEmail(email);
      setSelectedUserId('');
      
      // Fetch users if admin
      if (isAdmin) {
        fetchUsers();
      }
    }
  }, [open, formTitle, email, isAdmin]);
  
  // Function to fetch users for admin assignment
  const fetchUsers = async () => {
    if (!isAdmin) return;
    
    try {
      setFetchingUsers(true);
      const usersQuery = query(
        collection(db, 'users'),
        orderBy('email')
      );
      
      const snapshot = await getDocs(usersQuery);
      const usersList = snapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        role: doc.data().role || 'User'
      }));
      
      setUsers(usersList);
    } catch (err) {
      console.error("Error fetching users:", err);
    } finally {
      setFetchingUsers(false);
    }
  };
  
  // Handle form submission
  const handleDuplicate = async () => {
    if (!formId || !newTitle.trim() || !user) {
      return;
    }
    
    try {
      setLoading(true);
      
      // Determine if we need to assign a new user (admin only)
      const targetUserId = isAdmin && selectedUserId ? selectedUserId : undefined;
      
      // Admin data to pass if we're assigning to a different user
      const adminData = isAdmin && targetUserId && user
        ? { 
            id: user.id, // Use Clerk user ID for admin tracking
            email: user.emailAddresses[0]?.emailAddress || "" 
          }
        : undefined;
      
      // Call the duplicateForm function with all necessary parameters
      const newFormId = await duplicateForm(
        formId,
        newTitle,
        newEmail,
        targetUserId,
        adminData
      );
      
      setLoading(false);
      onClose();
      onSuccess(newFormId);
    } catch (error) {
      console.error("Error duplicating form:", error);
      alert("Failed to duplicate form. Please try again.");
      setLoading(false);
    }
  };
  
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: '12px', maxWidth: 500 }
      }}
    >
      <DialogTitle>
        <Typography variant="h6" fontWeight="600">Duplicate Form</Typography>
      </DialogTitle>
      
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Create a copy of this form with a new title and optionally assign it to a different user.
        </Typography>
        
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="New Form Title"
          type="text"
          fullWidth
          variant="outlined"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          sx={{ mb: 2 }}
          required
        />
        
        <TextField
          margin="dense"
          id="email"
          label="Notification Email"
          type="email"
          fullWidth
          variant="outlined"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          helperText="Form submission notifications will be sent to this email"
          sx={{ mb: 3 }}
        />
        
        {isAdmin && (
          <>
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle2" fontWeight="600" sx={{ mb: 1 }}>
              Admin Options
            </Typography>
            
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="assign-user-label">Assign To User</InputLabel>
              <Select
                labelId="assign-user-label"
                value={selectedUserId}
                label="Assign To User"
                onChange={(e) => setSelectedUserId(e.target.value)}
                disabled={fetchingUsers}
              >
                <MenuItem value="">
                  <em>Keep Current Owner</em>
                </MenuItem>
                {users.map((userItem) => (
                  <MenuItem key={userItem.id} value={userItem.id}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2">
                        {userItem.firstName && userItem.lastName 
                          ? `${userItem.firstName} ${userItem.lastName}`
                          : userItem.email}
                      </Typography>
                      {userItem.firstName && userItem.lastName && (
                        <Typography variant="caption" color="text.secondary">
                          {userItem.email}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              {fetchingUsers && (
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <CircularProgress size={16} />
                  <Typography variant="caption">Loading users...</Typography>
                </Box>
              )}
            </FormControl>
            
            {selectedUserId && (
              <Typography 
                variant="caption" 
                color="warning.main" 
                sx={{ display: 'block', mt: 1 }}
              >
                This form copy will be assigned to another user.
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button 
          onClick={handleDuplicate} 
          variant="contained" 
          disabled={!newTitle.trim() || loading}
          startIcon={loading ? <CircularProgress size={16} /> : null}
        >
          {loading ? "Duplicating..." : "Duplicate Form"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateFormDialog;