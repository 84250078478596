import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Divider, Grid, Paper, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Pie } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useNormalizedData } from '../VisualizationHelper';

interface TimeVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

// Helper function to calculate time duration
const calculateTimeDuration = (startTime: string, endTime: string) => {
  try {
    // Parse hours and minutes
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    
    // Convert to minutes
    let startMinutes = startHour * 60 + startMinute;
    let endMinutes = endHour * 60 + endMinute;
    
    // Handle overnight ranges
    if (endMinutes < startMinutes) {
      endMinutes += 24 * 60; // Add a day in minutes
    }
    
    // Calculate difference in minutes
    const diffMinutes = endMinutes - startMinutes;
    
    // Convert to hours and minutes
    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;
    
    // Format the result
    if (hours === 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (minutes === 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }
  } catch (e) {
    return 'Invalid time range';
  }
};

const TimeVisualization: React.FC<TimeVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  const normalizedData = useNormalizedData(question, summaryData, filteredSubmissions);
  
  // Check if this is a time range question rather than a single time
  const isTimeRange = question.type === 'time-range' || 
    (question.settings && question.settings.isRange);
  
  // Function to extract times from submissions
  function extractTimes(submissions: any[]) {
    return submissions
      .map(sub => {
        const value = sub?.responses?.[question.id];
        if (!value) return null;
        
        // Format should be HH:MM
        if (typeof value === 'string' && value.includes(':')) {
          return value;
        }
        
        // Handle time objects with startTime
        if (typeof value === 'object' && value?.startTime) {
          if (typeof value.startTime === 'string' && value.startTime.includes(':')) {
            return value.startTime;
          }
        }
        
        return null;
      })
      .filter(time => time !== null) as string[];
  }
  
  // Use normalized data to extract times - ALWAYS call this hook
  const times = useMemo(() => {
    if (!normalizedData) return [];
    
    // Use times from normalizedData if available
    if (normalizedData.timeResponses && Array.isArray(normalizedData.timeResponses)) {
      return normalizedData.timeResponses;
    }
    
    // Otherwise extract from filteredSubmissions
    return extractTimes(filteredSubmissions);
  }, [normalizedData, filteredSubmissions, question.id]);

  // Extract time ranges from the data - ALWAYS call this hook
  const timeRanges = useMemo(() => {
    if (!isTimeRange || !filteredSubmissions) return [];
    
    return filteredSubmissions
      .map(sub => {
        const value = sub?.responses?.[question.id];
        if (!value || typeof value !== 'object') return null;
        
        try {
          // Check for start and end times in the value
          if (typeof value.startTime === 'string' && 
              typeof value.endTime === 'string' && 
              value.startTime.includes(':') && 
              value.endTime.includes(':')) {
            
            return {
              startTime: value.startTime,
              endTime: value.endTime,
              duration: calculateTimeDuration(value.startTime, value.endTime),
              email: sub.email,
              label: sub.formId || sub.id
            };
          }
          
          return null;
        } catch (e) {
          return null;
        }
      })
      .filter(range => range !== null);
  }, [isTimeRange, filteredSubmissions, question.id]);
  
  // Group times into buckets - ALWAYS call this hook
  const timeGroups = useMemo(() => {
    const groups = {
      'Morning (6-12)': 0,
      'Afternoon (12-18)': 0,
      'Evening (18-22)': 0,
      'Night (22-6)': 0
    };
    
    // Define types for time groups
    interface TimeGroups {
      'Morning (6-12)': number;
      'Afternoon (12-18)': number;
      'Evening (18-22)': number;
      'Night (22-6)': number;
    }
    
    times.forEach((timeStr: string) => {
      try {
      const [hours, minutes]: [number, number] = timeStr.split(':').map(Number) as [number, number];
      if (hours >= 6 && hours < 12) {
        groups['Morning (6-12)']++;
      } else if (hours >= 12 && hours < 18) {
        groups['Afternoon (12-18)']++;
      } else if (hours >= 18 && hours < 22) {
        groups['Evening (18-22)']++;
      } else {
        groups['Night (22-6)']++;
      }
      } catch (e) {
      // Skip invalid times
      }
    });
    
    return groups;
  }, [times]);
  
  // Log data for debugging
  useEffect(() => {
    console.log('TimeVisualization data:', {
      isTimeRange,
      times,
      timeRanges,
      normalizedData,
      summaryData,
      filteredSubmissions: filteredSubmissions?.length
    });
  }, [isTimeRange, times, timeRanges, normalizedData, summaryData, filteredSubmissions]);
  
  // Handle loading state
  if (!normalizedData) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        Loading time visualization...
      </Typography>
    );
  }
  
  // Prepare chart data
  const chartData = {
    labels: Object.keys(timeGroups),
    datasets: [
      {
        data: Object.values(timeGroups),
        backgroundColor: [
          theme.palette.primary.light,
          theme.palette.primary.main,
          theme.palette.primary.dark,
          theme.palette.grey[800]
        ],
        borderColor: [
          theme.palette.background.paper,
          theme.palette.background.paper,
          theme.palette.background.paper,
          theme.palette.background.paper
        ],
        borderWidth: 1
      }
    ]
  };
  
  const chartOptions = {
    plugins: {
      legend: {
        position: 'right' as const,
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem: any) {
            const value = tooltipItem.raw;
            const total = tooltipItem.dataset.data.reduce((a: number, b: number) => a + b, 0);
            const percentage = Math.round((value / total) * 100);
            return `${value} responses (${percentage}%)`;
          }
        }
      }
    },
    maintainAspectRatio: false
  };
  
  // For time-range questions, render a specialized view
  if (isTimeRange && timeRanges.length > 0) {
    return (
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Time Range Responses ({timeRanges.length})
        </Typography>
        
        <Box sx={{ mt: 2, maxHeight: 400, overflowY: 'auto' }}>
          {timeRanges.map((range, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                mb: 1,
                borderRadius: 1,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                '&:hover': {
                  boxShadow: 1,
                  backgroundColor: theme.palette.action.hover
                },
                position: 'relative'
              }}
            >
              <Typography variant="subtitle1" color="text.primary">
                {range.startTime} — {range.endTime}
              </Typography>
              
              <Typography variant="body2" color="text.secondary">
                Duration: {range.duration}
              </Typography>
              
              {/* Always display email and form ID for attribution */}
              <Box sx={{ mt: 1, pt: 1, borderTop: `1px dashed ${theme.palette.divider}` }}>
                {range.email && (
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                    Submitted by: {range.email}
                  </Typography>
                )}
                
                {range.label && (
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                    Form ID: {range.label}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  
  // Check if we have data to visualize for regular time questions
  if (!times || times.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No time data available for this question
      </Typography>
    );
  }
  
  // Render regular time visualization
  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Time Distribution ({times.length} responses)
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: 180, display: 'flex', justifyContent: 'center' }}>
            <Pie data={chartData} options={chartOptions} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body2" gutterBottom>
              Most common times:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(
                times.reduce((acc: Record<string, number>, time: string) => {
                  acc[time] = (acc[time] || 0) + 1;
                  return acc;
                }, {})
                )
                .sort((a, b) => (b[1] as number) - (a[1] as number))
                .slice(0, 5)
                .map((entry) => {
                  const [time, count] = entry as [string, number];
                  return (
                    <Chip 
                    key={time}
                    label={`${time} (${count})`}
                    variant="outlined"
                    size="small"
                    sx={{ 
                      bgcolor: theme.palette.background.paper,
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.text.primary
                    }}
                    />
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimeVisualization;