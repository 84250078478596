import React from 'react';
import { Box, Typography } from '@mui/material';

interface CommentDisplayProps {
  comments: string | null;
}

const CommentDisplay: React.FC<CommentDisplayProps> = ({ comments }) => {
  if (!comments) return null;
  
  return (
    <Box sx={{ mt: 1.5, pt: 1.5, borderTop: '1px dashed', borderColor: 'divider' }}>
      <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
        Comment:
      </Typography>
      <Typography variant="body2" sx={{ pl: 1, borderLeft: '2px solid', borderColor: 'primary.main', fontSize: '0.9rem' }}>
        {comments}
      </Typography>
    </Box>
  );
};

export default CommentDisplay;