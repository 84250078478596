import React from 'react';
import { Typography } from '@mui/material';

interface QuestionProps {
  question: any;
  children: React.ReactNode;
}

const Question: React.FC<QuestionProps> = ({ question, children }) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg hover:border-gray-300 transition-colors">
      <div className="mb-4">
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 500, 
            display: 'flex',
            alignItems: 'center',
            '&::after': question.required ? {
              content: '"*"',
              color: 'red',
              ml: 0.5
            } : {}
          }}
        >
          {question.label}
        </Typography>
        {question.helperText && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {question.helperText}
          </Typography>
        )}
      </div>
      {children}
    </div>
  );
};

export default Question;