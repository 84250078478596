import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { generateChartColors } from '../Chartutils';
import FilterablePieChart from './FilterablePieChart';
import { normalizeVisualizationData } from '../VisualizationHelper';

interface CheckboxVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
  activeFilter?: any;
  onSegmentClick?: (index: number, value: any, label: string) => void;
  clearFilter?: () => void;
}

const CheckboxVisualization: React.FC<CheckboxVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = [],
  activeFilter,
  onSegmentClick,
  clearFilter
}) => {
  const theme = useTheme();
  const [normalizedData, setNormalizedData] = useState(summaryData);
  
  // Use the normalization helper
  useEffect(() => {
    const normalized = normalizeVisualizationData(question, summaryData, filteredSubmissions);
    setNormalizedData(normalized);
    
    console.log(`CheckboxVisualization for ${question.id}:`, {
      originalData: summaryData,
      normalizedData: normalized
    });
  }, [question, summaryData, filteredSubmissions]);
  
  if (!normalizedData || !normalizedData.options) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No checkbox data available for this question
      </Typography>
    );
  }
  
  // Prepare data for chart - similar to SelectVisualization which works
  const optionLabels = Object.keys(normalizedData.options);
  const optionValues = optionLabels.map(label => normalizedData.options[label]);
  
  // Generate colors based on theme
  const colors = generateChartColors(theme, optionLabels.length);
  
  const chartData = {
    labels: optionLabels,
    originalValues: optionLabels,
    datasets: [{
      data: optionValues,
      backgroundColor: colors,
      borderColor: colors.map((color: string) => theme.palette.mode === 'dark' ? color + '30' : color + '90'),
      borderWidth: 1,
    }]
  };
  
  return (
    <FilterablePieChart
      title="Response Distribution"
      type={optionLabels.length > 6 ? 'doughnut' : 'pie'}
      data={chartData}
      activeFilter={activeFilter}
      onSegmentClick={onSegmentClick}
      clearFilter={clearFilter}
      height={300}
      legendPosition={optionLabels.length > 4 ? 'right' : 'bottom'}
    />
  );
};

export default CheckboxVisualization;