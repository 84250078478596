import React from 'react';
import { Typography } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import { format, differenceInMinutes } from 'date-fns';

interface TimeResponseProps {
  question: any;
  submission: any;
}

const TimeResponse: React.FC<TimeResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  let displayValue = 'Not provided';
  
  try {
    // Log the raw time response for debugging
    console.log(`Time response for ${question.id}:`, response);
    
    // Helper to parse various time formats to Date
    const parseTimeToDate = (timeValue: any): Date | null => {
      if (!timeValue) return null;
      
      try {
        // Handle Firestore timestamp
        if (typeof timeValue.toDate === 'function') {
          return timeValue.toDate();
        }
        // Handle serialized timestamp
        else if (typeof timeValue === 'object' && timeValue.seconds !== undefined) {
          return new Date(timeValue.seconds * 1000);
        } 
        // Handle string time format (HH:MM)
        else if (typeof timeValue === 'string' && timeValue.includes(':')) {
          const today = new Date();
          const [hours, minutes] = timeValue.split(':').map(Number);
          today.setHours(hours, minutes, 0, 0);
          return today;
        }
        // Handle complete date string/object
        else {
          const date = new Date(timeValue);
          return !isNaN(date.getTime()) ? date : null;
        }
      } catch (err) {
        console.error("Error parsing time:", err);
        return null;
      }
    };
    
    if (response) {
      // Check if it's a time range with start/end times
      if (typeof response === 'object' && response !== null &&
          (
            ('startTime' in response && 'endTime' in response) ||
            (response.start && response.end)
          )) {
        
        let startTime, endTime;
        
        if (response.startTime && response.endTime) {
          startTime = parseTimeToDate(response.startTime);
          endTime = parseTimeToDate(response.endTime);
        } else if (response.start && response.end) {
          startTime = parseTimeToDate(response.start);
          endTime = parseTimeToDate(response.end);
        }
        
        // Format times if valid
        if (startTime && endTime && !isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
          // Format using 12-hour format with am/pm for readability
          const formattedStartTime = format(startTime, 'h:mm a');
          const formattedEndTime = format(endTime, 'h:mm a');
          
          // Include minutes in the response if available, or calculate if not
          const minutes = response.minutes !== undefined ? 
                        response.minutes : 
                        differenceInMinutes(endTime, startTime);
          
          // Format response with times and duration
          displayValue = `${formattedStartTime} to ${formattedEndTime} (${minutes} minute${minutes !== 1 ? 's' : ''})`;
        } else {
          // At least one time is invalid, show what we can
          const startStr = response.startTime || (response.start ? JSON.stringify(response.start) : 'Not set');
          const endStr = response.endTime || (response.end ? JSON.stringify(response.end) : 'Not set');
          displayValue = `${startStr} to ${endStr}`;
        }
      } else if (response) {
        // Single time value
        const timeDate = parseTimeToDate(response);
        
        if (timeDate) {
          displayValue = format(timeDate, 'h:mm a');
        } else if (typeof response === 'string') {
          // Already formatted or unparsable string
          displayValue = response;
        } else if (typeof response === 'object') {
          // Some other object, stringify it
          displayValue = JSON.stringify(response)
            .replace(/{|}/g, '')
            .replace(/"/g, '')
            .replace(/,/g, ', ');
        } else {
          // Fallback for any other type
          displayValue = String(response);
        }
      }
    }
  } catch (err) {
    console.error('Error processing time response:', err);
    displayValue = 'Invalid time format';
    
    // If response is an object, stringify it for debugging and display
    if (typeof response === 'object' && response !== null) {
      try {
        displayValue = JSON.stringify(response)
          .replace(/{|}/g, '')
          .replace(/"/g, '')
          .replace(/,/g, ', ');
      } catch (e) {
        // If even JSON.stringify fails
        displayValue = 'Complex time format';
      }
    }
  }
  
  return (
    <>
      <Typography variant="body1">{displayValue}</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

export default TimeResponse;