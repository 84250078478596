import React, { JSX } from 'react';
import TextResponse from './TextResponse';
import DateResponse from './DateResponse';
import TimeResponse from './TimeResponse';
import SelectResponse from './SelectResponse';
import MultiSelectResponse from './MultiselectResponse';
import CheckboxResponse from './CheckboxResponse';
import ImageResponse from './ImageResponse';
import FileResponse from './FileResponse';
import SignatureResponse from './SignatureResponse';
import RepeatableResponse from './RepeatableResponse';

// Define the mapping of question types to their renderers
const responseRenderers: Record<string, React.ComponentType<any>> = {
  'text': TextResponse,
  'long-text': TextResponse,
  'number': TextResponse,
  'date': DateResponse,
  'date-range': DateResponse,
  'time': TimeResponse,
  'select': SelectResponse,
  'multi-select': MultiSelectResponse,
  'checkbox': CheckboxResponse,
  'image': ImageResponse,
  'file': FileResponse,
  'signature': SignatureResponse,
  'repeatable': RepeatableResponse,
};

// Main function to render the appropriate response component
export const renderResponse = (question: any, submission: any): JSX.Element => {
  const { type } = question;
  const ResponseComponent = responseRenderers[type];
  
  if (!ResponseComponent) {
    // Fallback for unknown question types
    return React.createElement('div', {}, `Unsupported question type: ${type}`);
  }
  
  return React.createElement(ResponseComponent, { question, submission });
};

// Export components individually
export { default as TextResponse } from './TextResponse';
export { default as DateResponse } from './DateResponse';
export { default as TimeResponse } from './TimeResponse';
export { default as SelectResponse } from './SelectResponse';
export { default as MultiSelectResponse } from './MultiselectResponse';
export { default as CheckboxResponse } from './CheckboxResponse';
export { default as ImageResponse } from './ImageResponse';
export { default as FileResponse } from './FileResponse';
export { default as SignatureResponse } from './SignatureResponse';
export { default as RepeatableResponse } from './RepeatableResponse';