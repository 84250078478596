import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Divider, useTheme } from '@mui/material';
import { SelectVisualization } from '..';
import { CheckboxVisualization } from '..';
import { NumberVisualization } from '..';
import { DateVisualization } from '..';
import { TimeVisualization } from '..';
import { TextVisualization } from '..';
import { RepeatableVisualization } from '..';

interface SectionVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
  childQuestions: any[];
}

/**
 * A component that renders a section header and visualizations for all child questions
 */
const SectionVisualization: React.FC<SectionVisualizationProps> = ({
  question, 
  summaryData,
  filteredSubmissions = [],
  childQuestions = []
}) => {
  const theme = useTheme();
  const [sectionData, setSectionData] = useState<{[key: string]: any}>({});

  useEffect(() => {
    console.log(`SectionVisualization for ${question.id}:`, {
      sectionQuestion: question,
      childQuestionsCount: childQuestions.length,
      childQuestions: childQuestions.map(q => ({ id: q.id, type: q.type })),
      hasSummaryData: !!summaryData,
      submissionCount: filteredSubmissions.length,
      firstSubmission: filteredSubmissions[0]?.responses ? 
        Object.entries(filteredSubmissions[0].responses).slice(0, 10).map(([key, value]) => `${key}: ${value}`) : 
        'No responses'
    });

    // Look for ANY section-prefixed keys in responses - not just with this section ID
    const sectionKeyPattern = new RegExp(`^section_q_(\\d+)$`);
    const sectionResponses: {[key: string]: any[]} = {};
    
    filteredSubmissions.forEach(submission => {
      if (submission.responses) {
        Object.entries(submission.responses).forEach(([key, value]) => {
          // Check for direct section_q_[number] pattern
          const match = key.match(sectionKeyPattern);
          if (match) {
            const sectionId = match[1];
            if (!sectionResponses[sectionId]) {
              sectionResponses[sectionId] = [];
            }
            
            sectionResponses[sectionId].push({
              value,
              submissionId: submission.id
            });
            
            console.log(`Found section response: ${key} = ${value}`);
          }
        });
      }
    });
    
    // Log discovered section responses
    console.log("All section responses found:", sectionResponses);
    
    // Create section data map for synthetic questions
    const sectionQuestionMap: {[key: string]: any} = {};
    
    Object.entries(sectionResponses).forEach(([sectionId, responses]) => {
      const questionData = {
        id: sectionId,
        label: `Section ${sectionId}`,
        type: 'text',
        sectionId: question.id,
        responses: responses.map(r => r.value)
      };
      
      sectionQuestionMap[sectionId] = questionData;
    });
    
    setSectionData(sectionQuestionMap);
    
  }, [question, summaryData, filteredSubmissions, childQuestions]);

  // Extract child questions directly from submissions if none are provided
  const extractChildQuestionsFromSubmissions = () => {
    // First check if we have defined childQuestions (from sectionQuestions array)
    if (question.sectionQuestions && question.sectionQuestions.length > 0) {
      console.log(`Using ${question.sectionQuestions.length} sectionQuestions from question data`);
      return question.sectionQuestions;
    }
    
    if (childQuestions.length > 0) {
      console.log(`Using ${childQuestions.length} childQuestions from props`);
      return childQuestions;
    }
    
    // Next, look for section_q_[number] pattern entries (without requiring section ID match)
    const sectionKeyPattern = new RegExp(`^section_q_(\\d+)$`);
    const sectionResponseMap = new Map<string, {id: string, responses: any[], label: string}>();
    
    filteredSubmissions.forEach(submission => {
      if (submission.responses) {
        Object.entries(submission.responses).forEach(([key, value]) => {
          // Check if it's a section response directly
          const match = key.match(sectionKeyPattern);
          
          if (match) {
            const questionId = match[1];
            if (!sectionResponseMap.has(questionId)) {
              // Create a synthetic question
              sectionResponseMap.set(questionId, {
                id: questionId,
                responses: [],
                label: `Section Question ${questionId}`
              });
            }
            
            const questionData = sectionResponseMap.get(questionId);
            if (questionData) {
              questionData.responses.push(value);
            }
          }
        });
      }
    });
    
    // If we found section responses, create synthetic questions
    if (sectionResponseMap.size > 0) {
      console.log(`Generated ${sectionResponseMap.size} synthetic section questions`);
      
      // Convert to array of question objects
      return Array.from(sectionResponseMap.values()).map(({ id, label, responses }) => {
        // Determine type from responses
        let inferredType = 'text';
        let effectiveLabel = label;
        
        // Try to find a better label from the actual values
        // If the responses look like names, use the first one as a label
        if (responses.length > 0 && typeof responses[0] === 'string') {
          // If the string seems to be a name (contains spaces, starts with capital)
          if (/^[A-Z][a-z]+ [A-Z][a-z]+/.test(responses[0])) {
            effectiveLabel = `Name: ${responses[0]}`;
          }
        }
        
        if (responses.length > 0) {
          const firstNonEmptyResponse = responses.find(r => r !== null && r !== undefined);
          if (firstNonEmptyResponse !== undefined) {
            if (typeof firstNonEmptyResponse === 'number') {
              inferredType = 'number';
            } else if (typeof firstNonEmptyResponse === 'boolean') {
              inferredType = 'checkbox';
            } else if (typeof firstNonEmptyResponse === 'object') {
              if (firstNonEmptyResponse.seconds !== undefined) {
                inferredType = 'date';
              } else if (Array.isArray(firstNonEmptyResponse)) {
                inferredType = 'multi-select';
              }
            } else if (typeof firstNonEmptyResponse === 'string') {
              // Check if it starts with data:image for signature/drawing
              if (firstNonEmptyResponse.startsWith('data:image')) {
                inferredType = 'signature';
                effectiveLabel = 'Signature';
              }
            }
          }
        }
        
        return {
          id,
          label: effectiveLabel,
          type: inferredType,
          sectionId: question.id,
          // Add synthetic summary data for this question
          summaryData: {
            responseCount: responses.length,
            responses: responses,
            textResponses: responses.map(r => String(r)),
            options: {}
          }
        };
      });
    }
    
    // If still nothing, try to use the sectionData from state
    const sectionQuestions = Object.values(sectionData);
    if (sectionQuestions.length > 0) {
      console.log(`Using ${sectionQuestions.length} questions from sectionData state`);
      return sectionQuestions;
    }
    
    console.log(`No child questions found for section ${question.id} using any method`);
    return [];
  };

  const effectiveChildQuestions = extractChildQuestionsFromSubmissions();

  // If no child questions, show a message
  if (!effectiveChildQuestions || effectiveChildQuestions.length === 0) {
    return (
      <Paper sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Typography variant="h6" color="primary" gutterBottom>
          {question.label || 'Section'}
        </Typography>
        
        {question.description && (
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            {question.description}
          </Typography>
        )}
        
        <Box sx={{ py: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            No questions to visualize in this section
          </Typography>
        </Box>
      </Paper>
    );
  }
  
  // Apply different styles based on the section style setting
  const getSectionStyles = () => {
    const baseStyles = {
      p: 3,
      mb: 4,
      backgroundColor: question.sectionBgColor || 'transparent',
      borderColor: question.sectionBorderColor || theme.palette.divider,
    };

    switch (question.sectionStyle) {
      case "card":
        return {
          ...baseStyles,
          borderRadius: 2,
          boxShadow: 3,
          border: 'none',
        };
      case "outlined":
        return {
          ...baseStyles,
          border: 1,
          borderRadius: 1,
          boxShadow: 0,
        };
      case "subtle":
        return {
          ...baseStyles,
          borderLeft: 4,
          borderColor: theme.palette.primary.main,
          borderRadius: 0,
          boxShadow: 0,
          bgcolor: 'rgba(0, 0, 0, 0.02)',
        };
      default: // "default"
        return {
          ...baseStyles,
          border: 1,
          borderRadius: 1,
          boxShadow: 1,
        };
    }
  };

  return (
    <Paper sx={getSectionStyles()} elevation={0}>
      {/* Section Header */}
      <Box>
        <Typography 
          variant="h5" 
          fontWeight="500" 
          gutterBottom
          color="primary"
        >
          {question.label || 'Section'}
        </Typography>
        
        {question.description && (
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mt: 0.5, mb: 2 }}
          >
            {question.description}
          </Typography>
        )}
      </Box>
      
      {/* Divider */}
      <Divider sx={{ mt: 1, mb: 3 }} />
      
      {/* Render visualizations for each child question */}
      <Box sx={{ '& > *:not(:last-child)': { mb: 4 } }}>
        {effectiveChildQuestions.map((childQuestion: any) => (
          <Box key={childQuestion.id} sx={{ mb: 3 }}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 2, 
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper' 
              }}
            >
              <Typography variant="h6" gutterBottom>
                {childQuestion.label || childQuestion.id}
              </Typography>
              
              {childQuestion.description && (
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {childQuestion.description}
                </Typography>
              )}
              
              {/* Render the appropriate visualization based on question type */}
              {renderVisualizationForChildQuestion(childQuestion, filteredSubmissions)}
            </Paper>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

/**
 * Helper function to render the appropriate visualization for a child question
 */
const renderVisualizationForChildQuestion = (question: any, filteredSubmissions: any[]) => {
  // Prepare specific data for this child question from all submissions
  const extractQuestionData = (childQuestion: any) => {
    // If the question already has summaryData, use it
    if (childQuestion.summaryData) {
      return childQuestion.summaryData;
    }
    
    const sectionPrefix = `section_q_${childQuestion.id}`;
    const options: Record<string, number> = {};
    const textResponses: string[] = [];
    
    // Extract data from submissions
    filteredSubmissions.forEach(submission => {
      if (submission.responses) {
        Object.entries(submission.responses).forEach(([key, value]) => {
          // Check for exact match or pattern match
          if (key === childQuestion.id || key === sectionPrefix) {
            if (value !== undefined && value !== null) {
              // Process the value based on its type
              if (typeof value === 'string') {
                textResponses.push(value);
                options[value] = (options[value] || 0) + 1;
              } else if (typeof value === 'number') {
                textResponses.push(String(value));
                options[String(value)] = (options[String(value)] || 0) + 1;
              } else if (typeof value === 'boolean') {
                const strValue = value ? 'Yes' : 'No';
                textResponses.push(strValue);
                options[strValue] = (options[strValue] || 0) + 1;
              } else if (Array.isArray(value)) {
                value.forEach(v => {
                  options[String(v)] = (options[String(v)] || 0) + 1;
                });
                textResponses.push(value.join(', '));
              } else if (typeof value === 'object' && value !== null) {
                textResponses.push(JSON.stringify(value));
              }
            }
          }
        });
      }
    });
    
    // Create a synthetic summary data object
    return {
      responseCount: textResponses.length,
      textResponses,
      options,
      submissions: filteredSubmissions
    };
  };

  // Get data specific to this question
  const questionData = extractQuestionData(question);
  
  // For signature/image question types
  if (question.type === 'signature' || 
      (questionData.textResponses && 
       questionData.textResponses.length > 0 && 
       typeof questionData.textResponses[0] === 'string' && 
       questionData.textResponses[0].startsWith('data:image'))) {
    
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {questionData.textResponses && questionData.textResponses.length > 0 ? 
            `${questionData.textResponses.length} signatures collected` : 
            'No signatures collected'}
        </Typography>
        
        {questionData.textResponses && questionData.textResponses.length > 0 && (
          <Box sx={{ maxWidth: '300px', mx: 'auto', mt: 2 }}>
            <img 
              src={questionData.textResponses[0]} 
              alt="Signature"
              style={{ 
                width: '100%', 
                border: '1px solid #eee',
                borderRadius: '4px' 
              }}
            />
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
              Sample signature
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
  
  switch (question.type) {
    case 'select':
    case 'radio':
    case 'multi-select':
      return (
        <SelectVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
      
    case 'checkbox':
      return (
        <CheckboxVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
      
    case 'number':
    case 'rating':
      return (
        <NumberVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
      
    case 'date':
    case 'date-range':
      return (
        <DateVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
      
    case 'time':
    case 'time-range':
      return (
        <TimeVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
      
    case 'text':
    case 'textarea':
    case 'email':
    case 'phone':
    case 'name':
    default: // Default to text visualization for unknown types
      return (
        <TextVisualization 
          question={question} 
          summaryData={questionData}
          filteredSubmissions={filteredSubmissions}
        />
      );
  }
};

export default SectionVisualization;