import { format, differenceInMinutes, differenceInCalendarDays, differenceInBusinessDays } from 'date-fns';

/**
 * Helper to convert various timestamp formats to Date
 */
export const convertToDate = (dateValue: any): Date | null => {
  if (!dateValue) return null;
  
  try {
    // Handle Firestore timestamp
    if (typeof dateValue.toDate === 'function') {
      return dateValue.toDate();
    }
    // Handle serialized timestamp
    else if (typeof dateValue === 'object' && dateValue.seconds !== undefined) {
      return new Date(dateValue.seconds * 1000);
    }
    // Regular date string or timestamp
    else {
      const date = new Date(dateValue);
      return !isNaN(date.getTime()) ? date : null;
    }
  } catch (err) {
    console.error("Error converting date:", err);
    return null;
  }
};

/**
 * Safely formats a date value, handling various formats and invalid values
 */
export const formatDate = (timestamp: any): string => {
  if (!timestamp) return 'N/A';
  
  try {
    const date = convertToDate(timestamp);
    if (date) {
      return format(date, 'MMMM d, yyyy');
    }
    return typeof timestamp === 'object' ? JSON.stringify(timestamp) : String(timestamp);
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

/**
 * Format a date range object with start and end dates, including day calculation
 */
export const formatDateRange = (dateRange: any): string => {
  try {
    if (!dateRange) return 'Not provided';
    
    // Check if we have a proper date range object
    if (typeof dateRange === 'object' && dateRange !== null) {
      let startDate = null;
      let endDate = null;
      
      // Check for nested timestamp objects (timestamps can be stored in various ways)
      if (dateRange.startDate && dateRange.endDate) {
        startDate = convertToDate(dateRange.startDate);
        endDate = convertToDate(dateRange.endDate);
      } else if (dateRange.start && dateRange.end) {
        startDate = convertToDate(dateRange.start);
        endDate = convertToDate(dateRange.end);
      } else if (dateRange.from && dateRange.to) {
        startDate = convertToDate(dateRange.from);
        endDate = convertToDate(dateRange.to);
      }
      
      // Format dates if they're valid
      if (startDate && endDate) {
        // Calculate days between dates
        const includeWeekends = dateRange.includeWeekends !== false; // Default to true if not specified
        let days = 0;
        
        if (includeWeekends) {
          days = differenceInCalendarDays(endDate, startDate) + 1; // +1 to include both start and end dates
        } else {
          days = differenceInBusinessDays(endDate, startDate) + 1; // +1 for inclusive counting
        }
        
        // Format the response with dates and days calculation
        let result = `${format(startDate, 'MMMM d, yyyy')} to ${format(endDate, 'MMMM d, yyyy')} (${days} day${days !== 1 ? 's' : ''})`;
        
        // Add hours if they're included in the response
        if (dateRange.useHours && dateRange.hours) {
          result += `, ${dateRange.hours} hour${dateRange.hours !== 1 ? 's' : ''}`;
        }
        
        return result;
      }
      
      // If we can't parse it properly, return a reasonable string representation
      return JSON.stringify(dateRange)
        .replace(/{|}/g, '')
        .replace(/"/g, '')
        .replace(/,/g, ', ');
    }
    
    // Handle case where it's just a string
    return String(dateRange);
  } catch (error) {
    console.error("Error in formatDateRange:", error);
    return 'Invalid date range format';
  }
};

/**
 * Parse various time formats to Date object
 */
export const parseTimeToDate = (timeValue: any): Date | null => {
  if (!timeValue) return null;
  
  try {
    // Handle Firestore timestamp
    if (typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    // Handle serialized timestamp
    else if (typeof timeValue === 'object' && timeValue.seconds !== undefined) {
      return new Date(timeValue.seconds * 1000);
    } 
    // Handle string time format (HH:MM)
    else if (typeof timeValue === 'string' && timeValue.includes(':')) {
      const today = new Date();
      const [hours, minutes] = timeValue.split(':').map(Number);
      today.setHours(hours, minutes, 0, 0);
      return today;
    }
    // Handle complete date string/object
    else {
      const date = new Date(timeValue);
      return !isNaN(date.getTime()) ? date : null;
    }
  } catch (err) {
    console.error("Error parsing time:", err);
    return null;
  }
};

/**
 * Safely formats a time value, handling various formats including time ranges
 */
export const formatTime = (response: any): string => {
  try {
    if (!response) return 'Not provided';
    
    // Handle time range object with start/end times
    if (typeof response === 'object' && response !== null) {
      if ((response.startTime && response.endTime) || (response.start && response.end)) {
        let startTime, endTime;
        
        if (response.startTime && response.endTime) {
          startTime = parseTimeToDate(response.startTime);
          endTime = parseTimeToDate(response.endTime);
        } else if (response.start && response.end) {
          startTime = parseTimeToDate(response.start);
          endTime = parseTimeToDate(response.end);
        }
        
        // Format times if valid
        if (startTime && endTime) {
          const formattedStartTime = format(startTime, 'h:mm a');
          const formattedEndTime = format(endTime, 'h:mm a');
          
          // Calculate minutes if not provided
          let minutes = response.minutes;
          if (minutes === undefined && startTime && endTime) {
            minutes = differenceInMinutes(endTime, startTime);
          }
          
          if (minutes !== undefined) {
            return `${formattedStartTime} to ${formattedEndTime} (${minutes} minute${minutes !== 1 ? 's' : ''})`;
          } else {
            return `${formattedStartTime} to ${formattedEndTime}`;
          }
        }
        
        // Fallback to showing raw values if dates are invalid
        const startStr = response.startTime || (response.start ? JSON.stringify(response.start) : 'Not set');
        const endStr = response.endTime || (response.end ? JSON.stringify(response.end) : 'Not set');
        return `${startStr} to ${endStr}`;
      }
      
      // Handle single time value as object
      const timeDate = parseTimeToDate(response);
      if (timeDate) {
        return format(timeDate, 'h:mm a');
      }
      
      // If it's an object but not a recognized format, stringify it
      return JSON.stringify(response)
        .replace(/{|}/g, '')
        .replace(/"/g, '')
        .replace(/,/g, ', ');
    }
    
    // Handle single time value
    const timeDate = parseTimeToDate(response);
    if (timeDate) {
      return format(timeDate, 'h:mm a');
    }
    
    // If all else fails, return the input as string
    return String(response);
  } catch (error) {
    console.error("Error in formatTime:", error);
    return 'Invalid time format';
  }
};

/**
 * Format file name from URL or object 
 */
export const formatFileName = (fileData: any): string => {
  // If it's a string (URL), try to extract filename
  if (typeof fileData === 'string') {
    try {
      if (fileData.includes('/')) {
        const urlParts = fileData.split('/');
        let fileName = urlParts[urlParts.length - 1].split('?')[0];
        // Decode URI components
        fileName = decodeURIComponent(fileName);
        // Limit filename length
        if (fileName.length > 30) {
          fileName = fileName.substring(0, 27) + '...';
        }
        return fileName;
      }
    } catch (err) {
      console.error("Error extracting filename:", err);
    }
    return "Download File";
  }
  
  // If it's an object with name property
  if (fileData && typeof fileData === 'object' && fileData.name) {
    return fileData.name;
  }
  
  return "Download File";
};