import React, { useState, useEffect } from 'react';
import { TextField, Typography, Box, FormControlLabel, Checkbox, Divider, Paper, InputAdornment, CircularProgress, Switch, Grid, Tooltip, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { isValidEmail, isValidMultipleEmails } from '../../utils/validators';

interface FormHeaderProps {
  formTitle: string;
  email: string;
  formDescription: string;
  formSlug: string;
  companySlug: string | null;
  onFormTitleChange: (title: string) => void;
  onEmailChange: (email: string) => void;
  onFormDescriptionChange: (description: string) => void;
  onFormSlugChange: (slug: string) => void;
  allowPdfCopy: boolean;
  onAllowPdfCopyChange: (allow: boolean) => void;
  requiresApproval: boolean;
  onRequiresApprovalChange: (requires: boolean) => void;
  isSlugAvailable: boolean;
  isCheckingSlug: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  formTitle,
  email,
  formDescription,
  formSlug,
  companySlug,
  onFormTitleChange,
  onEmailChange,
  onFormDescriptionChange,
  onFormSlugChange,
  allowPdfCopy,
  onAllowPdfCopyChange,
  requiresApproval,
  onRequiresApprovalChange,
  isSlugAvailable,
  isCheckingSlug
}) => {
  const [emailError, setEmailError] = useState<string>('');
  
  // Generate slug from form title
  useEffect(() => {
    if (!formSlug && formTitle) {
      const generatedSlug = formTitle
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '');
      onFormSlugChange(generatedSlug);
    }
  }, [formTitle, formSlug, onFormSlugChange]);
  
  // Validate email when it changes
  useEffect(() => {
    if (email && !isValidMultipleEmails(email)) {
      setEmailError('Please enter valid email address(es). For multiple emails, separate with comma.');
    } else {
      setEmailError('');
    }
  }, [email]);

  // Handle slug change
  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = e.target.value
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    onFormSlugChange(newSlug);
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mb: 4 }}>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Form Details
      </Typography>
      
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          Form Title*
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter form title"
          value={formTitle}
          onChange={(e) => onFormTitleChange(e.target.value)}
          required
        />
      </Box>
      
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          Form Description
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          Describe the purpose of this form. This will appear on your landing page.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          placeholder="Enter a description to help users understand what this form is for"
          value={formDescription}
          onChange={(e) => onFormDescriptionChange(e.target.value)}
        />
      </Box>
      
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          Custom URL Slug
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          Create a custom URL for this form. 
          {companySlug ? 
            `The URL will be: formcraft.co.uk/${companySlug}/${formSlug || 'your-form'}` : 
            "Set up your company profile first to create custom URLs."
          }
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="your-form-name"
          value={formSlug}
          onChange={handleSlugChange}
          error={!isSlugAvailable && !isCheckingSlug && !!formSlug}
          helperText={
            isCheckingSlug 
              ? "Checking availability..." 
              : !isSlugAvailable && formSlug 
                ? "This URL is already taken. Please try another." 
                : companySlug 
                  ? `Your form will be available at formcraft.co.uk/${companySlug}/${formSlug || 'your-form'}` 
                  : "Set up your company profile to create custom URLs."
          }
          InputProps={{
            startAdornment: companySlug && (
              <InputAdornment position="start">
                {companySlug}/
              </InputAdornment>
            ),
            endAdornment: isCheckingSlug && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }}
          disabled={isCheckingSlug || !companySlug}
        />
      </Box>
      
      <Divider sx={{ my: 3 }} />
      
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          Notification Email Address(es)*
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          When someone submits your form, we'll send a notification to this email. For multiple recipients, separate with commas.
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="you@example.com"
          value={email}
          onChange={(e) => onEmailChange(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          required
        />
      </Box>
      
      <Box mt={4}>
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          Form Settings
        </Typography>
        <Box sx={{ 
          p: 2, 
          bgcolor: 'background.paper', 
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowPdfCopy}
                    onChange={(e) => onAllowPdfCopyChange(e.target.checked)}
                    color="primary"
                  />
                }
                label="Allow form respondents to receive a PDF copy of their submission"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={requiresApproval}
                      onChange={(e) => onRequiresApprovalChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Require approval for submissions"
                />
                <Tooltip title="When enabled, form submissions will require manual approval before being considered complete.">
                  <IconButton size="small" color="primary">
                    <InfoOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              {requiresApproval && (
                <Typography variant="body2" color="text.secondary" sx={{ ml: 4, mt: 0.5 }}>
                  Form submissions will be held pending until approved. You can manage approvals from your dashboard.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default FormHeader;