import React, { useEffect } from 'react';
import { Box, Typography, Paper, Grid, Divider, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { sortSubmissionsByDate, formatSubmissionLabel } from '../Chartutils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { SubField } from 'store/store';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RepeatableVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

const RepeatableVisualization: React.FC<RepeatableVisualizationProps> = ({ 
  question, 
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  
  // Theme colors for consistent application
  const primaryColor = theme.palette.primary.main;
  const primaryColorLight = theme.palette.primary.main + '40'; // 25% opacity
  
  // Add this effect at the top of your component
  useEffect(() => {
    // Debug the incoming data structure
    console.log('RepeatableVisualization received data:', {
      question,
      summaryData,
      filteredSubmissionsLength: filteredSubmissions.length
    });
    
    // Log details about the first couple of submissions
    if (filteredSubmissions.length > 0) {
      console.log('First submission:', filteredSubmissions[0]);
      if (filteredSubmissions.length > 1) {
        console.log('Second submission:', filteredSubmissions[1]);
      }
    }
  }, []);
  
  // Add after the component declaration, before other functions
  const extractRepeatableItems = () => {
    console.log("Analyzing repeatable data sources for", question.id);
    
    // Check submissions for repeatable data
    let submissionsWithItems = 0;
    let totalItemsFound = 0;
    
    // For debugging purposes, find the first instance of repeatable item
    interface RepeatableItemInfo {
      source: string;
      data: any;
      submissionId: string;
    }
    
    let firstRepeatableItemFound: RepeatableItemInfo | null = null;
    
    effectiveSubmissions.forEach((submission: any) => {
      if (!submission) return; // Skip invalid submissions
      
      let itemsFoundInThisSubmission = false;
      let itemsCountInThisSubmission = 0;
      
      // Check for data in repeatableItems
      if (submission.repeatableItems && submission.repeatableItems[question.id]) {
        const items = submission.repeatableItems[question.id];
        if (Array.isArray(items) && items.length > 0) {
          itemsFoundInThisSubmission = true;
          itemsCountInThisSubmission += items.length;
          totalItemsFound += items.length;
          
          // Store first item for debugging
          if (!firstRepeatableItemFound) {
            firstRepeatableItemFound = {
              source: 'repeatableItems',
              data: items[0],
              submissionId: submission.id
            };
          }
        }
      }
      
      // Check for data in responses
      if (submission.responses && submission.responses[question.id]) {
        const responseData = submission.responses[question.id];
        
        // Handle array of responses
        if (Array.isArray(responseData) && responseData.length > 0) {
          itemsFoundInThisSubmission = true;
          itemsCountInThisSubmission += responseData.length;
          totalItemsFound += responseData.length;
          
          // Store first item for debugging if not already found
          if (!firstRepeatableItemFound) {
            firstRepeatableItemFound = {
              source: 'responses (array)',
              data: responseData[0],
              submissionId: submission.id
            };
          }
        } 
        // Special case: sometimes repeatable data might be stored as an object with numeric keys
        else if (typeof responseData === 'object' && responseData !== null) {
          const possibleArrayItems = Object.keys(responseData)
            .filter(key => !isNaN(Number(key)))
            .map(key => responseData[key]);
            
          if (possibleArrayItems.length > 0) {
            itemsFoundInThisSubmission = true;
            itemsCountInThisSubmission += possibleArrayItems.length;
            totalItemsFound += possibleArrayItems.length;
            
            // Store first item for debugging if not already found
            if (!firstRepeatableItemFound) {
              firstRepeatableItemFound = {
                source: 'responses (object with numeric keys)',
                data: possibleArrayItems[0],
                submissionId: submission.id
              };
            }
          }
        }
      }
      
      if (itemsFoundInThisSubmission) {
        submissionsWithItems++;
        
        // Log detailed info about the first submission with data
        if (submissionsWithItems === 1) {
          console.log(`Found ${itemsCountInThisSubmission} repeatable items in submission ${submission.id}`, {
            submissionData: submission,
            repeatableItems: submission.repeatableItems?.[question.id],
            responses: submission.responses?.[question.id]
          });
        }
      }
    });
    
    console.log(`Found ${submissionsWithItems} submissions with repeatable data, total of ${totalItemsFound} items`);
    
    // If we found any repeatable items, log a sample for debugging
    if (firstRepeatableItemFound) {
      console.log('Sample repeatable item:', firstRepeatableItemFound);
    }
    
    return { 
      submissionsWithItems, 
      totalItemsFound,
      firstRepeatableItemFound
    };
  };

  // Use these helper functions that we've separated from QuestionSummary
  const calculateTotalRepeatableItems = (questionId: string, submissions: any[] = []) => {
    let total = 0;
    let submissionsWithItemsCount = 0;
    
    console.log(`Calculating total repeatable items for ${questionId} with ${submissions.length} submissions`);
    
    // Debug the exact structure of submissions
    if (submissions.length > 0) {
      console.log('First submission structure for debugging:', JSON.stringify(submissions[0], null, 2));
    }
    
    submissions.forEach((submission, index) => {
      let foundItems = false;
      
      // Check if the submission object has expected structure
      if (!submission) {
        console.warn(`Submission at index ${index} is undefined or null`);
        return; // Skip this iteration
      }
      
      // First check repeatableItems structure (primary location)
      if (submission.repeatableItems) {
        const repeatableItems = submission.repeatableItems[questionId];
        console.log(`Repeatable items for ${questionId} in submission ${index}:`, repeatableItems);
        
        if (Array.isArray(repeatableItems) && repeatableItems.length > 0) {
          const itemCount = repeatableItems.length;
          total += itemCount;
          foundItems = true;
          console.log(`Found ${itemCount} items in repeatableItems for submission ${submission.id || index}`);
        }
      }
      
      // Then check responses structure (fallback)
      if (submission.responses) {
        const responseArray = submission.responses[questionId];
        console.log(`Response array for ${questionId} in submission ${index}:`, responseArray);
        
        if (Array.isArray(responseArray) && responseArray.length > 0) {
          const itemCount = responseArray.length;
          total += itemCount;
          foundItems = true;
          console.log(`Found ${itemCount} items in responses for submission ${submission.id || index}`);
        }
      }
      
      if (foundItems) {
        submissionsWithItemsCount++;
      }
    });
    
    console.log(`Total repeatable items for ${questionId}: ${total} in ${submissionsWithItemsCount}/${submissions.length} submissions`);
    return total;
  };

  const calculateAverageRepeatableItems = (questionId: string, submissions: any[] = []) => {
    if (!submissions || submissions.length === 0) return 0;
    
    const total = calculateTotalRepeatableItems(questionId, submissions);
    return total / submissions.length;
  };

  const calculateMaxRepeatableItems = (questionId: string, submissions: any[] = []) => {
    let max = 0;
    
    submissions.forEach(submission => {
      let itemCount = 0;
      
      if (submission.repeatableItems?.[questionId]?.length) {
        itemCount = submission.repeatableItems[questionId].length;
      } else if (Array.isArray(submission.responses?.[questionId])) {
        itemCount = submission.responses[questionId].length;
      }
      
      if (itemCount > max) {
        max = itemCount;
      }
    });
    
    return max;
  };

  // Update your generateRepeatableItemsChart function to better handle edge cases
  const generateRepeatableItemsChart = (questionId: string, submissions: any[] = []) => {
    if (!submissions || submissions.length === 0) {
      // Return a valid empty chart configuration
      return {
        labels: ['No Data'],
        datasets: [{
          label: 'Items',
          data: [0],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      };
    }
    
    // Make sure we have submissions with items data
    const submissionsWithData = submissions.filter(sub => {
      return (sub.repeatableItems?.[questionId]?.length > 0) || 
             (Array.isArray(sub.responses?.[questionId]) && sub.responses[questionId].length > 0);
    });
    
    // If no submissions have data, return empty chart
    if (submissionsWithData.length === 0) {
      return {
        labels: ['No Data'],
        datasets: [{
          label: 'Items',
          data: [0],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      };
    }
    
    // Proceed with chart generation as before
    const sortedSubmissions = sortSubmissionsByDate(submissionsWithData);
    const recentSubmissions = sortedSubmissions.slice(0, 10);
    
    // Generate labels
    const labels = recentSubmissions.map((sub, index) => formatSubmissionLabel(sub, index));
    
    // Count repeatable items for each submission
    const data = recentSubmissions.map(sub => {
      let itemCount = 0;
      
      if (sub.repeatableItems?.[questionId]?.length) {
        itemCount += sub.repeatableItems[questionId].length;
      }
      
      if (Array.isArray(sub.responses?.[questionId])) {
        itemCount += sub.responses[questionId].length;
      }
      
      return itemCount;
    });
    
    return {
      labels,
      datasets: [{
        label: 'Number of Items',
        data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
        hoverBorderColor: 'rgba(75, 192, 192, 1)',
        hoverBorderWidth: 2
      }]
    };
  };

  const generateSubFieldSummary = (questionId: string, subFieldId: string, subFieldType: string, submissions: any[] = []) => {
    if (!submissions || submissions.length === 0) {
      return "No data available";
    }
    
    // Collect values for this subfield from all submissions
    const values: any[] = [];
    let validCount = 0;
    
    submissions.forEach(submission => {
      let items: any[] = [];
      
      // Get items from either repeatableItems or responses
      if (submission.repeatableItems?.[questionId]?.length) {
        items = submission.repeatableItems[questionId];
      } else if (Array.isArray(submission.responses?.[questionId])) {
        items = submission.responses[questionId];
      }
      
      // Collect subfield values from each item
      items.forEach(item => {
        if (item[subFieldId] !== undefined && item[subFieldId] !== null) {
          values.push(item[subFieldId]);
          validCount++;
        }
      });
    });
    
    if (validCount === 0) {
      return "No values provided";
    }
    
    // Generate different summaries based on subfield type
    if (subFieldType === 'text' || subFieldType === 'long-text') {
      return `${validCount} text entries provided`;
    } else if (subFieldType === 'number') {
      // Calculate average for numeric fields
      const numValues = values.map(v => typeof v === 'number' ? v : Number(v)).filter(n => !isNaN(n));
      if (numValues.length === 0) return "No valid numeric values";
      const avg = numValues.reduce((a, b) => a + b, 0) / numValues.length;
      return `Average: ${avg.toFixed(1)}, Count: ${numValues.length}`;
    } else if (subFieldType === 'select' || subFieldType === 'multi-select') {
      // Count option frequencies
      const optionCounts: Record<string, number> = {};
      values.forEach(val => {
        if (Array.isArray(val)) {
          // Handle multi-select
          val.forEach(option => {
            optionCounts[option] = (optionCounts[option] || 0) + 1;
          });
        } else {
          // Handle single select
          optionCounts[val] = (optionCounts[val] || 0) + 1;
        }
      });
      
      // Get top 2 options
      const sortedOptions = Object.entries(optionCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 2);
      
      if (sortedOptions.length === 0) return "No options selected";
      
      return `Most common: ${sortedOptions.map(([option, count]) => 
        `"${option}" (${count})`).join(', ')}`;
    } else if (subFieldType === 'checkbox') {
      // For checkbox, count true values
      const trueCount = values.filter(v => v === true || v === 'true' || v === 'yes' || v === 'Yes').length;
      return `${trueCount} checked / ${validCount} total (${Math.round((trueCount/validCount) * 100)}%)`;
    } else if (subFieldType === 'date') {
      return `${validCount} dates provided`;
    } else {
      return `${validCount} values provided`;
    }
  };

  // Replace your getEffectiveSubmissions function with this simpler version
  const getEffectiveSubmissions = () => {
    console.log('Getting effective submissions for repeatable item visualization', {
      summaryData,
      filteredSubmissions,
      questionId: question.id
    });

    // Always prefer filteredSubmissions if available
    if (filteredSubmissions && filteredSubmissions.length > 0) {
      console.log(`Using filteredSubmissions array with ${filteredSubmissions.length} items`);
      return filteredSubmissions;
    }
    
    // If we don't have filtered submissions, create a sample
    console.warn('No valid submissions found, using synthetic data for demonstration');
    return injectTestDataIfNeeded([]);
  };

  // At the top level, force using synthesized data when no real data is detected
  const effectiveSubmissions = filteredSubmissions;

  // Add this right after your const effectiveSubmissions = ... line
  // Extract repeatable data more directly, similar to TextVisualization's approach
  const extractRepeatableData = () => {
    const items: any[] = [];
    let totalItemCount = 0;
    
    // Always work with effectiveSubmissions
    effectiveSubmissions.forEach(sub => {
      // First check for data in repeatableItems (preferred way)
      if (sub && sub.repeatableItems && sub.repeatableItems[question.id]) {
        const repeatableItems = sub.repeatableItems[question.id];
        if (Array.isArray(repeatableItems) && repeatableItems.length > 0) {
          items.push(...repeatableItems);
          totalItemCount += repeatableItems.length;
          console.log(`Found ${repeatableItems.length} items in repeatableItems for submission ${sub.id}`);
        }
      }
      
      // Then check for data in responses (fallback)
      if (sub && sub.responses && sub.responses[question.id]) {
        const responses = sub.responses[question.id];
        if (Array.isArray(responses) && responses.length > 0) {
          items.push(...responses);
          totalItemCount += responses.length;
          console.log(`Found ${responses.length} items in responses for submission ${sub.id}`);
        }
      }
    });
    
    console.log(`Total repeatable items extracted: ${totalItemCount} from ${effectiveSubmissions.length} submissions`);
    return {
      items,
      totalItemCount,
      hasData: totalItemCount > 0
    };
  };

  // Use this more direct extraction
  const repeatableData = extractRepeatableData();
  const hasItems = repeatableData.hasData || effectiveSubmissions.length > 0;

  // For debugging, add this right after:
  console.log('RepeatableVisualization render decision:', {
    hasItems,
    effectiveSubmissionsLength: effectiveSubmissions.length
  });

  // Add right after the effectiveSubmissions declaration
  console.log(`DEBUG: RepeatableVisualization Component IDs:`, {
    componentQuestionId: question.id,
    expectedQuestionId: 'ee7d5852-84b0-430f-a683-9d7486c7e3ac', 
    match: question.id === 'ee7d5852-84b0-430f-a683-9d7486c7e3ac'
  });

  // Call this function in the component's return statement, right after the effectiveSubmissions calculation
  useEffect(() => {
    // Only run this once when the component mounts
    extractRepeatableItems();
  }, []);

  // After the effectiveSubmissions definition, add these debug logs
  useEffect(() => {
    // Debug effective submissions in more detail
    console.log(`RepeatableVisualization for question ${question.id}:`, {
      filteredSubmissionsLength: filteredSubmissions.length,
      summaryDataSubmissionsLength: summaryData?.submissions?.length || 0,
      effectiveSubmissionsLength: effectiveSubmissions.length,
      questionId: question.id
    });

    // Inspect first few submissions to see their structure
    if (effectiveSubmissions.length > 0) {
      console.log('First submission structure:', {
        id: effectiveSubmissions[0].id,
        hasRepeatableItems: !!effectiveSubmissions[0].repeatableItems,
        hasResponses: !!effectiveSubmissions[0].responses,
        questionInRepeatableItems: effectiveSubmissions[0].repeatableItems?.[question.id] ? 
          `Array with ${effectiveSubmissions[0].repeatableItems[question.id].length} items` : 
          'Not found',
        questionInResponses: effectiveSubmissions[0].responses?.[question.id] ?
          (Array.isArray(effectiveSubmissions[0].responses[question.id]) ?
            `Array with ${effectiveSubmissions[0].responses[question.id].length} items` : 
            'Not an array') : 
          'Not found'
      });
    } else {
      console.log('No effective submissions available to inspect');
    }

    // Extract repeatable items info
    const { submissionsWithItems, totalItemsFound } = extractRepeatableItems();
    
    console.log(`Repeatable visualization rendering decision:`, {
      totalItems: calculateTotalRepeatableItems(question.id, effectiveSubmissions),
      submissionsWithItems,
      totalItemsFound,
      willRenderVisualization: calculateTotalRepeatableItems(question.id, effectiveSubmissions) > 0
    });
  }, [effectiveSubmissions, question.id]); // Depend on these values to re-run when they change

  // Add a function to inject test data if real data is missing
  const injectTestDataIfNeeded = (submissions: any[]) => {
    // If we already have submissions with repeatable data, return them unchanged
    const dataCheck = extractRepeatableItems();
    if (dataCheck.totalItemsFound > 0) {
      return submissions;
    }
    
    // Otherwise, create a synthetic submission with the expected structure
    console.log('No repeatable items found in submissions, creating test data');
    
    // Check if we have question.subFields
    if (!question.subFields || !Array.isArray(question.subFields) || question.subFields.length === 0) {
      console.warn('Cannot create test data - no subFields defined in the question');
      return submissions;
    }
    
    // Create sample repeatable items based on the question's subfields
    const createSampleItem = () => {
      const item: any = {};
      
      question.subFields.forEach((subField: any) => {
        // Populate with dummy values based on type
        switch (subField.type) {
          case 'text':
          case 'long-text':
            item[subField.id] = `Sample text for ${subField.label}`;
            break;
          case 'number':
            item[subField.id] = Math.floor(Math.random() * 100);
            break;
          case 'select':
            item[subField.id] = 'Option 1';
            break;
          case 'multi-select':
            item[subField.id] = ['Option 1', 'Option 3'];
            break;
          case 'checkbox':
            item[subField.id] = Math.random() > 0.5;
            break;
          case 'date':
            item[subField.id] = new Date().toISOString().split('T')[0];
            break;
          default:
            item[subField.id] = 'Sample value';
        }
      });
      
      return item;
    };
    
    // Create sample submissions
    const sampleSubmissions = [];
    for (let i = 0; i < 3; i++) {
      // Create a submission with 1-3 repeatable items
      const itemCount = Math.floor(Math.random() * 3) + 1;
      const repeatableItems: any[] = [];
      
      for (let j = 0; j < itemCount; j++) {
        repeatableItems.push(createSampleItem());
      }
      
      // Create a submission with the right structure
      sampleSubmissions.push({
        id: `sample-${i}`,
        email: `user${i}@example.com`,
        submittedAt: new Date(),
        repeatableItems: {
          [question.id]: repeatableItems
        },
        responses: {}
      });
    }
    
    // Combine the existing submissions with our sample data
    // Using a special flag to mark the synthetic data
    const combinedSubmissions = [
      ...submissions,
      ...sampleSubmissions.map(sub => ({ ...sub, __synthetic: true }))
    ];
    
    console.log('Created combined submissions with synthetic data:', combinedSubmissions);
    return combinedSubmissions;
  };

  // 1. Add this aggregation function to count and group text values
  const aggregateSubFieldValues = (subFieldId: string) => {
    const valueMap: Record<string, number> = {};
    let totalValues = 0;
    
    // Process all items from all submissions
    effectiveSubmissions.forEach(sub => {
      // Get repeatable items from either source
      const items = [
        ...(sub.repeatableItems?.[question.id] || []),
        ...(Array.isArray(sub.responses?.[question.id]) ? sub.responses[question.id] : [])
      ];
      
      // Count occurrences of each value
      items.forEach(item => {
        if (item && item[subFieldId] !== undefined && item[subFieldId] !== null) {
          const value = String(item[subFieldId]).trim();
          if (value) {
            valueMap[value] = (valueMap[value] || 0) + 1;
            totalValues++;
          }
        }
      });
    });
    
    // Sort values by frequency (most frequent first)
    const sortedValues = Object.entries(valueMap)
      .sort((a, b) => b[1] - a[1])
      .map(([value, count]) => ({ value, count }));
    
    return {
      values: sortedValues,
      totalValues,
      uniqueValues: sortedValues.length
    };
  };

  // Fix the conditional rendering in the return statement
  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {question.label || 'Repeatable Items'} Summary
      </Typography>
      
      {hasItems ? (
        <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.paper' }}>
          {/* Statistics about repeatable items */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                bgcolor: theme.palette.primary.main + '15', 
                textAlign: 'center', 
                borderRadius: 2,
                border: `1px solid ${theme.palette.primary.main + '30'}` 
              }}>
                <Typography variant="caption" color="text.secondary">Total Items</Typography>
                <Typography variant="h5" color="primary.main" fontWeight="bold">
                  {calculateTotalRepeatableItems(question.id, effectiveSubmissions)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                bgcolor: theme.palette.primary.main + '15', 
                textAlign: 'center', 
                borderRadius: 2,
                border: `1px solid ${theme.palette.primary.main + '30'}`
              }}>
                <Typography variant="caption" color="text.secondary">Average per Response</Typography>
                <Typography variant="h5" color="primary.main" fontWeight="bold">
                  {calculateAverageRepeatableItems(question.id, effectiveSubmissions).toFixed(1)}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                bgcolor: theme.palette.primary.main + '15', 
                textAlign: 'center', 
                borderRadius: 2,
                border: `1px solid ${theme.palette.primary.main + '30'}`
              }}>
                <Typography variant="caption" color="text.secondary">Max Items</Typography>
                <Typography variant="h5" color="primary.main" fontWeight="bold">
                  {calculateMaxRepeatableItems(question.id, effectiveSubmissions)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* Now add the text summary section to display medication names */}
                {question.subFields && 
                 question.subFields.length > 0 && 
                 question.subFields.some((sf: SubField) => sf.type === 'text' || sf.type === 'long-text') && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                {question.label || 'Item'} Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              {/* Get the first text field to show values for */}
              {(() => {
                const textField: SubField | undefined = question.subFields.find((sf: SubField) => sf.type === 'text' || sf.type === 'long-text');
                if (!textField) return null;
                
                const { values, totalValues, uniqueValues } = aggregateSubFieldValues(textField.id);
                
                return (
                  <Box>
                    <Typography variant="body2" fontWeight="medium" gutterBottom>
                      {textField.label} ({uniqueValues} unique {uniqueValues === 1 ? 'value' : 'values'})
                    </Typography>
                    
                    {values.length > 0 ? (
                      <Grid container spacing={1}>
                        {values.map(({ value, count }) => (
                          <Grid item xs={12} sm={6} md={4} key={value}>
                            <Paper 
                              elevation={0} 
                              sx={{ 
                                p: 1, 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                bgcolor: theme.palette.background.default,
                                borderRadius: 1,
                                border: `1px solid ${theme.palette.divider}`
                              }}
                            >
                              <Typography variant="body2" sx={{ fontWeight: count > 1 ? 'bold' : 'normal' }}>
                                {value}
                              </Typography>
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  ml: 1, 
                                  bgcolor: count > 1 ? theme.palette.primary.main + '30' : theme.palette.action.hover,
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                  fontWeight: count > 1 ? 'bold' : 'normal'
                                }}
                              >
                                {count}
                              </Typography>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No {textField.label.toLowerCase()} values found
                      </Typography>
                    )}
                  </Box>
                );
              })()}
            </Box>
          )}
          
          {/* Replace the Bar chart section with this updated version */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              {question.label || 'Items'} per Submission
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            {/* Table of submissions with their item counts */}
            <Box sx={{ 
              border: `1px solid ${theme.palette.divider}`, 
              borderRadius: 1, 
              overflow: 'hidden',
              maxHeight: '250px',
              overflowY: 'auto'
            }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ 
                    backgroundColor: theme.palette.background.default,
                    borderBottom: `1px solid ${theme.palette.divider}`
                  }}>
                    <th style={{ padding: '10px', textAlign: 'left' }}>Submission</th>
                    <th style={{ padding: '10px', textAlign: 'center' }}>{question.label || 'Items'}</th>
                    <th style={{ padding: '10px', textAlign: 'left' }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {sortSubmissionsByDate(effectiveSubmissions).map((sub, index) => {
                    const itemCount = (sub.repeatableItems?.[question.id]?.length || 0) + 
                                   (Array.isArray(sub.responses?.[question.id]) ? sub.responses[question.id].length : 0);
                    
                    // Format date
                    const dateStr = sub.submittedAt ? (() => {
                      try {
                        // Handle Firebase timestamp
                        if (typeof sub.submittedAt.toDate === 'function') {
                          return format(sub.submittedAt.toDate(), 'MMM d, yyyy');
                        }
                        // Handle serialized timestamp
                        if (sub.submittedAt.seconds) {
                          return format(new Date(sub.submittedAt.seconds * 1000), 'MMM d, yyyy');
                        }
                        // Handle string or Date
                        return format(new Date(sub.submittedAt), 'MMM d, yyyy');
                      } catch (e) {
                        return 'Invalid date';
                      }
                    })() : 'No date';
                    
                    return (
                      <tr key={sub.id || index} style={{ 
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        backgroundColor: index % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default
                      }}>
                        <td style={{ padding: '8px 10px' }}>
                          {sub.email ? sub.email.split('@')[0] : `Submission ${index + 1}`}
                        </td>
                        <td style={{ padding: '8px 10px', textAlign: 'center' }}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontWeight: 'bold',
                              display: 'inline-block',
                              bgcolor: itemCount > 0 ? theme.palette.primary.main + '20' : theme.palette.action.hover,
                              px: 1.5,
                              py: 0.5,
                              borderRadius: '4px',
                              minWidth: '30px'
                            }}
                          >
                            {itemCount}
                          </Typography>
                        </td>
                        <td style={{ padding: '8px 10px' }}>
                          <Typography variant="body2" color="text.secondary">
                            {dateStr}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </Box>

          {/* If there are subfields, show analysis of first few */}
          {question.subFields && question.subFields.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Sub-field Analysis
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {question.subFields.slice(0, 3).map((subField: any) => (
                <Box key={subField.id} sx={{ mb: 2 }}>
                  <Typography variant="body2" fontWeight="medium" gutterBottom>
                    {subField.label}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {generateSubFieldSummary(question.id, subField.id, subField.type, effectiveSubmissions)}
                  </Typography>
                </Box>
              ))}
              {question.subFields.length > 3 && (
                <Typography variant="caption" color="text.secondary">
                  + {question.subFields.length - 3} more sub-fields not shown
                </Typography>
              )}
            </Box>
          )}
        </Paper>
      ) : (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            No repeatable items found for this question
          </Typography>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            Question ID: {question.id}
          </Typography>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            Submissions available: {effectiveSubmissions.length}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => {
              // Force rendering with synthetic data
              const syntheticSubmissions = injectTestDataIfNeeded([]);
              console.log("Synthetic submissions created:", syntheticSubmissions);
              alert(`Created ${syntheticSubmissions.length} synthetic submissions with repeatable data. Please check console.`);
            }}
          >
            Force Test Data
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RepeatableVisualization;