import React from "react";
import { 
  Box, 
  TextField, 
  Typography,
  Select,
  MenuItem
} from "@mui/material";
import { Question } from "../../../store/store";

interface LongTextQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const LongTextQuestion: React.FC<LongTextQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Question Label"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle2" gutterBottom>Text Constraints</Typography>
        <Box display="flex" gap={2}>
          <TextField
            label="Min Characters"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.minChars || ""}
            onChange={(e) => onChange({ minChars: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
          <TextField
            label="Max Characters"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.maxChars || ""}
            onChange={(e) => onChange({ maxChars: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
        </Box>
        <TextField
          fullWidth
          label="Helper Text"
          placeholder="Enter helper text to guide respondents (optional)"
          value={question.helperText || ""}
          onChange={(e) => onChange({ helperText: e.target.value })}
          sx={{ mb: 2 }}
        />

        {/* Preview */}
        <Box mt={3} p={2} bgcolor="#f8f8f8" borderRadius={1}>
          <Typography variant="caption" color="textSecondary">Preview:</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder={question.helperText || "Enter your response here..."}
            disabled
            sx={{ mt: 1 }}
          />
          {((question.minChars ?? 0) > 0 || (question.maxChars ?? 0) > 0) && (
            <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5, display: 'block' }}>
              {(question.minChars ?? 0) > 0 && (question.maxChars ?? 0) > 0 
                ? `${question.minChars} to ${question.maxChars} characters required` 
                : (question.minChars ?? 0) > 0 
                  ? `Minimum ${question.minChars} characters required`
                  : (question.maxChars ?? 0) > 0 
                    ? `Maximum ${question.maxChars} characters allowed`
                    : ""}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LongTextQuestion;