import { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';

// Helper to ensure summaryData is in the expected format for each visualization type
export const normalizeVisualizationData = (question: any, summaryData: any, filteredSubmissions: any[] = []) => {
  // Make sure summaryData is an object
  const normalizedData = summaryData || {};
  
  // Common data that all visualizations need
  const baseData = {
    responseCount: filteredSubmissions.length || normalizedData.responseCount || 0,
    submissions: filteredSubmissions.length > 0 ? filteredSubmissions : (normalizedData.submissions || [])
  };
  
  // Handle different question types
  switch (question.type) {
    case 'repeatable':
      return {
        ...normalizedData,
        ...baseData
      };
    
    case 'text':
    case 'long-text':
      return {
        ...normalizedData,
        ...baseData,
        textResponses: normalizedData.textResponses || 
          extractTextResponses(question, baseData.submissions)
      };
    
    case 'number':
      return {
        ...normalizedData,
        ...baseData,
        numericResponses: normalizedData.numericResponses || 
          extractNumericResponses(question, baseData.submissions)
      };
    
    case 'date':
    case 'date-range':
      return {
        ...normalizedData,
        ...baseData,
        dateResponses: normalizedData.dateResponses || 
          extractDateResponses(question, baseData.submissions),
        // Add date ranges extraction for date-range questions
        dateRanges: question.type === 'date-range' ? (
          normalizedData.dateRanges || extractDateRanges(question, baseData.submissions)
        ) : undefined
      };
    
    case 'time':
      return {
        ...normalizedData,
        ...baseData,
        timeResponses: normalizedData.timeResponses || 
          extractTimeResponses(question, baseData.submissions)
      };
    
    case 'select':
    case 'multi-select':
    case 'checkbox':
      return {
        ...normalizedData,
        ...baseData,
        options: normalizedData.options || 
          extractOptions(question, baseData.submissions)
      };
    
    default:
      // For other question types, make sure responseCount exists
      return {
        ...normalizedData,
        ...baseData
      };
  }
};

// Helper functions to extract data from submissions

// Extract text responses from submissions
const extractTextResponses = (question: any, submissions: any[]) => {
  return submissions
    .map(sub => {
      const value = sub?.responses?.[question.id];
      if (value === undefined || value === null) return null;
      
      return typeof value === 'string' ? value.trim() : String(value).trim();
    })
    .filter(text => text !== null && text !== '') as string[];
};

// Extract numeric responses
const extractNumericResponses = (question: any, submissions: any[]) => {
  return submissions
    .map(sub => {
      const value = sub?.responses?.[question.id];
      if (value === undefined || value === null) return null;
      
      const numValue = typeof value === 'number' ? value : Number(value);
      return isNaN(numValue) ? null : numValue;
    })
    .filter(num => num !== null) as number[];
};

// Extract date responses
const extractDateResponses = (question: any, submissions: any[]) => {
  return submissions
    .map(sub => {
      const value = sub?.responses?.[question.id];
      if (value === undefined || value === null) return null;
      
      try {
        // Handle date objects
        if (value instanceof Date) return value;
        
        // Handle Firestore timestamps
        if (typeof value === 'object' && value !== null) {
          if (typeof value.toDate === 'function') {
            return value.toDate();
          }
          if (value.seconds !== undefined) {
            return new Date(value.seconds * 1000);
          }
        }
        
        // Handle string dates
        return new Date(value);
      } catch (e) {
        return null;
      }
    })
    .filter(date => date !== null && !isNaN(date.getTime())) as Date[];
};

// Update the extractDateRanges function to handle both field naming conventions:
const extractDateRanges = (question: any, submissions: any[]) => {
  console.log(`Extracting date ranges for question ${question.id}`, {
    type: question.type,
    submissionsCount: submissions.length,
    firstSubmission: submissions[0]
  });
  
  if (submissions.length === 0) {
    console.warn('No submissions available for date range extraction');
    return [];
  }
  
  // Check the format of the first submission to understand the data structure
  if (submissions[0]?.responses?.[question.id]) {
    console.log('First submission response format:', {
      response: submissions[0].responses[question.id],
      type: typeof submissions[0].responses[question.id],
      hasStart: !!submissions[0].responses[question.id]?.start,
      hasEnd: !!submissions[0].responses[question.id]?.end,
      hasStartDate: !!submissions[0].responses[question.id]?.startDate,
      hasEndDate: !!submissions[0].responses[question.id]?.endDate
    });
  }
  
  const ranges = submissions
    .map(sub => {
      const value = sub?.responses?.[question.id];
      if (!value || typeof value !== 'object') {
        return null;
      }
      
      try {
        console.log(`Processing date range from submission ${sub.id || 'unknown'}:`, value);
        
        // Extract start date - handle both 'start' and 'startDate' field names
        let startDate: Date | null = null;
        const startValue = value.start || value.startDate;
        
        if (startValue) {
          if (startValue instanceof Date) {
            startDate = startValue;
          } else if (typeof startValue === 'object' && typeof startValue.toDate === 'function') {
            startDate = startValue.toDate();
          } else if (typeof startValue === 'object' && startValue.seconds !== undefined) {
            startDate = new Date(startValue.seconds * 1000);
          } else {
            startDate = new Date(startValue);
          }
          
          // Validate the date
          if (startDate && isNaN(startDate.getTime())) {
            console.warn(`Invalid start date in submission ${sub.id || 'unknown'}:`, startValue);
            startDate = null;
          }
        }
        
        // Extract end date - handle both 'end' and 'endDate' field names
        let endDate: Date | null = null;
        const endValue = value.end || value.endDate;
        
        if (endValue) {
          if (endValue instanceof Date) {
            endDate = endValue;
          } else if (typeof endValue === 'object' && typeof endValue.toDate === 'function') {
            endDate = endValue.toDate();
          } else if (typeof endValue === 'object' && endValue.seconds !== undefined) {
            endDate = new Date(endValue.seconds * 1000);
          } else {
            endDate = new Date(endValue);
          }
          
          // Validate the date
          if (endDate && isNaN(endDate.getTime())) {
            console.warn(`Invalid end date in submission ${sub.id || 'unknown'}:`, endValue);
            endDate = null;
          }
        }
        
        // If we have both dates, return the range
        if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
          // Ensure end date is not before start date
          if (endDate < startDate) {
            console.warn(`End date is before start date in submission ${sub.id || 'unknown'}, swapping dates`);
            [startDate, endDate] = [endDate, startDate];
          }
          
          console.log(`Valid date range found: ${startDate.toISOString()} to ${endDate.toISOString()}`);
          
          return {
            start: startDate,
            end: endDate,
            email: sub.email || 'Anonymous',
            label: sub.formId || sub.id || 'Unknown',
            includeWeekends: value.includeWeekends !== false,
            useHours: !!value.useHours,
            hours: value.hours || null
          };
        }
        
        console.warn(`Missing or invalid date range in submission ${sub.id || 'unknown'}:`, { 
          startDate, 
          endDate,
          originalStart: startValue,
          originalEnd: endValue 
        });
        return null;
      } catch (e) {
        console.error(`Error processing date range for submission ${sub.id || 'unknown'}:`, e);
        return null;
      }
    })
    .filter(range => range !== null) as Array<{
      start: Date;
      end: Date;
      email?: string;
      label?: string;
      includeWeekends?: boolean;
      useHours?: boolean;
      hours?: number | null;
    }>;
    
  console.log(`Extracted ${ranges.length} valid date ranges`);
  return ranges;
};

// Extract time responses
const extractTimeResponses = (question: any, submissions: any[]) => {
  return submissions
    .map(sub => {
      const value = sub?.responses?.[question.id];
      if (value === undefined || value === null) return null;
      
      // Handle time string (HH:MM)
      if (typeof value === 'string' && value.includes(':')) {
        return value;
      }
      
      return null;
    })
    .filter(time => time !== null) as string[];
};

// Extract options for select/multi-select/checkbox
const extractOptions = (question: any, submissions: any[]) => {
  const options: Record<string, number> = {};
  
  submissions.forEach(sub => {
    const value = sub?.responses?.[question.id];
    if (value === undefined || value === null) return;
    
    // Handle single selection
    if (typeof value === 'string' || typeof value === 'number') {
      const optionKey = String(value);
      options[optionKey] = (options[optionKey] || 0) + 1;
    }
    // Handle multi-select (array)
    else if (Array.isArray(value)) {
      value.forEach(option => {
        if (option) {
          const optionKey = String(option);
          options[optionKey] = (options[optionKey] || 0) + 1;
        }
      });
    }
    // Handle checkbox-style object
    else if (typeof value === 'object') {
      Object.entries(value).forEach(([key, selected]) => {
        if (selected) {
          options[key] = (options[key] || 0) + 1;
        }
      });
    }
    // Handle boolean values
    else if (typeof value === 'boolean') {
      const optionKey = value ? 'Yes' : 'No';
      options[optionKey] = (options[optionKey] || 0) + 1;
    }
  });
  
  return options;
};

// Add a new hook for components that need normalized data
export const useNormalizedData = (question: any, summaryData: any, filteredSubmissions: any[] = []) => {
  const [normalizedData, setNormalizedData] = useState<any>(null);
  
  useEffect(() => {
    const normalized = normalizeVisualizationData(question, summaryData, filteredSubmissions);
    setNormalizedData(normalized);
  }, [question, summaryData, filteredSubmissions]);
  
  return normalizedData;
};