import React from "react";
import { Box, Paper, IconButton, Stack, Chip, Divider, Tooltip } from "@mui/material";
import {
  Delete,
  ArrowUpward,
  ArrowDownward,
  Check,
  DoNotDisturb,
  ContentCopy // Add this new import
} from "@mui/icons-material";

interface QuestionWrapperProps {
  children: React.ReactNode;
  questionId: string;
  index: number;
  required: boolean;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onMove: (direction: "up" | "down") => void;
  onDelete: () => void;
  onToggleRequired: () => void;
  onDuplicate: () => void; // Add this new prop
  showRequired: boolean;
}

const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
  children,
  questionId,
  index,
  required,
  canMoveUp,
  canMoveDown,
  onMove,
  onDelete,
  onToggleRequired,
  onDuplicate, // Add this new prop
  showRequired
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        pt: 4,
        mb: 3,
        borderRadius: 2,
        position: "relative",
        borderLeft: theme => `4px solid ${theme.palette.primary.main}`,
        transition: "box-shadow 0.3s, transform 0.2s",
        "&:hover": {
          boxShadow: 4,
          transform: "translateY(-2px)",
        }
      }}
    >
      <Box sx={{ position: "relative" }}>
        {/* Question Number Indicator */}
        <Chip 
          label={`Q${index + 1}`} 
          color="primary"
          size="small"
          sx={{ 
            position: 'absolute', 
            top: -32,
            left: -12,
            fontWeight: 'bold',
            boxShadow: 1
          }}
        />
        
        {children}
        
        <Divider sx={{ my: 2 }} />
        
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1
          }}
        >
          <Stack direction="row" spacing={1}>
            <Tooltip title="Move question up">
              <span>
                <IconButton
                  size="small"
                  onClick={() => onMove("up")}
                  disabled={!canMoveUp}
                >
                  <ArrowUpward fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            
            <Tooltip title="Move question down">
              <span>
                <IconButton
                  size="small"
                  onClick={() => onMove("down")}
                  disabled={!canMoveDown}
                >
                  <ArrowDownward fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            
            {/* Add duplicate button here */}
            <Tooltip title="Duplicate question">
              <IconButton
                size="small"
                color="info"
                onClick={onDuplicate}
              >
                <ContentCopy fontSize="small" />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Delete question">
              <IconButton
                size="small"
                color="error"
                onClick={onDelete}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
          
          {showRequired && (
            <Tooltip title={required ? "Make optional" : "Make required"}>
              <IconButton
                size="small"
                color={required ? "success" : "default"}
                onClick={onToggleRequired}
              >
                {required ? <Check /> : <DoNotDisturb />}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default QuestionWrapper;