import { format, isValid } from 'date-fns';

/**
 * Normalizes text responses for consistent representation
 */
export const normalizeTextResponses = (responses: Record<string, any>): Record<string, any> => {
  const normalizedResponses: Record<string, any> = {};
  
  for (const key in responses) {
    const value = responses[key];
    
    // Handle string values
    if (typeof value === 'string') {
      normalizedResponses[key] = value.trim();
    } 
    // Handle arrays (like multi-select)
    else if (Array.isArray(value)) {
      normalizedResponses[key] = value.map(item => 
        typeof item === 'string' ? item.trim() : item
      );
    }
    // Handle objects recursively
    else if (value !== null && typeof value === 'object') {
      normalizedResponses[key] = normalizeTextResponses(value);
    }
    // Keep other types as is
    else {
      normalizedResponses[key] = value;
    }
  }
  
  return normalizedResponses;
};

/**
 * Normalizes a response value to handle case sensitivity and yes/no variations
 */
export const normalizeResponseValue = (value: any): any => {
  // Handle null/undefined
  if (value === null || value === undefined) {
    return '';
  }
  
  // Handle boolean values
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  
  // Handle string values
  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase().trim();
    
    // Normalize yes/no/true/false variants
    if (lowerValue === 'yes' || lowerValue === 'true' || lowerValue === '1' || lowerValue === 'y') {
      return 'yes';
    }
    if (lowerValue === 'no' || lowerValue === 'false' || lowerValue === '0' || lowerValue === 'n') {
      return 'no';
    }
    
    // Return lowercase value for consistent case comparison
    return lowerValue;
  }
  
  // For other types, return as is
  return value;
};

/**
 * Helper to convert various timestamp formats to Date objects
 */
export const convertToDate = (dateValue: any): Date | null => {
  if (!dateValue) return null;
  
  try {
    // Handle Firestore timestamp
    if (typeof dateValue.toDate === 'function') {
      return dateValue.toDate();
    }
    // Handle serialized timestamp
    else if (typeof dateValue === 'object' && dateValue.seconds !== undefined) {
      return new Date(dateValue.seconds * 1000);
    }
    // Regular date string or timestamp
    else {
      const date = new Date(dateValue);
      return !isNaN(date.getTime()) ? date : null;
    }
  } catch (err) {
    console.error("Error converting date:", err);
    return null;
  }
};

/**
 * Helper to parse time values to Date objects
 */
export const parseTimeToDate = (timeValue: any): Date | null => {
  if (!timeValue) return null;
  
  try {
    // Handle Firestore timestamp
    if (typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    // Handle serialized timestamp
    else if (typeof timeValue === 'object' && timeValue.seconds !== undefined) {
      return new Date(timeValue.seconds * 1000);
    } 
    // Handle string time format (HH:MM)
    else if (typeof timeValue === 'string' && timeValue.includes(':')) {
      const today = new Date();
      const [hours, minutes] = timeValue.split(':').map(Number);
      today.setHours(hours, minutes, 0, 0);
      return today;
    }
    // Handle complete date string/object
    else {
      const date = new Date(timeValue);
      return !isNaN(date.getTime()) ? date : null;
    }
  } catch (err) {
    console.error("Error parsing time:", err);
    return null;
  }
};

/**
 * Normalize text responses for comparison purposes
 * Returns a standardized version of the input for comparing text responses
 */
export const normalizeTextForComparison = (text: string): string => {
  if (!text || typeof text !== 'string') return '';
  
  // Convert to lowercase and trim whitespace
  return text.toLowerCase().trim()
    // Remove multiple spaces
    .replace(/\s+/g, ' ')
    // Remove common punctuation for comparison
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
};

/**
 * Normalize a set of text responses and group similar responses together
 * This function will group responses that are the same except for case differences
 */
export const normalizeAndGroupTextResponses = (responses: string[]): Record<string, string[]> => {
  const normalizedGroups: Record<string, string[]> = {};
  
  if (!responses || !Array.isArray(responses)) {
    return normalizedGroups;
  }
  
  responses.forEach(response => {
    if (!response || typeof response !== 'string') return;
    
    // Normalize for grouping
    const normalizedKey = normalizeTextForComparison(response);
    
    if (!normalizedGroups[normalizedKey]) {
      normalizedGroups[normalizedKey] = [];
    }
    
    // Add the original response with its original casing
    // Only add if not already in the array (avoid duplicates)
    if (!normalizedGroups[normalizedKey].includes(response)) {
      normalizedGroups[normalizedKey].push(response);
    }
  });
  
  return normalizedGroups;
};

/**
 * Checks if a response represents a truthy value (yes, true, etc.)
 */
export const isTruthyResponse = (response: any): boolean => {
  return normalizeResponseValue(response) === 'yes';
};

/**
 * Get all selected options from a response, handling different formats
 */
export const getSelectedOptions = (response: any): string[] => {
  if (!response) return [];
  
  // If it's already an array, return it
  if (Array.isArray(response)) {
    return response.map(item => String(item));
  }
  
  // If it's a comma-separated string, split it
  if (typeof response === 'string' && response.includes(',')) {
    return response.split(',').map(s => s.trim());
  }
  
  // If it's an object with boolean values (checkbox responses)
  if (response && typeof response === 'object' && !Array.isArray(response)) {
    return Object.entries(response)
      .filter(([_, selected]) => selected === true)
      .map(([option, _]) => option);
  }
  
  // If it's a single value, return it as a one-item array
  return [String(response)];
};

/**
 * Get numeric value from response, with proper conversion
 */
export const getNumericValue = (response: any): number | null => {
  if (response === null || response === undefined || response === '') {
    return null;
  }
  
  if (typeof response === 'number') {
    return response;
  }
  
  if (typeof response === 'string') {
    const parsed = parseFloat(response);
    return !isNaN(parsed) ? parsed : null;
  }
  
  return null;
};

/**
 * Calculate time duration from a time range response
 */
export const calculateDuration = (timeRangeResponse: any): number | null => {
  if (!timeRangeResponse) return null;
  
  try {
    let startTime, endTime;
    
    if (timeRangeResponse.startTime && timeRangeResponse.endTime) {
      startTime = parseTimeToDate(timeRangeResponse.startTime);
      endTime = parseTimeToDate(timeRangeResponse.endTime);
    } else if (timeRangeResponse.start && timeRangeResponse.end) {
      startTime = parseTimeToDate(timeRangeResponse.start);
      endTime = parseTimeToDate(timeRangeResponse.end);
    }
    
    if (startTime && endTime) {
      // Calculate minutes
      const diffMs = endTime.getTime() - startTime.getTime();
      return Math.round(diffMs / 60000); // Convert to minutes
    }
    
    return null;
  } catch (error) {
    console.error("Error calculating duration:", error);
    return null;
  }
};