import React from 'react';
import { Typography } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';

interface TextResponseProps {
  question: any;
  submission: any;
}

const TextResponse: React.FC<TextResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // Format the response properly
  let displayValue = 'Not provided';
  
  if (response !== undefined && response !== null) {
    if (typeof response === 'string') {
      displayValue = response.trim(); // Preserve original casing for display
    } else {
      // Handle non-string values
      displayValue = String(response);
    }
  }
  
  return (
    <>
      <Typography variant="body1">{displayValue}</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

export default TextResponse;