import React from 'react';
import { TextField } from '@mui/material';
import Question from './Question';

interface TextQuestionProps {
  question: any;
  value: string;
  onChange: (value: string) => void;
}

const TextQuestion: React.FC<TextQuestionProps> = ({ 
  question, 
  value, 
  onChange 
}) => {

  return (
    <Question question={question}>
      <div>
        <TextField 
          fullWidth 
          value={value} 
          onChange={(e) => onChange(e.target.value)} 
          required={question.required}
        />
      </div>
    </Question>
  );
};

export default TextQuestion;
