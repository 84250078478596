import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useUser } from '@clerk/clerk-react';
import { themeOptions } from '../theme/themeOptions';

export interface UserPreferences {
  [x: string]: string;
  theme: string;
  viewMode: 'grid' | 'list';
  preferencesLoaded: boolean;
  setTheme: (theme: string) => Promise<void>;
  setViewMode: (mode: 'grid' | 'list') => Promise<void>;
}

export const useUserPreferences = (): UserPreferences => {
  // Initialize with localStorage defaults
  const [preferences, setPreferences] = useState({
    theme: localStorage.getItem('userThemePreference') || 'Blue',
    viewMode: localStorage.getItem('formsViewMode') as 'grid' | 'list' || 'grid'
  });
  const [preferencesLoaded, setPreferencesLoaded] = useState(false);
  const { user } = useUser();

  // Load preferences from Firebase when user is available
  useEffect(() => {
    const loadPreferences = async () => {
      if (!user?.id) return;
      
      try {
        const userDocRef = doc(db, 'users', user.id);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const data = userDoc.data();
          const newPreferences = { ...preferences };
          let changed = false;
          
          // Update theme if valid
          if (data.theme) {
            const validTheme = themeOptions.find(t => t.name === data.theme);
            if (validTheme) {
              newPreferences.theme = data.theme;
              localStorage.setItem('userThemePreference', data.theme);
              changed = true;
            }
          }
          
          // Update viewMode if valid
          if (data.viewMode && (data.viewMode === 'grid' || data.viewMode === 'list')) {
            newPreferences.viewMode = data.viewMode;
            localStorage.setItem('formsViewMode', data.viewMode);
            changed = true;
          }
          
          if (changed) {
            setPreferences(newPreferences);
          }
        }
        
        setPreferencesLoaded(true);
      } catch (error) {
        console.error('Error loading user preferences:', error);
        setPreferencesLoaded(true);
      }
    };
    
    loadPreferences();
  }, [user]);
  
  // Function to save preferences to Firebase
  const savePreference = async (key: keyof typeof preferences, value: string) => {
    // Update local state immediately for responsive UX
    setPreferences(prev => ({ ...prev, [key]: value }));
    
    // Also update localStorage as fallback
    localStorage.setItem(
      key === 'theme' ? 'userThemePreference' : 'formsViewMode', 
      value
    );
    
    // Persist to Firebase if user is logged in
    if (user?.id) {
      try {
        const userDocRef = doc(db, 'users', user.id);
        
        await setDoc(userDocRef, {
          [key]: value,
          preferencesUpdatedAt: serverTimestamp()
        }, { merge: true });
        
        console.log(`User preference ${key} saved to Firebase`);
      } catch (error) {
        console.error(`Error saving ${key} preference:`, error);
      }
    }
  };
  
  return {
    theme: preferences.theme,
    viewMode: preferences.viewMode,
    preferencesLoaded,
    setTheme: (theme: string) => savePreference('theme', theme),
    setViewMode: (mode: 'grid' | 'list') => savePreference('viewMode', mode)
  };
};