import React from 'react';
import { Typography } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import { format, differenceInCalendarDays, differenceInBusinessDays } from 'date-fns';

interface DateResponseProps {
  question: any;
  submission: any;
}

const DateResponse: React.FC<DateResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  let displayValue = 'Not provided';
  
  try {
    console.log(`Date response for ${question.id}:`, response);
    
    if (response) {
      // Check if this is a date range
      if (typeof response === 'object' && response !== null) {
        // Helper to convert various timestamp formats to Date
        const convertToDate = (dateValue: any): Date | null => {
          if (!dateValue) return null;
          
          try {
            // Handle Firestore timestamp
            if (typeof dateValue.toDate === 'function') {
              return dateValue.toDate();
            }
            // Handle serialized timestamp
            else if (typeof dateValue === 'object' && dateValue.seconds !== undefined) {
              return new Date(dateValue.seconds * 1000);
            }
            // Regular date string or timestamp
            else {
              const date = new Date(dateValue);
              return !isNaN(date.getTime()) ? date : null;
            }
          } catch (err) {
            console.error("Error converting date:", err);
            return null;
          }
        };

        // Check for nested timestamp objects
        if (
          (response.start && response.end) || 
          (response.startDate && response.endDate)
        ) {
          let startDate, endDate;

          if (response.startDate && response.endDate) {
            startDate = convertToDate(response.startDate);
            endDate = convertToDate(response.endDate);
          } else {
            startDate = convertToDate(response.start);
            endDate = convertToDate(response.end);
          }
          
          // Format dates if they're valid
          if (startDate && endDate) {
            // Format dates in a user-friendly format
            const formatDate = (date: Date) => format(date, 'MMMM d, yyyy');
            
            // Calculate days between dates
            const includeWeekends = response.includeWeekends !== false; // Default to true if not specified
            let days = 0;
            
            if (includeWeekends) {
              days = differenceInCalendarDays(endDate, startDate) + 1; // +1 to include both start and end dates
            } else {
              days = differenceInBusinessDays(endDate, startDate) + 1; // +1 for inclusive counting
            }
            
            // Format the response with dates and days calculation
            displayValue = `${formatDate(startDate)} to ${formatDate(endDate)} (${days} day${days !== 1 ? 's' : ''})`;
            
            // Add hours if they're included in the response
            if (response.useHours && response.hours) {
              displayValue += `, ${response.hours} hour${response.hours !== 1 ? 's' : ''}`;
            }
          } else {
            displayValue = "Invalid date range";
          }
        } else {
          // If we reached here, it's an object but not a properly formatted date range
          // Try to handle it as a timestamp
          const date = convertToDate(response);
          
          if (date) {
            displayValue = format(date, 'MMMM d, yyyy');
          } else {
            // It's some other object, stringify it
            displayValue = JSON.stringify(response)
              .replace(/{|}/g, '')
              .replace(/"/g, '')
              .replace(/,/g, ', ');
          }
        }
      } else {
        // It's a single date (not an object)
        try {
          // Try to parse as date
          const date = new Date(response);
          if (!isNaN(date.getTime())) {
            displayValue = format(date, 'MMMM d, yyyy');
          } else {
            // It's not a parsable date, just show the string
            displayValue = String(response);
          }
        } catch (err) {
          displayValue = String(response);
        }
      }
    }
  } catch (err) {
    console.error('Error formatting date value:', err, response);
    displayValue = 'Invalid date format';
  }
  
  return (
    <>
      <Typography variant="body1">{displayValue}</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

export default DateResponse;