import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Container, Typography, Paper, Button, 
  Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Chip, CircularProgress, Tabs, Tab,
  Accordion, AccordionSummary, AccordionDetails, 
  TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Avatar, Badge, IconButton, Tooltip, Grid, Divider, Alert,
  useMediaQuery, ThemeProvider, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Pagination
} from '@mui/material';
import { 
  ExpandMore, CheckCircle, Cancel, Visibility, 
  Comment, Send, Done, Close, OpenInNew, Class, DateRange, AccountCircle
} from '@mui/icons-material';
import { 
  collection, query, where, getDocs, orderBy, doc, 
  getDoc, updateDoc, Timestamp, writeBatch, limit
} from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import * as submissionReader from '../services/firebase/submissionReader';
import SharedNavBar from '../components/SharedNavBar';
import { formatDistanceToNow, format } from 'date-fns';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { createAppTheme } from '../theme/themeOptions';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';
import FixApprovalData from '../components/AdminTools/FixApprovalData';

// Interface for grouped submissions by form
interface GroupedSubmissions {
  formId: string;
  formTitle: string;
  formLogo?: string;
  totalCount: number;
  pendingCount: number;
  submissions: any[];
  hasMore?: boolean; // Add this property
}

// Interface for tab panel props
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Add these interfaces at the top of your ApprovalManager.tsx file
interface PaginationState {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface FormSubmissionCounts {
  [formId: string]: {
    pending: number;
    approved: number;
    rejected: number;
  };
}

// Tab Panel Component
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`approval-tabpanel-${index}`}
      aria-labelledby={`approval-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

// Utility function to format dates
const formatDate = (date: any): string => {
  try {
    if (typeof date.toDate === 'function') {
      return format(date.toDate(), 'MMM d, yyyy');
    } else if (date instanceof Date) {
      return format(date, 'MMM d, yyyy');
    }
  } catch (error) {
    console.error("Error formatting date:", error);
  }
  return 'Unknown Date';
};

// Utility function to send approval emails
const sendApprovalNotification = async (
  submission: any,
  status: 'approved' | 'rejected',
  comments: string
): Promise<boolean> => {
  try {
    const recipientEmail = submission.email;
    if (!recipientEmail) {
      console.error("Cannot send approval notification: Missing recipient email");
      return false;
    }

    // Format the date
    let submissionDate = "Unknown date";
    if (submission.submittedAt) {
      try {
        if (typeof submission.submittedAt.toDate === 'function') {
          submissionDate = format(submission.submittedAt.toDate(), 'MMM d, yyyy');
        } else if (submission.submittedAt instanceof Date) {
          submissionDate = format(submission.submittedAt, 'MMM d, yyyy');
        }
      } catch (e) {
        console.error("Error formatting date:", e);
      }
    }

    // Get form title
    const formTitle = submission.formTitle || "Untitled Form";
    
    // Define email subject and content based on status
    const subject = status === 'approved' 
      ? `Your form submission has been approved` 
      : `Your form submission requires attention`;

    // Create email HTML content
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #eaeaea; border-radius: 5px;">
        <h2 style="color: ${status === 'approved' ? '#4caf50' : '#f44336'}; border-bottom: 1px solid #eaeaea; padding-bottom: 10px;">
          Form Submission ${status === 'approved' ? 'Approved' : 'Rejected'}
        </h2>
        
        <p>Hello,</p>
        
        <p>
          Your submission to <strong>${formTitle}</strong> from <strong>${submissionDate}</strong>
          has been ${status === 'approved' ? 'approved' : 'rejected'}.
        </p>
        
        ${comments ? `
        <div style="background-color: #f9f9f9; border-left: 4px solid ${status === 'approved' ? '#4caf50' : '#f44336'}; padding: 15px; margin: 20px 0;">
          <p style="margin: 0; font-style: italic;">${comments}</p>
        </div>
        ` : ''}
        
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
          Form ID: ${submission.formId}<br>
          Submission ID: ${submission.id}<br>
          Reviewed on: ${format(new Date(), 'MMM d, yyyy')}
        </p>
      </div>
    `;

    // Plain text version for email clients that don't support HTML
    const text = `
      Form Submission ${status === 'approved' ? 'Approved' : 'Rejected'}

      Hello,

      Your submission to "${formTitle}" from ${submissionDate} has been ${status === 'approved' ? 'approved' : 'rejected'}.
      
      ${comments ? `Comments: ${comments}` : ''}

      Form ID: ${submission.formId}
      Submission ID: ${submission.id}
      Reviewed on: ${format(new Date(), 'MMM d, yyyy')}
    `;

    // Send the email using the Resend API
    const apiUrl = process.env.NODE_ENV === 'development' 
      ? 'http://localhost:3000/api/send-email' 
      : '/api/send-email';
      
    const response = await axios.post(apiUrl, {
      email: recipientEmail,
      subject,
      html,
      text
    });

    console.log("Approval notification sent:", response.data);
    return true;
  } catch (error) {
    console.error("Error sending approval notification:", error);
    return false;
  }
};

const ApprovalManager: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  
  // Theme setup
  const { theme: themePreference } = useUserPreferences();
  const theme = createAppTheme(themePreference || 'Blue');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State variables
  const [loading, setLoading] = useState(true);
  const [groupedSubmissions, setGroupedSubmissions] = useState<GroupedSubmissions[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [expandedFormId, setExpandedFormId] = useState<string | false>(false);
  
  // Selected submission and approval dialog state
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [processingAction, setProcessingAction] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);
  const [actionSuccess, setActionSuccess] = useState<string | null>(null);

  // Pagination state
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0
  });

  // Submission detail dialog state
  const [showSubmissionDetail, setShowSubmissionDetail] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);

  // For admin filtering
  const [users, setUsers] = useState<Array<{id: string, email: string, name: string}>>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [userRole, setUserRole] = useState<string | null>(null);

  // Add this state variable for tracking total count per form
  const [formSubmissionCounts, setFormSubmissionCounts] = useState<FormSubmissionCounts>({});

  // Add this useEffect to fetch the user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user?.id) return;
      try {
        const userRef = doc(db, "users", user.id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserRole(userSnap.data().role);
        } else {
          console.warn("No role found for user in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [user]);

  // Add this useEffect to fetch all users if the current user is an admin
  useEffect(() => {
    if (userRole === "Admin") {
      fetchUsers();
    }
  }, [userRole]);

  // Add this function to fetch all users
  const fetchUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email || "No email",
        name: doc.data().firstName && doc.data().lastName ? 
          `${doc.data().firstName} ${doc.data().lastName}` : 
          doc.data().firstName || doc.data().email || doc.id
      }));
      
      // Sort users alphabetically by name
      usersData.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Add a handler for user selection changes
  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setSelectedUserId(event.target.value);
    setExpandedFormId(false); // Close any open accordions
    loadSubmissions(true); // Reset pagination
  };

  // Add this useEffect to reload submissions when selectedUserId changes
  useEffect(() => {
    if (user) {
      loadSubmissions(true);
    }
  }, [selectedUserId]);

  // Add this useEffect to handle page changes
  useEffect(() => {
    if (user) {
      loadSubmissions(false);
    }
  }, [paginationState.page]);

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    loadSubmissions(true); // Reset pagination
  };

  // Handle expanding/collapsing form sections
  const handleExpandForm = (formId: string) => {
    setExpandedFormId(expandedFormId === formId ? false : formId);
  };

  // Load submissions grouped by form with proper pagination
  const loadSubmissions = async (resetPagination: boolean = false) => {
    if (!user) return;
    
    try {
      setLoading(true);
      setActionError(null);
      setActionSuccess(null);
      
      // Status to filter by based on active tab
      const statusFilter = 
        tabValue === 0 ? "pending" : 
        tabValue === 1 ? "approved" : "rejected";
      
      // Reset pagination if needed
      if (resetPagination) {
        setPaginationState(prev => ({
          ...prev,
          page: 0
        }));
      }
      
      // Find forms that require approval
      let formsQuery;
      
      if (userRole === "Admin" && selectedUserId) {
        // Admin is filtering for a specific user
        formsQuery = query(
          collection(db, "forms"),
          where("userId", "==", selectedUserId),
          where("requiresApproval", "==", true)
        );
      } else if (userRole === "Admin" && !selectedUserId) {
        // Admin is viewing all forms
        formsQuery = query(
          collection(db, "forms"),
          where("requiresApproval", "==", true)
        );
      } else {
        // Regular user - only show their own forms
        formsQuery = query(
          collection(db, "forms"),
          where("userId", "==", user.id),
          where("requiresApproval", "==", true)
        );
      }
      
      const formsSnapshot = await getDocs(formsQuery);
      
      if (formsSnapshot.empty) {
        setGroupedSubmissions([]);
        setPaginationState(prev => ({
          ...prev,
          totalItems: 0,
          totalPages: 0
        }));
        setLoading(false);
        return;
      }
      
      // Get form details to group submissions
      const forms = formsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as { formTitle?: string; companyLogo?: string; userId: string; }) // Include userId
      }));
      
      // Get form IDs to use in the submissions query
      const formIds = forms.map(form => form.id);
      
      // Get counts for all status types for these forms
      try {
        const countsMap: FormSubmissionCounts = {};
        
        // Initialize countsMap for all forms
        formIds.forEach(formId => {
          countsMap[formId] = { pending: 0, approved: 0, rejected: 0 };
        });
        
        // For each form, get counts for each status type
        await Promise.all(formIds.map(async (formId) => {
          // Get pending count
          const pendingQuery = query(
            collection(db, "form_submissions"),
            where("formId", "==", formId),
            where("approvalRequired", "==", true),
            where("approvalStatus", "==", "pending"),
            where("archived", "==", false)
          );
          const pendingSnapshot = await getDocs(pendingQuery);
          countsMap[formId].pending = pendingSnapshot.size;
          
          // Get approved count
          const approvedQuery = query(
            collection(db, "form_submissions"),
            where("formId", "==", formId),
            where("approvalRequired", "==", true),
            where("approvalStatus", "==", "approved"),
            where("archived", "==", false)
          );
          const approvedSnapshot = await getDocs(approvedQuery);
          countsMap[formId].approved = approvedSnapshot.size;
          
          // Get rejected count
          const rejectedQuery = query(
            collection(db, "form_submissions"),
            where("formId", "==", formId),
            where("approvalRequired", "==", true),
            where("approvalStatus", "==", "rejected"),
            where("archived", "==", false)
          );
          const rejectedSnapshot = await getDocs(rejectedQuery);
          countsMap[formId].rejected = rejectedSnapshot.size;
        }));
        
        setFormSubmissionCounts(countsMap);
        
        // Calculate total count for the current tab
        const totalCount = Object.values(countsMap).reduce((sum, counts) => {
          return sum + (
            statusFilter === "pending" ? counts.pending :
            statusFilter === "approved" ? counts.approved :
            counts.rejected
          );
        }, 0);
        
        // Update pagination with total count
        setPaginationState(prev => ({
          ...prev,
          totalItems: totalCount,
          totalPages: Math.ceil(totalCount / prev.pageSize)
        }));
        
      } catch (error) {
        console.error("Error getting submission counts:", error);
      }

      // Query submissions for these forms with the right approval status and pagination
      // Exclude archived submissions
      let submissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "in", formIds),
        where("approvalRequired", "==", true),
        where("approvalStatus", "==", statusFilter),
        where("archived", "==", false),
        orderBy("submittedAt", "desc")
      );

      // Calculate pagination limits
      const start = paginationState.page * paginationState.pageSize;
      const paginatedQuery = query(
        submissionsQuery, 
        limit(paginationState.pageSize)
      );

      const submissionsSnapshot = await getDocs(paginatedQuery);
      
      if (submissionsSnapshot.empty) {
        setGroupedSubmissions([]);
        setLoading(false);
        return;
      }
      
      // Get all submissions
      const allSubmissions = submissionsSnapshot.docs.map(doc => ({
        id: doc.id,
        formId: doc.data().formId,
        ...doc.data()
      }));
      
      // Group submissions by form
      const submissionsByForm: Record<string, any[]> = {};
      
      allSubmissions.forEach(submission => {
        const formId = submission.formId;
        if (!submissionsByForm[formId]) {
          submissionsByForm[formId] = [];
        }
        submissionsByForm[formId].push(submission);
      });
      
      // Create the grouped submissions array with accurate counts
      const grouped: GroupedSubmissions[] = forms
        // Include forms with submissions or with known counts > 0
        .filter(form => submissionsByForm[form.id] || 
          (formSubmissionCounts[form.id] && 
            (statusFilter === "pending" ? formSubmissionCounts[form.id].pending > 0 :
             statusFilter === "approved" ? formSubmissionCounts[form.id].approved > 0 :
             formSubmissionCounts[form.id].rejected > 0)
          )
        )
        .map(form => {
          const formSubmissions = submissionsByForm[form.id] || [];
          const counts = formSubmissionCounts[form.id] || { pending: 0, approved: 0, rejected: 0 };
          
          return {
            formId: form.id,
            formTitle: form.formTitle || 'Untitled Form',
            formLogo: form.companyLogo || undefined,
            userId: form.userId,
            totalCount: statusFilter === "pending" ? counts.pending :
                       statusFilter === "approved" ? counts.approved :
                       counts.rejected,
            pendingCount: counts.pending,
            submissions: formSubmissions
          };
        });
      
      // Sort forms by those with most submissions first
      grouped.sort((a, b) => b.totalCount - a.totalCount);
      
      setGroupedSubmissions(grouped);
      
      // If there's only one form, expand it automatically
      if (grouped.length === 1 && !expandedFormId) {
        setExpandedFormId(grouped[0].formId);
      }
    } catch (error) {
      console.error("Error loading submissions:", error);
      setActionError("Failed to load submissions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // View submission details
  const handleViewSubmissionDetails = async (submissionId: string) => {
    try {
      setProcessingAction(true);
      
      // Get the submission document
      const submissionRef = doc(db, "form_submissions", submissionId);
      const submissionSnap = await getDoc(submissionRef);
      
      if (!submissionSnap.exists()) {
        setActionError("Submission not found");
        return;
      }
      
      const submissionData = submissionSnap.data();
      
      // Get the submission details document
      const detailsRef = doc(db, "submission_details", submissionId);
      const detailsSnap = await getDoc(detailsRef);
      
      if (!detailsSnap.exists()) {
        setActionError("Submission details not found");
        return;
      }
      
      const detailsData = detailsSnap.data();
      
      // Get the form document to get questions
      const formRef = doc(db, "forms", submissionData.formId);
      const formSnap = await getDoc(formRef);
      
      if (!formSnap.exists()) {
        setActionError("Form not found");
        return;
      }
      
      // Combine the data
      const fullSubmissionDetails = {
        id: submissionId,
        ...submissionData,
        responses: detailsData.responses || {},
        repeatableItems: detailsData.repeatableItems || {},
        signatures: detailsData.signatures || {},
        imagePreview: detailsData.imagePreview || {},
        annotatedImages: detailsData.annotatedImages || {},
        metadata: detailsData.metadata || {}
      };
      
      // Update state and show dialog
      setSubmissionDetails(fullSubmissionDetails);
      setQuestions(formSnap.data().questions || []);
      setShowSubmissionDetail(true);
      
    } catch (error) {
      console.error("Error loading submission details:", error);
      setActionError("Failed to load submission details");
    } finally {
      setProcessingAction(false);
    }
  };

  const handleCloseSubmissionDetail = () => {
    setShowSubmissionDetail(false);
    setSubmissionDetails(null);
  };

  // Open approval dialog
  const handleOpenApprovalDialog = (submission: any) => {
    setSelectedSubmission(submission);
    setComments('');
    setApprovalDialogOpen(true);
  };
  
  // Open rejection dialog
  const handleOpenRejectionDialog = (submission: any) => {
    setSelectedSubmission(submission);
    setComments('');
    setRejectionDialogOpen(true);
  };

  // Process submission approval
  const handleApproveSubmission = async () => {
    if (!selectedSubmission) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Update both the main submission and details document
      const submissionRef = doc(db, "form_submissions", selectedSubmission.id);
      const detailsRef = doc(db, "submission_details", selectedSubmission.id);
      
      // Use a batch to update both documents
      const batch = writeBatch(db);
      
      const updateData = {
        approvalStatus: 'approved',
        approvalComments: comments,
        approvalDate: Timestamp.now()
      };
      
      batch.update(submissionRef, updateData);
      
      // Check if details document exists
      try {
        const detailsSnap = await getDoc(detailsRef);
        if (detailsSnap.exists()) {
          batch.update(detailsRef, updateData);
        }
      } catch (error) {
        console.error("Error checking submission details:", error);
        // Continue with main submission update only
      }
      
      await batch.commit();
      
      // Send email notification
      const emailSent = await sendApprovalNotification(
        selectedSubmission,
        'approved',
        comments
      );
      
      // Show success message
      setActionSuccess(
        emailSent
          ? `Submission approved successfully! A notification has been sent to ${selectedSubmission.email}.`
          : `Submission approved successfully! (Email notification could not be sent)`
      );
      
      // Close dialog and refresh data
      setApprovalDialogOpen(false);
      setSelectedSubmission(null);
      setComments('');
      await loadSubmissions();
      
    } catch (error) {
      console.error("Error approving submission:", error);
      setActionError("Failed to approve submission. Please try again.");
    } finally {
      setProcessingAction(false);
    }
  };

  // Process submission rejection
  const handleRejectSubmission = async () => {
    if (!selectedSubmission || !comments.trim()) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Update both the main submission and details document
      const submissionRef = doc(db, "form_submissions", selectedSubmission.id);
      const detailsRef = doc(db, "submission_details", selectedSubmission.id);
      
      // Use a batch to update both documents
      const batch = writeBatch(db);
      
      const updateData = {
        approvalStatus: 'rejected',
        approvalComments: comments,
        approvalDate: Timestamp.now()
      };
      
      batch.update(submissionRef, updateData);
      
      // Check if details document exists
      try {
        const detailsSnap = await getDoc(detailsRef);
        if (detailsSnap.exists()) {
          batch.update(detailsRef, updateData);
        }
      } catch (error) {
        console.error("Error checking submission details:", error);
        // Continue with main submission update only
      }
      
      await batch.commit();
      
      // Send email notification
      const emailSent = await sendApprovalNotification(
        selectedSubmission,
        'rejected',
        comments
      );
      
      // Show success message
      setActionSuccess(
        emailSent
          ? `Submission rejected successfully. A notification has been sent to ${selectedSubmission.email}.`
          : `Submission rejected successfully. (Email notification could not be sent)`
      );
      
      // Close dialog and refresh data
      setRejectionDialogOpen(false);
      setSelectedSubmission(null);
      setComments('');
      await loadSubmissions();
      
    } catch (error) {
      console.error("Error rejecting submission:", error);
      setActionError("Failed to reject submission. Please try again.");
    } finally {
      setProcessingAction(false);
    }
  };

  // Add this Pagination component inside the ApprovalManager component
  const PaginationControls = () => {
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      // Page values from MUI are 1-based, we need to convert to 0-based
      setPaginationState(prev => ({
        ...prev,
        page: value - 1
      }));
    };

    return (
      <Box sx={{ 
        mt: 2, 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        flexWrap: 'wrap'
      }}>
        <Pagination 
          count={paginationState.totalPages} 
          page={paginationState.page + 1} // Convert back to 1-based for display
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
          disabled={loading}
        />
        
        <Typography variant="body2" color="text.secondary">
          Showing {Math.min(paginationState.totalItems, paginationState.page * paginationState.pageSize + 1)} 
          - {Math.min(paginationState.totalItems, (paginationState.page + 1) * paginationState.pageSize)} 
          of {paginationState.totalItems} submissions
        </Typography>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar title="Approval Manager" />
        
        <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, mb: 8 }}>
          {/* Header Section */}
          <Paper 
            elevation={0}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 }, 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.default})`
            }}
          >
            <Typography variant="h4" gutterBottom>
              Manage Form Approvals
            </Typography>
            <Typography color="text.secondary">
              Review and manage submissions that require your approval.
            </Typography>
          </Paper>

          {/* Admin Filter - Only show to admins */}
          {userRole === "Admin" && (
            <Paper 
              elevation={0}
              sx={{ 
                p: { xs: 2, sm: 2 }, 
                mb: { xs: 2, sm: 3 }, 
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography variant="subtitle2" color="text.secondary">
                  Admin View:
                </Typography>
              </Box>
              
              <FormControl sx={{ minWidth: 220, flexGrow: { xs: 1, sm: 0 } }} size="small">
                <InputLabel id="user-filter-label">Filter by Form Creator</InputLabel>
                <Select
                  labelId="user-filter-label"
                  value={selectedUserId}
                  label="Filter by Form Creator"
                  onChange={handleUserChange}
                >
                  <MenuItem value="">
                    <em>All Users</em>
                  </MenuItem>
                  {users.map(user => (
                    <MenuItem key={user.id} value={user.id}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2">{user.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {user.email}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {selectedUserId && (
                <Chip 
                  label={`Viewing ${users.find(u => u.id === selectedUserId)?.name || 'user'}'s forms`}
                  onDelete={() => setSelectedUserId("")}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              )}
              
              {!selectedUserId && (
                <Chip 
                  label="Viewing all users' forms"
                  color="default"
                  variant="outlined"
                  size="small"
                />
              )}
            </Paper>
          )}

          {/* Admin Tools Section - Only visible to admins */}
          {userRole === "Admin" && (
            <FixApprovalData isAdmin={userRole === "Admin"} />
          )}
          
          {/* Success/Error Messages */}
          {actionError && (
            <Alert 
              severity="error" 
              sx={{ mb: 2 }}
              onClose={() => setActionError(null)}
            >
              {actionError}
            </Alert>
          )}
          
          {actionSuccess && (
            <Alert 
              severity="success" 
              sx={{ mb: 2 }}
              onClose={() => setActionSuccess(null)}
            >
              {actionSuccess}
            </Alert>
          )}
          
          {/* Tabs Section */}
          <Paper 
            elevation={0}
            sx={{ 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              overflow: 'hidden'
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant={isMobile ? "fullWidth" : "standard"}
              sx={{ 
                borderBottom: '1px solid',
                borderColor: 'divider',
                bgcolor: theme.palette.background.paper
              }}
            >
              <Tab 
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Badge 
                      badgeContent={
                        Object.values(formSubmissionCounts).reduce(
                          (sum, counts) => sum + counts.pending, 0
                        )
                      } 
                      color="error" 
                      max={99}
                      sx={{ mr: 1 }}
                    >
                      <span>Pending</span>
                    </Badge>
                  </Box>
                } 
              />
              <Tab label="Approved" />
              <Tab label="Rejected" />
            </Tabs>
            
            {/* Tab Panels */}
            <TabPanel value={tabValue} index={0}>
              {renderSubmissionsList("pending")}
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
              {renderSubmissionsList("approved")}
            </TabPanel>
            
            <TabPanel value={tabValue} index={2}>
              {renderSubmissionsList("rejected")}
            </TabPanel>
          </Paper>

          {/* Pagination Controls */}
          <PaginationControls />
        </Container>
        
        {/* Submission Detail Dialog */}
        <Dialog
          open={showSubmissionDetail}
          onClose={handleCloseSubmissionDetail}
          maxWidth="lg"
          fullWidth
          fullScreen={isMobile}
          PaperProps={{
            sx: isMobile ? {
              margin: 0,
              maxHeight: '100vh',
              borderRadius: 0
            } : { 
              borderRadius: { xs: '8px', sm: '12px' }, 
              height: { xs: '95vh', sm: '90vh' },
              width: { xs: '95%', sm: 'auto' },
              margin: { xs: '8px', sm: 'auto' }
            }
          }}
        >
          <DialogTitle sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            pb: isMobile ? 1 : 2,
            pt: isMobile ? 1 : { xs: 1.5, sm: 2 },
            px: isMobile ? 1 : { xs: 1.5, sm: 2.5 }
          }}>
            <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
              Submission Details
            </Typography>
            <IconButton onClick={handleCloseSubmissionDetail} size="small">
              <Close fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            {submissionDetails && (
              <SubmissionDetail
                submission={submissionDetails}
                questions={questions}
                formId={submissionDetails.formId || ''}
                formTitle={submissionDetails.formTitle || 'Untitled Form'}
                theme={theme}
                onArchiveChange={() => {
                  handleCloseSubmissionDetail();
                  loadSubmissions(true);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
        
        {/* Approval Dialog */}
        <Dialog
          open={approvalDialogOpen}
          onClose={() => !processingAction && setApprovalDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ pb: 1 }}>
            Approve Submission
          </DialogTitle>
          
          <DialogContent>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Submission from
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedSubmission?.email || 'Anonymous'}
              </Typography>
            </Box>
            
            <Divider sx={{ mb: 2 }} />
            
            <Typography variant="body1" sx={{ mb: 2 }}>
              Add approval comments (optional):
            </Typography>
            
            <TextField
              multiline
              rows={4}
              fullWidth
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter any comments about this approval..."
              variant="outlined"
            />
          </DialogContent>
          
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={() => setApprovalDialogOpen(false)} 
              disabled={processingAction}
              color="inherit"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleApproveSubmission}
              variant="contained"
              color="success"
              disabled={processingAction}
              startIcon={<CheckCircle />}
            >
              {processingAction ? "Processing..." : "Approve"}
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Rejection Dialog */}
        <Dialog
          open={rejectionDialogOpen}
          onClose={() => !processingAction && setRejectionDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ pb: 1 }}>
            Reject Submission
          </DialogTitle>
          
          <DialogContent>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Submission from
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {selectedSubmission?.email || 'Anonymous'}
              </Typography>
            </Box>
            
            <Divider sx={{ mb: 2 }} />
            
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please provide a reason for rejection:
            </Typography>
            
            <TextField
              multiline
              rows={4}
              fullWidth
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter the reason for rejection..."
              variant="outlined"
              required
              error={rejectionDialogOpen && !comments.trim()}
              helperText={rejectionDialogOpen && !comments.trim() ? "Rejection reason is required" : ""}
            />
          </DialogContent>
          
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={() => setRejectionDialogOpen(false)} 
              disabled={processingAction}
              color="inherit"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleRejectSubmission}
              variant="contained"
              color="error"
              disabled={processingAction || !comments.trim()}
              startIcon={<Cancel />}
            >
              {processingAction ? "Processing..." : "Reject"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
  
  // Function to render submissions list by status
  function renderSubmissionsList(status: string) {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
          <CircularProgress />
        </Box>
      );
    }
    
    if (groupedSubmissions.length === 0) {
      return (
        <Box sx={{ 
          py: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          color: 'text.secondary'
        }}>
          <Class sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
          <Typography variant="h6" gutterBottom>
            No {status} submissions found
          </Typography>
          <Typography variant="body2">
            {status === 'pending' 
              ? "You don't have any submissions waiting for approval." 
              : `You don't have any ${status} submissions yet.`}
          </Typography>
        </Box>
      );
    }
    
    // Render the list of forms with their submissions
    return (
      <Box sx={{ p: { xs: 0, sm: 2 } }}>
        {groupedSubmissions.map((group) => (
          <Accordion 
            key={group.formId}
            expanded={expandedFormId === group.formId}
            onChange={() => handleExpandForm(group.formId)}
            sx={{ 
              mb: 2,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: '8px !important',
              '&:before': { display: 'none' },
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ 
                bgcolor: expandedFormId === group.formId 
                  ? `${theme.palette.primary.main}08`
                  : theme.palette.background.paper
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '100%'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {group.formLogo ? (
                    <Avatar 
                      src={group.formLogo} 
                      alt={group.formTitle}
                      variant="rounded"
                      sx={{ mr: 2, width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar 
                      sx={{ mr: 2, bgcolor: theme.palette.primary.main }}
                      variant="rounded"
                    >
                      {group.formTitle.charAt(0)}
                    </Avatar>
                  )}
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {group.formTitle}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {group.totalCount} {status} submission{group.totalCount !== 1 ? 's' : ''}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {/* Add calendar view button - hide on mobile */}
                  <Tooltip title="Calendar View">
                    <IconButton
                      size="small"
                      sx={{ display: { xs: 'none', sm: 'flex' } }} // Hide on extra small screens
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/approval-calendar/${group.formId}`);
                      }}
                    >
                      <DateRange />
                    </IconButton>
                  </Tooltip>
                  
                  {/* Show badge with pending count on non-pending tabs */}
                  {status !== 'pending' && group.pendingCount > 0 && (
                    <Chip 
                      label={`${group.pendingCount} pending`} 
                      color="warning"
                      size="small"
                    />
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            
            <AccordionDetails sx={{ p: 0 }}>
              <TableContainer sx={{ 
                maxWidth: '100%',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '3px',
                }
              }}>
                <Table size="medium">
                  <TableHead sx={{ bgcolor: theme.palette.background.default }}>
                    <TableRow>
                      <TableCell>Submitted By</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Date</TableCell>
                      {status !== 'pending' && !isMobile && (
                        <TableCell>Comments</TableCell>
                      )}
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.submissions.map((submission) => (
                      <TableRow key={submission.id}>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            {submission.email}
                          </Typography>
                          {isMobile && submission.submittedAt && (
                            <Typography variant="caption" color="text.secondary">
                              {typeof submission.submittedAt.toDate === 'function' 
                                ? formatDistanceToNow(submission.submittedAt.toDate(), { addSuffix: true })
                                : 'Unknown date'
                              }
                              <br />
                              {typeof submission.submittedAt.toDate === 'function'
                                ? format(submission.submittedAt.toDate(), 'MMM d, yyyy')
                                : 'Unknown Date'
                              }
                            </Typography>
                          )}
                          {userRole === "Admin" && !selectedUserId && (
                            <Box>
                              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                Form created by: {
                                  users.find(u => u.id === submission.userId)?.email || 
                                  'Unknown user'
                                }
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          <Typography variant="body2">
                            {formatDate(submission.submittedAt)}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {submission.submittedAt && typeof submission.submittedAt.toDate === 'function' && 
                              formatDistanceToNow(submission.submittedAt.toDate(), { addSuffix: true })}
                          </Typography>
                        </TableCell>
                        
                        {/* Comments column for approved/rejected submissions - hide on mobile */}
                        {status !== 'pending' && !isMobile && (
                          <TableCell>
                            {submission.approvalComments ? (
                              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                "{submission.approvalComments}"
                              </Typography>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No comments
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        
                        <TableCell align="right">
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                            <Tooltip title="View Submission">
                              <IconButton 
                                size="small" 
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent accordion from toggling
                                  handleViewSubmissionDetails(submission.id);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            
                            {status === 'pending' && (
                              <>
                                <Tooltip title="Approve">
                                  <IconButton 
                                    size="small" 
                                    color="success" 
                                    onClick={() => handleOpenApprovalDialog(submission)}
                                  >
                                    <Done />
                                  </IconButton>
                                </Tooltip>
                                
                                <Tooltip title="Reject">
                                  <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => handleOpenRejectionDialog(submission)}
                                  >
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {group.hasMore && (
                <Box sx={{ 
                  p: 2, 
                  display: 'flex', 
                  justifyContent: 'center', 
                  borderTop: '1px solid', 
                  borderColor: 'divider' 
                }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent accordion from toggling
                      loadSubmissions();
                    }}
                    startIcon={loading ? <CircularProgress size={16} /> : null}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  }
};

export default ApprovalManager;