import React, { useState } from 'react';
import { 
  Box, Typography, Chip, Tooltip, IconButton, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  CircularProgress,
  useMediaQuery, useTheme
} from '@mui/material';
import { 
  AccessTime, Email, Archive, Unarchive, 
  Delete as DeleteIcon, Done as DoneIcon,
  Close as CloseIcon, Assignment,
  Archive as ArchiveIcon, Unarchive as UnarchiveIcon,
  CalendarMonth as CalendarIcon
} from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

interface SubmissionHeaderProps {
  submission: any;
  onArchiveChange?: () => void;
  isMobile?: boolean;
}

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
  submission,
  onArchiveChange,
  isMobile = false
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  // Format date helper
  const formatDate = (dateValue: any): string => {
    try {
      if (!dateValue) return 'No date';
      
      // Handle Firestore timestamp
      if (typeof dateValue.toDate === 'function') {
        return format(dateValue.toDate(), 'MMM d, yyyy HH:mm');
      }
      
      // Handle seconds timestamp
      if (dateValue.seconds !== undefined) {
        return format(new Date(dateValue.seconds * 1000), 'MMM d, yyyy HH:mm');
      }
      
      // Handle string or Date
      return format(new Date(dateValue), 'MMM d, yyyy HH:mm');
    } catch (error) {
      return 'Invalid date';
    }
  };

  const getRelativeTime = (timestamp: any): string => {
    if (!timestamp) return '';
    
    try {
      // Handle Firestore timestamp
      if (typeof timestamp.toDate === 'function') {
        return formatDistanceToNow(timestamp.toDate(), { addSuffix: true });
      }
      
      // Handle Date objects
      if (timestamp instanceof Date) {
        return formatDistanceToNow(timestamp, { addSuffix: true });
      }
      
      return '';
    } catch (error) {
      return '';
    }
  };

  const handleArchiveToggle = async () => {
    if (!submission.id) return;
    
    try {
      setProcessing(true);
      
      // Toggle archived status
      const newArchived = !submission.archived;
      
      // Update the main form_submissions document
      await updateDoc(doc(db, "form_submissions", submission.id), {
        archived: newArchived,
        archivedAt: newArchived ? new Date() : null
      });
      
      // Close dialog and notify parent component
      setArchiveDialogOpen(false);
      if (onArchiveChange) onArchiveChange();
      
    } catch (error) {
      console.error("Error toggling archive status:", error);
    } finally {
      setProcessing(false);
    }
  };

  // Generate approval status chip
  const getApprovalStatusChip = () => {
    if (!submission.approvalRequired) {
      return null;
    }
    
    switch (submission.approvalStatus) {
      case 'approved':
        return (
          <Chip 
            label="Approved" 
            color="success" 
            size="small" 
            icon={<DoneIcon />}
            onClick={() => navigate('/approval-manager')} 
            sx={{ cursor: 'pointer' }}
          />
        );
      case 'rejected':
        return (
          <Chip 
            label="Rejected" 
            color="error" 
            size="small" 
            icon={<CloseIcon />}
            onClick={() => navigate('/approval-manager')} 
            sx={{ cursor: 'pointer' }}
          />
        );
      case 'pending':
      default:
        return (
          <Chip 
            label="Pending Approval" 
            color="warning" 
            size="small" 
            icon={<Assignment />}
            onClick={() => navigate('/approval-manager')} 
            sx={{ cursor: 'pointer' }}
          />
        );
    }
  };

  return (
    <Box>
      {/* Top row */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start',
        mb: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 1, sm: 0 }
      }}>
        <Box>
          <Typography variant="h5" gutterBottom={!isMobile} sx={{ fontWeight: 600 }}>
            {submission.formTitle || 'Untitled Form'}
          </Typography>
          
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap',
            alignItems: 'center', 
            gap: 1,
            mt: { xs: 0, sm: 0.5 }
          }}>
            {/* Submission ID */}
            <Chip
              label={`ID: ${submission.id.slice(0, 8)}...`}
              size="small"
              color="primary"
              variant="outlined"
            />
            
            {/* Status chip */}
            {submission.approvalStatus && (
              <Chip 
                label={submission.approvalStatus.charAt(0).toUpperCase() + submission.approvalStatus.slice(1)}
                size="small"
                color={
                  submission.approvalStatus === 'approved' ? 'success' :
                  submission.approvalStatus === 'rejected' ? 'error' : 'warning'
                }
              />
            )}
            
            {/* Archived chip */}
            {submission.archived && (
              <Chip
                label="Archived"
                size="small"
                color="default"
                variant="outlined"
              />
            )}
          </Box>
        </Box>
        
        {/* Action buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: { xs: 1, sm: 0 } }}>
          {/* Archive/Unarchive button */}
          <Tooltip title={submission.archived ? "Unarchive submission" : "Archive submission"}>
            <IconButton 
              color={submission.archived ? "primary" : "default"}
              onClick={() => setArchiveDialogOpen(true)}
              size="small"
            >
              {submission.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      {/* Second row - Metadata */}
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
        gap: 2
      }}>
        {/* Submitter Info */}
        <Box>
          <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
            Submitted By
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {submission.email || 'Anonymous'}
          </Typography>
        </Box>
        
        {/* Submission Date */}
        <Box>
          <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
            Submitted on
          </Typography>
          <Typography variant="body2">
            {formatDate(submission.submittedAt)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {getRelativeTime(submission.submittedAt)}
          </Typography>
        </Box>
        
        {/* Approval Date - only show if approved/rejected */}
        {submission.approvalDate && (
          <Box>
            <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
              {submission.approvalStatus === 'approved' ? 'Approved on' : 'Rejected on'}
            </Typography>
            <Typography variant="body2">
              {formatDate(submission.approvalDate)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {getRelativeTime(submission.approvalDate)}
            </Typography>
          </Box>
        )}
      </Box>
      
      {/* Archive Confirmation Dialog */}
      <Dialog
        open={archiveDialogOpen}
        onClose={() => !processing && setArchiveDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {submission.archived ? 'Unarchive Submission' : 'Archive Submission'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {submission.archived
              ? 'Unarchiving this submission will make it visible in the default submissions view again.'
              : 'Archiving this submission will hide it from the default view. You can still access archived submissions.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={() => setArchiveDialogOpen(false)}
            disabled={processing}
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleArchiveToggle}
            variant="contained"
            color={submission.archived ? "primary" : "warning"}
            disabled={processing}
            startIcon={processing ? <CircularProgress size={16} /> : null}
          >
            {processing 
              ? "Processing..." 
              : submission.archived ? "Unarchive" : "Archive"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubmissionHeader;