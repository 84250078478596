import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import FollowUpDisplay from '../shared/FollowUpDisplay';

interface MultiSelectResponseProps {
  question: any;
  submission: any;
}

const MultiSelectResponse: React.FC<MultiSelectResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // Debug logging
  console.log(`MultiSelect response for ${question.id}:`, {
    response,
    type: typeof response,
    isArray: Array.isArray(response),
    questionOptions: question.options,
    questionFollowUps: question.followUps,
    relatedResponses: Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`))
      .reduce((obj, key) => ({ ...obj, [key]: responses[key] }), {})
  });
  
  // Handle different formats of multi-select responses
  let selectedOptions: string[] = [];
  
  if (Array.isArray(response)) {
    selectedOptions = response;
  } else if (typeof response === 'string') {
    selectedOptions = [response];
  } else if (typeof response === 'object' && response !== null) {
    selectedOptions = Object.keys(response).filter(key => response[key] === true);
  } else {
    // Check for individual options stored as separate fields
    const relevantResponses = Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`) && 
              key !== `${question.id}_other` && 
              key !== `${question.id}_comment` &&
              !key.includes('followUp'))
      .filter(key => responses[key] === true);
      
    if (relevantResponses.length > 0) {
      selectedOptions = relevantResponses.map(key => key.replace(`${question.id}_`, ''));
    }
  }
  
  // If no options were selected after all our checks, show "Not provided"
  if (selectedOptions.length === 0) {
    return (
      <>
        <Typography variant="body1">Not provided</Typography>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  return (
    <>
      <Box>
        {selectedOptions.map((item, index) => (
          <Chip 
            key={index} 
            label={item} 
            size="small" 
            sx={{ mr: 0.5, mb: 0.5 }} 
          />
        ))}
      </Box>
      
      {/* Display follow-up responses for multi-select options */}
      {question.followUps && question.followUps.map((followUp: any, idx: number) => {
        if (selectedOptions.includes(followUp.triggerOption)) {
          const followUpResponse = responses[`${question.id}_followUp_${idx}`];
          if (followUpResponse) {
            return (
              <FollowUpDisplay 
                key={idx}
                question={followUp.question}
                response={followUpResponse}
              />
            );
          }
        }
        return null;
      })}
      
      {/* Check for individual option follow-ups (alternative format) */}
      {selectedOptions.map(option => {
        const followUpKey = `${question.id}_followUp_${option}`;
        if (responses[followUpKey]) {
          return (
            <FollowUpDisplay 
              key={option}
              question={`Follow-up for "${option}"`}
              response={responses[followUpKey]}
            />
          );
        }
        return null;
      })}
      
      {/* Check for older format follow-ups (legacy) */}
      {selectedOptions.map(option => {
        if (question.conditionalFollowUps && question.conditionalFollowUps[option]) {
          const followUpResponse = responses[`${question.id}_followUp_${option}`];
          if (followUpResponse) {
            return (
              <FollowUpDisplay
                key={`legacy_${option}`}
                question={question.conditionalFollowUps[option].question}
                response={followUpResponse}
              />
            );
          }
        }
        return null;
      })}
      
      {/* Check for "other" option responses */}
      {question.allowOther && 
        (selectedOptions.includes('Other') || responses[`${question.id}_other`]) && (
        <FollowUpDisplay 
          question="Other"
          response={responses[`${question.id}_other`] || '(No details provided)'}
        />
      )}
      
      <CommentDisplay comments={comment} />
    </>
  );
};

export default MultiSelectResponse;