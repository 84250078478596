import React, { useState, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PhotoCamera, Edit, Delete } from '@mui/icons-material';
import Question from './Question';

interface ImageQuestionProps {
  question: any;
  value: string | null;
  onChange: (file: File | null) => void;
  imagePreview?: string;
  onAnnotate?: () => void;
  isAnnotated?: boolean;
}

const ImageQuestion: React.FC<ImageQuestionProps> = ({
  question,
  value,
  onChange,
  imagePreview,
  onAnnotate,
  isAnnotated = false
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  return (
    <Question question={question}>
      <Box className="p-6 border border-gray-200 rounded-lg hover:border-gray-300 transition-colors mb-6">
        <div className="mb-3">
          <label 
            htmlFor={`file-upload-${question.id}`} 
            className="cursor-pointer inline-block py-2 px-4 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 transition-colors"
          >
            Choose Image
            <input 
              id={`file-upload-${question.id}`}
              type="file" 
              accept="image/*" 
              onChange={(e) => onChange(e.target.files?.[0] || null)} 
              required={question.required}
              className="hidden"
            />
          </label>
        </div>
        
        {/* Image Preview */}
        {imagePreview && (
          <div className="mt-3 p-3 border rounded-lg bg-gray-50">
            <div className="mb-2">
              <Typography variant="subtitle2">Image Preview</Typography>
            </div>
            <div className="border border-gray-300 rounded-md overflow-hidden bg-white p-2 flex justify-center">
              <img 
                src={imagePreview} 
                alt="Preview" 
                className="max-h-48 object-contain"
                key={imagePreview} // Add key to force re-render when image changes
              />
            </div>
            <div className="mt-3 flex space-x-3">
              <Button 
                size="small"
                variant="outlined"
                color="error"
                startIcon={<Delete />}
                onClick={() => onChange(null)}
              >
                Remove
              </Button>
              <Button 
                size="small"
                variant="outlined" 
                color="primary"
                startIcon={<Edit />}
                onClick={onAnnotate}
              >
                {isAnnotated ? 'Edit Annotations' : 'Annotate Image'}
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Question>
  );
};

export default ImageQuestion;
export type { ImageQuestionProps };