import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  Chip,
} from '@mui/material';
import { Question } from '../../../store/store';

interface FileQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const FileQuestion: React.FC<FileQuestionProps> = ({
  question,
  onChange,
  questionTypes
}) => {
  const commonFileTypes = [
    { label: 'PDFs', value: '.pdf' },
    { label: 'Images', value: 'image/*' },
    { label: 'Documents', value: '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf' },
    { label: 'Archives', value: '.zip,.rar,.tar,.gz' },
    { label: 'Text files', value: 'text/*,.txt,.md' },
    { label: 'All files', value: '*' },
  ];

  const handleAddFileType = (type: string) => {
    const currentTypes = question.acceptedFileTypes ? question.acceptedFileTypes.split(',') : [];
    if (!currentTypes.includes(type)) {
      const newTypes = [...currentTypes, type].join(',');
      onChange({ acceptedFileTypes: newTypes });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField
          label="Question Label"
          fullWidth
          value={question.label}
          onChange={(e) => onChange({ label: e.target.value })}
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2} p={2} border={1} borderRadius={1} borderColor="divider" bgcolor="#f5f8ff">
        <Typography variant="subtitle2" color="primary" gutterBottom>
          File Upload Options
        </Typography>

        <TextField
          fullWidth
          label="Upload Button Text"
          value={question.uploadButtonText || "Select a file"}
          onChange={(e) => onChange({ uploadButtonText: e.target.value })}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Helper Text"
          value={question.helperText || ""}
          onChange={(e) => onChange({ helperText: e.target.value })}
          margin="normal"
        />

        <Box mt={2}>
          <Typography variant="subtitle2" gutterBottom>
            Accepted File Types
          </Typography>
          <TextField
            fullWidth
            label="File Extensions/MIME Types"
            value={question.acceptedFileTypes || ""}
            onChange={(e) => onChange({ acceptedFileTypes: e.target.value })}
            placeholder=".pdf,.docx,image/*"
            helperText="Comma separated list of file extensions or MIME types"
            margin="normal"
          />

          <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
            {commonFileTypes.map((type) => (
              <Chip
                key={type.value}
                label={type.label}
                onClick={() => handleAddFileType(type.value)}
                variant="outlined"
                size="small"
                clickable
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FileQuestion;