import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { differenceInBusinessDays, differenceInCalendarDays, isWeekend } from 'date-fns';
import { Box, Grid, TextField, Typography, FormControlLabel, Checkbox, Switch } from '@mui/material';
import Question from './Question';

interface DateRangeQuestionProps {
  question: any;
  value: {
    startDate: Date | null;
    endDate: Date | null;
    hours?: number | null;
    useHours?: boolean;
    includeWeekends?: boolean;
  } | null;
  onChange: (value: any) => void;
}

const DateRangeQuestion: React.FC<DateRangeQuestionProps> = ({
  question,
  value,
  onChange
}) => {
  // Initialize state with provided value or defaults
  const [startDate, setStartDate] = useState<Date | null>(value?.startDate || null);
  const [endDate, setEndDate] = useState<Date | null>(value?.endDate || null);
  const [useHours, setUseHours] = useState<boolean>(value?.useHours || false);
  const [hours, setHours] = useState<number | null>(value?.hours || null);
  const [includeWeekends, setIncludeWeekends] = useState<boolean>(
    value?.includeWeekends !== undefined ? value.includeWeekends : false
  );
  const [daysCount, setDaysCount] = useState<number | null>(null);
  
  // Calculate days when dates change
  useEffect(() => {
    if (startDate && endDate) {
      // Ensure end date is not before start date
      if (endDate < startDate) {
        return;
      }
      
      let days;
      if (includeWeekends) {
        days = differenceInCalendarDays(endDate, startDate) + 1; // Include the end day
      } else {
        // Count business days (excluding weekends)
        days = differenceInBusinessDays(endDate, startDate) + (isWeekend(endDate) ? 0 : 1);
      }
      setDaysCount(days);
    } else {
      setDaysCount(null);
    }
  }, [startDate, endDate, includeWeekends]);
  
  // Update parent component with all changes
  useEffect(() => {
    // Original response object with all the properties
    const responseObj = {
      startDate,
      endDate,
      useHours,
      hours: useHours ? hours : null,
      includeWeekends,
      // Add a string representation that won't break string methods
      toString: function() { return "DateRange"; }, // This prevents .startsWith() errors
    };
    
    // Make sure the object works with string methods
    Object.defineProperty(responseObj, 'startsWith', {
      value: function() { return false; }, // Always return false for .startsWith()
      writable: true,
      configurable: true
    });
    
    onChange(responseObj);
  }, [startDate, endDate, useHours, hours, includeWeekends, onChange]);

  return (
    <Question question={question}>
      <Grid container spacing={3}>
        {/* Start Date Picker */}
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <DatePicker
              label={question.startLabel || "Start Date"}
              value={startDate}
              format="dd/MM/yyyy"
              onChange={(date) => setStartDate(date)}
              slotProps={{ 
                textField: {
                  fullWidth: true,
                  required: question.required,
                  helperText: startDate ? "" : "Please select a start date"
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        
        {/* End Date Picker */}
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <DatePicker
              label={question.endLabel || "End Date"}
              value={endDate}
              format="dd/MM/yyyy"
              minDate={startDate || undefined}
              disabled={!startDate}
              onChange={(date) => setEndDate(date)}
              slotProps={{ 
                textField: {
                  fullWidth: true,
                  required: question.required,
                  helperText: endDate ? "" : "Please select an end date"
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/* Weekend inclusion toggle */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={includeWeekends}
                onChange={(e) => setIncludeWeekends(e.target.checked)}
                color="primary"
              />
            }
            label="Include weekends in calculation"
          />
        </Grid>

        {/* Display calculated days */}
        {startDate && endDate && daysCount !== null && (
          <Grid item xs={12}>
            <Box bgcolor="#f8f9fa" p={2} borderRadius={1} mt={1}>
              <Typography variant="body2" fontWeight="medium" color="primary">
                {daysCount === 0 ? (
                  "Same day selected"
                ) : (
                  `${daysCount} ${daysCount === 1 ? 'day' : 'days'} selected${includeWeekends ? '' : ' (excluding weekends)'}`
                )}
              </Typography>
            </Box>
          </Grid>
        )}

        {/* Hours option */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox 
                checked={useHours} 
                onChange={(e) => setUseHours(e.target.checked)}
              />
            }
            label="Specify hours (for partial days)"
          />
        </Grid>
        
        {/* Hours input field */}
        {useHours && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label={question.hoursLabel || "Hours"}
              value={hours ?? ""}
              onChange={(e) => setHours(e.target.value ? Number(e.target.value) : null)}
              InputProps={{ 
                inputProps: { 
                  min: 0, 
                  max: 24,
                  step: 0.5
                } 
              }}
              helperText="Enter number of hours (e.g. 4, 7.5)"
            />
          </Grid>
        )}
      </Grid>
    </Question>
  );
};

export default DateRangeQuestion;