import React from 'react';
import { Typography, Box } from '@mui/material';

interface InstructionsBlockProps {
  question: any;
}

const InstructionsBlock: React.FC<InstructionsBlockProps> = ({ question }) => {
  return (
    <div className={`p-4 rounded-lg ${
      question.instructionStyle === "warning" ? "bg-red-50 border border-red-200" :
      question.instructionStyle === "note" ? "bg-blue-50 border border-blue-200" :
      question.instructionStyle === "tip" ? "bg-green-50 border border-green-200" :
      "bg-gray-50 border border-gray-200"
    }`}>
      <div className="flex items-start">
        <div className="mr-3 mt-1">
          {question.instructionStyle === "warning" && <span className="text-red-500 text-xl">⚠️</span>}
          {question.instructionStyle === "note" && <span className="text-blue-500 text-xl">ℹ️</span>}
          {question.instructionStyle === "tip" && <span className="text-green-500 text-xl">💡</span>}
        </div>
        <div className="flex-1">
          <Typography 
            variant="subtitle1" 
            className={`font-medium ${
              question.instructionStyle === "warning" ? "text-red-700" :
              question.instructionStyle === "note" ? "text-blue-700" :
              question.instructionStyle === "tip" ? "text-green-700" :
              "text-gray-700"
            }`}
          >
            {question.label}
          </Typography>
          
          {/* Use instructionText with preserved line breaks */}
          {question.instructionText && (
            <Typography 
              variant="body2" 
              component="div" 
              className={`mt-1 ${
                question.instructionStyle === "warning" ? "text-red-600" :
                question.instructionStyle === "note" ? "text-blue-600" :
                question.instructionStyle === "tip" ? "text-green-600" :
                "text-gray-600"
              }`}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {question.instructionText}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstructionsBlock;