import React from "react";
import { Box, TextField, Select, MenuItem, Typography } from "@mui/material";
import { Question } from "../../../store/store";

interface TextQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const TextQuestion: React.FC<TextQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Question Label"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle2" gutterBottom>Text Field Options</Typography>
        <Box display="flex" gap={2}>
          <TextField
            label="Placeholder / Helper Text"
            fullWidth
            value={question.helperText || ""}
            onChange={(e) => onChange({ helperText: e.target.value })}
            placeholder="Enter text that will help guide the user's input"
            sx={{ mb: 2 }}
          />
        </Box>
        
        <Box mt={1} display="flex" gap={2}>
          <TextField
            label="Min Characters"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.minChars || ""}
            onChange={(e) => onChange({ minChars: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
          <TextField
            label="Max Characters"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.maxChars || ""}
            onChange={(e) => onChange({ maxChars: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
        </Box>

        {/* Preview */}
        <Box mt={3} p={2} bgcolor="#f8f8f8" borderRadius={1}>
          <Typography variant="caption" color="textSecondary">Preview:</Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={question.helperText || ""}
            helperText={((question.minChars ?? 0) > 0 || (question.maxChars ?? 0) > 0) ? 
              ((question.minChars ?? 0) > 0 && (question.maxChars ?? 0) > 0 
                ? `${question.minChars} to ${question.maxChars} characters required` 
                : (question.minChars ?? 0) > 0 
                  ? `Minimum ${question.minChars} characters required`
                  : (question.maxChars ?? 0) > 0 
                    ? `Maximum ${question.maxChars} characters allowed`
                    : "") : undefined}
            disabled
            sx={{ mt: 1 }}
          />
        </Box>
      </Box>
    </>
  );
};

export default TextQuestion;