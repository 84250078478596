import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, CircularProgress, Typography, Container, Button, Paper } from '@mui/material';

const FormSlugHandler: React.FC = () => {
  const { companySlug, formSlug } = useParams<{ companySlug: string, formSlug: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const findFormBySlug = async () => {
      try {
        if (!companySlug || !formSlug) {
          setError("Invalid URL");
          setLoading(false);
          return;
        }

        // First check users by shortCompanyName (highest priority)
        const userQuery = query(
          collection(db, "users"),
          where("shortCompanyName", "==", companySlug)
        );
        
        const userSnapshot = await getDocs(userQuery);
        let foundForm = false;
        
        // If user(s) found with matching short company name
        if (!userSnapshot.empty) {
          console.log("Found users with matching company slug:", userSnapshot.size);
          // Try each user ID to find the matching form
          for (const userDoc of userSnapshot.docs) {
            const userId = userDoc.id;
            console.log("Checking forms for user:", userId);
            
            // Look for the form with this slug
            const formQuery = query(
              collection(db, "forms"),
              where("userId", "==", userId),
              where("formSlug", "==", formSlug)
            );
            
            const formSnapshot = await getDocs(formQuery);
            console.log("Form search results:", formSnapshot.size);
            
            if (!formSnapshot.empty) {
              // Found a match! Redirect and exit
              const formId = formSnapshot.docs[0].id;
              console.log(`Found form with ID: ${formId}, redirecting to /fill/${formId}`);
              navigate(`/fill/${formId}`, { replace: true });
              foundForm = true;
              break;
            }
          }
          
          if (!foundForm) {
            // If no form found with the exact slug, try to find forms by this user
            // to see if maybe the slug changed
            const firstUserId = userSnapshot.docs[0].id;
            
            // Get all forms for this user
            const allFormsQuery = query(
              collection(db, "forms"),
              where("userId", "==", firstUserId)
            );
            
            const allFormsSnapshot = await getDocs(allFormsQuery);
            console.log("All forms for this user:", allFormsSnapshot.size);
            
            // Check if any forms have a title that might match our slug
            // (Sometimes slugs come from titles)
            let formIdByTitle = null;
            for (const formDoc of allFormsSnapshot.docs) {
              const formData = formDoc.data();
              const formTitle = formData.formTitle || formData.title || '';
              
              // Convert the title to a slug-like format
              const titleAsSlug = formTitle
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '');
              
              if (titleAsSlug === formSlug || formData.formTitle === formSlug) {
                formIdByTitle = formDoc.id;
                console.log("Found form with matching title:", formIdByTitle);
                break;
              }
            }
            
            if (formIdByTitle) {
              console.log(`Redirecting to form with matching title: ${formIdByTitle}`);
              navigate(`/fill/${formIdByTitle}`, { replace: true });
              foundForm = true;
            }
          }
        }
        
        // If not found via short company name, try landing pages
        if (!foundForm) {
          console.log("Trying to find via landing pages");
          const landingPagesQuery = query(
            collection(db, "landing_pages"),
            where("companySlug", "==", companySlug)
          );
          
          const landingPagesSnapshot = await getDocs(landingPagesQuery);
          console.log("Landing pages found:", landingPagesSnapshot.size);
          
          if (!landingPagesSnapshot.empty) {
            // Get the landing page
            const landingPageData = landingPagesSnapshot.docs[0].data();
            const userId = landingPageData.userId;
            
            if (userId) {
              // Look for the form with this slug
              const formQuery = query(
                collection(db, "forms"),
                where("userId", "==", userId),
                where("formSlug", "==", formSlug)
              );
              
              const formSnapshot = await getDocs(formQuery);
              console.log("Form search results from landing page:", formSnapshot.size);
              
              if (!formSnapshot.empty) {
                // Found a match! Redirect
                const formId = formSnapshot.docs[0].id;
                console.log(`Found form via landing page with ID: ${formId}`);
                navigate(`/fill/${formId}`, { replace: true });
                foundForm = true;
              } else {
                // Try by form title as fallback
                const allFormsQuery = query(
                  collection(db, "forms"),
                  where("userId", "==", userId)
                );
                
                const allFormsSnapshot = await getDocs(allFormsQuery);
                
                // Check if any forms have a title that might match our slug
                for (const formDoc of allFormsSnapshot.docs) {
                  const formData = formDoc.data();
                  const formTitle = formData.formTitle || formData.title || '';
                  
                  // Convert the title to a slug-like format
                  const titleAsSlug = formTitle
                    .toLowerCase()
                    .replace(/\s+/g, '-')
                    .replace(/[^\w\-]+/g, '');
                  
                  if (titleAsSlug === formSlug) {
                    console.log(`Redirecting to form with matching title: ${formDoc.id}`);
                    navigate(`/fill/${formDoc.id}`, { replace: true });
                    foundForm = true;
                    break;
                  }
                }
              }
            }
          }
        }
        
        // If we get here and haven't found the form, it doesn't exist
        if (!foundForm) {
          console.log("No matching form found");
          setError("Form not found");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error finding form:", error);
        setError("An error occurred while finding the form");
        setLoading(false);
      }
    };
    
    findFormBySlug();
  }, [companySlug, formSlug, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
          <Typography paragraph>
            The form you are looking for could not be found. Please check the URL and try again.
          </Typography>
          <Button variant="contained" onClick={() => navigate("/")}>
            Return to Home
          </Button>
        </Paper>
      </Container>
    );
  }

  return null; // We should redirect before rendering anything
};

export default FormSlugHandler;