import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material';

import { 
  SelectVisualization, 
  CheckboxVisualization,
  NumberVisualization,
  DateVisualization,
  TimeVisualization,
  TextVisualization,
  RepeatableVisualization,
  SectionVisualization
} from './Visualizations';
import { filterArchivedSubmissions } from '../utils/submissionFilters';

// QuestionSummary props interface
interface QuestionSummaryProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
  onFilterChange?: (questionId: string, filterValue: any) => void;
  allQuestions?: any[];
  includeArchived?: boolean; // Add this new prop
}

// Add this helper function to find section child questions
const findSectionChildQuestions = (sectionId: string, allQuestions: any[], submissions: any[]) => {
  // First, try to find questions that explicitly reference this section
  const childQuestions = allQuestions.filter(q => 
    q.sectionId === sectionId || 
    (q.path && (q.path.includes(sectionId) || q.path.includes(`section_q_${sectionId}`)))
  );
  
  if (childQuestions.length > 0) {
    return childQuestions;
  }
  
  // If no explicit references, look for responses with the section prefix
  if (submissions && submissions.length > 0) {
    const sectionResponsePattern = new RegExp(`^section_q_${sectionId}_(.*)`);
    const childQuestionIds = new Set<string>();
    
    submissions.forEach(submission => {
      if (submission.responses) {
        Object.keys(submission.responses).forEach(key => {
          const match = key.match(sectionResponsePattern);
          if (match && match[1]) {
            childQuestionIds.add(match[1]);
          }
        });
      }
    });
    
    // Check if we found any child question IDs
    if (childQuestionIds.size > 0) {
      // Try to find these questions in the allQuestions array
      const foundQuestions = Array.from(childQuestionIds)
        .map(id => allQuestions.find(q => q.id === id))
        .filter(q => q !== undefined);
      
      if (foundQuestions.length > 0) {
        return foundQuestions;
      }
      
      // If we can't find them, create synthetic questions
      return Array.from(childQuestionIds).map(id => ({
        id,
        label: id.replaceAll('_', ' ').trim(),
        type: 'text', // Default to text type
        sectionId: sectionId
      }));
    }
  }
  
  return [];
};

// Update the QuestionSummary component to handle section questions
const QuestionSummary: React.FC<QuestionSummaryProps> = ({ 
  question, 
  summaryData,
  filteredSubmissions = [],
  onFilterChange,
  allQuestions = [], // Default to empty array if not provided
  includeArchived = false // Add this new prop
}) => {
  const theme = useTheme();
  const [activeFilter, setActiveFilter] = useState<any>(null);
  
  // Filter out archived submissions
  const effectiveSubmissions = React.useMemo(() => 
    filterArchivedSubmissions(filteredSubmissions, includeArchived),
    [filteredSubmissions, includeArchived]
  );
  
  // Add debug logging to check incoming data
  useEffect(() => {
    console.log(`QuestionSummary for ${question.id} (${question.type}):`, {
      hasSummaryData: !!summaryData,
      totalSubmissionsCount: filteredSubmissions.length,
      activeSubmissionsCount: effectiveSubmissions.length,
      allQuestionsCount: allQuestions.length
    });
    
    if (question.type === 'section') {
      const childQuestions = findSectionChildQuestions(question.id, allQuestions, effectiveSubmissions);
      console.log(`Section ${question.id} has ${childQuestions.length} child questions:`, 
        childQuestions.map(q => `${q.id} (${q.type})`));
    }
  }, [question, summaryData, filteredSubmissions, effectiveSubmissions, allQuestions]);
  
  // Handle section type questions specially
  if (question.type === 'section') {
    const childQuestions = findSectionChildQuestions(question.id, allQuestions, effectiveSubmissions);
    
    return (
      <SectionVisualization
        question={question}
        summaryData={summaryData}
        filteredSubmissions={effectiveSubmissions} // Use filtered submissions
        childQuestions={childQuestions}
      />
    );
  }
  
  // Handle filter changes for this question
  const handleFilterChange = (value: any) => {
    setActiveFilter(value);
    if (onFilterChange) {
      onFilterChange(question.id, value);
    }
  };
  
  // Generate a filter handler for Select visualization
  const handleSelectFilterChange = (index: number, value: any, label: string) => {
    // Extract just the value for simple filtering
    handleFilterChange(value);
  };
  
  return (
    <Card sx={{ mb: 3, overflow: 'hidden', boxShadow: 1, borderRadius: 2 }}>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight="500" color="text.primary">
            {question.label}
          </Typography>
        }
        subheader={
          <Typography variant="body2" color="text.secondary">
            {question.description}
          </Typography>
        }
        sx={{ 
          bgcolor: theme.palette.grey[50],
          borderBottom: `1px solid ${theme.palette.divider}`
        }}
      />
      
      <CardContent sx={{ p: 0 }}>
        <Box p={2}>
          {/* Select visualization based on question type */}
          {question.type === 'select' || question.type === 'radio' || question.type === 'multi-select' ? (
            <SelectVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
              onSegmentClick={handleSelectFilterChange}  // Use correctly typed handler
              activeFilter={activeFilter}               // Pass the activeFilter
            />
          ) : question.type === 'checkbox' ? (
            <CheckboxVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
              activeFilter={activeFilter}              // Add activeFilter prop
            />
          ) : question.type === 'number' || question.type === 'rating' ? (
            <NumberVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
            />
          ) : question.type === 'date' || question.type === 'date-range' ? (
            <DateVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
            />
          ) : question.type === 'time' || question.type === 'time-range' ? (
            <TimeVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
            />
          ) : (question.type === 'text' || question.type === 'textarea' || 
              question.type === 'email' || question.type === 'phone' || 
              question.type === 'name') ? (
            <TextVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
            />
          ) : question.type === 'repeatable' ? (
            <RepeatableVisualization 
              question={question} 
              summaryData={summaryData}
              filteredSubmissions={effectiveSubmissions} // Use filtered submissions
            />
          ) : (
            <Typography variant="body2" color="text.secondary" textAlign="center" py={2}>
              No visualization available for question type: {question.type}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuestionSummary;