import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

/**
 * Synchronizes company information from user profile to landing page
 * @param userId User ID to synchronize
 */
export const syncProfileToLandingPage = async (userId: string): Promise<boolean> => {
  try {
    // Get user profile
    const userDoc = await getDoc(doc(db, "users", userId));
    
    if (!userDoc.exists()) {
      return false;
    }
    
    const userData = userDoc.data();
    
    // Check if landing page exists
    const landingPageRef = doc(db, "landing_pages", userId);
    const landingPageSnap = await getDoc(landingPageRef);
    
    // If no landing page exists for this user yet, create one
    if (!landingPageSnap.exists()) {
      if (userData.shortCompanyName) {
        console.log(`Creating new landing page for user ${userId}`);
        // Create a new landing page with the shortCompanyName
        await setDoc(landingPageRef, {
          userId,
          companyName: userData.companyName || '',
          companyLogo: userData.companyLogo || null,
          companySlug: userData.shortCompanyName, // Use shortCompanyName as companySlug
          title: `${userData.companyName || 'Company'} Forms`,
          description: 'Please select a form to complete',
          isActive: true,
          forms: [],
          theme: userData.theme || 'Blue',
          createdAt: new Date(),
          updatedAt: new Date()
        });
        return true;
      }
      return false; // No shortCompanyName to use for creating the landing page
    }
    
    const landingPageData = landingPageSnap.data();
    
    // Determine which slug to use (prioritize shortCompanyName if available)
    const effectiveSlug = userData.shortCompanyName || landingPageData.companySlug;
    
    // Update landing page with latest profile data, including theme
    await setDoc(landingPageRef, {
      companyName: userData.companyName || '',
      companyLogo: userData.companyLogo || null,
      companySlug: effectiveSlug, // Always use shortCompanyName when available
      theme: userData.theme || landingPageData.theme || 'Blue',
      updatedAt: new Date()
    }, { merge: true });
    
    // IMPORTANT: Now also update the user profile to ensure both fields are set
    // This ensures shortCompanyName and companySlug are always in sync
    if (userData.shortCompanyName && !userData.companySlug) {
      await setDoc(doc(db, "users", userId), {
        companySlug: userData.shortCompanyName // Sync the companySlug field from shortCompanyName
      }, { merge: true });
      console.log(`Updated user ${userId} to add companySlug field`);
    }
    
    return true;
  } catch (error) {
    console.error("Error synchronizing profile data:", error);
    return false;
  }
};

/**
 * One-time function to synchronize all users' profile and landing page data
 * This can be run from an admin dashboard or as a maintenance task
 */
export const syncAllProfiles = async (): Promise<{ success: number, failed: number }> => {
  const result = { success: 0, failed: 0 };
  
  try {
    // Get all users with landing pages
    const landingPagesQuery = query(collection(db, "landing_pages"));
    const landingPagesSnapshot = await getDocs(landingPagesQuery);
    
    // Process each landing page
    for (const landingPage of landingPagesSnapshot.docs) {
      const userId = landingPage.id;
      
      try {
        const success = await syncProfileToLandingPage(userId);
        if (success) {
          result.success++;
        } else {
          result.failed++;
        }
      } catch (error) {
        console.error(`Error syncing profile for user ${userId}:`, error);
        result.failed++;
      }
    }
    
    // Get all users with shortCompanyName but no landing page
    const usersWithShortNameQuery = query(
      collection(db, "users"),
      where("shortCompanyName", "!=", null)
    );
    
    const usersWithShortNameSnapshot = await getDocs(usersWithShortNameQuery);
    
    // Process each user with shortCompanyName
    for (const userDoc of usersWithShortNameSnapshot.docs) {
      const userId = userDoc.id;
      
      // Check if we already processed this user
      const landingPageRef = doc(db, "landing_pages", userId);
      const landingPageSnap = await getDoc(landingPageRef);
      
      // Skip if already has a landing page (would have been processed above)
      if (landingPageSnap.exists()) {
        continue;
      }
      
      try {
        const userData = userDoc.data();
        
        // Create a new landing page with the shortCompanyName
        if (userData.shortCompanyName) {
          await setDoc(landingPageRef, {
            userId,
            companyName: userData.companyName || '',
            companyLogo: userData.companyLogo || null,
            companySlug: userData.shortCompanyName,
            title: `${userData.companyName || 'Company'} Forms`,
            description: 'Please select a form to complete',
            isActive: true,
            forms: [],
            theme: 'light',
            createdAt: new Date(),
            updatedAt: new Date()
          });
          
          result.success++;
          console.log(`Created new landing page for user ${userId} with slug "${userData.shortCompanyName}"`);
        }
      } catch (error) {
        console.error(`Error creating landing page for user ${userId}:`, error);
        result.failed++;
      }
    }
    
    return result;
  } catch (error) {
    console.error("Error in bulk synchronization:", error);
    return result;
  }
};