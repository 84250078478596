import { useState } from 'react';
import { PDFDocument } from '../components/PDFGeneration';

export const usePdfGenerator = () => {
  const [pdfGenerating, setPdfGenerating] = useState<boolean>(false);

  const generatePdf = (
    formId: string,
    form: any,
    responses: { [key: string]: any },
    repeatableItems: { [key: string]: Array<{ [key: string]: any }> },
    signatures: { [key: string]: string },
    imagePreview: { [key: string]: string },
    annotatedImages: { [key: string]: string },
    logoUrl: string | null,
    theme: any,
    followUpResponses?: Record<string, string>
  ) => {
    setPdfGenerating(true);
    
    try {
      // Merge followUpResponses with regular responses if provided
      const mergedResponses = { ...responses };
      
      if (followUpResponses) {
        Object.keys(followUpResponses).forEach(key => {
          mergedResponses[key] = followUpResponses[key];
        });
      }
      
      // Add debugging for repeatableItems
      console.log("PDF Generation - repeatableItems:", repeatableItems);
      
      // Use our new PDFDocument component to create the PDF
      const doc = PDFDocument({
        formId,
        form,
        responses: mergedResponses,
        repeatableItems: repeatableItems || {}, // Ensure it's never undefined
        signatures,
        imagePreview,
        annotatedImages,
        logoUrl,
        theme
      });
      
      setPdfGenerating(false);
      return doc;
    } catch (error) {
      console.error('Error generating PDF:', error);
      setPdfGenerating(false);
      throw error;
    }
  };

  return { generatePdf, pdfGenerating };
};
