import React from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentDisplay from '../shared/CommentDisplay';

interface RepeatableResponseProps {
  question: any;
  submission: any;
}

const RepeatableResponse: React.FC<RepeatableResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const repeatableItems = submission?.repeatableItems || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  
  // Debug logging
  console.log(`Repeatable response for ${question.id}:`, {
    itemsInRepeatableItems: repeatableItems[question.id] ? repeatableItems[question.id].length : 0,
    itemsInResponses: responses[question.id] ? 
      (Array.isArray(responses[question.id]) ? responses[question.id].length : 'not an array') : 'not found',
    subFields: question.subFields
  });
  
  // First check if items are in the repeatableItems object (preferred location)
  if (repeatableItems && repeatableItems[question.id] && repeatableItems[question.id].length > 0) {
    return (
      <>
        <Box sx={{ mt: 1 }}>
          {repeatableItems[question.id].map((item: any, index: number) => (
            <Accordion key={index} sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`item-${index}-content`}
                id={`item-${index}-header`}
              >
                <Typography variant="subtitle2">Item {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {question.subFields?.map((subField: any) => (
                    <Grid item xs={12} sm={6} key={subField.id}>
                      <Typography variant="caption" color="text.secondary">
                        {subField.label}
                      </Typography>
                      <Typography variant="body2">
                        {renderSubfieldValue(item[subField.id], subField.type)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // Alternatively, check if items are directly in the responses object
  const response = responses[question.id];
  if (response && Array.isArray(response) && response.length > 0) {
    return (
      <>
        <Box sx={{ mt: 1 }}>
          {response.map((item: any, index: number) => (
            <Card key={index} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                  Item {index + 1}
                </Typography>
                <Grid container spacing={2}>
                  {question.subFields?.map((subField: any) => (
                    <Grid item xs={12} sm={6} key={subField.id}>
                      <Typography variant="caption" color="text.secondary">
                        {subField.label}
                      </Typography>
                      <Typography variant="body2">
                        {renderSubfieldValue(item[subField.id], subField.type)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Box>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // No repeatable items found
  return (
    <>
      <Typography variant="body1">No items provided</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

// Helper function to render subfieldValue based on its type
const renderSubfieldValue = (value: any, type: string): string => {
  if (value === undefined || value === null) {
    return 'Not provided';
  }
  
  // Handle specific types
  switch (type) {
    case 'date':
      if (typeof value === 'object' && value.toDate) {
        try {
          return value.toDate().toLocaleDateString();
        } catch (e) {
          return String(value);
        }
      }
      try {
        return new Date(value).toLocaleDateString();
      } catch (e) {
        return String(value);
      }
      
    case 'checkbox':
      return value === true ? 'Yes' : value === false ? 'No' : String(value);
      
    case 'multi-select':
    case 'checkbox-multi':
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return String(value);
      
    default:
      return String(value);
  }
};

export default RepeatableResponse;