import React from 'react';
import { Typography, Button, TextField, Box, Chip, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Question from './Question';

interface RepeatableQuestionProps {
  question: any;
  repeatableItems: Array<{ [key: string]: any }>;
  onAddItem: () => void;
  onRemoveItem: (index: number) => void;
  onItemChange: (itemIndex: number, fieldId: string, value: any) => void;
}

const RepeatableQuestion: React.FC<RepeatableQuestionProps> = ({
  question,
  repeatableItems,
  onAddItem,
  onRemoveItem,
  onItemChange
}) => {
  return (
    <Question question={question}>
      <div className="border border-blue-100 rounded-lg p-4 mt-2 bg-blue-50">
        <div className="mb-4">
          <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 500 }}>
            {repeatableItems?.length || 0} {question.label} added
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Click "Add New" to add more items
          </Typography>
        </div>
        
        {repeatableItems?.map((item, itemIndex) => (
          <div 
            key={itemIndex} 
            className="mb-6 bg-white p-5 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-center mb-4 border-b pb-3">
              <div className="flex items-center">
                <div className="h-8 w-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-semibold mr-2">
                  {itemIndex + 1}
                </div>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{question.label} #{itemIndex + 1}</Typography>
              </div>
              
              <Button 
                variant="outlined" 
                color="error" 
                size="small"
                startIcon={<Delete />}
                disabled={repeatableItems.length <= 1}
                onClick={() => onRemoveItem(itemIndex)}
              >
                Remove
              </Button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {question.subFields.map((field: any) => (
                <div key={field.id} className="mb-3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {field.label}
                    {field.required && <span className="text-red-500 ml-1">*</span>}
                  </label>

                  {/* Text Input */}
                  {field.type === "text" && (
                    <TextField
                      fullWidth
                      size="small"
                      value={item[field.id] || ""}
                      onChange={(e) => onItemChange(itemIndex, field.id, e.target.value)}
                      required={field.required} 
                    />
                  )}

                  {/* Number Input */}
                  {field.type === "number" && (
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      value={item[field.id] || ""}
                      onChange={(e) => onItemChange(itemIndex, field.id, e.target.value)}
                      required={field.required} 
                    />
                  )}

                  {/* Select Input */}
                  {field.type === "select" && field.options && (
                    <div>
                      <FormControl fullWidth size="small" required={field.required}>
                        <Select
                          value={item[field.id] || ""}
                          onChange={(e) => onItemChange(itemIndex, field.id, e.target.value)}
                        >
                          <MenuItem value="" disabled>
                            <em>Select an option</em>
                          </MenuItem>
                          {field.options.map((option: string, optIndex: number) => (
                            <MenuItem key={optIndex} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Render follow-ups based on selected value */}
                      {item[field.id] && (field.followUps || [])
                        .filter((followUp: { triggerOption: any; }) => followUp.triggerOption === item[field.id])
                        .map((followUp: { question: string; triggerOption: any; required: boolean | undefined; }, index: number) => (
                          <div key={index} className="mt-2 pl-3 border-l-4 border-blue-300">
                            <p className="text-sm font-medium mb-1">{followUp.question}</p>
                            <TextField
                              fullWidth
                              size="small"
                              multiline
                              rows={2}
                              value={item[`${field.id}-followup-${followUp.triggerOption}`] || ""}
                              onChange={(e) => onItemChange(itemIndex, `${field.id}-followup-${followUp.triggerOption}`, e.target.value)}
                              required={followUp.required} 
                            />
                          </div>
                        ))}

                      {/* Backward compatibility */}
                      {item[field.id] === field.followUpTriggerOption && field.followUp?.question && (
                        <div className="mt-2 pl-3 border-l-4 border-blue-300">
                          <p className="text-sm font-medium mb-1">{field.followUp.question}</p>
                          <TextField
                            fullWidth
                            size="small"
                            multiline
                            rows={2}
                            value={item[`${field.id}-followup`] || ""}
                            onChange={(e) => onItemChange(itemIndex, `${field.id}-followup`, e.target.value)}
                            required={field.followUp.required} 
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {/* Date Picker */}
                  {field.type === "date" && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={item[field.id]}
                        onChange={(newValue) => onItemChange(itemIndex, field.id, newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: "small",
                            required: field.required
                          }
                        }} 
                      />
                    </LocalizationProvider>
                  )}

                  {/* Multi-Select Input */}
                  {field.type === "multi-select" && field.options && (
                    <div>
                      <FormControl fullWidth size="small" required={field.required}>
                        <InputLabel>Select multiple</InputLabel>
                        <Select
                          multiple
                          value={item[field.id] || []}
                          onChange={(e) => onItemChange(itemIndex, field.id, e.target.value)}
                          label="Select multiple"
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {(selected as string[]).map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                        >
                          {field.options.map((option: string, optIndex: number) => (
                            <MenuItem key={optIndex} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Multi-select follow-ups */}
                      {Array.isArray(item[field.id]) && (field.followUps || [])
                        .filter((followUp: { triggerOption: any; }) => item[field.id].includes(followUp.triggerOption))
                        .map((followUp: { question: string; triggerOption: string; required: boolean | undefined; }, index: number) => (
                          <div key={index} className="mt-2 pl-3 border-l-4 border-purple-300">
                            <p className="text-sm font-medium mb-1">
                              {followUp.question}
                              <Chip
                                label={followUp.triggerOption}
                                size="small"
                                sx={{ ml: 1 }}
                                variant="outlined" 
                              />
                            </p>
                            <TextField
                              fullWidth
                              size="small"
                              multiline
                              rows={2}
                              value={item[`${field.id}-followup-${followUp.triggerOption}`] || ""}
                              onChange={(e) => onItemChange(itemIndex, `${field.id}-followup-${followUp.triggerOption}`, e.target.value)}
                              required={followUp.required} 
                            />
                          </div>
                        ))}

                      {/* Multi-select backward compatibility */}
                      {Array.isArray(item[field.id]) &&
                        item[field.id].includes(field.followUpTriggerOption) &&
                        field.followUp?.question && (
                          <div className="mt-2 pl-3 border-l-4 border-purple-300">
                            <p className="text-sm font-medium mb-1">{field.followUp.question}</p>
                            <TextField
                              fullWidth
                              size="small"
                              multiline
                              rows={2}
                              value={item[`${field.id}-followup`] || ""}
                              onChange={(e) => onItemChange(itemIndex, `${field.id}-followup`, e.target.value)}
                              required={field.followUp.required} 
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        
        <div className="mt-4">
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth
            startIcon={<Add />}
            onClick={onAddItem}
            sx={{ py: 1.5 }}
          >
            Add New {question.label}
          </Button>
        </div>
      </div>
    </Question>
  );
};

export default RepeatableQuestion;