import React from 'react';
import { Typography, Box } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import FollowUpDisplay from '../shared/FollowUpDisplay';

interface SelectResponseProps {
  question: any;
  submission: any;
}

const SelectResponse: React.FC<SelectResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // For select questions, response should be a string
  const displayValue = response || 'Not provided';
  
  return (
    <>
      <Typography variant="body1">{displayValue}</Typography>
      
      {/* Display follow-up response if applicable */}
      {question.followUps && question.followUps.map((followUp: any, idx: number) => {
        if (followUp.triggerOption === response) {
          const followUpResponse = responses[`${question.id}_followUp_${idx}`];
          if (followUpResponse) {
            return (
              <FollowUpDisplay
                key={idx}
                question={followUp.question}
                response={followUpResponse}
              />
            );
          }
        }
        return null;
      })}
      
      {/* Handle conditional follow-up (older format) */}
      {question.conditionalFollowUps && response && question.conditionalFollowUps[response] && (
        <FollowUpDisplay
          question={question.conditionalFollowUps[response].question}
          response={responses[`${question.id}_followUp_${response}`] || 'Not provided'}
        />
      )}
      
      {/* Single follow-up (oldest format) */}
      {question.followUp && responses[`${question.id}_followUp`] && (
        <FollowUpDisplay
          question={question.followUp.question}
          response={responses[`${question.id}_followUp`]}
        />
      )}
      
      {/* Handle "other" option if applicable */}
      {question.allowOther && response === 'Other' && responses[`${question.id}_other`] && (
        <FollowUpDisplay
          question="Other"
          response={responses[`${question.id}_other`]}
        />
      )}
      
      <CommentDisplay comments={comment} />
    </>
  );
};

export default SelectResponse;