import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Divider, 
  Paper, 
  Chip,
  IconButton,
  Collapse,
  Tooltip,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { 
  ExpandMore,
  ExpandLess, 
  Layers as LayersIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
import SubmissionHeader from './SubmissionHeader';
import { renderResponse } from './ResponseRenderers';

interface SubmissionDetailProps {
  submission: any;
  questions: any[];
  formId: string;
  formTitle: string;
  theme: any;
  onArchiveChange?: () => void;
}

const SubmissionDetail: React.FC<SubmissionDetailProps> = ({
  submission,
  questions,
  formId,
  formTitle,
  theme,
  onArchiveChange
}) => {
  // Use system theme and check for mobile viewport
  const systemTheme = useTheme();
  const isMobile = useMediaQuery(systemTheme.breakpoints.down('sm'));
  
  // State for controlling expanded/collapsed sections
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});
  const [showAll, setShowAll] = useState(false);
  
  // Toggle a specific section
  const toggleSection = (questionId: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };
  
  // Toggle all sections at once
  const toggleAllSections = () => {
    const newShowAll = !showAll;
    setShowAll(newShowAll);
    
    // Create an object with all question IDs set to the new state
    const newExpandedSections: Record<string, boolean> = {};
    questions.forEach(q => {
      if (q.type !== 'section' && q.type !== 'instructions') {
        newExpandedSections[q.id] = newShowAll;
      }
    });
    
    setExpandedSections(newExpandedSections);
  };

  // Handle empty submission state
  if (!submission) {
    return (
      <Box 
        display="flex" 
        flexDirection="column"
        justifyContent="center" 
        alignItems="center" 
        height="100%"
        gap={2}
      >
        <DescriptionIcon color="disabled" sx={{ fontSize: 48 }} />
        <Typography variant="body1" color="text.secondary">
          Select a submission to view details
        </Typography>
      </Box>
    );
  }

  // Group questions by sections if any
  const sections: Record<string, any[]> = {};
  const topLevelQuestions: any[] = [];
  
  questions.forEach(q => {
    if (q.sectionId) {
      if (!sections[q.sectionId]) {
        sections[q.sectionId] = [];
      }
      sections[q.sectionId].push(q);
    } else if (q.type !== 'section' && q.type !== 'instructions') {
      topLevelQuestions.push(q);
    }
  });

  // Find section details
  const sectionDetails = questions
    .filter(q => q.type === 'section')
    .reduce((acc, section) => {
      acc[section.id] = {
        label: section.label,
        description: section.description
      };
      return acc;
    }, {} as Record<string, {label: string, description: string}>);

  return (
    <Box sx={{ p: { xs: 1, sm: 3 } }}>
      {/* Improved Header & Metadata */}
      <Paper 
        elevation={0} 
        sx={{ 
          mb: 3, 
          p: { xs: 2, sm: 3 }, 
          borderRadius: 2, 
          bgcolor: `${theme.palette.primary.main}08`,
          border: `1px solid ${theme.palette.primary.main}22`
        }}
      >
        {/* Submission header with metadata */}
        <SubmissionHeader 
          submission={{
            ...submission,
            formId: formId,
            formSlug: submission.formSlug || ''
          }}
          onArchiveChange={onArchiveChange}
          isMobile={isMobile}
        />
      </Paper>
      
      {/* Section Controls */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1, sm: 0 }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LayersIcon color="primary" fontSize="small" />
          <Typography variant="h6" sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
            Form Responses
          </Typography>
          <Chip 
            label={`${questions.filter(q => q.type !== 'section' && q.type !== 'instructions').length} questions`}
            size="small"
            color="primary"
            variant="outlined"
            sx={{ ml: 1 }}
          />
        </Box>

        <Tooltip title={showAll ? "Collapse all sections" : "Expand all sections"}>
          <IconButton onClick={toggleAllSections} size="small" color="primary">
            {showAll ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* Render top-level questions */}
      {topLevelQuestions.length > 0 && (
        <Box sx={{ mb: 3 }}>
          {topLevelQuestions.map((question) => {
            try {
              const isExpanded = expandedSections[question.id] ?? false;
              
              return (
                <Paper 
                  key={question.id} 
                  variant="outlined" 
                  sx={{ 
                    mb: 2, 
                    borderRadius: 2,
                    overflow: 'hidden',
                    transition: 'box-shadow 0.2s',
                    '&:hover': {
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                    }
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: 2,
                      py: 1.5,
                      bgcolor: `${theme.palette.background.default}80`,
                      borderBottom: isExpanded ? `1px solid ${theme.palette.divider}` : 'none',
                      cursor: 'pointer'
                    }}
                    onClick={() => toggleSection(question.id)}
                  >
                    <Box>
                      <Typography 
                        variant="subtitle1" 
                        fontWeight="500"
                        sx={{ 
                          fontSize: { xs: '0.95rem', sm: '1rem' },
                          pr: 2
                        }}
                      >
                        {question.label}
                        {question.required && <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>}
                      </Typography>
                      {question.description && (
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                          {question.description}
                        </Typography>
                      )}
                    </Box>
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSection(question.id);
                      }}
                    >
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                  
                  <Collapse in={isExpanded}>
                    <Box sx={{ p: 2 }}>
                      {renderResponse(question, submission)}
                    </Box>
                  </Collapse>
                </Paper>
              );
            } catch (err) {
              console.error(`Error processing question ${question.id}:`, err);
              return (
                <Paper 
                  key={question.id} 
                  variant="outlined" 
                  sx={{ 
                    p: 2, 
                    mb: 2, 
                    borderRadius: 2, 
                    borderColor: 'error.main',
                    bgcolor: 'error.main05'
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="medium" color="error">
                    {question.label || 'Unknown Question'}
                    {question.required && <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>}
                  </Typography>
                  <Typography variant="body2" color="error">
                    Error displaying this response. See console for details.
                  </Typography>
                </Paper>
              );
            }
          })}
        </Box>
      )}

      {/* Render section-based questions */}
      {Object.keys(sections).map(sectionId => {
        const sectionQuestions = sections[sectionId];
        const section = sectionDetails[sectionId] || { 
          label: 'Untitled Section', 
          description: '' 
        };
        
        return (
          <Accordion 
            key={sectionId}
            defaultExpanded
            sx={{ 
              mb: 3, 
              borderRadius: '8px !important',
              '&:before': { display: 'none' }, // Remove the default line
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ 
                bgcolor: `${theme.palette.primary.main}10`,
                borderRadius: '8px 8px 0 0'
              }}
            >
              <Box>
                <Typography variant="subtitle1" fontWeight="600">{section.label}</Typography>
                {section.description && (
                  <Typography 
                    variant="caption" 
                    color="text.secondary"
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                  >
                    {section.description}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 3, px: { xs: 1, sm: 2 } }}>
              {sectionQuestions.map(question => {
                try {
                  const isExpanded = expandedSections[question.id] ?? false;
                  
                  return (
                    <Paper 
                      key={question.id} 
                      variant="outlined" 
                      sx={{ 
                        mb: 2, 
                        borderRadius: 2,
                        overflow: 'hidden'
                      }}
                    >
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1.5,
                          bgcolor: `${theme.palette.background.default}80`,
                          borderBottom: isExpanded ? `1px solid ${theme.palette.divider}` : 'none',
                          cursor: 'pointer'
                        }}
                        onClick={() => toggleSection(question.id)}
                      >
                        <Typography 
                          variant="subtitle1" 
                          fontWeight="500"
                          sx={{ fontSize: { xs: '0.95rem', sm: '1rem' } }}
                        >
                          {question.label}
                          {question.required && <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>}
                        </Typography>
                        <IconButton 
                          size="small" 
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSection(question.id);
                          }}
                        >
                          {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Box>
                      
                      <Collapse in={isExpanded}>
                        <Box sx={{ p: 2 }}>
                          {renderResponse(question, submission)}
                        </Box>
                      </Collapse>
                    </Paper>
                  );
                } catch (err) {
                  console.error(`Error processing sectioned question ${question.id}:`, err);
                  return (
                    <Paper 
                      key={question.id} 
                      variant="outlined" 
                      sx={{ 
                        p: 2, 
                        mb: 2, 
                        borderRadius: 2, 
                        borderColor: 'error.main' 
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="medium" color="error">
                        {question.label || 'Unknown Question'}
                      </Typography>
                      <Typography variant="body2" color="error">
                        Error displaying this response.
                      </Typography>
                    </Paper>
                  );
                }
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
      
      {/* Empty state for no questions */}
      {questions.filter(q => q.type !== 'instructions').length === 0 && (
        <Box sx={{ textAlign: 'center', py: 5 }}>
          <Typography variant="body1" color="text.secondary">
            No questions defined for this form.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SubmissionDetail;