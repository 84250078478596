import React, { useState } from "react";
import { 
  Box, 
  TextField, 
  Typography, 
  Select, 
  MenuItem, 
  FormControlLabel,
  Switch,
  IconButton,
  List,
  ListItem,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText
} from "@mui/material";
import { 
  Add as AddIcon, 
  Delete as DeleteIcon,
  ExpandMore,
  Close
} from '@mui/icons-material';
import { Question } from "../../../store/store";

interface CheckboxQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleAddOption = () => {
    const options = [...(question.options || []), ""];
    onChange({ options });
  };

  const handleOptionChange = (index: number, value: string) => {
    const options = [...(question.options || [])];
    options[index] = value;
    onChange({ options });
  };

  const handleRemoveOption = (index: number) => {
    const options = [...(question.options || [])];
    options.splice(index, 1);
    onChange({ options });
  };

  // Follow-up management functions (similar to SelectQuestion)
  const addFollowUpQuestion = (option: string) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          question: "",
          required: false
        }
      }
    });
    
    setSelectedOption(option);
  };

  const updateFollowUpQuestion = (option: string, questionText: string) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          ...currentFollowUps[option],
          question: questionText
        }
      }
    });
  };

  const updateFollowUpRequired = (option: string, required: boolean) => {
    const currentFollowUps = question.conditionalFollowUps || {};
    
    onChange({
      conditionalFollowUps: {
        ...currentFollowUps,
        [option]: {
          ...currentFollowUps[option],
          required
        }
      }
    });
  };

  const removeFollowUpQuestion = (option: string) => {
    const currentFollowUps = { ...(question.conditionalFollowUps || {}) };
    delete currentFollowUps[option];
    
    onChange({
      conditionalFollowUps: Object.keys(currentFollowUps).length > 0 ? currentFollowUps : undefined
    });
    
    setSelectedOption(null);
  };

  // Get options with follow-up questions
  const optionsWithFollowUps = Object.keys(question.conditionalFollowUps || {});

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Question Label"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle2" gutterBottom>Checkbox Style</Typography>
        <Select
          value={question.checkboxStyle || "single"}
          onChange={(e) => {
            const newStyle = e.target.value as "single" | "yes-no" | "multi";
            // Initialize options array if switching to multi style
            if (newStyle === "multi" && (!question.options || question.options.length === 0)) {
              onChange({ 
                checkboxStyle: newStyle,
                options: ["Option 1", "Option 2"]
              });
            } else {
              onChange({ checkboxStyle: newStyle });
            }
          }}
          fullWidth
          size="small"
          sx={{ mb: 2 }}
        >
          <MenuItem value="single">Single Checkbox</MenuItem>
          <MenuItem value="yes-no">Yes/No</MenuItem>
          <MenuItem value="multi">Multiple Checkboxes</MenuItem>
        </Select>
        
        {question.checkboxStyle === "single" && (
          <TextField
            fullWidth
            label="Checkbox Label"
            value={question.checkboxLabel || ""}
            onChange={(e) => onChange({ checkboxLabel: e.target.value })}
            sx={{ mb: 2 }}
            placeholder="e.g., I agree to the terms and conditions"
          />
        )}
        
        {question.checkboxStyle === "multi" && (
          <Box mt={2} p={2} bgcolor="#f5f8ff" borderRadius={1}>
            <Typography variant="subtitle2" gutterBottom>
              Checkbox Options
            </Typography>
            
            <List sx={{ width: '100%' }}>
              {(question.options || []).map((option, index) => (
                <ListItem key={index} sx={{ px: 0 }}>
                  <TextField
                    fullWidth
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton 
                            size="small" 
                            onClick={() => handleRemoveOption(index)}
                            disabled={(question.options || []).length <= 1}
                            sx={{ color: 'error.main' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </ListItem>
              ))}
            </List>
            
            <Box mt={1} display="flex" justifyContent="center">
              <IconButton 
                onClick={handleAddOption}
                color="primary"
                size="small"
                sx={{ border: '1px dashed', borderColor: 'primary.main' }}
              >
                <AddIcon />
              </IconButton>
            </Box>

            {/* Conditional Follow-up Questions for Multi-checkbox */}
            <Box mt={3}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle2">Conditional Follow-up Questions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="text.secondary" mb={2}>
                    Add follow-up questions that appear when specific options are selected.
                  </Typography>
                  
                  {/* Option Selection */}
                  <Box mt={2} display="flex" gap={1} flexWrap="wrap">
                    {(question.options || []).map((option, index) => (
                      <Chip
                        key={index}
                        label={option || `Option ${index + 1}`}
                        color={selectedOption === option ? "primary" : 
                              optionsWithFollowUps.includes(option) ? "secondary" : "default"}
                        onClick={() => setSelectedOption(option)}
                        sx={{ 
                          border: selectedOption === option ? '2px solid' : 'none',
                          cursor: 'pointer'
                        }}
                      />
                    ))}
                  </Box>
                  
                  {/* Follow-up Question Editor */}
                  {selectedOption && (
                    <Box mt={2} p={2} bgcolor="#f5f8ff" borderRadius={1}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2">
                          Follow-up for option: <strong>{selectedOption}</strong>
                        </Typography>
                        
                        {!question.conditionalFollowUps?.[selectedOption] ? (
                          <Button 
                            startIcon={<AddIcon />}
                            onClick={() => addFollowUpQuestion(selectedOption)}
                            size="small"
                          >
                            Add Follow-up
                          </Button>
                        ) : (
                          <IconButton 
                            color="error" 
                            size="small"
                            onClick={() => removeFollowUpQuestion(selectedOption)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                      
                      {question.conditionalFollowUps?.[selectedOption] && (
                        <Box mt={2}>
                          <TextField
                            fullWidth
                            label="Follow-up Question"
                            value={question.conditionalFollowUps[selectedOption].question}
                            onChange={(e) => updateFollowUpQuestion(selectedOption, e.target.value)}
                            placeholder="e.g., Please provide more details"
                            size="small"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={question.conditionalFollowUps[selectedOption].required}
                                onChange={(e) => updateFollowUpRequired(selectedOption, e.target.checked)}
                                size="small"
                              />
                            }
                            label="Required"
                            sx={{ mt: 1 }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                  
                  {/* Message when no option is selected */}
                  {!selectedOption && question.options && question.options.length > 0 && (
                    <Typography align="center" color="text.secondary" py={2}>
                      Click on an option chip above to add or edit a follow-up question
                    </Typography>
                  )}
                  
                  {/* List of options with follow-ups */}
                  {optionsWithFollowUps.length > 0 && (
                    <Box mt={3} pt={2} borderTop={1} borderColor="divider">
                      <Typography variant="subtitle2" gutterBottom>
                        Options with follow-up questions:
                      </Typography>
                      
                      <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={2}>
                        {optionsWithFollowUps.map((option) => (
                          <Box 
                            key={option} 
                            p={1.5} 
                            bgcolor="#f0f7ff" 
                            borderRadius={1}
                            border="1px solid #e3f2fd"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={() => setSelectedOption(option)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Box flex={1}>
                              <Typography variant="body2" noWrap>
                                <strong>{option}</strong>
                              </Typography>
                              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                {question.conditionalFollowUps![option].question || "(No question text)"}
                                {question.conditionalFollowUps![option].required && " • Required"}
                              </Typography>
                            </Box>
                            <IconButton 
                              size="small" 
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFollowUpQuestion(option);
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={question.allowOther || false}
                    onChange={(e) => onChange({ allowOther: e.target.checked })}
                    color="primary"
                  />
                }
                label="Include 'Other' option"
              />
            </Box>

            <Box mt={2} pt={2} borderTop="1px dashed rgba(0,0,0,0.1)">
              <FormControl fullWidth size="small">
                <InputLabel id="checkbox-selection-mode-label">Selection Mode</InputLabel>
                <Select
                  labelId="checkbox-selection-mode-label"
                  id="checkbox-selection-mode"
                  value={question.selectionMode || "multiple"}
                  label="Selection Mode"
                  onChange={(e) => onChange({ selectionMode: e.target.value as "multiple" | "single" })}
                >
                  <MenuItem value="multiple">Allow Multiple Selections</MenuItem>
                  <MenuItem value="single">Only One Option (Radio-like)</MenuItem>
                </Select>
                <FormHelperText>
                  {question.selectionMode === "single" 
                    ? "Users can select only one checkbox option" 
                    : "Users can select multiple checkbox options"}
                </FormHelperText>
              </FormControl>
            </Box>
          </Box>
        )}
        
        {question.checkboxStyle === "yes-no" && (
          <Box mt={2}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle2">Conditional Follow-up Questions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" color="text.secondary" mb={2}>
                  Add follow-up questions that appear when Yes or No is selected.
                </Typography>
                
                <Box display="flex" gap={1} mb={2}>
                  <Chip 
                    label="Yes" 
                    color={selectedOption === "Yes" ? "primary" : "default"}
                    onClick={() => setSelectedOption("Yes")}
                    sx={{ 
                      border: selectedOption === "Yes" ? '2px solid' : 'none',
                      cursor: 'pointer'
                    }}
                  />
                  <Chip 
                    label="No" 
                    color={selectedOption === "No" ? "primary" : "default"}
                    onClick={() => setSelectedOption("No")}
                    sx={{ 
                      border: selectedOption === "No" ? '2px solid' : 'none',
                      cursor: 'pointer'
                    }}
                  />
                </Box>
                
                {selectedOption && (
                  <Box mt={2} p={2} bgcolor="#f5f8ff" borderRadius={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="subtitle2">
                        Follow-up for: <strong>{selectedOption}</strong>
                      </Typography>
                      
                      {!question.conditionalFollowUps?.[selectedOption] ? (
                        <Button 
                          startIcon={<AddIcon />}
                          onClick={() => addFollowUpQuestion(selectedOption)}
                          size="small"
                        >
                          Add Follow-up
                        </Button>
                      ) : (
                        <IconButton 
                          color="error" 
                          size="small"
                          onClick={() => removeFollowUpQuestion(selectedOption)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                    
                    {question.conditionalFollowUps?.[selectedOption] && (
                      <Box mt={2}>
                        <TextField
                          fullWidth
                          label="Follow-up Question"
                          value={question.conditionalFollowUps[selectedOption].question}
                          onChange={(e) => updateFollowUpQuestion(selectedOption, e.target.value)}
                          placeholder="e.g., Please provide more details"
                          size="small"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={question.conditionalFollowUps[selectedOption].required}
                              onChange={(e) => updateFollowUpRequired(selectedOption, e.target.checked)}
                              size="small"
                            />
                          }
                          label="Required"
                          sx={{ mt: 1 }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                
                {/* List of options with follow-ups */}
                {optionsWithFollowUps.length > 0 && (
                  <Box mt={3} pt={2} borderTop={1} borderColor="divider">
                    <Typography variant="subtitle2" gutterBottom>
                      Options with follow-up questions:
                    </Typography>
                    
                    {optionsWithFollowUps.map((option) => (
                      <Box 
                        key={option} 
                        p={1.5} 
                        mb={1.5} 
                        bgcolor="#f0f7ff" 
                        borderRadius={1}
                        border="1px solid #e3f2fd"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => setSelectedOption(option)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Box flex={1}>
                          <Typography variant="body2">
                            <strong>{option}</strong>
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {question.conditionalFollowUps![option].question || "(No question text)"}
                            {question.conditionalFollowUps![option].required && " • Required"}
                          </Typography>
                        </Box>
                        <IconButton 
                          size="small" 
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFollowUpQuestion(option);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
            
            {/* Comment Section Option */}
            <Box mt={2}>
              <Paper variant="outlined" sx={{ p: 2, borderRadius: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={question.allowComment || false}
                      onChange={(e) => onChange({ allowComment: e.target.checked })}
                      color="primary"
                    />
                  }
                  label="Include general comment field"
                />
                
                {question.allowComment && (
                  <Box mt={1}>
                    <TextField
                      fullWidth
                      label="Comment Field Label"
                      size="small"
                      value={question.commentLabel || ""}
                      onChange={(e) => onChange({ commentLabel: e.target.value })}
                      placeholder="e.g., Additional comments"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={question.commentRequired || false}
                          onChange={(e) => onChange({ commentRequired: e.target.checked })}
                          size="small"
                        />
                      }
                      label="Comment required"
                    />
                  </Box>
                )}
              </Paper>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CheckboxQuestion;