import React from 'react';
import { TextField, Box, Typography } from '@mui/material';
import Question from './Question';

interface CheckboxQuestionProps {
  question: any;
  value: string | boolean | string[];
  onChange: (value: string | boolean | string[]) => void;
  onFollowUpChange?: (fieldId: string, value: string) => void;
  followUpValues?: Record<string, string>;
}

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({ 
  question, 
  value, 
  onChange,
  onFollowUpChange,
  followUpValues = {}
}) => {
  // Helper function for multi-checkbox handling
  const handleMultiCheckboxChange = (option: string) => {
    const currentValue = Array.isArray(value) ? value : [];
    
    if (currentValue.includes(option)) {
      // Remove option if already selected
      onChange(currentValue.filter(item => item !== option));
    } else {
      // Add option if not selected
      onChange([...currentValue, option]);
    }
  };

  // Handle "Other" option input change
  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Array.isArray(value) ? value : [];
    const otherValue = e.target.value;
    
    // Filter out any previous "other:" entries
    const filteredValues = currentValue.filter(item => !item.startsWith('other:'));
    
    // Only add the other value if it's not empty
    if (otherValue) {
      onChange([...filteredValues, `other:${otherValue}`]);
    } else {
      onChange(filteredValues);
    }
  };

  // Extract "other" value if present
  const getOtherValue = () => {
    if (!Array.isArray(value)) return '';
    
    const otherEntry = value.find(item => item.startsWith('other:'));
    return otherEntry ? otherEntry.substring(6) : '';
  };

  // Check if "Other" is selected
  const isOtherSelected = () => {
    return Array.isArray(value) && value.some(item => item.startsWith('other:'));
  };

  // Handle change for single and multi-selection modes
  const handleChange = (option: string) => {
    // Get the current value as an array regardless of the original type
    const currentValue = Array.isArray(value) ? value : [];
    
    // If selectionMode is "single", replace the entire selection
    if (question.selectionMode === "single") {
      // If the same option is clicked again, deselect it (toggle behavior)
      if (currentValue.includes(option)) {
        onChange([]);
      } else {
        // Select just this option
        onChange([option]);
      }
    } else {
      // Normal multi-selection mode
      if (currentValue.includes(option)) {
        // Remove the option if already selected
        onChange(currentValue.filter(item => item !== option));
      } else {
        // Add the option
        onChange([...currentValue, option]);
      }
    }
  };

  return (
    <Question question={question}>
      <div>
        {question.checkboxStyle === "multi" ? (
          // Multi-checkbox style
          <div className="space-y-2">
            {(question.options || []).map((option: string, index: number) => (
              <div key={index} className="flex items-center">
                <input 
                  type="checkbox"
                  id={`${question.id}-option-${index}`}
                  checked={Array.isArray(value) && value.includes(option)}
                  onChange={() => handleChange(option)}
                  className="w-5 h-5 mr-2"
                />
                <label 
                  htmlFor={`${question.id}-option-${index}`} 
                  className="cursor-pointer"
                >
                  {option}
                </label>
              </div>
            ))}

            {/* "Other" option if enabled */}
            {question.allowOther && (
              <div className="mt-2">
                <div className="flex items-center">
                  <input 
                    type="checkbox"
                    id={`${question.id}-option-other`}
                    checked={isOtherSelected()}
                    onChange={() => {
                      if (isOtherSelected()) {
                        // Remove other option
                        onChange(Array.isArray(value) ? 
                          value.filter(item => !item.startsWith('other:')) : 
                          []);
                      } else {
                        // Add empty other option
                        onChange([...(Array.isArray(value) ? value : []), 'other:']);
                      }
                    }}
                    className="w-5 h-5 mr-2"
                  />
                  <label 
                    htmlFor={`${question.id}-option-other`} 
                    className="cursor-pointer"
                  >
                    Other
                  </label>
                </div>
                
                {isOtherSelected() && (
                  <div className="ml-7 mt-1">
                    <TextField 
                      size="small"
                      fullWidth
                      placeholder="Please specify"
                      value={getOtherValue()}
                      onChange={handleOtherInputChange}
                    />
                  </div>
                )}
              </div>
            )}

            {/* Conditional follow-ups for multi-checkbox options */}
            {question.checkboxStyle === "multi" && 
             question.conditionalFollowUps && 
             Array.isArray(value) && (
              <div className="mt-4 space-y-3">
                {Object.keys(question.conditionalFollowUps)
                  .filter(option => Array.isArray(value) && value.includes(option))
                  .map(option => (
                    <div key={`followup-${option}`} className="pl-4 border-l-4 border-blue-300 py-2">
                      <p className="text-sm font-medium mb-2">
                        <strong>{option}:</strong> {question.conditionalFollowUps[option].question}
                        {question.conditionalFollowUps[option].required && <span className="text-red-500 ml-1">*</span>}
                      </p>
                      <TextField 
                        fullWidth
                        size="small"
                        multiline
                        rows={2}
                        value={followUpValues[`${question.id}_${option}_followup`] || ""}
                        onChange={(e) => onFollowUpChange && 
                          onFollowUpChange(`${question.id}_${option}_followup`, e.target.value)}
                        required={question.conditionalFollowUps[option].required}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : question.checkboxStyle === "yes-no" ? (
          // Yes/No style checkboxes
          <div className="space-y-4">
            <div className="flex space-x-8">
              <div className="flex items-center">
                <input 
                  type="radio"
                  id={`${question.id}-yes`}
                  name={question.id}
                  value="yes"
                  checked={value === "yes"}
                  onChange={() => onChange("yes")}
                  required={question.required}
                  className="w-5 h-5 mr-2"
                />
                <label htmlFor={`${question.id}-yes`} className="cursor-pointer">Yes</label>
              </div>
              
              <div className="flex items-center">
                <input 
                  type="radio"
                  id={`${question.id}-no`}
                  name={question.id}
                  value="no"
                  checked={value === "no"}
                  onChange={() => onChange("no")}
                  required={question.required}
                  className="w-5 h-5 mr-2"
                />
                <label htmlFor={`${question.id}-no`} className="cursor-pointer">No</label>
              </div>
            </div>
            
            {/* NEW: Handle conditional follow-ups using conditionalFollowUps */}
            {value && question.conditionalFollowUps && question.conditionalFollowUps[value as string] && (
              <div className="mt-3 pl-4 border-l-4 border-gray-300">
                <p className="text-sm font-medium mb-2">{question.conditionalFollowUps[value as string].question}</p>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}_followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(`${question.id}_followup`, e.target.value)}
                  required={question.conditionalFollowUps[value as string].required}
                />
              </div>
            )}
            
            {/* Legacy support for old format */}
            {value === "no" && !question.conditionalFollowUps && question.noFollowUp && (
              <div className="mt-3 pl-4 border-l-4 border-gray-300">
                <p className="text-sm font-medium mb-2">{question.noFollowUp.question}</p>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}-followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(`${question.id}-followup`, e.target.value)}
                  required={question.noFollowUp?.required}
                />
              </div>
            )}
            
            {/* Legacy support for very old format */}
            {value === (question.followUpTrigger || "no") && !question.conditionalFollowUps && !question.noFollowUp && question.followUp && (
              <div className="mt-3 pl-4 border-l-4 border-gray-300">
                <p className="text-sm font-medium mb-2">{question.followUp.question}</p>
                <TextField 
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={followUpValues[`${question.id}-followup`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(`${question.id}-followup`, e.target.value)}
                  required={question.followUp?.required}
                />
              </div>
            )}
            
            {/* Add comment field if allowed */}
            {question.allowComment && (
              <div className="mt-4 pt-3 border-t border-gray-200">
                <p className="text-sm font-medium mb-2">
                  {question.commentLabel || "Additional Comments"}
                  {question.commentRequired && <span className="text-red-500 ml-1">*</span>}
                </p>
                <TextField 
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  placeholder="Enter your comments here..."
                  value={followUpValues[`${question.id}_comment`] || ""}
                  onChange={(e) => onFollowUpChange && onFollowUpChange(`${question.id}_comment`, e.target.value)}
                  required={question.commentRequired}
                />
              </div>
            )}
          </div>
        ) : (
          // Single checkbox style
          <div className="flex items-center">
            <input 
              type="checkbox"
              checked={Boolean(value)}
              onChange={(e) => onChange(e.target.checked)}
              required={question.required}
              className="w-5 h-5 mr-2"
              id={`checkbox-${question.id}`}
            />
            <label 
              htmlFor={`checkbox-${question.id}`} 
              className="cursor-pointer"
            >
              {question.checkboxLabel || "Yes"}
            </label>
          </div>
        )}
      </div>
    </Question>
  );
};

export default CheckboxQuestion;