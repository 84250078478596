import React from 'react';
import { TextField } from '@mui/material';
import Question from './Question';

interface NumberQuestionProps {
  question: any;
  value: string;
  onChange: (value: string) => void;
}

const NumberQuestion: React.FC<NumberQuestionProps> = ({ 
  question, 
  value, 
  onChange 
}) => {
  return (
    <Question question={question}>
      <TextField 
        fullWidth 
        type="number"
        value={value} 
        onChange={(e) => onChange(e.target.value)} 
        required={question.required}
      />
    </Question>
  );
};

export default NumberQuestion;