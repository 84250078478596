import { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export const useSignatures = () => {
  const sigCanvasRefs = useRef<{[key: string]: SignatureCanvas | null}>({});
  const [signatures, setSignatures] = useState<{[key: string]: string}>({});
  
  const handleSignatureEnd = (questionId: string, signatureData: string) => {
    const canvas = sigCanvasRefs.current[questionId];
    if (canvas && !canvas.isEmpty()) {
      try {
        // Explicitly use PNG format with quality parameters
        const signatureData = canvas.toDataURL("image/png", 1.0);
        
        console.log(`✅ Signature captured for ${questionId}: ${signatureData.substring(0, 30)}...`);
        console.log(`Canvas dimensions: ${canvas.getCanvas().width}x${canvas.getCanvas().height}`);
        
        setSignatures(prev => ({
          ...prev,
          [questionId]: signatureData
        }));
        return signatureData;
      } catch (err) {
        console.error(`Error capturing signature for ${questionId}:`, err);
      }
    } else {
      console.log(`⚠️ No signature captured for ${questionId} - canvas is empty or not found`);
    }
    return null;
  };
  
  const clearSignature = (questionId: string) => {
    const canvas = sigCanvasRefs.current[questionId];
    if (canvas) {
      canvas.clear();
      setSignatures(prev => {
        const updated = {...prev};
        delete updated[questionId];
        return updated;
      });
    }
  };
  
  return {
    sigCanvasRefs,
    signatures,
    handleSignatureEnd,
    clearSignature
  };
};