import React, { useMemo } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Bar, Line } from 'react-chartjs-2';
import { calculateStats, formatNumber } from '../Chartutils';

interface NumberVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

const NumberVisualization: React.FC<NumberVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  
  // Extract numeric values safely
  const numericValues = useMemo(() => {
    if (summaryData?.numericResponses && Array.isArray(summaryData.numericResponses)) {
      return summaryData.numericResponses;
    }
    
    return filteredSubmissions
      .map(sub => {
        const val = sub?.responses?.[question.id];
        if (val === undefined || val === null) return null;
        
        const numVal = typeof val === 'number' ? val : Number(val);
        return isNaN(numVal) ? null : numVal;
      })
      .filter(val => val !== null) as number[];
  }, [summaryData, filteredSubmissions, question.id]);
  
  if (numericValues.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No numeric data available for this question
      </Typography>
    );
  }
  
  // Calculate statistics
  const stats = calculateStats(numericValues);
  
  // Generate histogram data
  const generateHistogramData = () => {
    const min = Math.floor(stats.min);
    const max = Math.ceil(stats.max);
    const range = max - min;
    
    // Determine bin size based on range
    let binSize = 1;
    let binCount = range;
    
    if (range > 20) {
      binCount = 10;
      binSize = range / binCount;
    }
    
    // Create bins
    const bins: number[] = Array(binCount).fill(0);
    const binLabels: string[] = [];
    
    // Create labels
    for (let i = 0; i < binCount; i++) {
      const start = min + (i * binSize);
      const end = min + ((i + 1) * binSize);
      binLabels.push(`${formatNumber(start, 1)}-${formatNumber(end, 1)}`);
    }
    
    // Fill bins
    numericValues.forEach((value: number) => {
      const binIndex = Math.min(
        Math.floor((value - min) / binSize),
        binCount - 1
      );
      bins[binIndex]++;
    });
    
    return {
      labels: binLabels,
      datasets: [{
        label: 'Count',
        data: bins,
        backgroundColor: theme.palette.primary.main + '80',
        borderColor: theme.palette.primary.main,
        borderWidth: 1
      }]
    };
  };
  
  const histogramData = generateHistogramData();
  
  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Numeric Response Analysis
      </Typography>
      
      {/* Stats summary cards */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6} sm={3}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Average
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {formatNumber(stats.avg)}
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={3}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Median
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {formatNumber(stats.median)}
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={3}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Minimum
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {formatNumber(stats.min)}
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={3}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Maximum
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {formatNumber(stats.max)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Histogram */}
      <Box sx={{ height: 250, mt: 2 }}>
        <Typography variant="caption" color="text.secondary" gutterBottom>
          Value Distribution
        </Typography>
        <Bar 
          data={histogramData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  title: function(tooltipItems) {
                    return tooltipItems[0].label;
                  },
                  label: function(context) {
                    return `Count: ${context.raw}`;
                  }
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Count'
                },
                ticks: {
                  precision: 0
                }
              },
              x: {
                title: {
                  display: true,
                  text: 'Value Range'
                }
              }
            }
          }}
        />
      </Box>
      
      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, textAlign: 'center' }}>
        Based on {numericValues.length} numeric responses
      </Typography>
    </Box>
  );
};

export default NumberVisualization;