import React, { useState } from "react";
import { 
  Box, 
  TextField,
  Typography,
  Select,
  MenuItem,
  Paper,
  Slider,
  FormControlLabel,
  Switch
} from "@mui/material";
import { Info, Warning, Lightbulb, NoteAlt } from "@mui/icons-material";
import { Question } from "../../../store/store";

interface InstructionsQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const InstructionsQuestion: React.FC<InstructionsQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  // State to track the number of visible rows (default: 5)
  const [visibleRows, setVisibleRows] = useState(question.instructionRows || 5);
  
  // Style map for instruction types
  const styleMap = {
    standard: { 
      icon: <NoteAlt />, 
      color: "#2196f3", 
      bgColor: "#e3f2fd" 
    },
    note: { 
      icon: <Info />, 
      color: "#673ab7", 
      bgColor: "#ede7f6" 
    },
    warning: { 
      icon: <Warning />, 
      color: "#ff9800", 
      bgColor: "#fff3e0" 
    },
    tip: { 
      icon: <Lightbulb />, 
      color: "#4caf50", 
      bgColor: "#e8f5e9" 
    }
  };
  
  const currentStyle = question.instructionStyle || "standard";
  const styleInfo = styleMap[currentStyle as keyof typeof styleMap];

  // Handle changing the number of visible rows
  const handleRowsChange = (event: Event, newValue: number | number[]) => {
    const rows = newValue as number;
    setVisibleRows(rows);
    onChange({ instructionRows: rows });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Instruction Title"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle2" gutterBottom>Instruction Style</Typography>
        <Select
          value={currentStyle}
          onChange={(e) => onChange({ instructionStyle: e.target.value as "standard" | "warning" | "note" | "tip" })}
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value="standard">Standard</MenuItem>
          <MenuItem value="note">Note</MenuItem>
          <MenuItem value="warning">Warning</MenuItem>
          <MenuItem value="tip">Tip</MenuItem>
        </Select>
        
        <Box mb={3}>
          <TextField
            fullWidth
            multiline
            rows={visibleRows}
            label="Instruction Text"
            placeholder="Enter detailed instructions or information here. Press Enter for line breaks."
            value={question.instructionText || ""}
            onChange={(e) => onChange({ instructionText: e.target.value })}
            helperText="Press Enter for line breaks. They will be preserved in the form."
            sx={{
              '& .MuiInputBase-root': {
                minHeight: `${Math.max(100, visibleRows * 24)}px`,
              }
            }}
          />
          
          <Box mt={1} mx={1}>
            <Typography variant="caption" color="text.secondary" gutterBottom>
              Input field size
            </Typography>
            <Slider
              value={visibleRows}
              onChange={handleRowsChange}
              step={1}
              marks
              min={2}
              max={20}
              valueLabelDisplay="auto"
              aria-label="Editor rows"
            />
          </Box>
        </Box>
        
        <FormControlLabel
          control={
            <Switch
              checked={question.fullWidth || false}
              onChange={(e) => onChange({ fullWidth: e.target.checked })}
              color="primary"
            />
          }
          label="Display as full-width section"
          sx={{ mb: 2 }}
        />
        
        {/* Preview */}
        <Typography variant="caption" color="textSecondary">Preview:</Typography>
        <Paper 
          elevation={0} 
          sx={{ 
            mt: 1, 
            p: 2, 
            bgcolor: styleInfo.bgColor,
            borderLeft: `4px solid ${styleInfo.color}`,
            borderRadius: 1,
            maxHeight: '400px',
            overflow: 'auto'
          }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <Box sx={{ color: styleInfo.color, mr: 1 }}>
              {styleInfo.icon}
            </Box>
            <Typography variant="subtitle1" fontWeight="medium">
              {question.label || "Instruction Title"}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {question.instructionText || "Instruction text will appear here"}
          </Typography>
        </Paper>
      </Box>
    </>
  );
};

export default InstructionsQuestion;