import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Container, Typography, Paper, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Divider, TextField, Alert, Chip, Tooltip,
  IconButton, useMediaQuery, ThemeProvider, MenuItem, Select, FormControl, InputLabel, OutlinedInput
} from '@mui/material';
import {
  CheckCircle, Cancel, ArrowBack, Done, Close,
  Event, Today, DateRange, Visibility, FilterList
} from '@mui/icons-material';
import { format, parseISO, differenceInDays, addDays } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { EventInput, DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { collection, query, where, getDocs, doc, getDoc, writeBatch, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import SharedNavBar from '../components/SharedNavBar';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { createAppTheme } from '../theme/themeOptions';
import { sendApprovalNotification } from '../utils/notificationHelpers';

interface FormQuestion {
  id: string;
  type: string;          // instead of questionType
  label: string;         // instead of questionText
  fieldName?: string;    
  required?: boolean;
}

interface DateEvent {
  id: string; // Added id property
  submissionId: string;
  email: string;
  formId: string;
  formTitle: string;
  fieldName: string;
  startDate: Date;
  endDate: Date;
  questionText: string;
  status: 'pending' | 'approved' | 'rejected';
  comments?: string;
  submittedAt: Date;
  title: string; // Added title property
  description?: string; // Added description property
}

const ApprovalCalendarView: React.FC = () => {
  const { formId } = useParams<{ formId: string }>();
  const { user } = useUser();
  const navigate = useNavigate();
  
  // Theme setup
  const { theme: themePreference } = useUserPreferences();
  const theme = createAppTheme(themePreference || 'Blue');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State variables
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<any>(null);
  const [dateQuestions, setDateQuestions] = useState<FormQuestion[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [dateEvents, setDateEvents] = useState<DateEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<DateEvent | null>(null);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [processingAction, setProcessingAction] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);
  const [actionSuccess, setActionSuccess] = useState<string | null>(null);
  
  // Add these state variables after your existing state declarations
  const [uniqueEmails, setUniqueEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>('all');
  const [filteredEvents, setFilteredEvents] = useState<EventInput[]>([]);
  
  // Fix the processTimestamp function around line 85-115
  const processTimestamp = (value: any): Date | null => {
    if (!value) return null;
    
    try {
      // Case 1: Already a JavaScript Date object
      if (value instanceof Date) {
        return value;
      }
      
      // Case 2: Firestore Timestamp object with toDate method
      if (typeof value.toDate === 'function') {
        return value.toDate();
      }
      
      // Case 3: Serialized timestamp with seconds
      if (typeof value === 'object' && value !== null && value.seconds !== undefined) {
        return new Date(value.seconds * 1000);
      }
      
      // Case 4: ISO string or other parseable string
      if (typeof value === 'string') {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          return date;
        }
      }
      
      // Case 5: Numeric timestamp in milliseconds
      if (typeof value === 'number') {
        return new Date(value);
      }
      
      console.warn("Unrecognized date format:", value);
      return null;
    } catch (err) {
      console.error("Error processing timestamp:", err);
      return null;
    }
  };
  
  // Load form data and submissions with date fields
  useEffect(() => {
    if (user && formId) {
      loadFormAndDateSubmissions();
    }
  }, [user, formId]);
  
  // Method to load form and submissions with date fields
  const loadFormAndDateSubmissions = async () => {
    try {
      setLoading(true);
      
      // 1. Fetch the form details
      const formRef = doc(db, "forms", formId || '');
      const formSnap = await getDoc(formRef);
      
      if (!formSnap.exists()) {
        setActionError("Form not found.");
        setLoading(false);
        return;
      }
      
      const formData = formSnap.data();
      setForm(formData);
      
      // 2. Identify date/daterange questions in the form
      const questions = formData.questions || [];
      const dateFieldQuestions = questions.filter(
        (q: any) => q.type === 'date' || q.type === 'dateTime' || q.type === 'dateRange'
      );

      interface DateQuestionSummary {
        id: string;
        type: string;
        label: string;
      }

      console.log("All date questions found:", dateFieldQuestions.map((q: FormQuestion): DateQuestionSummary => ({
        id: q.id,
        type: q.type,
        label: q.label || 'Unnamed Question'
      })));

      // Get the IDs of all date-related questions
      const dateFieldIds = dateFieldQuestions.map((q: any) => q.id);
      console.log("Date field IDs:", dateFieldIds);

      // IMPORTANT: Add this specific question ID that you know exists
      if (!dateFieldIds.includes('d0712c47-ab99-4cbc-87af-bcbed0033968')) {
        console.log("Adding missing date range question ID manually");
        dateFieldIds.push('d0712c47-ab99-4cbc-87af-bcbed0033968');
      }
      
      setDateQuestions(dateFieldQuestions);
      
      // After setting dateFieldQuestions (around line 151):
      console.log("Date questions found:", dateFieldQuestions.map((q: FormQuestion) => ({ id: q.id, type: q.type, label: q.label })));
      
      if (dateFieldQuestions.length === 0) {
        setActionError("This form does not contain any date questions.");
        setLoading(false);
        return;
      }
      
      // 3. Get the field IDs for these date questions - use id instead of fieldName
      
      // 4. Fetch submissions that require approval
      const submissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "==", formId),
        where("approvalRequired", "==", true),
        where("archived", "==", false) // Add this condition
      );

      console.log("Fetching submissions with query:", submissionsQuery);
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      
      // Add this right after getting the submissionsSnapshot:
      console.log(`Found ${submissionsSnapshot.docs.length} submissions that require approval`);

      // Debug each submission's metadata
      submissionsSnapshot.docs.forEach((doc, index) => {
        const data = doc.data();
        console.log(`Submission ${index + 1} (ID: ${doc.id}):`, {
          formId: data.formId,
          email: data.email,
          status: data.approvalStatus,
          submittedAt: data.submittedAt ? 
            (typeof data.submittedAt.toDate === 'function' ? 
              data.submittedAt.toDate().toString() : 'Not a timestamp') : 'Missing'
        });
      });
      
      if (submissionsSnapshot.empty) {
        setLoading(false);
        return;
      }
      
      // 5. For each submission, get detailed data to access date responses
      const eventsData: DateEvent[] = [];
      
      // Before adding any events, create a Map to track already added events by ID:
      const addedEventIds = new Map();

      // Let's also check the first few submissions response fields:
      let responseFieldsFound = new Set();
      let firstFewSubmissions = [];

      // Add this after getting all submission details:
      if (submissionsSnapshot.docs.length > 0) {
        // Get first 3 submissions or all if less than 3
        const maxToCheck = Math.min(submissionsSnapshot.docs.length, 3);
        for (let i = 0; i < maxToCheck; i++) {
          const subDoc = submissionsSnapshot.docs[i];
          const detailsRef = doc(db, "submission_details", subDoc.id);
          const detailsSnap = await getDoc(detailsRef);
          
          if (detailsSnap.exists() && detailsSnap.data().responses) {
            const responses = detailsSnap.data().responses;
            
            // Record all response fields
            Object.keys(responses).forEach(field => responseFieldsFound.add(field));
            
            // Store basic info about response
            firstFewSubmissions.push({
              id: subDoc.id,
              email: subDoc.data().email,
              responseFields: Object.keys(responses)
            });
          }
        }
        
        console.log("Response fields found in submissions:", Array.from(responseFieldsFound));
        console.log("First few submissions:", firstFewSubmissions);
        
        // Check for potential field ID mismatches
        console.log("Potential field ID mismatches:");
        dateFieldIds.forEach((fieldId: string) => {
          if (!responseFieldsFound.has(fieldId)) {
            console.log(`Field ${fieldId} from form questions not found in any responses`);
            
            // Try to find similar fields
            const similarFields: string[] = Array.from(responseFieldsFound).filter(
              (field: unknown): field is string => typeof field === 'string' && 
          (field.includes(fieldId) || fieldId.includes(field))
            );
            
            if (similarFields.length > 0) {
              console.log(`Possible matches for ${fieldId}:`, similarFields);
            }
          }
        });
      }

      // Add this before the eventsData processing loop:

      // Create a map to help match question IDs to response fields
      const fieldMapping = new Map();

      // First try exact matches
      dateFieldIds.forEach((fieldId: string): void => {
        fieldMapping.set(fieldId, fieldId);
      });

      // Then look for similar field names if needed
      if (responseFieldsFound.size > 0 && !dateFieldIds.some((id: unknown) => responseFieldsFound.has(id))) {
        console.warn("No exact matches found between question IDs and response fields, trying fuzzy matching");
        
        // Try to match by label
        dateFieldQuestions.forEach((question: FormQuestion) => {
          // Convert label to lowercase and remove spaces for comparison
          const normalizedLabel: string = question.label.toLowerCase().replace(/\s+/g, '');
          
          // Look for fields that might match this label
          const potentialMatches: string[] = Array.from(responseFieldsFound).filter((field: unknown): field is string => {
        if (typeof field !== 'string') return false;
        const fieldLower: string = field.toLowerCase();
        return fieldLower.includes(normalizedLabel) || 
          normalizedLabel.includes(fieldLower) ||
          (field.toLowerCase().includes('date') && question.type.toLowerCase().includes('date'));
          });
          
          if (potentialMatches.length === 1) {
        console.log(`Found likely match: question ID ${question.id} -> field ${potentialMatches[0]}`);
        fieldMapping.set(question.id, potentialMatches[0]);
          } else if (potentialMatches.length > 1) {
        console.log(`Multiple potential matches for question ${question.id}:`, potentialMatches);
        // Just use the first one in this case
        fieldMapping.set(question.id, potentialMatches[0]);
          }
        });
      }

      console.log("Field ID mapping:", Object.fromEntries(fieldMapping));

      await Promise.all(submissionsSnapshot.docs.map(async (submissionDoc) => {
        const submissionData = submissionDoc.data();
        
        // Get submission details with responses
        const detailsRef = doc(db, "submission_details", submissionDoc.id);
        const detailsSnap = await getDoc(detailsRef);
        
        if (!detailsSnap.exists() || !detailsSnap.data().responses) return;
        
        const detailsData = detailsSnap.data();
        const responses = detailsData.responses || {};
        
        // Add this log
        console.log(`Submission ${submissionDoc.id} details:`, detailsData);
        console.log(`Submission ${submissionDoc.id} responses:`, responses);

        // Check for available dateFieldIds
        console.log(`Looking for date fields: ${dateFieldIds.join(', ')}`);

        // Modify your for loop around line 242:
        for (const fieldId of dateFieldIds) {
          // Get the mapped response field name (may be the same as fieldId)
          const responseFieldName = fieldMapping.get(fieldId) || fieldId;
          
          console.log(`Checking for field ${fieldId} (mapped to ${responseFieldName}) in responses`);
          
          if (responses[responseFieldName]) {
            const questionData = dateFieldQuestions.find((q: any) => q.id === fieldId);
            
            if (questionData) {
              console.log(`Found question data for ${fieldId}:`, questionData);
              let startDate, endDate;
              
              // Handle different date question types
              if (questionData.type === 'date' || questionData.type === 'dateTime') {
                // For single dates, use the same date for start and end
                try {
                  // Check if the response is already a Date object
                  if (responses[responseFieldName] instanceof Date) {
                    startDate = responses[responseFieldName];
                    endDate = responses[responseFieldName];
                  } else if (typeof responses[responseFieldName] === 'string') {
                    startDate = new Date(responses[responseFieldName]);
                    endDate = new Date(responses[responseFieldName]);
                  } else if (responses[responseFieldName].seconds) {
                    // Handle Firestore timestamp
                    startDate = new Date(responses[responseFieldName].seconds * 1000);
                    endDate = new Date(responses[responseFieldName].seconds * 1000);
                  } else {
                    console.error(`Unsupported date format for ${fieldId}:`, responses[responseFieldName]);
                    continue;
                  }
                } catch (e) {
                  console.error(`Invalid date format for ${fieldId}:`, responses[responseFieldName], e);
                  continue;
                }

                const eventId = `${submissionDoc.id}-${fieldId}`;
                if (addedEventIds.has(eventId)) {
                  console.log(`Skipping duplicate event ${eventId}`);
                  continue;
                }

                // For regular date fields, ensure id and title are set
                eventsData.push({
                  id: eventId,  // IMPORTANT: Add this
                  title: `${submissionData.email}: ${questionData.label || 'Date'}`,  // IMPORTANT: Add this
                  description: `${questionData.label || 'Date'}: ${format(startDate, 'MMM d, yyyy')}`,
                  startDate: startDate,
                  endDate: endDate, // For single dates, use same date for start/end
                  status: submissionData.approvalStatus || 'pending',
                  email: submissionData.email || 'Anonymous',
                  submissionId: submissionDoc.id,
                  formTitle: formData.formTitle || 'Untitled Form',
                  fieldName: fieldId,
                  questionText: questionData.label || 'Date',
                  formId: formId || '',
                  submittedAt: submissionData.submittedAt?.toDate(),
                  comments: submissionData.approvalComments
                });
                addedEventIds.set(eventId, true);
                console.log("✅ Added date event:", {
                    id: eventId,  // IMPORTANT: Add this
                    title: `${submissionData.email}: ${questionData.label || 'Date'}`,  // IMPORTANT: Add this
                    startDate,
                    endDate
                  });
              } else if (questionData.type === 'dateRange') {
                // For date ranges, get both start and end dates
                try {
                  console.log(`Processing dateRange for ${fieldId}:`, responses[responseFieldName]);
                  
                  let startDate = null;
                  let endDate = null;
                  
                  const processTimestamp = (value: any): Date | null => {
                    if (!value) return null;
                    
                    try {
                      // Case 1: Firestore Timestamp object with toDate method
                      if (typeof value.toDate === 'function') {
                        return value.toDate();
                      }
                      // Case 2: Serialized timestamp with seconds
                      else if (typeof value === 'object' && value !== null && value.seconds !== undefined) {
                        return new Date(value.seconds * 1000);
                      }
                      // Case 3: Date object
                      else if (value instanceof Date) {
                        return value;
                      }
                      // Case 4: ISO string or other parseable string
                      else if (typeof value === 'string') {
                        const date = new Date(value);
                        return !isNaN(date.getTime()) ? date : null;
                      }
                      // Case 5: Numeric timestamp in milliseconds
                      else if (typeof value === 'number') {
                        return new Date(value);
                      }
                      return null;
                    } catch (err) {
                      console.error("Error processing timestamp:", err);
                      return null;
                    }
                  };
                  
                  // Handle different structure types for dateRange
                  if (typeof responses[responseFieldName] === 'object' && responses[responseFieldName] !== null) {
                    // Structure 1: {startDate: timestamp, endDate: timestamp}
                    if (responses[responseFieldName].startDate && responses[responseFieldName].endDate) {
                      startDate = processTimestamp(responses[responseFieldName].startDate);
                      endDate = processTimestamp(responses[responseFieldName].endDate);
                      console.log("Found startDate/endDate format:", startDate, endDate);
                    }
                    // Structure 2: {start: timestamp, end: timestamp}
                    else if (responses[responseFieldName].start && responses[responseFieldName].end) {
                      startDate = processTimestamp(responses[responseFieldName].start);
                      endDate = processTimestamp(responses[responseFieldName].end);
                      console.log("Found start/end format:", startDate, endDate);
                    }
                    // Structure 3: Array format [startDate, endDate]
                    else if (Array.isArray(responses[responseFieldName]) && responses[responseFieldName].length >= 2) {
                      startDate = processTimestamp(responses[responseFieldName][0]);
                      endDate = processTimestamp(responses[responseFieldName][1]);
                      console.log("Found array format:", startDate, endDate);
                    }
                    // Structure 4: Nested fields (sometimes happens with form libraries)
                    else {
                      // Look for nested date fields
                      const keys = Object.keys(responses[responseFieldName]);
                      console.log("Complex object with keys:", keys);
                      
                      // Try to find start date field
                      const startKey = keys.find(k => 
                        k.toLowerCase().includes('start') || 
                        k.toLowerCase() === 'from' || 
                        k.toLowerCase() === 'begin'
                      );
                      
                      // Try to find end date field
                      const endKey = keys.find(k => 
                        k.toLowerCase().includes('end') || 
                        k.toLowerCase() === 'to' || 
                        k.toLowerCase() === 'finish'
                      );
                      
                      if (startKey && responses[responseFieldName][startKey]) {
                        startDate = processTimestamp(responses[responseFieldName][startKey]);
                        console.log("Found start date in field:", startKey, startDate);
                      }
                      
                      if (endKey && responses[responseFieldName][endKey]) {
                        endDate = processTimestamp(responses[responseFieldName][endKey]);
                        console.log("Found end date in field:", endKey, endDate);
                      }
                      
                      // If we still don't have dates, try to dump the structure
                      if (!startDate || !endDate) {
                        console.log("Full structure dump:", JSON.stringify(responses[responseFieldName]));
                      }
                    }
                  } else {
                    console.error(`Invalid date range format for ${fieldId}:`, responses[responseFieldName]);
                    continue;
                  }
                  
                  // Verify we have valid dates
                  if (!startDate || !endDate) {
                    console.error(`Missing start or end date for ${fieldId}`);
                    continue;
                  }
                  
                  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                    console.error(`Invalid date values for ${fieldId}:`, startDate, endDate);
                    continue;
                  }
                  
                  console.log(`Successfully processed date range for ${fieldId}:`, startDate, "to", endDate);
                  
                  const eventId = `${submissionDoc.id}-${fieldId}`;
                  if (addedEventIds.has(eventId)) {
                    console.log(`Skipping duplicate event ${eventId}`);
                    continue;
                  }

                  // Now we have valid start and end dates, create the event...
                  const eventTitle = `${submissionData.email}: ${questionData.label || 'Date Range'}`;

                  eventsData.push({
                    id: eventId,
                    title: eventTitle,
                    description: `${questionData.label || 'Date Range'} from ${format(startDate, 'MMM d, yyyy')} to ${format(endDate, 'MMM d, yyyy')}`,
                    startDate: startDate,
                    endDate: endDate,
                    status: submissionData.approvalStatus || 'pending',
                    email: submissionData.email || 'Anonymous',
                    submissionId: submissionDoc.id,
                    formTitle: formData.formTitle || 'Untitled Form',
                    fieldName: fieldId,
                    questionText: questionData.label || 'Date Range',
                    formId: formId || '',
                    submittedAt: submissionData.submittedAt?.toDate(),
                    comments: submissionData.approvalComments
                  });
                  addedEventIds.set(eventId, true);

                  console.log(`Added dateRange event: ${eventId}`, {
                    title: eventTitle,
                    startDate: startDate.toString(),
                    endDate: endDate.toString()
                  });
                } catch (e) {
                  console.error(`Error processing date range for ${fieldId}:`, e);
                  continue;
                }
              }
            }
          }
        }

        // After you've retrieved the submission details and responses, add this (around line 310):

        // Check if there are any date-related fields in the responses
        const responseDateFields = Object.keys(responses).filter(key => {
          const value = responses[key];
          
          // Check if it's a date value (either a Date object, a timestamp, or has date-related properties)
          if (value instanceof Date) return true;
          
          if (typeof value === 'object' && value !== null) {
            // Check if it's a timestamp
            if (value.seconds !== undefined) return true;
            
            // Check if it has toDate method (Firestore timestamp)
            if (typeof value.toDate === 'function') return true;
            
            // Check if it looks like a date range (has start/end or startDate/endDate)
            if ((value.start && value.end) || (value.startDate && value.endDate)) return true;
          }
          
          // Check if the field name contains 'date'
          if (key.toLowerCase().includes('date')) return true;
          
          return false;
        });

        console.log(`Found ${responseDateFields.length} potential date fields in responses:`, responseDateFields);

        // Try to process these fields even if they don't match your expected IDs
        for (const fieldName of responseDateFields) {
          console.log(`Processing potential date field: ${fieldName}`, responses[fieldName]);
          
          const fieldValue = responses[fieldName];
          
          // Try to determine if it's a date range
          if (typeof fieldValue === 'object' && fieldValue !== null && 
              ((fieldValue.startDate && fieldValue.endDate) || 
              (fieldValue.start && fieldValue.end))) {
            
            // It's a date range, create an event
            console.log(`Found date range in field ${fieldName}:`, fieldValue);
            
            // Find a matching question or use a default
            const matchingQuestion: FormQuestion = dateFieldQuestions.find((q: FormQuestion) => q.id === fieldName) || {
              id: fieldName,
              type: 'dateRange',
              label: 'Date Range'
            };
            
            let startDate, endDate;
            
            // Process start date
            if (fieldValue.startDate) {
              startDate = processTimestamp(fieldValue.startDate);
            } else if (fieldValue.start) {
              startDate = processTimestamp(fieldValue.start);
            }
            
            // Process end date
            if (fieldValue.endDate) {
              endDate = processTimestamp(fieldValue.endDate);
            } else if (fieldValue.end) {
              endDate = processTimestamp(fieldValue.end);
            }
            
            if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
              // Create event
              const eventId = `${submissionDoc.id}-${fieldName}`;
              const eventTitle = `${submissionData.email}: ${matchingQuestion.label || 'Date Range'}`;
              
              // Add to eventsData if not already added
              if (!addedEventIds.has(eventId)) {
                eventsData.push({
                  id: eventId,
                  title: eventTitle,
                  description: `${matchingQuestion.label || 'Date Range'} from ${format(startDate, 'MMM d, yyyy')} to ${format(endDate, 'MMM d, yyyy')}`,
                  startDate: startDate,
                  endDate: endDate,
                  status: submissionData.approvalStatus || 'pending',
                  email: submissionData.email || 'Anonymous',
                  submissionId: submissionDoc.id,
                  formTitle: formData.title || formData.formTitle || 'Untitled Form',
                  fieldName: fieldName,
                  questionText: matchingQuestion.label || 'Date Range',
                  formId: formId || '',
                  submittedAt: submissionData.submittedAt?.toDate(),
                  comments: submissionData.approvalComments
                });
                
                addedEventIds.set(eventId, true);
                console.log(`✓ Added date range event for field ${fieldName}:`, {
                  id: eventId,
                  title: eventTitle,
                  startDate: startDate.toString(),
                  endDate: endDate.toString()
                });
              }
            } else {
              console.log(`Could not create valid dates from field ${fieldName}:`, {
                startDate,
                endDate
              });
            }
          }
          // Handle single date fields
          else if (fieldValue instanceof Date || 
                (typeof fieldValue === 'object' && fieldValue !== null && 
                  (fieldValue.seconds !== undefined || typeof fieldValue.toDate === 'function'))) {
            
            console.log(`Found single date in field ${fieldName}:`, fieldValue);
            
            // Find a matching question or use a default
            const matchingQuestion: FormQuestion = dateFieldQuestions.find((q: FormQuestion) => q.id === fieldName) || {
              id: fieldName,
              type: 'date',
              label: 'Date'
            };
            
            const date = processTimestamp(fieldValue);
            
            if (date && !isNaN(date.getTime())) {
              // Create event
              const eventId = `${submissionDoc.id}-${fieldName}`;
              const eventTitle = `${submissionData.email}: ${matchingQuestion.label || 'Date'}`;
              
              // Add to eventsData if not already added
              if (!addedEventIds.has(eventId)) {
                eventsData.push({
                  id: eventId,
                  title: eventTitle,
                  description: `${matchingQuestion.label || 'Date'}: ${format(date, 'MMM d, yyyy')}`,
                  startDate: date,
                  endDate: date,
                  status: submissionData.approvalStatus || 'pending',
                  email: submissionData.email || 'Anonymous',
                  submissionId: submissionDoc.id,
                  formTitle: formData.title || formData.formTitle || 'Untitled Form',
                  fieldName: fieldName,
                  questionText: matchingQuestion.label || 'Date',
                  formId: formId || '',
                  submittedAt: submissionData.submittedAt?.toDate(),
                  comments: submissionData.approvalComments
                });
                
                addedEventIds.set(eventId, true);
                console.log(`✓ Added single date event for field ${fieldName}:`, {
                  id: eventId,
                  title: eventTitle,
                  date: date.toString()
                });
              }
            }
          }
        }
      }));
      
      // Save the date events data
      setDateEvents(eventsData);

      // After processing all submissions and creating eventsData
      console.log("Raw events data before validation:", eventsData.map(e => ({
        id: e.id,
        title: e.title,
        startDate: e.startDate ? e.startDate.toString() : 'null',
        endDate: e.endDate ? e.endDate.toString() : 'null',
        status: e.status
      })));
      
      // Validate eventsData before creating calendar events
      const validEvents = eventsData.filter(event => {
        if (!event.id || !event.title) {
          console.error("Event missing required fields:", event);
          return false;
        }
        
        if (!event.startDate || !event.endDate) {
          console.error("Event missing date fields:", event);
          return false;
        }
        
        if (!(event.startDate instanceof Date) || !(event.endDate instanceof Date)) {
          console.error("Event dates are not Date objects:", event);
          return false;
        }
        
        if (isNaN(event.startDate.getTime()) || isNaN(event.endDate.getTime())) {
          console.error("Event has invalid date values:", event);
          return false;
        }
        
        return true;
      });
      
      console.log(`${validEvents.length} valid events out of ${eventsData.length} total`);

      // Add this debugging code
      console.log('Valid events by type:');
      const typeGroups = {
        date: validEvents.filter(e => e.questionText.toLowerCase().includes('date') && !e.questionText.toLowerCase().includes('range')),
        range: validEvents.filter(e => e.questionText.toLowerCase().includes('range')),
        other: validEvents.filter(e => !e.questionText.toLowerCase().includes('date') && !e.questionText.toLowerCase().includes('range'))
      };

      Object.entries(typeGroups).forEach(([type, events]) => {
        console.log(`Type ${type}: ${events.length} events`);
        if (events.length > 0) {
          console.log('Sample:', events[0]);
        }
      });

      // Use validEvents instead of eventsData for creating calendar events
      const fullCalendarEvents = validEvents.map(event => {
        // Convert dates to ISO strings
        const startStr = event.startDate.toISOString();
        const endDate = new Date(event.endDate);
        // FullCalendar end dates are exclusive - add 1 day so the range includes the end date
        endDate.setDate(endDate.getDate() + 1);
        const endStr = endDate.toISOString();
        
        console.log(`Creating calendar event: ${event.id}`, {
          title: event.title,
          start: startStr,
          end: endStr
        });
        
        return {
          id: event.id,
          title: event.title,
          start: startStr,
          end: endStr,
          allDay: true,
          extendedProps: {
            status: event.status,
            email: event.email,
            description: event.description,
            submissionId: event.submissionId,
            fieldName: event.fieldName
          },
          backgroundColor: getStatusColor(event.status),
          borderColor: getStatusColor(event.status, true),
          textColor: '#000000'
        };
      });

      // Add explicit debugging for FullCalendar events
      console.log("FullCalendar events:", fullCalendarEvents);

      // Add a test event directly to see if FullCalendar works:
      if (fullCalendarEvents.length === 0) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        fullCalendarEvents.push({
          id: 'test-event',
          title: 'Test Event',
          start: today.toISOString(),
          end: tomorrow.toISOString(),
          allDay: true,
          backgroundColor: '#FF9800',
          borderColor: '#F57C00',
          extendedProps: {
            status: 'pending',
            email: 'test@example.com',
            description: 'Test Event',
            submissionId: 'test',
            fieldName: 'test'
          },
          textColor: ''
        });
        
        console.log("Added test event to calendar");
      }

      setCalendarEvents(fullCalendarEvents);

      // Update the loadFormAndDateSubmissions function to collect unique emails
      // Add this code right after creating fullCalendarEvents:

      // Extract unique emails from events and submissions
      const emails = new Set<string>();

      // Method 1: Extract from dateEvents
      validEvents.forEach(event => {
        if (event.email && typeof event.email === 'string') {
          emails.add(event.email);
        }
      });

      // Method 2: Directly extract from submissions as fallback
      if (emails.size === 0 && submissionsSnapshot.docs.length > 0) {
        console.log("No emails found in events, extracting directly from submissions");
        submissionsSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.email && typeof data.email === 'string') {
            emails.add(data.email);
          }
        });
      }

      // Debug the emails found
      console.log(`Found ${emails.size} unique emails:`, Array.from(emails));

      // Set the uniqueEmails state
      setUniqueEmails(Array.from(emails).sort());
    } catch (error) {
      console.error("Error loading form data or submissions:", error);
      setActionError("Failed to load calendar data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Helper for status colors
  function getStatusColor(status: string, isBorder: boolean = false): string {
    const alpha = isBorder ? 1 : 0.8; // Border is more solid
    
    switch (status) {
      case 'approved':
        return `rgba(46, 125, 50, ${alpha})`;  // Green
      case 'rejected':
        return `rgba(198, 40, 40, ${alpha})`;  // Red
      case 'pending':
      default:
        return `rgba(237, 108, 2, ${alpha})`;  // Orange/Amber
    }
  }
  
  // Handle clicking an event in the calendar
  const handleEventClick = async (eventClickInfo: EventClickArg) => {
    const { event } = eventClickInfo;
    const { submissionId, fieldName } = event.extendedProps;
    
    try {
      // First, find the basic event data
      const foundEvent = dateEvents.find(
        e => e.submissionId === submissionId && e.fieldName === fieldName
      );
      
      if (foundEvent) {
        setSelectedEvent(foundEvent);
        
        // Show the appropriate dialog based on status
        if (foundEvent.status === 'pending') {
          setComments('');
          setApprovalDialogOpen(true);
        } else {
          // For non-pending items, just show the submission detail
          navigate(`/submission/${submissionId}`);
        }
      }
    } catch (error) {
      console.error("Error handling event click:", error);
    }
  };
  
  // Open approval dialog
  const handleOpenApprovalDialog = () => {
    if (!selectedEvent) return;
    setComments('');
    setApprovalDialogOpen(true);
  };
  
  // Open rejection dialog
  const handleOpenRejectionDialog = () => {
    if (!selectedEvent) return;
    setComments('');
    setRejectionDialogOpen(true);
  };
  
  // Approve selected submission
  const handleApproveSubmission = async () => {
    if (!selectedEvent) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Update both the main submission and details document
      const submissionRef = doc(db, "form_submissions", selectedEvent.submissionId);
      const detailsRef = doc(db, "submission_details", selectedEvent.submissionId);
      
      // Use a batch to update both documents
      const batch = writeBatch(db);
      
      const updateData = {
        approvalStatus: 'approved',
        approvalComments: comments,
        approvalDate: Timestamp.now()
      };
      
      batch.update(submissionRef, updateData);
      
      // Check if details document exists
      try {
        const detailsSnap = await getDoc(detailsRef);
        if (detailsSnap.exists()) {
          batch.update(detailsRef, updateData);
        }
      } catch (error) {
        console.error("Error checking submission details:", error);
      }
      
      await batch.commit();
      
      // Send email notification
      const emailSent = await sendApprovalNotification({
        id: selectedEvent.submissionId,
        email: selectedEvent.email,
        formId: selectedEvent.formId,
        formTitle: selectedEvent.formTitle,
        submittedAt: Timestamp.fromDate(selectedEvent.submittedAt)
      }, 'approved', comments);
      
      // Show success message
      setActionSuccess(
        emailSent
          ? `Date approved successfully! A notification has been sent to ${selectedEvent.email}.`
          : `Date approved successfully! (Email notification could not be sent)`
      );
      
      // Close dialog and refresh data
      setApprovalDialogOpen(false);
      setSelectedEvent(null);
      setComments('');
      loadFormAndDateSubmissions();
      
    } catch (error) {
      console.error("Error approving submission:", error);
    } finally {
      setProcessingAction(false);
    }
  };
  
  // Reject selected submission
  const handleRejectSubmission = async () => {
    if (!selectedEvent || !comments.trim()) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Update both the main submission and details document
      const submissionRef = doc(db, "form_submissions", selectedEvent.submissionId);
      const detailsRef = doc(db, "submission_details", selectedEvent.submissionId);
      
      // Use a batch to update both documents
      const batch = writeBatch(db);
      
      const updateData = {
        approvalStatus: 'rejected',
        approvalComments: comments,
        approvalDate: Timestamp.now()
      };
      
      batch.update(submissionRef, updateData);
      
      // Check if details document exists
      try {
        const detailsSnap = await getDoc(detailsRef);
        if (detailsSnap.exists()) {
          batch.update(detailsRef, updateData);
        }
      } catch (error) {
        console.error("Error checking submission details:", error);
      }
      
      await batch.commit();
      
      // Send email notification
      const emailSent = await sendApprovalNotification({
        id: selectedEvent.submissionId,
        email: selectedEvent.email,
        formId: selectedEvent.formId,
        formTitle: selectedEvent.formTitle,
        submittedAt: Timestamp.fromDate(selectedEvent.submittedAt)
      }, 'rejected', comments);
      
      // Show success message
      setActionSuccess(
        emailSent
          ? `Date rejected successfully. A notification has been sent to ${selectedEvent.email}.`
          : `Date rejected successfully. (Email notification could not be sent)`
      );
      
      // Close dialog and refresh data
      setRejectionDialogOpen(false);
      setSelectedEvent(null);
      setComments('');
      loadFormAndDateSubmissions();
      
    } catch (error) {
      console.error("Error rejecting submission:", error);
      setActionError("Failed to reject submission. Please try again.");
    } finally {
      setProcessingAction(false);
    }
  };
  
  // Navigate back to the regular approval manager
  const handleBackToApprovals = () => {
    navigate('/approval-manager');
  };

  // Fix the handleViewSubmissionDetails function
  const handleViewSubmissionDetails = (submissionId: string) => {
    // Close any open dialogs first
    setApprovalDialogOpen(false);
    setRejectionDialogOpen(false);
    
    // Then navigate to the submission detail
    navigate(`/submission/${submissionId}`);
  };

  // Update the applyFilters function around line 1050
  const applyFilters = useCallback(() => {
    if (!calendarEvents.length) {
      setFilteredEvents([]);
      return;
    }
    
    let filtered = [...calendarEvents];
    
    // Apply email filter if not set to 'all'
    if (selectedEmail !== 'all') {
      filtered = filtered.filter(event => {
        const email = event.extendedProps?.email;
        return email === selectedEmail;
      });
      
      console.log(`Filtered to ${filtered.length} events for email: ${selectedEmail}`);
    }
    
    setFilteredEvents(filtered);
  }, [calendarEvents, selectedEmail]);

  // Call this effect after calendarEvents or selectedEmail changes
  useEffect(() => {
    applyFilters();
  }, [applyFilters, calendarEvents, selectedEmail]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar title="Calendar Approval View" />
        
        <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, mb: 8 }}>
          {/* Header Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, flexWrap: 'wrap', gap: 2 }}>
            <IconButton 
              onClick={handleBackToApprovals}
              sx={{ mr: 1 }}
            >
              <ArrowBack />
            </IconButton>
            
            <Typography variant="h5" component="h1">
              {form?.formTitle || "Calendar View"}
            </Typography>
            
            <Box sx={{ flexGrow: 1 }} />
            
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Pending Approvals">
                <Chip 
                  icon={<Event />} 
                  label={`${dateEvents.filter(e => e.status === 'pending').length} Pending`} 
                  color="warning" 
                />
              </Tooltip>
              
              <Tooltip title="Approved">
                <Chip 
                  icon={<CheckCircle />} 
                  label={`${dateEvents.filter(e => e.status === 'approved').length} Approved`} 
                  color="success" 
                />
              </Tooltip>
              
              <Tooltip title="Rejected">
                <Chip 
                  icon={<Cancel />} 
                  label={`${dateEvents.filter(e => e.status === 'rejected').length} Rejected`} 
                  color="error" 
                />
              </Tooltip>
            </Box>
          </Box>
          
          {/* Filters Section */}
          <Paper 
            sx={{
              p: 2,
              mb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 2,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Box display="flex" alignItems="center">
              <FilterList sx={{ color: 'text.secondary', mr: 1 }} />
              <Typography variant="subtitle2" color="text.secondary">
                Filters:
              </Typography>
            </Box>
            
            <FormControl size="small" sx={{ minWidth: 200, flexGrow: { xs: 1, sm: 0 } }}>
              <InputLabel id="email-filter-label">Requester</InputLabel>
              <Select
                labelId="email-filter-label"
                id="email-filter"
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value as string)}
                input={<OutlinedInput label="Requester" />}
              >
                <MenuItem value="all">All Requesters</MenuItem>
                {uniqueEmails.map(email => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {selectedEmail !== 'all' && (
              <Chip 
                label={`Showing requests from ${selectedEmail}`}
                onDelete={() => setSelectedEmail('all')}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            
            <Box sx={{ flexGrow: 1 }} />
            
            <Typography variant="body2" color="text.secondary">
              {filteredEvents.length} {filteredEvents.length === 1 ? 'event' : 'events'} displayed
            </Typography>
          </Paper>
          
          {/* Instructions */}
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="body2">
              Click on any date in the calendar to view details and manage approvals.
              Different colors indicate status: orange for pending, green for approved, and red for rejected items.
            </Typography>
          </Alert>

          {/* Mobile Notice */}
          {isMobile && (
            <Alert 
              severity="info" 
              sx={{ mb: 2 }}
              onClose={() => {}} 
            >
              <Typography variant="body2">
                For a better calendar experience, try landscape mode or use a larger screen.
              </Typography>
            </Alert>
          )}
          
          {/* Success/Error Messages */}
          {actionError && (
            <Alert 
              severity="error" 
              sx={{ mb: 2 }}
              onClose={() => setActionError(null)}
            >
              {actionError}
            </Alert>
          )}
          
          {actionSuccess && (
            <Alert 
              severity="success" 
              sx={{ mb: 2 }}
              onClose={() => setActionSuccess(null)}
            >
              {actionSuccess}
            </Alert>
          )}
          
          {/* Calendar Section */}
          <Paper 
            elevation={0}
            sx={{ 
              p: { xs: 1, sm: 2 }, 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              overflow: 'hidden'
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ 
                '.fc .fc-toolbar-title': {
                  fontSize: { xs: '1.2rem', sm: '1.5rem' }
                },
                '.fc .fc-button': {
                  textTransform: 'capitalize',
                  fontWeight: 'normal'
                },
                '.fc .fc-daygrid-day-number': {
                  fontSize: '0.9rem',
                  padding: '4px 8px'
                },
                '.fc .fc-event': {
                  cursor: 'pointer'
                }
              }}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  initialView={isMobile ? "listWeek" : "dayGridMonth"}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: isMobile ? 'listWeek,dayGridMonth' : 'dayGridMonth,timeGridWeek,listWeek'
                  }}
                  events={filteredEvents.length > 0 ? filteredEvents : []} // Use filteredEvents instead of calendarEvents
                  eventClick={handleEventClick}
                  height="auto"
                  aspectRatio={1.8}
                  stickyHeaderDates={true}
                  timeZone="local" // Add this to ensure proper timezone handling
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                  }}
                  views={{
                    listWeek: {
                      listDayFormat: { weekday: 'long' },
                      listDayAltFormat: { month: 'long', day: 'numeric' }
                    }
                  }}
                />
              </Box>
            )}
          </Paper>
        </Container>
        
        {/* Detail/Approval Dialog */}
        <Dialog
          open={approvalDialogOpen}
          onClose={() => !processingAction && setApprovalDialogOpen(false)}
          maxWidth="sm"
          fullWidth
          fullScreen={isMobile}
          PaperProps={{
            sx: isMobile ? {} : { borderRadius: 2 }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            {selectedEvent?.status === 'pending' ? (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Date Request</Typography>
                <Chip 
                  label="Pending Approval"
                  color="warning"
                  size="small"
                />
              </Box>
            ) : selectedEvent?.status === 'approved' ? (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Date Request</Typography>
                <Chip 
                  label="Approved"
                  color="success"
                  size="small"
                />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Date Request</Typography>
                <Chip 
                  label="Rejected"
                  color="error"
                  size="small"
                />
              </Box>
            )}
          </DialogTitle>
          
          <DialogContent>
            {selectedEvent && (
              <Box>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Request from
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {selectedEvent.email}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Submitted on {format(selectedEvent.submittedAt, 'PPP')}
                  </Typography>
                </Box>
                
                <Divider sx={{ mb: 3 }} />
                
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {selectedEvent.questionText}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    {selectedEvent.startDate.getTime() === selectedEvent.endDate.getTime() ? (
                      <>
                        <Today color="primary" sx={{ mr: 1 }} />
                        <Typography>
                          {format(selectedEvent.startDate, 'PPP')}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <DateRange color="primary" sx={{ mr: 1 }} />
                        <Typography>
                          {format(selectedEvent.startDate, 'PPP')} - {format(selectedEvent.endDate, 'PPP')}
                          {' '}
                          <Typography component="span" color="text.secondary">
                            ({differenceInDays(selectedEvent.endDate, selectedEvent.startDate) + 1} days)
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
                  
                {/* Comments section for pending rejects */}
                {selectedEvent.status === 'pending' && rejectionDialogOpen && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Reason for rejection (required)
                    </Typography>
                    <TextField
                      multiline
                      rows={3}
                      fullWidth
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      placeholder="Please provide a reason for rejecting this date request..."
                      variant="outlined"
                      disabled={processingAction}
                      error={rejectionDialogOpen && !comments.trim()}
                      helperText={rejectionDialogOpen && !comments.trim() ? "A reason is required for rejection" : ""}
                    />
                  </Box>
                )}
                  
                {/* Existing comments for approved/rejected items */}
                {(selectedEvent.status === 'approved' || selectedEvent.status === 'rejected') && selectedEvent.comments && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      {selectedEvent.status === 'approved' ? 'Approval notes:' : 'Reason for rejection:'}
                    </Typography>
                    <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.default' }}>
                      <Typography variant="body2">{selectedEvent.comments}</Typography>
                    </Paper>
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
          
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button 
              onClick={() => {
                setApprovalDialogOpen(false);
                setRejectionDialogOpen(false);
              }} 
              disabled={processingAction}
              color="inherit"
            >
              Close
            </Button>
            
            {selectedEvent?.status === 'pending' && !rejectionDialogOpen && (
              <>
                <Button 
                  onClick={() => {
                    setApprovalDialogOpen(false);
                    setRejectionDialogOpen(true);
                  }}
                  color="error"
                  disabled={processingAction}
                  startIcon={<Cancel />}
                >
                  Reject
                </Button>
                
                <Button 
                  onClick={handleApproveSubmission}
                  variant="contained"
                  color="success"
                  disabled={processingAction}
                  startIcon={<Done />}
                >
                  {processingAction ? "Processing..." : "Approve"}
                </Button>
              </>
            )}
            
            {selectedEvent?.status === 'pending' && rejectionDialogOpen && (
              <Button 
                onClick={handleRejectSubmission}
                variant="contained"
                color="error"
                disabled={processingAction || !comments.trim()}
                startIcon={<Close />}
              >
                {processingAction ? "Processing..." : "Confirm Rejection"}
              </Button>
            )}

            {selectedEvent && (
              <Button 
                onClick={() => handleViewSubmissionDetails(selectedEvent.submissionId)} 
                color="primary"
                disabled={processingAction}
                startIcon={<Visibility />}
                sx={{ mr: 'auto' }}
              >
                View Details
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default ApprovalCalendarView;