import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { 
  AppBar, Toolbar, Typography, Button, Box, 
  TextField, Avatar, Alert, Snackbar, Paper,
  Select, MenuItem, FormControl, InputLabel,
  alpha, useTheme, CircularProgress, Divider,
  InputAdornment, ThemeProvider
} from "@mui/material";
import { Add, Save, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SharedNavBar from "./SharedNavBar"; 
import { syncProfileToLandingPage } from '../utils/profileSync';
import { themeOptions, createAppTheme } from "../theme/themeOptions";

// Convert File to base64
const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

// Compress image before converting to base64
const compressAndConvertToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let width = img.width;
        let height = img.height;
        
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);
        
        // 70% quality JPEG
        const base64 = canvas.toDataURL('image/jpeg', 0.7);
        resolve(base64);
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

const UserProfile: React.FC = () => {
  const theme = useTheme();
  const { user } = useUser();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [shortCompanyName, setShortCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Profile updated successfully!");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState<string>("User");
  const [isAdmin, setIsAdmin] = useState(false);
  const [forms, setForms] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // New state for personal profile information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [updatingPersonalInfo, setUpdatingPersonalInfo] = useState(false);

  // New state for slug validation
  const [isCheckingSlug, setIsCheckingSlug] = useState(false);
  const [isSlugAvailable, setIsSlugAvailable] = useState(true);
  const [slugError, setSlugError] = useState<string | null>(null);

  // Add theme state
  const [userTheme, setUserTheme] = useState<string>("Blue"); // Default theme

  // Add a function to generate slugs from text
  const generateSlug = (text: string): string => {
    return text.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  // Add a function to check slug availability
  const checkSlugAvailability = async (slug: string) => {
    if (!slug || !user) return;
    
    try {
      setIsCheckingSlug(true);
      setSlugError(null);
      
      // First check if this is the current user's slug
      const userRef = doc(db, "users", user.id);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists() && userDoc.data().shortCompanyName === slug) {
        // This is the user's current slug, so it's available for them
        setIsSlugAvailable(true);
        setIsCheckingSlug(false);
        return;
      }
      
      // Check if slug is already taken by another user
      const slugQuery = query(
        collection(db, "users"),
        where("shortCompanyName", "==", slug)
      );
      
      const slugSnapshot = await getDocs(slugQuery);
      
      if (!slugSnapshot.empty) {
        setIsSlugAvailable(false);
        setSlugError("This URL slug is already taken by another company");
      } else {
        setIsSlugAvailable(true);
        setSlugError(null);
      }
    } catch (error) {
      console.error("Error checking slug availability:", error);
      setSlugError("Error checking availability. Please try again.");
    } finally {
      setIsCheckingSlug(false);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!user) return;
      
      try {
        const userDoc = await getDoc(doc(db, "users", user.id));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCompanyName(userData.companyName || "");
          setShortCompanyName(userData.shortCompanyName || "");
          setCompanyLogo(userData.companyLogo || null);
          setUserRole(userData.role || "User");
          setIsAdmin(userData.role === "Admin");
          
          // Set the user's theme preference
          const savedTheme = userData.theme || "Blue";
          setUserTheme(savedTheme);
        }
        
        // Set initial name values from Clerk user
        setFirstName(user.firstName || "");
        setLastName(user.lastName || "");
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user]);

  useEffect(() => {
    if (!user || !userRole) return;
    setIsLoading(true);
  
    const fetchForms = async () => {
      try {
        let q;
        if (userRole === "Admin" || userRole === "Manager") {
          // Admin & Manager see all forms
          q = query(collection(db, "forms"));
        } else {
          // Users see only their forms
          q = query(
            collection(db, "forms"), 
            where("userId", "==", user.id)
          );
        }
  
        const querySnapshot = await getDocs(q);
        const formList = querySnapshot.docs.map((doc) => ({ 
          id: doc.id, 
          ...(doc.data() as any),
          // Format timestamps and add default values
          createdAt: doc.data().createdAt ? 
            (typeof doc.data().createdAt.toDate === 'function' ? doc.data().createdAt.toDate() : new Date()) 
            : new Date(),
          responses: doc.data().responses || 0
        }));
        console.log("Fetched forms:", formList);
        setForms(formList);
      } catch (error) {
        console.error("Error fetching forms:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchForms();
  }, [user, userRole]);

  useEffect(() => {
    if (shortCompanyName) {
      // Use a timeout to prevent too many requests while typing
      const timer = setTimeout(() => {
        checkSlugAvailability(shortCompanyName);
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [shortCompanyName]);

  const handleSaveProfile = async () => {
    if (!user) return;
    
    // Check if the slug is available
    if (shortCompanyName && !isSlugAvailable) {
      setSnackbarMessage("The URL slug is already taken. Please choose a different one.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    
    let logoBase64 = companyLogo;
    
    if (newLogo) {
      try {
        logoBase64 = await compressAndConvertToBase64(newLogo);
      } catch (error) {
        console.error("Error processing logo:", error);
      }
    }

    try {
      // First, update the user profile
      await setDoc(doc(db, "users", user.id), {
        companyName,
        shortCompanyName,
        companySlug: shortCompanyName, // Also set companySlug to match shortCompanyName
        companyLogo: logoBase64,
        role: userRole,
        theme: userTheme, // Save the theme
        updatedAt: new Date()
      }, { merge: true });
      
      // Use the sync utility to ensure landing page is updated
      await syncProfileToLandingPage(user.id);
      
      setSnackbarMessage("Company profile updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving profile:", error);
      setSnackbarMessage("Failed to save company profile. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  // New function to update personal profile information
  const updatePersonalProfile = async () => {
    if (!user) return;
    
    setUpdatingPersonalInfo(true);
    
    try {
      // Update the user's first and last name using Clerk's client-side API
      await user.update({
        firstName,
        lastName,
      });
      
      // If there's a new profile picture, upload it
      if (profilePicture) {
        // Clerk allows direct image uploads
        await user.setProfileImage({ file: profilePicture });
      }
      
      setSnackbarMessage("Personal profile updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating personal profile:", error);
      setSnackbarMessage("Failed to update personal profile. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setUpdatingPersonalInfo(false);
    }
  };

  // Preview logo from file or existing base64
  const logoPreview = newLogo ? URL.createObjectURL(newLogo) : companyLogo;
  
  // Preview profile picture
  const profilePicturePreview = profilePicture 
    ? URL.createObjectURL(profilePicture) 
    : user?.imageUrl;

  // Create the theme using the utility function
  const appTheme = createAppTheme(userTheme);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={appTheme}>
      <div>
        <SharedNavBar title="User Profile" />

        <Box className="max-w-4xl mx-auto mt-8 p-6">
          {/* Personal Profile Information */}
          <Paper elevation={3} sx={{ p: 4, mb: 4, borderRadius: '12px' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>Personal Profile</Typography>
            
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3, alignItems: 'center', mb: 4 }}>
              {/* Profile picture preview and upload */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Avatar 
                  src={profilePicturePreview} 
                  alt={firstName || "User"}
                  sx={{ width: 100, height: 100 }}
                >
                  {(firstName?.[0] || "U")}
                </Avatar>
                
                <Button
                  variant="outlined"
                  size="small"
                  component="label"
                  sx={{ mt: 1 }}
                >
                  Change Photo
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => setProfilePicture(e.target.files?.[0] || null)}
                  />
                </Button>
              </Box>
              
              {/* Name fields */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
                <TextField
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={updatingPersonalInfo}
                />
                
                <TextField
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={updatingPersonalInfo}
                />
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={updatePersonalProfile}
                    disabled={updatingPersonalInfo}
                    startIcon={updatingPersonalInfo ? <CircularProgress size={20} /> : <Save />}
                  >
                    {updatingPersonalInfo ? "Saving..." : "Save Personal Info"}
                  </Button>
                </Box>
              </Box>
            </Box>
            
            {/* Read-only information */}
            <Box sx={{ mt: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">Email Address</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>{user?.primaryEmailAddress?.emailAddress}</Typography>
              
              <Typography variant="body2" color="text.secondary">User Role</Typography>
              <Typography variant="body1">{userRole}</Typography>
            </Box>
          </Paper>
          
          <Divider sx={{ my: 4 }} />

          {/* Company Information */}
          <Paper elevation={3} sx={{ p: 4, borderRadius: '12px' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>Company Information</Typography>
            
            <TextField
              fullWidth
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              sx={{ mb: 3 }}
            />
            
            <TextField
              fullWidth
              label="Short Company Name (for URLs)"
              value={shortCompanyName}
              onChange={(e) => setShortCompanyName(generateSlug(e.target.value))}
              sx={{ mb: 3 }}
              helperText={
                isCheckingSlug ? 
                  "Checking availability..." : 
                  slugError || 
                  "A shorter version of your company name to use in URLs (e.g. 'acme' instead of 'Acme Corporation Ltd')"
              }
              error={!!slugError}
              disabled={isCheckingSlug}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    formcraft.co.uk/
                  </InputAdornment>
                ),
                endAdornment: isCheckingSlug && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />
            
            <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>Company Logo</Typography>
            
            {logoPreview && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                <img 
                  src={logoPreview} 
                  alt="Company Logo Preview" 
                  style={{ maxHeight: '120px', objectFit: 'contain', border: '1px solid #eee', borderRadius: '4px', padding: '8px' }}
                />
              </Box>
            )}
            
            <Button
              variant="outlined"
              component="label"
              sx={{ mb: 3 }}
            >
              Upload Logo
              <input
                type="file" 
                accept="image/*" 
                hidden
                onChange={(e) => setNewLogo(e.target.files?.[0] || null)}
              />
            </Button>
            
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSaveProfile}
                startIcon={<Save />}
              >
                Save Company Info
              </Button>
            </Box>
          </Paper>

          {/* Theme Settings */}
          <Paper elevation={3} sx={{ p: 4, mt: 4, borderRadius: '12px' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>Theme Settings</Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle1">Choose your preferred theme:</Typography>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {themeOptions.map((option) => (
                  <Box
                    key={option.name}
                    onClick={() => setUserTheme(option.name)}
                    sx={{
                      width: '100px',
                      height: '80px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '2px solid',
                      borderColor: userTheme === option.name ? option.primary : 'transparent',
                      borderRadius: 2,
                      transition: 'all 0.2s',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 2
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        backgroundColor: option.primary,
                        mb: 1
                      }}
                    />
                    <Typography variant="body2">{option.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Paper>
        </Box>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackbarSeverity} onClose={() => setSnackbarOpen(false)}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default UserProfile;