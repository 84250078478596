import React from 'react';
import { Box, Typography } from '@mui/material';

interface FollowUpDisplayProps {
  question: string;
  response: string;
}

const FollowUpDisplay: React.FC<FollowUpDisplayProps> = ({ question, response }) => {
  return (
    <Box sx={{ mt: 1, ml: 2, borderLeft: '2px solid', borderColor: 'divider', pl: 2 }}>
      <Typography variant="caption" color="text.secondary">
        Follow-up: {question}
      </Typography>
      <Typography variant="body2">
        {response}
      </Typography>
    </Box>
  );
};

export default FollowUpDisplay;