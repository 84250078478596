import React from "react";
import { Box, TextField, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Question } from "../../../store/store";

interface TimeQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
}

const TimeQuestion: React.FC<TimeQuestionProps> = ({ question, onChange }) => {
  // Get current timeMode with fallback to "single"
  const timeMode = question.timeMode || "single";

  // Debug log when component renders and when changes are made
  React.useEffect(() => {
    console.log("TimeQuestion builder rendering:", { 
      id: question.id, 
      timeMode: timeMode 
    });
  }, [question.id, timeMode]);

  // Handle timeMode change - modified to use the correct SelectChangeEvent type
  const handleTimeModeChange = (event: SelectChangeEvent) => {
    const newTimeMode = event.target.value;
    console.log("Changing timeMode to:", newTimeMode);
    
    // Important - call onChange to update the question in the store
    onChange({ timeMode: newTimeMode });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Label Editing */}
      <TextField 
        fullWidth 
        label="Question Label" 
        value={question.label || ""} 
        onChange={(e) => onChange({ label: e.target.value })}
      />

      {/* Time Mode Selector */}
      <FormControl fullWidth>
        <InputLabel id={`time-mode-label-${question.id}`}>Time Mode</InputLabel>
        <Select
          labelId={`time-mode-label-${question.id}`}
          id={`time-mode-select-${question.id}`}
          value={timeMode}
          label="Time Mode"
          onChange={handleTimeModeChange}
        >
          <MenuItem value="single">Single Time</MenuItem>
          <MenuItem value="range">Time Range</MenuItem>
        </Select>
      </FormControl>

      {/* Helper text */}
      <Typography variant="caption" color="text.secondary">
        {timeMode === "single" 
          ? "Users will select a single time value" 
          : "Users will select a start and end time, and the duration will be calculated"}
      </Typography>
      
      {/* Preview of how it will look */}
      <Box sx={{ 
        mt: 2, 
        p: 2, 
        border: '1px dashed', 
        borderColor: 'divider',
        borderRadius: 1
      }}>
        <Typography variant="subtitle2" gutterBottom>Preview:</Typography>
        {timeMode === "single" ? (
          <Typography>A single time picker will be shown to users.</Typography>
        ) : (
          <Typography>Users will select both a start and end time, and the duration will be calculated.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default TimeQuestion;