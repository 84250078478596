import { Chart as ChartJS, ArcElement, LineElement, BarElement, PointElement, 
  CategoryScale, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js';

// Register Chart.js components
ChartJS.register(
  ArcElement,       // Required for Pie/Doughnut charts
  LineElement,      // Required for Line charts
  BarElement,       // Required for Bar charts
  PointElement,     // Required for Line/Scatter charts
  CategoryScale,    // Required for x-axis
  LinearScale,      // Required for y-axis
  Title,            // Required for titles
  Tooltip,          // Required for tooltips
  Legend,           // Required for legends
  Filler            // Required for area filling
);

// This file doesn't export anything, it just registers Chart.js components