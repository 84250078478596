import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Fade } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignatureCanvas from "react-signature-canvas";
import Question from './Question';

interface SignatureQuestionProps {
  question: any;
  value: string | null;
  onChange: (value: string | null) => void;
  onClear: () => void;
  sigCanvasRef: (ref: SignatureCanvas | null) => void;
  onEnd: () => void;
}

export type { SignatureQuestionProps };

const SignatureQuestion: React.FC<SignatureQuestionProps> = ({
  question,
  value,
  onChange,
  sigCanvasRef,
  onEnd,
  onClear
}) => {
  const localRef = useRef<SignatureCanvas | null>(null);
  const [signatureCaptured, setSignatureCaptured] = useState<boolean>(Boolean(value));
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  // Show confirmation message briefly when signature is captured
  useEffect(() => {
    if (value && !signatureCaptured) {
      setSignatureCaptured(true);
      setShowConfirmation(true);
      const timer = setTimeout(() => setShowConfirmation(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [value, signatureCaptured]);

  // Handle the ref setting without re-rendering the canvas
  const setRef = (ref: SignatureCanvas | null) => {
    // Store locally
    localRef.current = ref;
    
    // Pass to parent
    if (ref) {
      sigCanvasRef(ref);
      
      // Important: Only attempt to restore signature once when the canvas is first mounted
      if (value && typeof value === 'string' && !signatureCaptured) {
        setTimeout(() => {
          try {
            if (ref && !ref.isEmpty()) {
              // Skip restoration if there's already a signature drawn
              return;
            }
            
            const img = new Image();
            img.onload = () => {
              if (ref) {
                ref.clear();
                const canvas = ref.getCanvas();
                const ctx = canvas.getContext('2d');
                if (ctx) {
                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                  setSignatureCaptured(true);
                }
              }
            };
            img.src = value;
          } catch (err) {
            console.error("Error restoring signature:", err);
          }
        }, 100);
      }
    }
  };

  return (
    <Question question={question}>
      <Box position="relative">
        <Box className="border rounded-md bg-white p-3 shadow-md">
          <SignatureCanvas 
            ref={setRef}
            penColor="black" 
            canvasProps={{ 
              className: "w-full h-32 border rounded bg-white", 
              id: `sig-canvas-${question.id}` 
            }} 
            onEnd={() => {
              // Call onEnd to capture signature data
              onEnd();
              // Set confirmation without re-rendering signature
              setShowConfirmation(true);
              setTimeout(() => setShowConfirmation(false), 2000);
            }}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
            <Button 
              size="small" 
              onClick={() => {
                onClear();
                onChange(null);
                setSignatureCaptured(false);
              }} 
              color="error"
              variant="text"
            >
              Clear Signature
            </Button>
            
            {/* Static signature indicator that doesn't cause movement */}
            {signatureCaptured && (
              <Typography variant="caption" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
                Signature saved
              </Typography>
            )}
          </Box>
        </Box>
        
        {/* Overlay confirmation that floats above without moving content */}
        <Fade in={showConfirmation}>
          <Box 
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(46, 125, 50, 0.9)',
              color: 'white',
              borderRadius: 1,
              px: 3,
              py: 1.5,
              display: 'flex',
              alignItems: 'center',
              pointerEvents: 'none', // Make sure it doesn't interfere with clicks
              zIndex: 10
            }}
          >
            <CheckCircleIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Signature Captured!</Typography>
          </Box>
        </Fade>
      </Box>
    </Question>
  );
};

export default SignatureQuestion;