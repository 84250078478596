import { safeTextWithNewlines } from './PDFUtils';

interface PDFRepeatableRendererProps {
  doc: any;
  questionsToInclude: any[];
  responses: { [key: string]: any };
  repeatableItems: { [key: string]: Array<{ [key: string]: any }> };
  currentY: number;
  primaryColorRgb: [number, number, number];
  blackTextColor: [number, number, number];
  lightGrayColor: [number, number, number];
  safeText: (value: any) => string;
}

export const PDFRepeatableRenderer = ({
  doc,
  questionsToInclude,
  responses,
  repeatableItems,
  currentY,
  primaryColorRgb,
  blackTextColor,
  lightGrayColor,
  safeText
}: PDFRepeatableRendererProps): number => {
  
  // Get all repeatable questions
  const repeatableQuestions = questionsToInclude.filter(q => q.type === "repeatable");
  
  // If no repeatable questions, return the current Y position
  if (repeatableQuestions.length === 0) {
    return currentY;
  }
  
  // Check if there are any items in any repeatable question
  const hasItems = Object.values(repeatableItems).some(items => items && items.length > 0);
  if (!hasItems) {
    return currentY;
  }
  
  // Add a page break for repeatable items section
  doc.addPage();
  currentY = 20;
  
  // Add section title for repeatable items
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 10, 2, 2, "FD");
  
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("REPEATABLE ITEMS", doc.internal.pageSize.getWidth() / 2, currentY + 7, { align: "center" });
  
  // Reset text color for content
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  currentY += 15;
  
  // Process each repeatable question
  repeatableQuestions.forEach((question, questionIndex) => {
    const items = repeatableItems[question.id] || [];
    
    if (items.length === 0) {
      return; // Skip questions with no items
    }
    
    // Add question header
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    doc.text(`${questionIndex + 1}. ${safeText(question.label)} (${items.length} item${items.length !== 1 ? 's' : ''})`, 15, currentY + 6);
    currentY += 15;
    
    // For each item in this repeatable question
    items.forEach((item, itemIndex) => {
      // Check if we need a new page
      if (currentY > doc.internal.pageSize.getHeight() - 60) {
        doc.addPage();
        currentY = 20;
      }
      
      // Add item header with number
      doc.setFillColor(240, 240, 245);
      doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
      doc.roundedRect(15, currentY, doc.internal.pageSize.getWidth() - 30, 10, 2, 2, "FD");
      
      doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Item #${itemIndex + 1}`, 20, currentY + 7);
      currentY += 15;
      
      // Process each subfield
      if (question.subFields && Array.isArray(question.subFields)) {
        question.subFields.forEach((field: any) => {
          // Check if we need a new page
          if (currentY > doc.internal.pageSize.getHeight() - 40) {
            doc.addPage();
            currentY = 20;
          }
          
          // Field label
          doc.setFontSize(9);
          doc.setFont("helvetica", "bold");
          doc.text(`${safeText(field.label)}:`, 20, currentY);
          
          // Get field value
          let fieldValue = item[field.id] || "Not provided";
          let fieldText = "";
          
          // Format value based on field type
          if (field.type === "date" && fieldValue) {
            try {
              fieldText = new Date(fieldValue).toLocaleDateString();
            } catch {
              fieldText = String(fieldValue);
            }
          } else if (field.type === "select" || field.type === "text" || field.type === "number") {
            fieldText = String(fieldValue);
          } else if (field.type === "multi-select" && Array.isArray(fieldValue)) {
            fieldText = fieldValue.join(", ");
          } else {
            fieldText = String(fieldValue);
          }
          
          // Field value with slight indent
          doc.setFont("helvetica", "normal");
          const splitText = doc.splitTextToSize(
            safeText(fieldText), 
            doc.internal.pageSize.getWidth() - 45
          );
          
          doc.text(splitText, 25, currentY + 5);
          
          // Update Y position based on text height
          const textHeight = splitText.length * 5;
          currentY += Math.max(10, textHeight + 5);
          
          // Check for follow-ups
          // Handle select field followups
          if (field.type === "select" && fieldValue) {
            // Check for modern followups array
            if (field.followUps && Array.isArray(field.followUps)) {
              const matchingFollowUp = field.followUps.find(
                (f: any) => f.triggerOption === fieldValue
              );
              
              if (matchingFollowUp) {
                const followUpValue = item[`${field.id}-followup-${fieldValue}`] || "";
                
                if (followUpValue) {
                  doc.setFontSize(9);
                  doc.setFont("helvetica", "italic");
                  doc.text(`Follow-up: ${safeText(matchingFollowUp.question)}`, 25, currentY);
                  
                  doc.setFont("helvetica", "normal");
                  const followUpText = doc.splitTextToSize(
                    safeText(followUpValue), 
                    doc.internal.pageSize.getWidth() - 50
                  );
                  
                  doc.text(followUpText, 30, currentY + 5);
                  currentY += followUpText.length * 5 + 8;
                }
              }
            }
            
            // Check for legacy followup format
            if (field.followUpTriggerOption === fieldValue && field.followUp) {
              const followUpValue = item[`${field.id}-followup`] || "";
              
              if (followUpValue) {
                doc.setFontSize(9);
                doc.setFont("helvetica", "italic");
                doc.text(`Follow-up: ${safeText(field.followUp.question)}`, 25, currentY);
                
                doc.setFont("helvetica", "normal");
                const followUpText = doc.splitTextToSize(
                  safeText(followUpValue), 
                  doc.internal.pageSize.getWidth() - 50
                );
                
                doc.text(followUpText, 30, currentY + 5);
                currentY += followUpText.length * 5 + 8;
              }
            }
          }
          
          // Handle multi-select field followups
          if (field.type === "multi-select" && Array.isArray(fieldValue) && fieldValue.length > 0) {
            // Check for modern followups array
            if (field.followUps && Array.isArray(field.followUps)) {
              field.followUps.forEach((f: any) => {
                if (fieldValue.includes(f.triggerOption)) {
                  const followUpValue = item[`${field.id}-followup-${f.triggerOption}`] || "";
                  
                  if (followUpValue) {
                    doc.setFontSize(9);
                    doc.setFont("helvetica", "italic");
                    doc.text(`Follow-up for "${f.triggerOption}": ${safeText(f.question)}`, 25, currentY);
                    
                    doc.setFont("helvetica", "normal");
                    const followUpText = doc.splitTextToSize(
                      safeText(followUpValue), 
                      doc.internal.pageSize.getWidth() - 50
                    );
                    
                    doc.text(followUpText, 30, currentY + 5);
                    currentY += followUpText.length * 5 + 8;
                  }
                }
              });
            }
            
            // Check for legacy followup format
            if (fieldValue.includes(field.followUpTriggerOption) && field.followUp) {
              const followUpValue = item[`${field.id}-followup`] || "";
              
              if (followUpValue) {
                doc.setFontSize(9);
                doc.setFont("helvetica", "italic");
                doc.text(`Follow-up: ${safeText(field.followUp.question)}`, 25, currentY);
                
                doc.setFont("helvetica", "normal");
                const followUpText = doc.splitTextToSize(
                  safeText(followUpValue), 
                  doc.internal.pageSize.getWidth() - 50
                );
                
                doc.text(followUpText, 30, currentY + 5);
                currentY += followUpText.length * 5 + 8;
              }
            }
          }
        });
      }
      
      // Add separator after each item
      doc.setDrawColor(200, 200, 200);
      doc.setLineWidth(0.5);
      doc.line(20, currentY, doc.internal.pageSize.getWidth() - 20, currentY);
      currentY += 10;
    });
    
    // Add space between questions
    currentY += 10;
  });
  
  return currentY;
};