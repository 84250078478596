import React from 'react';
import { Button, Typography } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface FileResponseProps {
  question: any;
  submission: any;
}

const FileResponse: React.FC<FileResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // Debug logging
  console.log(`File response for ${question.id}:`, {
    response,
    responseType: response ? typeof response : 'undefined',
    isUrl: typeof response === 'string' && (response.startsWith('http') || response.startsWith('data:'))
  });
  
  // If response is a URL, we can provide a link
  if (response && typeof response === 'string' && 
      (response.startsWith('http') || response.startsWith('blob:') || response.startsWith('data:'))) {
    
    // Try to extract filename from URL
    let fileName = "Download File";
    try {
      if (response.includes('/')) {
        const urlParts = response.split('/');
        fileName = urlParts[urlParts.length - 1].split('?')[0];
        // Decode URI components
        fileName = decodeURIComponent(fileName);
        // Limit filename length
        if (fileName.length > 30) {
          fileName = fileName.substring(0, 27) + '...';
        }
      }
    } catch (err) {
      console.error("Error extracting filename:", err);
    }
    
    return (
      <>
        <Button 
          variant="outlined" 
          size="small" 
          href={response} 
          target="_blank" 
          rel="noopener noreferrer"
          startIcon={<AttachFileIcon />}
        >
          {fileName}
        </Button>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // If response is an object with a URL property
  if (response && typeof response === 'object' && response.url) {
    return (
      <>
        <Button 
          variant="outlined" 
          size="small" 
          href={response.url} 
          target="_blank" 
          rel="noopener noreferrer"
          startIcon={<AttachFileIcon />}
        >
          {response.name || "Download File"}
        </Button>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // No file found
  return (
    <>
      <Typography variant="body1">No file provided</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

export default FileResponse;