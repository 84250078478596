import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { doc as firestoreDoc, getDoc, addDoc, collection, updateDoc, setDoc } from "firebase/firestore";
import { 
  TextField, Select, MenuItem, Button, 
  Snackbar, Alert, InputLabel,
  FormControl,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
  Typography,
  Box,
  Chip,
  Dialog, AppBar, Toolbar, IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ThemeProvider,
  Theme
} from "@mui/material";
import TextQuestion from '../components/form/questions/TextQuestion';
import NumberQuestion from '../components/form/questions/NumberQuestion';
import DateQuestion from '../components/form/questions/DateQuestion';
import LongTextQuestion from '../components/form/questions/LongTextQuestion';
import CheckboxQuestion from '../components/form/questions/CheckboxQuestion';
import SelectQuestion from '../components/form/questions/SelectQuestion';
import MultiSelectQuestion from '../components/form/questions/MultiSelectQuestion';
import ImageQuestion from '../components/form/questions/ImageQuestion';
import InstructionsBlock from '../components/form/questions/InstructionsBlock';
import SignatureQuestion from '../components/form/questions/SignatureQuestion';
import RepeatableQuestion from '../components/form/questions/RepeatableQuestion';
import SectionQuestion from '../components/form/questions/SectionQuestion';
import DateRangeQuestion from '../components/form/questions/DateRangeQuestion';
import FileQuestion from '../components/form/questions/FileQuestion';
import TimeQuestion from '../components/form/questions/TimeQuestion';
import { usePdfGenerator } from '../hooks/usePdfGenerator';
import { useSignatures } from '../hooks/useSignatures';
import { recordFormSubmission } from '../services/firebase/submissionWriter';

// Make sure these imports are correct based on your MUI version
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SignatureCanvas from "react-signature-canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { format } from 'date-fns';
import { JSX } from "react/jsx-runtime";
import { Add, Delete, Close, Save, Edit } from "@mui/icons-material";
import Painter from 'react-painter';
import { createTheme } from "@mui/material";
import { differenceInBusinessDays, differenceInCalendarDays, isWeekend } from 'date-fns';
import { themeOptions, createAppTheme } from '../theme/themeOptions';

// Define the EMAIL_API_URL constant
const EMAIL_API_URL = "http://your-email-api-url.com";

// Define the extended type for jsPDF with autoTable
interface ExtendedJsPDF extends jsPDF {
  autoTable: (options: any) => any;
  lastAutoTable?: { finalY: number };
}


// Load user theme preference
const getUserTheme = () => {
  const savedTheme = localStorage.getItem("userThemePreference");
  return themeOptions.find(theme => theme.name === savedTheme) || themeOptions[0];
};

// Helper function to create empty repeatable item
const createEmptyRepeatableItem = (subFields: Array<{id: string, type: string}>) => {
  const emptyItem: { [key: string]: any } = {};
  subFields.forEach(field => {
    if (field.type === "multi-select") {
      emptyItem[field.id] = [];
    } else if (field.type === "date" || field.type === "image") {
      emptyItem[field.id] = null;
    } else if (field.type === "checkbox") {
      emptyItem[field.id] = false;
    } else {
      emptyItem[field.id] = "";
    }
  });
  return emptyItem;
};

const FillForm: React.FC = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState<any>(null);
  const [responses, setResponses] = useState<{ [key: string]: any }>({
    email: "", // Add email to initial responses
  });
  const [loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<{[key: string]: string}>({});
  const [repeatableItems, setRepeatableItems] = useState<{ 
    [questionId: string]: Array<{ [key: string]: any }> 
  }>({});
  const [annotatingImage, setAnnotatingImage] = useState<string | null>(null);
  const [annotatedImages, setAnnotatedImages] = useState<{[key: string]: string}>({});
  const painterRef = useRef<any>(null);

  // Add these state variables to manage our custom confirm dialogs
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => {});
  const [confirmMessage, setConfirmMessage] = useState("");
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [downloadFilename, setDownloadFilename] = useState("");

  // Add this state variable:
  const [followUpResponses, setFollowUpResponses] = useState<Record<string, string>>({});

  // Add new state for PDF email preference
  const [sendPdfToUser, setSendPdfToUser] = useState<boolean>(false);

  // Add this to your state variables at the top of the component
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add this state variable
  const [formCreatorTheme, setFormCreatorTheme] = useState<string>("Blue");

  // Create theme based on user preference
  const theme = createAppTheme(formCreatorTheme);

  const { generatePdf, pdfGenerating } = usePdfGenerator();
  const { sigCanvasRefs, signatures, handleSignatureEnd, clearSignature } = useSignatures();

  const [fileUploads, setFileUploads] = useState<{ [key: string]: File }>({});
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);

  useEffect(() => {
    const fetchForm = async () => {
      if (!formId) {
        console.error("No formId provided");
        setLoading(false);
        navigate("/"); // Redirect to home if no formId
        return;
      }
      
      try {
        const docRef = firestoreDoc(db, "forms", formId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const formData = docSnap.data();
          console.log("Loaded form data:", formData); // Debug log
          setForm(formData);

          // Fetch the company logo from the user's profile
          if (formData.userId) {
            console.log("Attempting to load logo for user ID:", formData.userId); // Debug log
            const userRef = firestoreDoc(db, "users", formData.userId);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              const userData = userSnap.data();
              if (userData.companyLogo) {
                console.log("Found company logo in user profile");
                setLogoUrl(userData.companyLogo);
              } else {
                console.log("No company logo found in user profile");
              }
              if (userData.theme) {
                setFormCreatorTheme(userData.theme);
              }
            } else {
              console.log("No user profile found");
            }
          } else {
            console.log("No userId found in form data");
          }

          // Initialize responses for each question
          const initialResponses: { [key: string]: any } = {};
          const initialRepeatableItems: { [key: string]: Array<{ [key: string]: any }> } = {};
          
          formData.questions.forEach((q: any) => {
            // Default values based on question type
            if (q.type === "multi-select") {
              initialResponses[q.id] = [];
            } else if (q.type === "checkbox" && q.checkboxStyle === "multi") {
              initialResponses[q.id] = [];
            } else if (q.type === "date") {
              initialResponses[q.id] = null;
            } else if (q.type === "image") {
              initialResponses[q.id] = null;
            } else if (q.type === "repeatable" && q.subFields) {
              console.log(`Initializing repeatable for ${q.id} with ${q.subFields.length} subFields`);
              initialRepeatableItems[q.id] = [createEmptyRepeatableItem(q.subFields)];
            } else if (q.type === "date-range") {
              initialResponses[q.id] = {
                startDate: null,
                endDate: null,
                useHours: false,
                hours: null,
                includeWeekends: false
              };
            } else if (q.type === "file") {
              initialResponses[q.id] = null;
            } else {
              initialResponses[q.id] = "";
            }
          });
          
          console.log("Initial repeatable items:", initialRepeatableItems);
          setRepeatableItems(initialRepeatableItems);
          
          setResponses(initialResponses);
        } else {
          alert("Form not found!");
        }
      } catch (error) {
        console.error("Error fetching form:", error);
        alert("Error loading form");
        navigate("/"); // Redirect on error
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, navigate]);

  useEffect(() => {
    const fetchForm = async () => {
      if (!formId) {
        console.error("No formId provided");
        setLoading(false);
        navigate("/"); // Redirect to home if no formId
        return;
      }
      
      try {
        const docRef = firestoreDoc(db, "forms", formId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const formData = docSnap.data();
          console.log("Loaded form data:", formData); // Debug log
          setForm(formData);

          // Initialize responses for each question
          const initialResponses: { [key: string]: any } = {};
          const initialRepeatableItems: { [key: string]: Array<{ [key: string]: any }> } = {};
          
          formData.questions.forEach((q: any) => {
            // Add special debug logging for time questions
            if (q.type === "time") {
              console.log("Initializing time question:", q.id, "Mode:", q.timeMode);
              
              // Initialize time questions correctly based on mode
              if (q.timeMode === "range") {
                initialResponses[q.id] = { startTime: null, endTime: null, minutes: 0 };
              } else {
                initialResponses[q.id] = null;
              }
            } 
            // Rest of your initialization code
            else if (q.type === "multi-select") {
              initialResponses[q.id] = [];
            } else if (q.type === "checkbox") {
              if (q.checkboxStyle === "yes-no") {
                initialResponses[q.id] = false;
              } else if (q.checkboxStyle === "multi") {
                initialResponses[q.id] = [];
              }
            } else if (q.type === "date") {
              initialResponses[q.id] = null;
            } else if (q.type === "repeatable" && Array.isArray(q.subFields)) {
              initialRepeatableItems[q.id] = [];
            } else if (q.type !== "instructions" && q.type !== "section") {
              initialResponses[q.id] = "";
            }
          });
          
          console.log("Initial repeatable items:", initialRepeatableItems);
          setRepeatableItems(initialRepeatableItems);
          
          // Always include email in responses
          initialResponses.email = "";
          
          console.log("Initial responses:", initialResponses);
          setResponses(initialResponses);
        } else {
          alert("Form not found!");
        }
      } catch (error) {
        console.error("Error fetching form:", error);
        alert("Error loading form");
        navigate("/"); // Redirect on error
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, navigate]);

  // Enhance the handleChange function to better log time questions

const handleChange = (questionId: string, value: any) => {
  // Add logging for time questions to help diagnose the issue
  if (form?.questions?.some((q: { id: string; type: string; }) => q.id === questionId && q.type === "time")) {
    console.log(`TimeQuestion update (${questionId}):`, value);
  }
  
  setResponses((prev) => ({
    ...prev,
    [questionId]: value,
  }));
};

  const handleFollowUpChange = (id: string, value: string) => {
    setFollowUpResponses(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleImageUpload = async (questionId: string, file: File | null) => {
    if (!file) {
      setResponses((prev) => ({
        ...prev,
        [questionId]: null,
      }));
      setImagePreview((prev) => {
        const newPreviews = { ...prev };
        delete newPreviews[questionId];
        return newPreviews;
      });
      return;
    }

    try {
      // Convert image to base64 for preview and submission
      const base64Image = await fileToBase64(file);
      
      setResponses((prev) => ({
        ...prev,
        [questionId]: base64Image,
      }));
      
      if (base64Image) {
        setImagePreview((prev) => ({
          ...prev,
          [questionId]: base64Image,
        }));
      }
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  const handleAddRepeatableItem = (questionId: string, subFields: Array<any>) => {
    setRepeatableItems(prev => ({
      ...prev,
      [questionId]: [...(prev[questionId] || []), createEmptyRepeatableItem(subFields)]
    }));
  };

  const handleRemoveRepeatableItem = (questionId: string, index: number) => {
    setRepeatableItems(prev => ({
      ...prev,
      [questionId]: prev[questionId].filter((_, i) => i !== index)
    }));
  };

  const handleRepeatableItemChange = (questionId: string, itemIndex: number, fieldId: string, value: any) => {
    setRepeatableItems(prev => {
      const updatedItems = [...prev[questionId]];
      updatedItems[itemIndex] = { 
        ...updatedItems[itemIndex],
        [fieldId]: value 
      };
      return {
        ...prev,
        [questionId]: updatedItems
      };
    });
  };

  // Replace this entire handleSubmit function with this fixed version:
const handleSubmit = async () => {
  // Prevent multiple submissions
  if (isSubmitting) return;
  
  console.log("Starting form submission process");
  if (!form) return;
  if (!formId) {
    console.error("Cannot submit without a valid formId");
    alert("Form ID is missing. Please reload the page.");
    return;
  }

  // First, validate the email address
  if (!responses.email || !isValidEmail(responses.email)) {
    alert("Please provide a valid email address.");
    return;
  }

  // 1. Validate form responses
  const requiredQuestions = form.questions.filter((q: any) => q.required);
  for (const question of requiredQuestions) {
    if (question.type === "checkbox" && question.checkboxStyle === "yes-no") {
      if (!responses[question.id]) {
        alert(`Please answer the required question: ${question.label}`);
        return;
      }
      
      if (responses[question.id] === "no" && 
          question.noFollowUp?.required && 
          !responses[`${question.id}-followup`]) {
        alert(`Please provide follow-up information for: ${question.label}`);
        return;
      }
    } else if (!responses[question.id]) {
      alert(`Please fill out the required question: ${question.label}`);
      return;
    }
  }

  // Ensure email address is captured
  if (!responses["email"]) {
    alert("Please provide your email address.");
    return;
  }

  // Set submitting state to true
  setIsSubmitting(true);
  
  // 2. Start submission process
  setSnackbarOpen(true);
  
  // 3. Generate PDF
  const mergedSignatures = { ...signatures };
  
  // Ensure all signatures are in the mergedSignatures object
  form.questions.forEach((q: any) => {
    if (q.type === "signature") {
      if (responses[q.id] && typeof responses[q.id] === 'string' && responses[q.id].startsWith('data:image')) {
        mergedSignatures[q.id] = responses[q.id];
      } else if (signatures[q.id]) {
        mergedSignatures[q.id] = signatures[q.id];
      }
    }
  });
  
  // Log the repeatableItems to debug
  console.log("Submitting with repeatableItems:", repeatableItems);

  // Generate PDF - ensure formId is not undefined
  const doc = generatePdf(
    formId || '',
    form,
    prepareQuestionDataForPDF(),  // Use the merged responses here
    repeatableItems, // Make sure this is passed correctly
    mergedSignatures,
    imagePreview,
    annotatedImages,
    logoUrl,
    theme
  );

  try {
    const pdfBlob = doc.output('blob');
    
    // Convert PDF blob to base64 string for email attachment
    const pdfBase64 = await blobToBase64(pdfBlob);
    
    // Prepare form filename - DEFINE IT ONCE HERE
    const formTitleForFilename = form.formTitle 
      ? form.formTitle.replace(/[^a-z0-9]/gi, '_').toLowerCase() 
      : "filled_form";
    
    // 4. Record the submission in Firestore first
    let submissionId = null;
    try {
      console.log("Recording form submission for form:", formId);
      
      if (!formId) {
        console.error("Missing formId for submission");
        throw new Error("Cannot submit form without a valid form ID");
      }
      
      // Merge follow-up responses and comments into the main responses object
      const mergedResponses = { ...responses };
      Object.keys(followUpResponses).forEach(key => {
        mergedResponses[key] = followUpResponses[key];
      });
      
      // Log repeatableItems before submitting
      console.log("Submitting repeatableItems:", repeatableItems);
      
      // Pass repeatableItems as the fourth parameter
      submissionId = await recordFormSubmission(
        formId, 
        mergedResponses.email, 
        mergedResponses, 
        repeatableItems // Add this parameter
      );
      console.log("Submission recorded with ID:", submissionId);
    } catch (recordError) {
      console.error("Error recording submission:", recordError);
      // Don't throw here - continue to sending email even if recording fails
    }
    
    // 5. Send email with PDF attachment (regardless of Firestore success)
    console.log("Sending email to form creator:", form.email);
    
    // Prepare attachments array - start with the PDF
    const attachments = [{
      filename: `${formTitleForFilename}.pdf`,
      content: pdfBase64.split('base64,')[1], // Remove data:application/pdf;base64, prefix
      encoding: 'base64',
      type: 'application/pdf'
    }];
    
    // Process file uploads and add them to attachments
    const fileQuestions = form.questions.filter((q: any) => q.type === "file");
    if (fileQuestions.length > 0) {
      console.log("Processing file attachments for email");
      
      // For each file question, add its file to attachments if present
      for (const fileQ of fileQuestions) {
        const file = fileUploads[fileQ.id];
        if (file) {
          try {
            // Convert file to base64
            const fileBase64 = await blobToBase64(file);
            // Extract the base64 data without the prefix
            const base64Data = fileBase64.split('base64,')[1];
            
            // Add to attachments
            attachments.push({
              filename: file.name,
              content: base64Data,
              encoding: 'base64',
              type: file.type || 'application/octet-stream'
            });
            
            console.log(`Added file attachment: ${file.name} (${file.type})`);
          } catch (error) {
            console.error(`Error processing file attachment ${file.name}:`, error);
          }
        }
      }
    }
    
    // Prepare recipient list - start with form creator's email
    let recipients = form.email;
    
    // Add user's email if they opted in to receive a PDF copy
    if (sendPdfToUser && responses.email) {
      // If multiple recipients, separate with commas
      recipients = `${recipients},${responses.email}`;
      console.log(`User opted to receive PDF copy at ${responses.email}`);
    }
    
    // Then modify the sendEmail call to include all attachments and recipients:
    const emailResult = await sendEmail(
      recipients, // Now includes user email if they opted in
      `Form Submission: ${form.formTitle || 'Untitled Form'}`,
      `A submission was received for your form "${form.formTitle || 'Untitled Form'}" from ${responses.email}.`,
      attachments // Now includes PDF and file attachments
    );
    
    // Store the PDF data in sessionStorage regardless of email success
    sessionStorage.setItem('pdfBlobUrl', URL.createObjectURL(pdfBlob));
    sessionStorage.setItem('formTitle', formTitleForFilename);
    sessionStorage.setItem('formSubmitted', 'true');
    sessionStorage.setItem("formId", formId || '');
    sessionStorage.setItem('approvalRequired', form?.requiresApproval ? 'true' : 'false');
    
    // Check if we need to show any errors before navigating
    let hasErrors = false;
    
    // Check if email send failed
    if (!emailResult.success) {
      hasErrors = true;
      console.error("Email sending failed:", emailResult.error);
      
      setConfirmMessage("Your form was submitted successfully, but we couldn't send the confirmation email. Would you like to download a PDF copy?");
      
      setConfirmAction(() => {
        return () => {
          // Download PDF and navigate
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = `${formTitleForFilename}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // Navigate to confirmation page anyway
          window.location.href = "/confirmation";
        };
      });
      
      setConfirmDialogOpen(true);
    }
    
    // Check if submission recording failed but we didn't already show an error dialog
    if (!submissionId && !hasErrors) {
      // Instead of showing an error dialog right away, check if it's just a submission_details error
      // We consider the submission successful as long as the base record is created
      console.log("Form recording partially failed but email was sent");
      
      // Just log it, don't show an error dialog
      // setConfirmMessage("...");
      // setConfirmAction(() => { ... });
      // setConfirmDialogOpen(true);
      
      // Instead, just navigate directly to the confirmation page
      console.log("Form submission successful despite detail errors - navigating to confirmation page");
      window.location.href = "/confirmation";
    }
    
    // If everything worked fine (no errors), navigate directly to confirmation page
    if (!hasErrors) {
      console.log("Form submission successful - navigating to confirmation page");
      window.location.href = "/confirmation";
    }
    
    // Add this right before the final navigation
    console.log({
      emailResult,
      submissionId,
      hasErrors
    });
    
  } catch (error) {
    console.error("Form submission error:", error);
    
    // Get the PDF blob again if needed
    const pdfBlob = doc.output('blob');
    const formTitleForFilename = form.formTitle 
      ? form.formTitle.replace(/[^a-z0-9]/gi, '_').toLowerCase() 
      : "filled_form";
    
    // Determine which error message to show based on the error type
    if (error instanceof Error && error.message.includes('Email')) {
      setConfirmMessage("Your form was submitted successfully, but we couldn't send the confirmation email. Would you like to download a PDF copy?");
    } else {
      setConfirmMessage("The form was submitted, but we had trouble recording your response. Would you like to download a PDF copy?");
    }
    
    setConfirmAction(() => {
      return () => {
        // Download PDF and navigate
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${formTitleForFilename}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Navigate to confirmation page anyway
        window.location.href = "/confirmation";
      };
    });
    
    setConfirmDialogOpen(true);
  }
};

// Add this helper function to convert blob to base64
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => reject(new Error("Failed to convert blob to base64"));
    reader.readAsDataURL(blob);
  });
};

// 2. Add this single implementation of the sendEmail function (place it with your other functions)
const sendEmail = async (email: string, subject: string, text: string, attachments?: any[]) => {
  try {
    console.log('Sending email to:', email);
    
    // Send using the API
    const response = await fetch('/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        subject,
        text,
        html: `<h1>${subject}</h1><p>${text}</p>`,
        attachments // Add attachments to request
      })
    });
    
    const data = await response.json();
    console.log('Email API response:', data);
    
    if (!response.ok) {
      throw new Error(data.error || data.details || 'Failed to send email');
    }
    
    return { success: true, data };
  } catch (error) {
    console.error('Email sending error:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Unknown error'
    };
  }
};

// 3. Add a separate test function for the test button
const testEmailApi = async () => {
  try {
    // Simple test email
    const response = await fetch('/api/send-email?test=true&email=' + responses.email);
    const data = await response.json();
    console.log('Test email API response:', data);
    
    if (response.ok) {
      alert('Test email sent successfully!');
    } else {
      alert('Test email failed: ' + (data.error || 'Unknown error'));
    }
  } catch (error) {
    console.error('Error testing email:', error);
    alert('Error testing email: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
};

  // Define valid question types
  type QuestionType = 'text' | 'number' | 'date' | 'long-text' | 'checkbox' | 'select' | 'multi-select' | 'image' | 'instructions' | 'signature' | 'repeatable' | 'section' | 'date-range' | 'file' | 'time';
  
  // Create a mapping of question types to components
  const questionComponents: Record<QuestionType, React.ComponentType<any>> = {
    "text": TextQuestion,
    "number": NumberQuestion,
    "date": DateQuestion,
    "long-text": LongTextQuestion,
    "checkbox": CheckboxQuestion,
    "select": SelectQuestion,
    "multi-select": MultiSelectQuestion,
    "image": ImageQuestion,
    "instructions": InstructionsBlock,
    "signature": SignatureQuestion,
    "repeatable": RepeatableQuestion,
    "section": SectionQuestion, // Add this line
    "date-range": DateRangeQuestion, // Add this line
    "file": FileQuestion,
    "time": TimeQuestion  // Add this new line
  };

  // Track current section for rendering
  let currentSectionId: string | null = null;
  let sectionsContent: { [sectionId: string]: React.ReactNode[] } = {};
  let standaloneContent: React.ReactNode[] = [];

  // Helper function to render question elements with appropriate props
  const renderQuestionElement = (q: any) => {
    const QuestionComponent = questionComponents[q.type as QuestionType];
    if (!QuestionComponent) return null;
    
    // Pass specific props based on question type
    if (q.type === "repeatable") {
      // Remove debug logs or make them less frequent
      // console.log(`Rendering repeatable question ${q.id}:`, {
      //   hasItems: Boolean(repeatableItems[q.id]),
      //   itemCount: repeatableItems[q.id]?.length || 0,
      //   subFields: q.subFields
      // });
      
      return (
        <QuestionComponent
          key={q.id}
          question={q}
          repeatableItems={repeatableItems[q.id] || []}
          onAddItem={() => handleAddRepeatableItem(q.id, q.subFields || [])}
          onRemoveItem={(index: number) => handleRemoveRepeatableItem(q.id, index)}
          onItemChange={(itemIndex: number, fieldId: string, value: any) => 
            handleRepeatableItemChange(q.id, itemIndex, fieldId, value)
          }
        />
      );
    } else if (q.type === "image") {
      return (
        <QuestionComponent
          key={q.id}
          question={q}
          value={responses[q.id]}
          onChange={(file: File | null) => handleImageUpload(q.id, file)}
          imagePreview={imagePreview[q.id]}
          onAnnotate={() => setAnnotatingImage(q.id)}
          isAnnotated={Boolean(annotatedImages[q.id])}
        />
      );
    } else if (q.type === "signature") {
      return (
        <QuestionComponent
          key={q.id}
          question={q}
          value={responses[q.id]}
          onChange={(value: any) => handleChange(q.id, value)}
          onClear={() => clearSignature(q.id)}
          sigCanvasRef={(ref: SignatureCanvas | null) => {
            if (!sigCanvasRefs.current) sigCanvasRefs.current = {};
            sigCanvasRefs.current[q.id] = ref;
          }}
          onEnd={() => {
            // This is the critical part for signature capture
            const canvas = sigCanvasRefs.current[q.id];
            if (canvas && !canvas.isEmpty()) {
              // Get signature as PNG with high quality
              const signatureData = canvas.toDataURL("image/png", 1.0);
              
              // IMPORTANT: Update both signatures state AND responses state
              handleSignatureEnd(q.id, signatureData);
              
              // This is the crucial line that was missing - update responses too
              setResponses(prev => ({
                ...prev,
                [q.id]: signatureData
              }));
              
              console.log(`Signature captured and saved for ${q.id}: ${signatureData.substring(0, 30)}...`);
            }
          }}
        />
      );
    } else if (q.type === "file") {
      return (
        <FileQuestion
          key={q.id}
          question={q}
          value={fileUploads[q.id] || null}
          onChange={async (file) => {
            if (file) {
              // Update fileUploads state with the File object
              setFileUploads(prev => ({ ...prev, [q.id]: file }));
              
              // Also store file metadata in responses for submission
              try {
                // Get base64 content for the response object
                const fileBase64 = await getFileAsBase64(file);
                setResponses(prev => ({ 
                  ...prev, 
                  [q.id]: {
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size,
                    fileContent: fileBase64
                  }
                }));
              } catch (error) {
                console.error("Error processing file for response:", error);
              }
            } else {
              // Clear both states when file is removed
              setFileUploads(prev => {
                const updated = { ...prev };
                delete updated[q.id];
                return updated;
              });
              
              setResponses(prev => ({
                ...prev,
                [q.id]: null
              }));
            }
          }}
        />
      );
    } else if (q.type === "select") {
      // Always render the select question, but DON'T render follow-ups here
      // They'll be handled internally by the SelectQuestion component
      return (
        <SelectQuestion 
          key={q.id}
          question={q}
          value={responses[q.id] || ""}
          onChange={(value) => handleChange(q.id, value)}
          onFollowUpChange={handleFollowUpChange}
          followUpValues={followUpResponses}
        />
      );
    } else if (q.type === "multi-select") {
      // IMPORTANT: Always render the multi-select question, regardless of follow-ups
      return (
        <MultiSelectQuestion 
          key={q.id}
          question={q}
          value={responses[q.id] || []}
          onChange={(value) => handleChange(q.id, value)}
          onFollowUpChange={handleFollowUpChange}
          followUpValues={followUpResponses}
        />
      );
    } else if (q.type === "checkbox") {
      return (
        <CheckboxQuestion
          key={q.id}
          question={q}
          value={responses[q.id] || ""}
          onChange={(value: any) => handleChange(q.id, value)}
          onFollowUpChange={handleFollowUpChange}
          followUpValues={followUpResponses}
        />
      );
    } else {
      return (
        <QuestionComponent
          key={q.id}
          question={q}
          value={responses[q.id] || ""}
          onChange={(value: any) => handleChange(q.id, value)}
        />
      );
    }
  };

  // Instead of the current content organization approach, use this function
  const organizeSectionsAndQuestions = () => {
    const standaloneQuestions: React.ReactNode[] = [];
    const sections: React.ReactNode[] = [];
    let sectionCounter = 1;
  
    // Process all questions to identify sections and standalone questions
    form?.questions.forEach((q: any) => {
      if (q.type === "section") {
        // Process section questions
        const sectionQuestionElements: React.ReactNode[] = [];
        
        // Ensure sectionQuestions is an array before processing
        const sectionQuestions = Array.isArray(q.sectionQuestions) ? q.sectionQuestions : [];
        
        // Render each question in this section
        sectionQuestions.forEach((sectionQ: any) => {
          const questionElement = renderQuestionElement(sectionQ);
          if (questionElement) {
            sectionQuestionElements.push(questionElement);
          }
        });
        
        // Create the section with its questions
        sections.push(
          <SectionQuestion 
            key={q.id} 
            question={{
              ...q,
              // Ensure all required props are strings
              label: String(q.label || 'Section'),
              description: q.description ? String(q.description) : undefined,
            }}
            sectionIndex={sectionCounter++}
          >
            {sectionQuestionElements}
          </SectionQuestion>
        );
      } else {
        // This is a standalone question (not in a section)
        const questionElement = renderQuestionElement(q);
        if (questionElement) {
          standaloneQuestions.push(questionElement);
        }
      }
    });
  
    return { standaloneQuestions, sections };
  };
  
  // Replace the renderContent function with this:
  const renderContent = () => {
    // Don't use separate arrays for standalone questions and sections
    // Instead use a single array to maintain the original order
    const orderedContent: React.ReactNode[] = [];
    let sectionCounter = 1;
    
    if (!form || !form.questions) {
      return <Typography>No questions found</Typography>;
    }
    
    // Process every question in the original order
    form.questions.forEach((q: any) => {
      if (q.type === "section") {
        // Process section questions
        const sectionQuestionElements: React.ReactNode[] = [];
        
        // Ensure sectionQuestions is an array before processing
        const sectionQuestions = Array.isArray(q.sectionQuestions) ? q.sectionQuestions : [];
        
        // Render each question in this section
        sectionQuestions.forEach((sectionQ: any) => {
          const questionElement = renderQuestionElement(sectionQ);
          if (questionElement) {
            sectionQuestionElements.push(questionElement);
          }
        });
        
        // Create the section with its questions
        orderedContent.push(
          <SectionQuestion 
            key={q.id} 
            question={{
              ...q,
              // Ensure all required props are strings
              label: String(q.label || 'Section'),
              description: q.description ? String(q.description) : undefined,
            }}
            sectionIndex={sectionCounter++}
          >
            {sectionQuestionElements}
          </SectionQuestion>
        );
      } else {
        // This is a standalone question (not in a section)
        const questionElement = renderQuestionElement(q);
        if (questionElement) {
          orderedContent.push(questionElement);
        }
      }
    });
    
    return orderedContent;
  };

  const handleGeneratePdf = async () => {
    if (!form) return;
    
    setGeneratingPdf(true);
    try {
      const doc = await generatePdf(
        formId || '',
        form,
        prepareQuestionDataForPDF(),  // Use the merged responses here
        repeatableItems,
        signatures,
        imagePreview,
        annotatedImages,
        logoUrl,
        theme
      );
      
      // Use form title for filename, fallback to "filled_form" if no title
      const filename = form.formTitle ? 
        form.formTitle
          .replace(/[^a-z0-9]/gi, '_')  // Replace special chars with underscore
          .toLowerCase()
          .trim() + ".pdf" : 
        "filled_form.pdf";
      
      doc.save(filename);
      setGeneratingPdf(false);
    } catch (error) {
      console.error("PDF generation error:", error);
      setGeneratingPdf(false);
    }
  };

  // Add this function to your FillForm component

// Helper function to prepare question data for PDF generation
const prepareQuestionDataForPDF = () => {
  // Start with a deep copy of regular responses
  const mergedResponses = { ...responses };
  
  // Merge in all follow-up responses
  Object.keys(followUpResponses).forEach(key => {
    mergedResponses[key] = followUpResponses[key];
  });
  
  console.log("Prepared data for PDF generation:", {
    responses: mergedResponses,
    repeatableItems: repeatableItems
  });
  
  return mergedResponses;
};

// Then in your generatePdf or similar function:
const handleGeneratePDF = () => {
  const mergedResponses = prepareQuestionDataForPDF();
  
  // Now generate the PDF with the merged data
  const doc = generatePdf(
    form.id,
    form,
    mergedResponses, // Use the merged responses here
    repeatableItems,
    signatures,
    imagePreview,
    annotatedImages, 
    logoUrl,
    theme
  );
  
  // Rest of PDF handling code...
};

  return (
    <ThemeProvider theme={theme}>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="container mx-auto px-4 max-w-5xl">
          {/* Company logo and form header */}
          <div className="bg-white shadow-md rounded-t-lg p-6 border-b-4" style={{ borderColor: theme.palette.primary.main }}>
            <div className="flex justify-between items-center">
              <Typography variant="h4" sx={{ fontWeight: 600, color: '#333' }}>
                {form?.formTitle || "Fill Form"}
              </Typography>

              {form?.userId && logoUrl && (
                <div className="company-logo">
                  <img 
                    src={logoUrl} 
                    alt="Company Logo" 
                    className="max-h-16 max-w-xs object-contain"
                  />
                </div>
              )}
            </div>
            
            {form?.description && (
              <Typography variant="subtitle1" sx={{ mt: 2, color: '#666' }}>
                {form.description}
              </Typography>
            )}
          </div>

          {/* Loading state */}
          {loading ? (
            <div className="bg-white shadow-md p-8 text-center">
              <Typography>Loading form...</Typography>
            </div>
          ) : (
            /* Form content */
            <div className="bg-white shadow-md rounded-b-lg p-6">
              <div className="space-y-8">
                {/* Email Address Field */}
                <div className="p-6 border border-gray-200 rounded-lg hover:border-gray-300 transition-colors">
                  <div className="mb-4">
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 500, 
                        display: 'flex',
                        alignItems: 'center',
                        '&::after': {
                          content: '"*"',
                          color: 'red',
                          ml: 0.5
                        }
                      }}
                    >
                      Email Address
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                      Please provide your email address.
                    </Typography>
                  </div>
                  <TextField 
                    fullWidth 
                    value={responses.email || ""} 
                    onChange={(e) => handleChange("email", e.target.value)} 
                    required
                    error={responses.email && !isValidEmail(responses.email)}
                    helperText={responses.email && !isValidEmail(responses.email) ? "Please enter a valid email address" : ""}
                    type="email"
                    inputProps={{
                      autoComplete: "email"
                    }}
                  />
                </div>

                {/* Render questions dynamically */}
                {renderContent()}
              </div>

              {/* Separate dialog component outside the question mapping */}
              <Dialog 
                open={Boolean(annotatingImage)} 
                onClose={() => setAnnotatingImage(null)}
                fullWidth
                maxWidth="md"
                // Remove any aria-hidden properties
                keepMounted={false} // This prevents hidden dialogs from maintaining focus
                disablePortal={false} // Makes sure dialog is properly portaled
              >
                <AppBar position="relative">
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setAnnotatingImage(null)} aria-label="close">
                      <Close />
                    </IconButton>
                    <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
                      Annotate Image
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {
                      try {
                        // Find the canvas element - try different selector strategies
                        const canvasElements = document.querySelectorAll('canvas');
                        
                        // Use the last canvas element which is likely the one with the annotation
                        const canvasElement = canvasElements[canvasElements.length - 1];
                        
                        if (canvasElement) {
                          // Convert canvas to data URL
                          const annotatedDataUrl = canvasElement.toDataURL('image/png');
                          
                          // Debug log to verify we're getting data
                          console.log("Canvas data captured successfully");
                          
                          // Update image preview FIRST
                          setImagePreview(prev => ({
                            ...prev,
                            [annotatingImage!]: annotatedDataUrl
                          }));
                          
                          // Then update the annotated images collection
                          setAnnotatedImages(prev => ({
                            ...prev,
                            [annotatingImage!]: annotatedDataUrl
                          }));
                          
                          // Also update the responses to include this image
                          setResponses(prev => ({
                            ...prev,
                            [annotatingImage!]: annotatedDataUrl
                          }));
                          
                          // Close dialog AFTER all state updates
                          setTimeout(() => setAnnotatingImage(null), 100);
                        } else {
                          console.error("Could not find canvas element");
                          alert("Could not save annotation. Please try again.");
                        }
                      } catch (error) {
                        console.error("Error saving annotation:", error);
                        alert("Error saving annotation. Please try again.");
                      }
                    }}>
                      Save
                    </Button>
                  </Toolbar>
                </AppBar>
                
                <Box className="p-4">
                  {annotatingImage && (
                    <>
                      {/* Responsive container for the painter */}
                      <Box 
                        className="painter-container" 
                        sx={{ 
                          width: '100%',
                          height: { xs: '300px', sm: '400px', md: '500px' },
                          overflow: 'hidden',
                          touchAction: 'none' // This prevents touch events from causing page scroll/move
                        }}
                      >
                        <Painter
                          ref={painterRef}
                          width={500}
                          height={500}
                          image={imagePreview[annotatingImage]}
                          onSave={async (blob) => {
                            try {
                              const reader = new FileReader();
                              reader.readAsDataURL(blob);
                              reader.onload = () => {
                                const base64data = reader.result as string;
                                
                                setAnnotatedImages(prev => ({
                                  ...prev,
                                  [annotatingImage!]: base64data
                                }));
                                
                                setResponses(prev => ({
                                  ...prev,
                                  [annotatingImage!]: base64data
                                }));
                              };
                            } catch (error) {
                              console.error("Error in onSave handler:", error);
                            }
                          }}
                        />
                      </Box>
                      
                      {/* Mobile instructions */}
                      <Box sx={{ mt: 2, display: { xs: 'block', sm: 'none' } }}>
                        <Typography variant="caption" color="text.secondary" align="center" display="block">
                          Tap and drag to draw. Pinch to zoom is disabled while annotating.
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Dialog>

              {/* Custom Confirm Dialog */}
              <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Email Delivery Issue</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {confirmMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button 
                    onClick={() => {
                      setConfirmDialogOpen(false);
                      navigate("/");  // Go home
                    }} 
                    color="primary"
                  >
                    Return Home
                  </Button>
                  <Button 
                    onClick={() => setConfirmDialogOpen(false)}
                    color="primary"
                  >
                    Stay on Form
                  </Button>
                  <Button 
                    onClick={() => {
                      setConfirmDialogOpen(false);
                      confirmAction();  // Download PDF and continue
                    }} 
                    color="primary" 
                    variant="contained"
                    autoFocus
                  >
                    Download PDF & Continue
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Download Dialog */}
              <Dialog
                open={downloadDialogOpen}
                onClose={() => setDownloadDialogOpen(false)}
              >
                <DialogTitle>Download Form</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Your form has been submitted, but some images were excluded from the email due to size limits. Would you like to download the complete version with all images?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDownloadDialogOpen(false)} color="primary">
                    No Thanks
                  </Button>
                  <Button 
                    onClick={() => {
                      if (downloadUrl) {
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = downloadFilename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }
                      setDownloadDialogOpen(false);
                      setTimeout(() => navigate("/confirmation"), 1000);
                    }} 
                    color="primary" 
                    autoFocus
                  >
                    Download
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Submit Button with improved styling */}
              <div className="mt-8 pt-6 border-t border-gray-200">
                {/* Only show PDF copy option if allowed by form creator */}
{form?.allowPdfCopy && (
  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor: 'rgba(0,0,0,0.03)', 
        p: 1.5, 
        borderRadius: 1,
        maxWidth: '600px'
      }}
    >
      <input
        type="checkbox"
        id="send-pdf"
        checked={sendPdfToUser}
        onChange={(e) => setSendPdfToUser(e.target.checked)}
        style={{ marginRight: '10px' }}
      />
      <label htmlFor="send-pdf">
        <Typography variant="body2">
          Send me a PDF copy of this submission to my email address
        </Typography>
      </label>
    </Box>
  </Box>
)}
                
                <div className="flex justify-center space-x-4">
                  <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    size="large"
                    disabled={isSubmitting}
                    sx={{ 
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.dark',
                      },
                      py: 1.5,
                      px: 5,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1rem'
                    }}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Form'}
                  </Button>
                </div>
                {form?.footerText && (
                  <Typography 
                    variant="body2" 
                    align="center" 
                    color="text.secondary"
                    sx={{ mt: 3 }}
                  >
                    {form.footerText}
                  </Typography>
                )}
              </div>
            </div>
          )}
          
          {/* Snackbar for notifications */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
              Form submitted successfully!
            </Alert>
          </Snackbar>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FillForm;

// Email validation helper function
function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Keep only this single implementation of fileToBase64
function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
    });
  }

// Add this helper function to get file content as base64
const getFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
};

// Commented out duplicated handleSubmit function that was causing errors
// This was likely meant as a comment/example and not actual code
