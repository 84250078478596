import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Chip, 
  Grid, 
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

interface TextVisualizationProps {
  question: any;
  summaryData: any;
  filteredSubmissions?: any[];
}

const TextVisualization: React.FC<TextVisualizationProps> = ({
  question,
  summaryData,
  filteredSubmissions = []
}) => {
  const theme = useTheme();
  const [showAllWords, setShowAllWords] = useState(false);
  const [textResponses, setTextResponses] = useState<string[]>([]);
  const [showAllResponses, setShowAllResponses] = useState(false);
  const [expandedResponse, setExpandedResponse] = useState<string | null>(null);
  
  // Improved data extraction that handles more cases
  useEffect(() => {
    console.log(`TextVisualization for ${question.id}:`, {
      questionType: question.type,
      hasSummaryData: !!summaryData,
      submissionCount: filteredSubmissions.length,
      textResponsesFromSummary: summaryData?.textResponses?.length || 0
    });
    
    // Function to extract all possible text responses based on question ID
    const extractTextResponses = () => {
      const responses: string[] = [];
      
      // Try to use textResponses from summaryData if available and valid
      if (summaryData?.textResponses && Array.isArray(summaryData.textResponses)) {
        return summaryData.textResponses;
      }
      
      // Otherwise, try to extract from filtered submissions
      if (filteredSubmissions && filteredSubmissions.length > 0) {
        filteredSubmissions.forEach(submission => {
          if (!submission.responses) return;
          
          // Check different possible key patterns
          const possibleKeys = [
            question.id,
            `section_q_${question.sectionId}_${question.id}`,
            // Add any other patterns here
          ];
          
          for (const key of possibleKeys) {
            if (submission.responses[key] !== undefined) {
              let value = submission.responses[key];
              
              // Convert different value types to string
              if (value === null) continue;
              
              if (typeof value === 'string') {
                if (value.trim() !== '') responses.push(value);
              } else if (typeof value === 'number' || typeof value === 'boolean') {
                responses.push(String(value));
              } else if (Array.isArray(value)) {
                if (value.length > 0) responses.push(value.join(', '));
              } else if (typeof value === 'object') {
                try {
                  // Try to convert object to string representation
                  responses.push(JSON.stringify(value));
                } catch (e) {
                  console.warn('Failed to stringify object response:', e);
                }
              }
              
              // Found a response, so break to avoid duplicates
              break;
            }
          }
        });
      }
      
      return responses;
    };
    
    // Set the text responses
    const extractedResponses = extractTextResponses();
    console.log(`Found ${extractedResponses.length} text responses for ${question.id}`);
    setTextResponses(extractedResponses);
  }, [question, summaryData, filteredSubmissions]);
  
  if (textResponses.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        No text responses in current filter selection
      </Typography>
    );
  }
  
  // Update the calculateResponseFrequency function to better normalize responses
  const calculateResponseFrequency = () => {
    // Create a map to track original texts to their normalized versions
    const normalizedToOriginalMap = new Map<string, string>();
    const frequencyMap: Record<string, number> = {};
    
    textResponses.forEach(response => {
      if (!response) return; // Skip empty responses
      
      // Normalize the response for comparison
      const normalizedResponse = normalizeTextForComparison(response);
      
      // If this normalized form hasn't been seen before, store the original as reference
      if (!normalizedToOriginalMap.has(normalizedResponse)) {
        // Store the first occurrence of this text (preserving original casing)
        normalizedToOriginalMap.set(normalizedResponse, response);
      }
      
      // Count this normalized response
      frequencyMap[normalizedResponse] = (frequencyMap[normalizedResponse] || 0) + 1;
    });
    
    // Convert to array for sorting, using the original text for display
    return Object.entries(frequencyMap)
      .sort((a, b) => b[1] - a[1]) // Sort by frequency descending
      .map(([normalizedText, count]) => ({ 
        text: normalizedToOriginalMap.get(normalizedText) || normalizedText,
        normalizedText,
        count 
      }));
  };
  
  // Add this helper function to normalize text for comparison
  const normalizeTextForComparison = (text: string): string => {
    if (!text) return '';
    
    return text
      .trim()                         // Remove leading/trailing whitespace
      .toLowerCase()                  // Convert to lowercase
      .replace(/\s+/g, ' ')          // Normalize whitespace (multiple spaces to single space)
      .replace(/[^\w\s]/g, '')       // Remove punctuation
      .replace(/\d+/g, num => num);  // Keep numbers as is
  };
  
  // Update the unique responses calculation to use the normalized text
  const calculateTextStats = () => {
    const totalResponses = textResponses.length;
    
    // Count unique responses using normalized text for comparison
    const normalizedResponses = textResponses.map(normalizeTextForComparison);
    const uniqueResponses = new Set(normalizedResponses).size;
    
    // Rest of the function remains the same...
    
    // Calculate average word count
    const wordCounts = textResponses.map((text: string) => {
      const words = text.split(/\s+/).filter(word => word.length > 0);
      return words.length;
    });
    
    const totalWords = wordCounts.reduce((sum: number, count: number) => sum + count, 0);
    const avgWordCount = totalWords / totalResponses;
    
    // Calculate average character count
    const charCounts = textResponses.map((text: string) => text.length);
    const totalChars = charCounts.reduce((sum: number, count: number) => sum + count, 0);
    const avgCharCount = totalChars / totalResponses;
    
    // Find longest and shortest responses
    const longestResponse = Math.max(...charCounts);
    const shortestResponse = Math.min(...charCounts);
    
    return {
      totalResponses,
      uniqueResponses,
      totalWords,
      avgWordCount,
      avgCharCount,
      longestResponse,
      shortestResponse
    };
  };
  
  // Calculate word frequencies
  const calculateWordFrequencies = () => {
    const stopWords = new Set([
      'a', 'an', 'the', 'and', 'or', 'but', 'is', 'are', 'was', 'were', 
      'in', 'on', 'at', 'to', 'for', 'with', 'by', 'about', 'as', 'of',
      'that', 'this', 'these', 'those', 'it', 'they', 'them', 'their'
    ]);
    
    const wordFreq: Record<string, number> = {};
    
    textResponses.forEach((text: string) => {
      const words = text.toLowerCase()
        .replace(/[^\w\s]/g, '') // Remove punctuation
        .split(/\s+/)
        .filter((word: string) => word.length > 2 && !stopWords.has(word));
      
      words.forEach((word: string) => {
        wordFreq[word] = (wordFreq[word] || 0) + 1;
      });
    });
    
    return Object.entries(wordFreq)
      .sort((a, b) => b[1] - a[1])
      .slice(0, showAllWords ? 50 : 15);
  };
  
  const stats = calculateTextStats();
  const topWords = calculateWordFrequencies();
  const responsesWithFrequency = calculateResponseFrequency();
  
  // Decide how many top responses to show initially
  const initialResponsesCount = 5;
  const topResponses = responsesWithFrequency.slice(0, initialResponsesCount);
  const hasMoreResponses = responsesWithFrequency.length > initialResponsesCount;
  
  // Calculate the distribution of response lengths
  const shortResponses = textResponses.filter(r => r.length < 50).length;
  const mediumResponses = textResponses.filter(r => r.length >= 50 && r.length < 200).length;
  const longResponses = textResponses.filter(r => r.length >= 200).length;
  
  return (
    <Box>
      {/* Summary stats row */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6} sm={4}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Total Responses
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {stats.totalResponses}
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={4}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Unique Responses
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {stats.uniqueResponses}
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <Paper elevation={0} sx={{ 
            p: 1.5, 
            textAlign: 'center', 
            bgcolor: theme.palette.primary.main + '15',
            borderRadius: 2,
            border: `1px solid ${theme.palette.primary.main + '30'}`
          }}>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Avg. Words
            </Typography>
            <Typography variant="h6" color="primary.main" fontWeight="bold">
              {Math.round(stats.avgWordCount)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      
      {stats.uniqueResponses < stats.totalResponses && (
        <Box sx={{ 
          p: 1.5, 
          mb: 2, 
          bgcolor: theme.palette.info.light + '15', 
          border: `1px solid ${theme.palette.info.light}`,
          borderRadius: 1,
          fontSize: '0.875rem'
        }}>
          <Typography variant="body2" color="info.dark">
            <strong>Note:</strong> Similar responses with different capitalization or formatting 
            are grouped together. The most common format is displayed.
          </Typography>
        </Box>
      )}
      
      {/* Top Responses section - the key improvement */}
      <Typography variant="subtitle2" gutterBottom color="text.secondary">
        MOST COMMON RESPONSES
      </Typography>
      
      <Paper
        elevation={0}
        sx={{
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden',
          mb: 3
        }}
      >
        <List disablePadding dense>
          {topResponses.map(({ text, count }, index) => (
            <React.Fragment key={index}>
              <ListItem
                sx={{
                  bgcolor: index % 2 === 0 ? 'rgba(0,0,0,0.02)' : 'transparent',
                  transition: 'all 0.2s',
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.05)',
                  }
                }}
                onClick={() => setExpandedResponse(expandedResponse === text ? null : text)}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          fontWeight: count > 1 ? 'bold' : 'normal',
                          flexGrow: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: text.length > 100 ? 'normal' : 'nowrap',
                          maxHeight: text.length > 100 ? '2.4em' : 'auto',
                          cursor: 'pointer'
                        }}
                      >
                        {text.length > 120 && expandedResponse !== text 
                          ? text.substring(0, 120) + '...' 
                          : text}
                        {count > 1 && normalizeTextForComparison(text) !== text.toLowerCase().trim() && (
                          <Chip
                            size="small"
                            label="Variant formats"
                            sx={{ 
                              ml: 1, 
                              height: 18, 
                              fontSize: '10px',
                              bgcolor: theme.palette.warning.light + '30',
                              color: theme.palette.warning.dark
                            }}
                          />
                        )}
                      </Typography>
                      
                      <Chip 
                        label={count}
                        size="small"
                        sx={{ 
                          ml: 1, 
                          bgcolor: count > 1 
                            ? theme.palette.primary.main 
                            : theme.palette.grey[300],
                          color: count > 1 ? 'white' : 'text.primary',
                          minWidth: '35px'
                        }}
                      />
                    </Box>
                  }
                />
              </ListItem>
              
              {/* Expanded view for longer responses */}
              {expandedResponse === text && text.length > 120 && (
                <Box sx={{ 
                  p: 2, 
                  bgcolor: 'rgba(0,0,0,0.02)', 
                  borderBottom: `1px dashed ${theme.palette.divider}`
                }}>
                  <Typography variant="body2">
                    {text}
                  </Typography>
                </Box>
              )}
              
              {index < topResponses.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
          
          {/* Show more button only if there are more responses */}
          {hasMoreResponses && (
            <ListItem
              sx={{
                justifyContent: 'center',
                p: 1,
                bgcolor: 'rgba(0,0,0,0.02)'
              }}
            >
              <Button 
                size="small"
                onClick={() => setShowAllResponses(!showAllResponses)}
                startIcon={<FormatListBulletedIcon />}
                sx={{ textTransform: 'none' }}
              >
                {showAllResponses 
                  ? 'Show Less' 
                  : `Show ${responsesWithFrequency.length - initialResponsesCount} More Responses`}
              </Button>
            </ListItem>
          )}
        </List>
      </Paper>
      
      {/* Show all responses if expanded */}
      {showAllResponses && responsesWithFrequency.length > initialResponsesCount && (
        <Accordion
          elevation={0}
          sx={{
            mb: 3,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px !important',
            '&:before': { display: 'none' },
            overflow: 'hidden'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ 
              bgcolor: theme.palette.primary.main + '10',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <Typography variant="subtitle2">
              All {responsesWithFrequency.length} Responses
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List disablePadding dense>
              {responsesWithFrequency.slice(initialResponsesCount).map(({ text, count }, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    sx={{
                      bgcolor: index % 2 === 0 ? 'rgba(0,0,0,0.02)' : 'transparent',
                      transition: 'all 0.2s',
                      '&:hover': {
                        bgcolor: 'rgba(0,0,0,0.05)',
                      }
                    }}
                    onClick={() => setExpandedResponse(expandedResponse === text ? null : text)}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography 
                            variant="body2"
                            sx={{ 
                              fontWeight: count > 1 ? 'bold' : 'normal',
                              flexGrow: 1,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: text.length > 100 ? 'normal' : 'nowrap',
                              maxHeight: text.length > 100 ? '2.4em' : 'auto',
                              cursor: 'pointer'
                            }}
                          >
                            {text.length > 120 && expandedResponse !== text 
                              ? text.substring(0, 120) + '...' 
                              : text}
                            {count > 1 && normalizeTextForComparison(text) !== text.toLowerCase().trim() && (
                              <Chip
                                size="small"
                                label="Variant formats"
                                sx={{ 
                                  ml: 1, 
                                  height: 18, 
                                  fontSize: '10px',
                                  bgcolor: theme.palette.warning.light + '30',
                                  color: theme.palette.warning.dark
                                }}
                              />
                            )}
                          </Typography>
                          
                          <Chip 
                            label={count}
                            size="small"
                            sx={{ 
                              ml: 1, 
                              bgcolor: count > 1 
                                ? theme.palette.primary.main 
                                : theme.palette.grey[300],
                              color: count > 1 ? 'white' : 'text.primary',
                              minWidth: '35px'
                            }}
                          />
                        </Box>
                      }
                    />
                  </ListItem>
                  
                  {/* Expanded view for longer responses */}
                  {expandedResponse === text && text.length > 120 && (
                    <Box sx={{ 
                      p: 2, 
                      bgcolor: 'rgba(0,0,0,0.02)', 
                      borderBottom: `1px dashed ${theme.palette.divider}`
                    }}>
                      <Typography variant="body2">
                        {text}
                      </Typography>
                    </Box>
                  )}
                  
                  {index < responsesWithFrequency.slice(initialResponsesCount).length - 1 && 
                    <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      
      {/* Common words section - optional, could be in an accordion */}
      <Accordion 
        elevation={0}
        sx={{
          mb: 3,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px !important',
          '&:before': { display: 'none' },
          overflow: 'hidden'
        }}
      >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            bgcolor: theme.palette.primary.main + '10',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Typography variant="subtitle2">
            Word Analysis
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="subtitle2" gutterBottom color="text.secondary" sx={{ mt: 1 }}>
            MOST COMMON WORDS
          </Typography>
          
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            my: 2,
            minHeight: 60
          }}>
            {topWords.map(([word, count]) => (
              <Chip 
                key={word}
                label={`${word} (${count})`}
                size="small"
                sx={{ 
                  bgcolor: theme.palette.primary.main + (70 - Math.min(count * 5, 60)).toString(16),
                  color: count > 3 ? 'white' : 'text.primary',
                  fontWeight: count > 2 ? 'bold' : 'normal',
                }}
              />
            ))}
            
            {topWords.length === 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                No common words found
              </Typography>
            )}
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <Button
              size="small"
              onClick={() => setShowAllWords(!showAllWords)}
              sx={{ textTransform: 'none' }}
            >
              {showAllWords ? "Show Less" : "Show More Words"}
            </Button>
          </Box>
          
          {/* Response length distribution */}
          <Typography variant="subtitle2" gutterBottom color="text.secondary" sx={{ mt: 3 }}>
            RESPONSE LENGTH DISTRIBUTION
          </Typography>
          
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                textAlign: 'center', 
                border: `1px solid ${theme.palette.success.light}`,
                bgcolor: theme.palette.success.light + '10',
                borderRadius: 1
              }}>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Short (&lt;50 chars)
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="success.main">
                  {shortResponses} ({Math.round(shortResponses / stats.totalResponses * 100)}%)
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                textAlign: 'center', 
                border: `1px solid ${theme.palette.warning.light}`,
                bgcolor: theme.palette.warning.light + '10',
                borderRadius: 1
              }}>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Medium (50-200)
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="warning.main">
                  {mediumResponses} ({Math.round(mediumResponses / stats.totalResponses * 100)}%)
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={4}>
              <Paper elevation={0} sx={{ 
                p: 1, 
                textAlign: 'center', 
                border: `1px solid ${theme.palette.info.light}`,
                bgcolor: theme.palette.info.light + '10',
                borderRadius: 1
              }}>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Long (&gt;200 chars)
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="info.main">
                  {longResponses} ({Math.round(longResponses / stats.totalResponses * 100)}%)
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TextVisualization;