import { format } from 'date-fns';

interface PDFMediaRendererProps {
  doc: any;
  questionsToInclude: any[];
  responses: { [key: string]: any };
  currentY: number;
  primaryColorRgb: [number, number, number];
  blackTextColor: [number, number, number];
  safeText: (value: any) => string;
}

export const PDFMediaRenderer = ({
  doc,
  questionsToInclude,
  responses,
  currentY,
  primaryColorRgb,
  blackTextColor,
  safeText
}: PDFMediaRendererProps): number => {
  // Collect all image questions
  const imageQuestions = questionsToInclude.filter(q => {
    // Regular image questions
    if (q.type === "image" && responses[q.id]) {
      return true;
    }
    
    // Section image questions
    if (q.type === "section" && Array.isArray(q.sectionQuestions)) {
      return q.sectionQuestions.some((sectionQ: any) => 
        sectionQ.type === "image" && responses[sectionQ.id]);
    }
    
    return false;
  });
  
  // If no images, just return current Y position
  if (imageQuestions.length === 0) {
    return currentY;
  }
  
  // Check if we need a new page for images section
  if (currentY > doc.internal.pageSize.getHeight() - 60) {
    doc.addPage();
    currentY = 20;
  }
  
  // Add section title for images
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 10, 2, 2, "FD");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("IMAGES", doc.internal.pageSize.getWidth() / 2, currentY + 7, { align: "center" });
  currentY += 15;
  
  // Reset text color for content
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  
  // Collect all image questions including section images
  const allImageQuestions: any[] = [];
  
  // Add regular image questions
  questionsToInclude.forEach((q: any) => {
    if (q.type === "image" && responses[q.id]) {
      allImageQuestions.push(q);
    }
    
    // Add section image questions
    if (q.type === "section" && Array.isArray(q.sectionQuestions)) {
      q.sectionQuestions.forEach((sectionQ: any) => {
        if (sectionQ.type === "image" && responses[sectionQ.id]) {
          // Add section context to the label
          sectionQ.fullLabel = `${q.label} - ${sectionQ.label}`;
          allImageQuestions.push(sectionQ);
        }
      });
    }
  });
  
  // Add each image with optimization
  allImageQuestions.forEach((q: any, index: number) => {
    // Check if we need a new page
    if (currentY > doc.internal.pageSize.getHeight() - 80) {
      doc.addPage();
      currentY = 20;
    }
    
    // Image label - use fullLabel for section questions
    doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    doc.text(safeText(q.fullLabel || q.label), 15, currentY);
    currentY += 8;
    
    // Add image with optimization
    try {
      // Use annotated image if available, otherwise fallback to original
      const imgData = responses[q.id];
      
      if (imgData && imgData.startsWith('data:image')) {
        // Calculate dimensions that preserve aspect ratio
        const maxWidth = doc.internal.pageSize.getWidth() - 40;
        const maxHeight = 120; // Maximum height for images
        
        let imgWidth = maxWidth;
        let imgHeight = 80; // Default height if we can't determine aspect ratio
        
        try {
          // Create a temporary image element to get dimensions
          const img = new Image();
          img.src = imgData;
          
          // If dimensions are available, calculate proportional height
          if (img.width && img.height) {
            imgHeight = maxWidth * (img.height / img.width);
            // If height exceeds maximum, scale down proportionally
            if (imgHeight > maxHeight) {
              imgHeight = maxHeight;
              imgWidth = maxHeight * (img.width / img.height);
            }
          }
        } catch (e) {
          console.error("Couldn't determine image dimensions, using default sizing", e);
        }
        
        // Add image with improved quality
        try {
          doc.addImage(
            imgData,
            "JPEG", // Use JPEG for better performance with photos
            (doc.internal.pageSize.getWidth() - imgWidth) / 2, // Center horizontally
            currentY,
            imgWidth,
            imgHeight
          );
          
          currentY += imgHeight + 15;
        } catch (error) {
          console.error("Error adding image to PDF:", error);
          doc.setFont("helvetica", "italic");
          doc.text("Error: Image could not be displayed", 15, currentY);
          currentY += 20;
        }
      } else {
        doc.setFont("helvetica", "italic");
        doc.text("No image data available", 15, currentY);
        currentY += 20;
      }
    } catch (error) {
      console.error("Error processing image for PDF:", error);
      doc.setFont("helvetica", "italic");
      doc.text("Error: Image could not be processed", 15, currentY);
      currentY += 20;
    }
    
    // Add a separator between images
    if (index < allImageQuestions.length - 1) {
      doc.setDrawColor(220, 220, 220);
      doc.setLineWidth(0.5);
      doc.line(20, currentY - 5, doc.internal.pageSize.getWidth() - 20, currentY - 5);
      doc.setLineWidth(0.1);
    }
  });
  
  return currentY;
};