import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// Register required elements for Pie/Doughnut charts
ChartJS.register(ArcElement, Tooltip, Legend);

interface FilterablePieChartProps {
  title: string;
  type: 'pie' | 'doughnut';
  data: any;
  activeFilter: {value: string, label: string} | null;
  onSegmentClick?: (index: number, value: any, label: string) => void;
  clearFilter?: () => void;
  height?: number;
  legendPosition?: 'top' | 'bottom' | 'left' | 'right';
}

const FilterablePieChart: React.FC<FilterablePieChartProps> = ({
  title,
  type, 
  data, 
  activeFilter,
  onSegmentClick,
  clearFilter,
  height = 300,
  legendPosition = 'right'
}) => {
  const theme = useTheme();
  
  const handleClick = (_: any, element: any) => {
    if (!onSegmentClick || !element.length) return;
    
    const clickedIndex = element[0].index;
    const label = data.labels[clickedIndex];
    const value = clickedIndex; // or extract specific value if needed
    
    onSegmentClick(clickedIndex, value, label);
  };
  
  const chartOptions = {
    onClick: onSegmentClick ? handleClick : undefined,
    plugins: {
      legend: {
        position: legendPosition,
        labels: {
          color: theme.palette.text.primary
        }
      },
      tooltip: {
        titleColor: theme.palette.primary.main,
        bodyColor: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        borderWidth: 1
      }
    },
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 2,
        borderColor: theme.palette.background.paper,
        hoverBorderWidth: 3,
        hoverBorderColor: theme.palette.background.default
      }
    }
  };

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center' 
      }}>
        <span>{title}</span>
        {activeFilter && clearFilter && (
          <Chip 
            size="small" 
            icon={<FilterAltIcon fontSize="small" />}
            label={`Filtered: ${activeFilter.label}`} 
            onDelete={clearFilter}
            color="primary"
            variant="outlined"
          />
        )}
      </Typography>
      
      <Box sx={{ height, cursor: onSegmentClick ? 'pointer' : 'default' }}>
        {type === 'pie' ? (
          <Pie data={data} options={chartOptions} />
        ) : (
          <Doughnut data={data} options={chartOptions} />
        )}
      </Box>
      
      {onSegmentClick && !activeFilter && (
        <Typography variant="caption" color="text.secondary" align="center" sx={{ display: 'block', mt: 1 }}>
          Click segments to filter results
        </Typography>
      )}
    </Box>
  );
};

export default FilterablePieChart;