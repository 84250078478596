/**
 * Utilities for processing and manipulating response data
 */

/**
 * Debug a response to help track issues
 */
export const debugResponseData = (questionId: string, response: any, responses: any) => {
  console.log(`Question ${questionId}:`, {
    responseDirectly: response,
    responseInResponses: responses[questionId],
    followUpResponses: Object.keys(responses)
      .filter(key => key.startsWith(`${questionId}_`))
      .reduce((acc: Record<string, any>, key: string) => ({...acc, [key]: responses[key]}), {}),
    responseType: response !== undefined ? typeof response : 'undefined',
    isArray: Array.isArray(response),
  });
};

/**
 * Check if a question has follow-up data
 */
export const hasFollowUpData = (questionId: string, responses: Record<string, any>): boolean => {
  return Object.keys(responses)
    .some(key => key.startsWith(`${questionId}_followUp`));
};

/**
 * Get all follow-up responses for a specific question
 */
export const getFollowUpResponses = (
  questionId: string, 
  responses: Record<string, any>
): Record<string, any> => {
  return Object.keys(responses)
    .filter(key => key.startsWith(`${questionId}_followUp`))
    .reduce((acc: Record<string, any>, key: string) => ({...acc, [key]: responses[key]}), {});
};

/**
 * Helper to check if an option has a follow-up response
 */
export const hasOptionFollowUp = (
  questionId: string, 
  option: string, 
  responses: Record<string, any>
): boolean => {
  return !!responses[`${questionId}_followUp_${option}`];
};

/**
 * Helper for boolean responses to check if value is truthy
 */
export const isTruthyResponse = (response: any): boolean => {
  if (typeof response === 'boolean') return response;
  if (typeof response === 'string') {
    const normalized = response.toLowerCase().trim();
    return normalized === 'true' || normalized === 'yes' || normalized === '1';
  }
  return !!response;
};

/**
 * Safely extract a list of options from different response formats
 * This handles various ways multi-select and checkbox responses can be stored
 */
export const getSelectedOptions = (response: any): string[] => {
  // Handle array of selected options
  if (Array.isArray(response)) {
    return response.map(item => String(item));
  } 
  
  // Handle object with boolean values (common for checkboxes)
  if (typeof response === 'object' && response !== null) {
    return Object.entries(response)
      .filter(([_, value]) => value === true)
      .map(([key]) => key);
  }
  
  // Handle single string or primitive value
  if (response !== null && response !== undefined) {
    return [String(response)];
  }
  
  return [];
};

/**
 * Get numeric value from a response, returning null if not a number
 * This is useful for numerical analysis of responses
 */
export const getNumericValue = (response: any): number | null => {
  // Handle direct number
  if (typeof response === 'number') {
    return isNaN(response) ? null : response;
  }
  
  // Try to parse string as number
  if (typeof response === 'string' && response.trim() !== '') {
    const num = Number(response);
    return !isNaN(num) ? num : null;
  }
  
  // Handle boolean values
  if (typeof response === 'boolean') {
    return response ? 1 : 0;
  }
  
  // Handle objects with numeric properties (like rating questions)
  if (typeof response === 'object' && response !== null) {
    if ('value' in response && typeof response.value === 'number') {
      return response.value;
    }
    if ('rating' in response && typeof response.rating === 'number') {
      return response.rating;
    }
    if ('score' in response && typeof response.score === 'number') {
      return response.score;
    }
  }
  
  return null;
};

/**
 * Normalize response value to handle case sensitivity and formatting
 */
export const normalizeResponseValue = (value: any): string => {
  // Handle boolean responses
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  
  // Handle string responses
  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase().trim();
    
    // Normalize yes/no/true/false variants
    if (lowerValue === 'yes' || lowerValue === 'true' || lowerValue === '1') {
      return 'yes';
    }
    if (lowerValue === 'no' || lowerValue === 'false' || lowerValue === '0') {
      return 'no';
    }
    
    // Return trimmed string for other values
    return value.trim();
  }
  
  // Convert other types to string
  return String(value);
};