import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Typography, Box, Paper, Divider, Alert, AlertTitle } from "@mui/material";
import { Home, Replay } from "@mui/icons-material";
import { useState, useEffect } from "react";

const Confirmation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formTitle, setFormTitle] = useState<string>("filled-form");
  const [pdfSentToUser, setPdfSentToUser] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  
  // Get the form id from location state if provided
  const formId = location.state?.formId;

  // Try to get form data from both location state and sessionStorage
  useEffect(() => {
    console.log("Loading confirmation data");
    
    // First check location state
    if (location.state?.formTitle) {
      setFormTitle(location.state.formTitle);
      setPdfSentToUser(location.state.pdfSentToUser || false);
      setUserEmail(location.state.userEmail || "");
    }
    // If not in location state, try sessionStorage
    else {
      const storedFormTitle = sessionStorage.getItem('formTitle');
      const pdfSent = sessionStorage.getItem('pdfSentToEmail') === 'true';
      const email = sessionStorage.getItem('userEmail');
      
      console.log("Checking sessionStorage:", { storedFormTitle, pdfSent, email });
      
      if (storedFormTitle) {
        setFormTitle(storedFormTitle);
      }
      
      setPdfSentToUser(pdfSent);
      setUserEmail(email || "");
    }
  }, [location.state]);
  
  const handleReturnHome = () => {
    navigate("/");
  };

  const handleSubmitAnotherForm = () => {
    // Attempt to get the formId first from location.state, then sessionStorage
    let id = location.state?.formId || sessionStorage.getItem("formId");
    console.log("Submit Another Form: found formId =", id);
    
    if (id) {
      navigate(`/fill/${id}`, { state: { formId: id } });
    } else {
      alert("Form ID not found. Please contact support.");
    }
  };

  return (
    <Box sx={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f5f5f5'
    }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 6, 
          borderRadius: 2, 
          maxWidth: '600px',
          width: '90%',
          textAlign: 'center'
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" color="primary">
            Thank You!
          </Typography>
          <Typography variant="h6">
            Your form has been submitted successfully
          </Typography>
        </Box>
        
        <Typography variant="body1" sx={{ mb: 3 }}>
          An email with your form has been sent to the form owner.
          {pdfSentToUser && userEmail && (
            <>
              <br/><br/>
              <Alert severity="success" sx={{ mb: 2, mx: 'auto', maxWidth: '450px' }}>
                A PDF copy has been sent to your email ({userEmail})
              </Alert>
            </>
          )}
        </Typography>

        {sessionStorage.getItem('approvalRequired') === 'true' && (
          <Alert severity="info" sx={{ mt: 3, mb: 3 }}>
            <AlertTitle>Approval Required</AlertTitle>
            <Typography>
              Your form submission requires approval before it's considered complete. 
              You'll be notified via email when your submission has been reviewed.
            </Typography>
          </Alert>
        )}
        
        <Divider sx={{ my: 3 }} />

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
          <Button
            variant="outlined"
            startIcon={<Home />}
            onClick={handleReturnHome}
            size="large"
          >
            Return to Home
          </Button>
          
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Replay />}
            onClick={handleSubmitAnotherForm}
            size="large"
          >
            Submit Another Form
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Confirmation;