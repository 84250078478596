import React, { useState, useEffect, useRef } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { differenceInMinutes, format } from "date-fns";
import Question from "./Question";

interface TimeRangeValue {
  startTime: Date | null;
  endTime: Date | null;
  minutes?: number;
}

interface TimeQuestionProps {
  question: any;
  value: Date | null | TimeRangeValue;
  onChange: (value: Date | null | TimeRangeValue) => void;
}

const TimeQuestion: React.FC<TimeQuestionProps> = ({ question, value, onChange }) => {
  // Add a mounted ref to track component lifecycle
  const isMountedRef = useRef(true);
  
  // Set up cleanup on unmount
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  
  // Debug log the question's timeMode and value
  useEffect(() => {
    console.log(`TimeQuestion (${question.id}) - Mode: ${question.timeMode}, Current value:`, value);
  }, [question, value]);
  
  // Modify your onChange handler to check if the component is still mounted
  const handleChange = (newValue: any) => {
    if (isMountedRef.current) {
      console.log(`TimeQuestion onChange (${question.id}) - New value:`, newValue);
      onChange(newValue);
    }
  };

  // Determine mode: "single" or "range" - be more explicit
  const mode = question.timeMode === "range" ? "range" : "single";

  // For range mode, initialize the local state from value or with nulls
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [calculatedMinutes, setCalculatedMinutes] = useState<number>(0);

  // Initialize state based on incoming value
  useEffect(() => {
    if (mode === "range") {
      // Handle range mode
      if (value && typeof value === "object" && "startTime" in value) {
        const timeRangeValue = value as TimeRangeValue;
        setStartTime(timeRangeValue.startTime);
        setEndTime(timeRangeValue.endTime);
        setCalculatedMinutes(timeRangeValue.minutes || 0);
      }
    }
  }, [value, mode]);

  // When in range mode, recalc minutes and update parent
  useEffect(() => {
    if (mode === "range" && startTime && endTime) {
      const minutes = differenceInMinutes(endTime, startTime);
      setCalculatedMinutes(minutes);
      handleChange({ startTime, endTime, minutes });
    } else if (mode === "range" && (startTime || endTime)) {
      // Only update if at least one time is set
      handleChange({ startTime, endTime, minutes: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  return (
    <Question question={question}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {mode === "single" ? (
          // Single time picker
          <TimePicker
            label={question.label || "Select time"}
            value={value instanceof Date ? value : null}
            onChange={(newTime) => handleChange(newTime)}
            slotProps={{ 
              textField: { 
                fullWidth: true, 
                required: question.required 
              } 
            }}
          />
        ) : (
          // Time range picker
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="subtitle1">
              {question.label || "Select Time Range"}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TimePicker
                label="Start Time"
                value={startTime}
                onChange={(newTime) => setStartTime(newTime)}
                slotProps={{ 
                  textField: { 
                    fullWidth: true, 
                    required: question.required,
                    size: "medium"
                  } 
                }}
              />
              <TimePicker
                label="End Time"
                value={endTime}
                onChange={(newTime) => setEndTime(newTime)}
                slotProps={{ 
                  textField: { 
                    fullWidth: true, 
                    required: question.required,
                    size: "medium"
                  } 
                }}
              />
            </Box>
            {startTime && endTime && (
              <Typography variant="body2">
                Total duration: {calculatedMinutes} {calculatedMinutes === 1 ? 'minute' : 'minutes'}
              </Typography>
            )}
          </Box>
        )}
      </LocalizationProvider>
    </Question>
  );
};

export default TimeQuestion;