import React from "react";
import { Question } from "../../store/store";
import TextQuestion from "./questions/TextQuestion";
import SelectQuestion from "./questions/SelectQuestion";
import CheckboxQuestion from "./questions/CheckboxQuestion";
import LongTextQuestion from "./questions/LongTextQuestion";
import FileQuestion from "./questions/FileQuestion";
import RepeatableQuestion from "./questions/RepeatableQuestion";
import InstructionsQuestion from "./questions/InstructionsQuestion";
import SectionQuestion from "./questions/SectionQuestion";
import TimeQuestion from "./questions/TimeQuestion";
import LikertQuestion from "./questions/LikertQuestion";
import { FormatListNumbered } from "@mui/icons-material"; // Add this import

const questionTypeIcons: Record<string, React.ReactNode> = {
  // ...other question types
  "likert": <FormatListNumbered />,
};

const questionTypeLabels: Record<string, string> = {
  // ...other question types
  "likert": "Likert Scale",
};

interface QuestionFactoryProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const QuestionFactory: React.FC<QuestionFactoryProps> = ({
  question,
  onChange,
  questionTypes
}) => {
  // Based on question type, render appropriate component
  switch(question.type) {
    case "select":
    case "multi-select":
      return <SelectQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "checkbox":
      return <CheckboxQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "long-text":
      return <LongTextQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "file":
      return <FileQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "repeatable":
      return <RepeatableQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "instructions":
      return <InstructionsQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
      
    case "section":
      return <SectionQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;

    case "time":
      return <TimeQuestion question={question} onChange={onChange} />;

    case "likert":
      return (
        <LikertQuestion
          label={question.label || ""}
          description={question.description}
          onChange={onChange}
          config={question.config || {
            scaleSize: 5,
            lowLabel: 'Strongly Disagree',
            highLabel: 'Strongly Agree',
            showNumbers: true,
            scaleType: 'agreement'
          }}
        />
      );

    // Default to text question for all other types (text, number, date, etc.)
    default:
      return <TextQuestion question={question} onChange={onChange} questionTypes={questionTypes} />;
  }
};

export default QuestionFactory;