import React, { useState, useRef } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  IconButton, 
  CircularProgress,
  Paper
} from '@mui/material';
import { 
  FileUpload as UploadIcon, 
  Delete as DeleteIcon,
  Description as FileIcon,
  PictureAsPdf as PdfIcon,
  Code as CodeIcon,
  Videocam as VideoIcon,
  AudioFile as AudioIcon,
  Archive as ArchiveIcon
} from '@mui/icons-material';
import Question from './Question';

// 5MB file size limit
const MAX_FILE_SIZE = 5 * 1024 * 1024;

interface FileQuestionProps {
  question: any;
  value: File | null;
  onChange: (file: File | null) => void;
}

const FileQuestion: React.FC<FileQuestionProps> = ({
  question,
  value,
  onChange
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setError(null);

    if (!file) {
      return;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      setError(`File size exceeds 5MB limit (${(file.size / 1024 / 1024).toFixed(2)}MB)`);
      return;
    }

    setLoading(true);
    
    // Simulate processing to show loading indicator briefly
    setTimeout(() => {
      onChange(file);
      setLoading(false);
    }, 500);
  };

  const handleClearFile = () => {
    onChange(null);
    setError(null);
    // Reset the input value to allow re-uploading the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Get appropriate icon based on file type
  const getFileIcon = (file: File) => {
    const type = file.type.split('/')[0];
    const extension = file.name.split('.').pop()?.toLowerCase();

    switch (type) {
      case 'image':
        return <FileIcon />;
      case 'application':
        if (file.type.includes('pdf')) return <PdfIcon />;
        if (file.type.includes('zip') || file.type.includes('rar') || extension === 'zip' || extension === 'rar')
          return <ArchiveIcon />;
        return <FileIcon />;
      case 'text':
        return <CodeIcon />;
      case 'video':
        return <VideoIcon />;
      case 'audio':
        return <AudioIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <Question question={question}>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" p={4}>
          <CircularProgress size={40} />
        </Box>
      ) : value ? (
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            bgcolor: '#f9f9f9'
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {getFileIcon(value)}
            <Box>
              <Typography variant="body2" fontWeight="medium">
                {value.name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {(value.size / 1024).toFixed(1)} KB • {value.type || "Unknown type"}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={handleClearFile} size="small" sx={{ color: 'error.main' }}>
            <DeleteIcon />
          </IconButton>
        </Paper>
      ) : (
        <Box>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept={question.acceptedFileTypes || '*'}
          />
          <Button
            variant="outlined"
            startIcon={<UploadIcon />}
            onClick={() => fileInputRef.current?.click()}
            fullWidth
            sx={{ 
              py: 1.5, 
              border: '1px dashed', 
              borderColor: 'primary.main',
              bgcolor: 'background.paper' 
            }}
          >
            {question.uploadButtonText || "Select a file"}
          </Button>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
            Maximum file size: 5MB (Files will be attached to email)
          </Typography>
          {question.acceptedFileTypes && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              Accepted formats: {question.acceptedFileTypes}
            </Typography>
          )}
        </Box>
      )}
      
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Question>
  );
};

export default FileQuestion;