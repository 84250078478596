import { createTheme } from '@mui/material';

// Define theme options as a centralized constant
export const themeOptions = [
  { name: "Blue", primary: "#1976d2", secondary: "#f50057" },
  { name: "Purple", primary: "#7b1fa2", secondary: "#ff4081" },
  { name: "Green", primary: "#388e3c", secondary: "#ffca28" },
  { name: "Orange", primary: "#e65100", secondary: "#2979ff" },
  { name: "Teal", primary: "#00796b", secondary: "#ff5722" }
];

// Helper function to get a theme by name
export const getThemeByName = (themeName: string) => {
  return themeOptions.find(theme => theme.name === themeName) || themeOptions[0];
};

// Function to create a Material-UI theme from our theme options
export const createAppTheme = (themeName: string) => {
  const selectedTheme = getThemeByName(themeName);
  
  return createTheme({
    palette: {
      primary: {
        main: selectedTheme.primary,
      },
      secondary: {
        main: selectedTheme.secondary,
      },
      background: {
        default: '#f5f5f5',
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
            },
          },
        },
      },
    },
  });
};