import { format, differenceInCalendarDays, differenceInBusinessDays, differenceInMinutes } from 'date-fns';
import { 
  extractComments, 
  extractFollowups, 
  hasOtherOption,
  extractOtherValue,
  getQuestionData
} from './PDFUtils';

interface PDFQuestionRendererProps {
  doc: any;
  question: any;
  questionIndex: number;
  questionCounter: number;
  sectionCounter: number;
  currentY: number;
  responses: { [key: string]: any };
  repeatableItems: { [key: string]: Array<{ [key: string]: any }> };
  imagePreview: { [key: string]: string };
  annotatedImages: { [key: string]: string };
  signatures: { [key: string]: string };
  blackTextColor: [number, number, number];
  lightGrayColor: [number, number, number];
  primaryColorRgb: [number, number, number];
  safeText: (value: any) => string;
}

export const PDFQuestionRenderer = ({
  doc,
  question: q,
  questionIndex,
  questionCounter,
  sectionCounter,
  currentY,
  responses,
  repeatableItems,
  imagePreview,
  annotatedImages,
  signatures,
  blackTextColor,
  lightGrayColor,
  primaryColorRgb,
  safeText
}: PDFQuestionRendererProps): number => {

  // Get all data related to this question
  const { response, comments, followups } = getQuestionData(responses, q.id);
  
  // Now you can simply use these arrays without worrying about key formats
  
  // Calculate extra height needed
  let extraContentHeight = 0;
  extraContentHeight += comments.length * 15;  // 15px for each comment
  extraContentHeight += followups.length * 15; // 15px for each followup
  
  // Also, for multi-checkbox questions, check for an "other" response.
  const otherValue = (q.type === "checkbox" && q.checkboxStyle === "multi" && Array.isArray(responses[q.id]))
    ? extractOtherValue(responses[q.id])
    : null;
  
  if (otherValue) extraContentHeight += 15;

  let contentHeight = 20; // Default height
  
  // Skip repeatable details in the main section (we'll add a detailed section for them)
  if (q.type === "repeatable") {
    // Add a placeholder for repeatable items with proper styling
    doc.setDrawColor(lightGrayColor[0], lightGrayColor[1], lightGrayColor[2]);
    doc.setFillColor(
      questionIndex % 2 === 0 ? 252 : 248, 
      questionIndex % 2 === 0 ? 252 : 248, 
      questionIndex % 2 === 0 ? 252 : 248
    );
    doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 20, 2, 2, "FD");
    
    // Question text
    doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    doc.text(safeText(q.label), 15, currentY + 6);
    
    // Response text
    doc.setFont("helvetica", "italic");
    doc.setFontSize(10);
    // Ensure we're accessing the repeatableItems correctly
    const itemCount = repeatableItems && repeatableItems[q.id] ? repeatableItems[q.id].length : 0;
    console.log(`PDF: Repeatable items for ${q.id}:`, itemCount);
    doc.text(`${itemCount} item${itemCount !== 1 ? 's' : ''} - See detailed section below`, 15, currentY + 14);
    
    // Increase the vertical spacing after this item
    return currentY + 25; // Increased from 15 to 25 for more spacing
  }
  
  // Format response based on question type
  let responseValue;
  let hasSignatureThumbnail = false;
  let signatureThumbnailData = "";
  let splitText = null;
  
  if (q.type === "date" && responses[q.id]) {
    try {
      responseValue = format(new Date(responses[q.id]), 'dd/MM/yyyy');
    } catch (error) {
      responseValue = responses[q.id]; // Fallback to raw value if date parsing fails
    }
  } else if (q.type === "multi-select" && Array.isArray(responses[q.id])) {
    responseValue = responses[q.id].join(", ");
  } else if (q.type === "checkbox" && q.checkboxStyle === "yes-no") {
    responseValue = responses[q.id] === true || responses[q.id] === "yes" ? "Yes" : "No";
  } else if (q.type === "image") {
    try {
      const imgData = annotatedImages[q.id] || imagePreview[q.id] || responses[q.id];
      
      if (imgData) {
        responseValue = "Image provided (see Images section)";
      } else {
        responseValue = "No image provided";
      }
    } catch (error) {
      console.error("Error processing image reference:", error);
      responseValue = "Error displaying image";
    }
  } else if (q.type === "signature") {
    try {
      let sigData = signatures[q.id];
      
      // If not in signatures, check responses
      if (!sigData && responses[q.id] && responses[q.id].startsWith('data:image')) {
        sigData = responses[q.id];
      }
      
      if (sigData && sigData.startsWith('data:image')) {
        responseValue = "Signature provided (see Signatures section)";
        hasSignatureThumbnail = true;
        signatureThumbnailData = sigData;
      } else {
        responseValue = "No signature provided";
      }
    } catch (error) {
      console.error("Error processing signature reference:", error);
      responseValue = "Error displaying signature";
    }
  } else if (q.type === "date-range") {
    const dateRangeResponse = responses[q.id];
    
    if (dateRangeResponse && dateRangeResponse.startDate && dateRangeResponse.endDate) {
      try {
        const startDate = new Date(dateRangeResponse.startDate);
        const endDate = new Date(dateRangeResponse.endDate);
        
        // Format dates in UK format (DD/MM/YYYY)
        const formatDate = (date: Date) => format(date, 'dd/MM/yyyy');
        
        // Calculate days between dates
        const includeWeekends = dateRangeResponse.includeWeekends !== false; // Default to true if not specified
        let days = 0;
        
        if (includeWeekends) {
          days = differenceInCalendarDays(endDate, startDate) + 1; // +1 to include both start and end dates
        } else {
          days = differenceInBusinessDays(endDate, startDate) + 1; // +1 for inclusive counting
        }
        
        // Format the response with dates and days calculation
        responseValue = `${formatDate(startDate)} to ${formatDate(endDate)}`;
        
        // Create a second line for the calculation details
        const calculationDetails = [
          `${days} ${days === 1 ? 'day' : 'days'}`,
          includeWeekends ? "(including weekends)" : "(excluding weekends)"
        ];
        
        // Add hours if they're included in the response
        if (dateRangeResponse.useHours === true && dateRangeResponse.hours !== null && dateRangeResponse.hours !== undefined) {
          const hoursValue = typeof dateRangeResponse.hours === 'number' 
            ? dateRangeResponse.hours 
            : parseFloat(String(dateRangeResponse.hours));
          
          if (!isNaN(hoursValue)) {
            calculationDetails.push(`+ ${hoursValue} ${hoursValue === 1 ? 'hour' : 'hours'}`);
          }
        }
        
        // Add the calculation details as a second line
        responseValue += `\n${calculationDetails.join(' ')}`;
      } catch (error) {
        console.error("Error formatting date range:", error);
        responseValue = "Invalid date range format";
      }
    } else {
      responseValue = "Date range not provided";
    }
  } else if (q.type === "checkbox" && q.checkboxStyle === "multi" && Array.isArray(responses[q.id])) {
    // Format the multi-checkbox response
    const selections = responses[q.id] as string[];
    
    // Handle "other" option specially - our utilities will handle this later
    const regularSelections = selections.filter(item => !item.startsWith('other:'));
    responseValue = regularSelections.join(", ") || "None selected";
  } else if (q.type === "time") {
    try {
      // Check if it's a time range question
      const timeResponse = responses[q.id];
      
      if (timeResponse && typeof timeResponse === 'object' && 'startTime' in timeResponse && 'endTime' in timeResponse) {
        // It's a time range
        if (timeResponse.startTime && timeResponse.endTime) {
          const startTime = new Date(timeResponse.startTime);
          const endTime = new Date(timeResponse.endTime);
          
          // Format the times in 24h format for clarity
          const formattedStartTime = format(startTime, 'HH:mm');
          const formattedEndTime = format(endTime, 'HH:mm');
          
          // Include minutes in the response if available
          let minutes = timeResponse.minutes;
          if (!minutes && timeResponse.startTime && timeResponse.endTime) {
            minutes = differenceInMinutes(endTime, startTime);
          }
          
          // Format response with times and duration
          responseValue = `${formattedStartTime} to ${formattedEndTime} (${minutes} minute${minutes !== 1 ? 's' : ''})`;
        } else {
          responseValue = "Time range not completely provided";
        }
      } else if (timeResponse) {
        // It's a single time
        try {
          // Check if it's already a string (formatted time)
          if (typeof timeResponse === 'string' && !timeResponse.includes('T')) {
            responseValue = timeResponse;
          } else {
            // It's a date object or ISO string
            const timeDate = new Date(timeResponse);
            responseValue = format(timeDate, 'HH:mm');
          }
        } catch (error) {
          // If formatting fails, just use the raw value
          responseValue = String(timeResponse);
        }
      } else {
        responseValue = "Time not provided";
      }
    } catch (error) {
      console.error("Error formatting time response:", error);
      responseValue = "Invalid time format";
    }
  } else {
    responseValue = responses[q.id] || "No response";
  }
  
  // Calculate extra height needed for follow-up responses and comments
  // Add height for all comments we found
  // Add height for all followups we found
  // Add height for "other" option if present
  // Question box
  if (responseValue && typeof responseValue === 'string') {
    // Use smaller font size for long responses
    const fontSize = responseValue.length > 50 ? 9 : 10;
    doc.setFontSize(fontSize);
    doc.setFont("helvetica", "normal");
    
    // Split text to calculate height
    splitText = doc.splitTextToSize(safeText(responseValue), doc.internal.pageSize.getWidth() - 30);
    
    // Calculate height needed for text (line height is approximately fontSize * 1.2)
    const textHeight = splitText.length * (fontSize * 1.2);
    contentHeight = Math.max(20, textHeight + 15); // Minimum 20px height, plus padding
  }
  
  // Add extra height for follow-ups and comments
  contentHeight += extraContentHeight;
  
  // Now draw the container with the proper height
  doc.setDrawColor(lightGrayColor[0], lightGrayColor[1], lightGrayColor[2]);
  doc.setFillColor(
    questionIndex % 2 === 0 ? 252 : 248, 
    questionIndex % 2 === 0 ? 252 : 248, 
    questionIndex % 2 === 0 ? 252 : 248
  );
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, contentHeight, 2, 2, "FD");
  
  // Add question number before label
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.text(`${sectionCounter}.${questionCounter}. ${safeText(q.label)}`, 15, currentY + 6);
  
  // Add response text
  if (splitText) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10); // Ensure consistent font size
    doc.text(splitText, 15, currentY + 14);
  } else if (typeof responseValue === 'string' || typeof responseValue === 'number') {
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(safeText(responseValue), 15, currentY + 14);
  } else {
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("No response", 15, currentY + 14);
  }
  
  // Calculate position for follow-up content
  let followUpY = currentY + 24;
  if (splitText && splitText.length > 1) {
    followUpY = currentY + 14 + (splitText.length * 12);
  }
  
  // Render all comments (with consistent styling)
  if (otherValue) {
    doc.setFont("helvetica", "italic");
    doc.setFontSize(10);
    doc.text(`Other: ${safeText(otherValue)}`, 20, followUpY);
    followUpY += 12;
  }
  comments.forEach(comment => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text(`Comment: ${safeText(comment)}`, 20, followUpY);
    followUpY += 12;
  });
  
  // Render all followups (with consistent styling)
  followups.forEach(followup => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text(`Follow-up: ${safeText(followup)}`, 20, followUpY);
    followUpY += 12;
  });
  
  // Handle "Other" option for multi-checkbox
  if (q.type === "checkbox" && q.checkboxStyle === "multi" && Array.isArray(responses[q.id])) {
    const otherValue = extractOtherValue(responses[q.id]);
    if (otherValue) {
      doc.setFontSize(10);
      doc.setFont("helvetica", "italic");
      doc.text(`Other: ${safeText(otherValue)}`, 20, followUpY);
      followUpY += 12;
    }
  }

  return currentY + contentHeight + 4; // Add a bit of extra space between questions
};