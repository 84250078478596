/**
 * Validates a single email address
 * @param email Email address to validate
 * @returns Boolean indicating if the email is valid
 */
export const isValidEmail = (email: string): boolean => {
  // Basic email regex pattern
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email.trim());
};

/**
 * Validates multiple email addresses separated by commas
 * @param emails String containing one or more comma-separated email addresses
 * @returns Boolean indicating if all emails are valid
 */
export const isValidMultipleEmails = (emails: string): boolean => {
  if (!emails) return false;
  
  // Split the input by commas and trim each email
  const emailList = emails.split(',').map(email => email.trim());
  
  // Check if any emails are empty after splitting
  if (emailList.some(email => email === '')) return false;
  
  // Check that every email is valid
  return emailList.every(email => isValidEmail(email));
};