import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormStore, Question } from "../store/store";
import {
  Snackbar,
  Alert,
  Box,
  ThemeProvider,
  createTheme,
  Typography,
  Button,
  Fab,
  Zoom,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from "@mui/material";
import { useUser } from "@clerk/clerk-react";
import SharedNavBar from "../components/SharedNavBar";
import FormHeader from "../components/form-builder/FormHeader";
import QuestionWrapper from "../components/form-builder/QuestionWrapper";
import QuestionFactory from "../components/form-builder/QuestionFactory";
import AddQuestionButton from "../components/form-builder/AddQuestionButton";
import FormActions from "../components/form-builder/FormActions";
import { Alert as MuiAlert, Chip } from '@mui/material';
import { InfoOutlined, TextFields, List, CalendarMonth, Dashboard } from '@mui/icons-material';

import { doc, getDoc, serverTimestamp, query, collection, where, getDocs } from "firebase/firestore";
import AdminUserSelect from "../components/admin/AdminUserSelect";
import { db } from "../firebase";
import { themeOptions, createAppTheme } from '../theme/themeOptions';
import { isValidMultipleEmails } from "../utils/validators";

export type QuestionType = 
  | "text"
  | "number"
  | "select"
  | "multi-select"
  | "checkbox"
  | "image"
  | "date"
  | "date-range"
  | "signature"
  | "repeatable"
  | "long-text"
  | "file"  
  | "instructions"
  | "section"
  | "time"
  | "likert"; // Make sure "likert" is included

interface FormBuilderProps {
  isEditing?: boolean;
}

const questionTypes: QuestionType[] = [
  "text",
  "number",
  "select",
  "multi-select",
  "checkbox",
  "likert", // Add this new type
  "image",
  "file",
  "date",
  "date-range",
  "signature",
  "repeatable",
  "long-text",
  "instructions",
  "section",
  "time"
];

const FormBuilder: React.FC<FormBuilderProps> = ({ isEditing: initialIsEditing = false }) => {
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  const { 
    formTitle, setFormTitle,
    formDescription, setFormDescription,
    formSlug, setFormSlug,
    questions, addQuestion, updateQuestion, removeQuestion, 
    saveForm, setQuestions, setFormId, 
    email, setEmail, formId,
    setUserId,
    allowPdfCopy, setAllowPdfCopy,
    requiresApproval, setRequiresApproval  // Add these new properties
  } = useFormStore();

  const navigate = useNavigate();
  const { user } = useUser();
  const { formId: paramFormId } = useParams();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  const [themeColor, setThemeColor] = useState<string>("Blue");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  
  const selectedTheme = themeOptions.find(theme => theme.name === themeColor) || themeOptions[0];
  const theme = createTheme({
    palette: {
      primary: {
        main: selectedTheme.primary,
      },
      secondary: {
        main: selectedTheme.secondary,
      },
      background: {
        default: '#f5f5f5',
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    const savedTheme = localStorage.getItem("userThemePreference");
    if (savedTheme && themeOptions.some(theme => theme.name === savedTheme)) {
      setThemeColor(savedTheme);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, [user, setUserId]);

  useEffect(() => {
    if (!isEditing) {
      setFormTitle("");
      setQuestions([]);
      setFormId("");
      setEmail("");
    } else if (paramFormId) {
      setFormId(paramFormId); 
    }
  }, [isEditing, paramFormId, setFormTitle, setQuestions, setFormId, setEmail]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [assignedUserId, setAssignedUserId] = useState<string | null>(null);
  const [assignedUserEmail, setAssignedUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user?.id) return;
      
      try {
        const userRef = doc(db, "users", user.id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const role = userSnap.data().role || "User";
          setUserRole(role);
          setIsAdmin(role === "Admin");
        } else {
          setUserRole("User");
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole("User");
        setIsAdmin(false);
      }
    };

    fetchUserRole();
  }, [user]);

  const [landingPageInfo, setLandingPageInfo] = useState<{
    exists: boolean;
    hasThisForm: boolean;
    slug: string | null;
  }>({
    exists: false,
    hasThisForm: false,
    slug: null
  });

  useEffect(() => {
    const checkLandingPageStatus = async () => {
      if (!user?.id || !formId) return;
      
      try {
        const landingPageRef = doc(db, "landing_pages", user.id);
        const landingPageSnap = await getDoc(landingPageRef);
        
        if (landingPageSnap.exists()) {
          const landingPageData = landingPageSnap.data();
          const formsInLanding = landingPageData.forms || [];
          const isFormIncluded = formsInLanding.some((form: any) => form.id === formId);
          
          setLandingPageInfo({
            exists: true,
            hasThisForm: isFormIncluded,
            slug: landingPageData.companySlug || null
          });
        } else {
          setLandingPageInfo({
            exists: false,
            hasThisForm: false,
            slug: null
          });
        }
      } catch (error) {
        console.error("Error checking landing page status:", error);
      }
    };
    
    checkLandingPageStatus();
  }, [user?.id, formId]);

  const [isSlugAvailable, setIsSlugAvailable] = useState(true);
  const [isCheckingSlug, setIsCheckingSlug] = useState(false);
  const [companySlug, setCompanySlug] = useState<string | null>(null);
  const [shortCompanyName, setShortCompanyName] = useState<string | null>(null);
  const [originalFormSlug, setOriginalFormSlug] = useState<string | null>(null);

  useEffect(() => {
    const loadCompanySlug = async () => {
      if (!user) return;
      
      try {
        let formCreatorId = user.id;
        
        if (isEditing && formId) {
          // Get the form's actual creator ID
          const formDoc = await getDoc(doc(db, "forms", formId));
          if (formDoc.exists()) {
            formCreatorId = formDoc.data().userId || user.id;
          }
        }
        
        // First get user's profile for short company name
        const userDoc = await getDoc(doc(db, "users", formCreatorId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setShortCompanyName(userData.shortCompanyName || null);
          
          if (userData.shortCompanyName) {
            setCompanySlug(userData.shortCompanyName);
            return;
          }
        }
        
        // Fallback to landing page
        const landingPageRef = doc(db, "landing_pages", formCreatorId);
        const landingPageSnap = await getDoc(landingPageRef);
        
        if (landingPageSnap.exists()) {
          const landingPageData = landingPageSnap.data();
          setCompanySlug(landingPageData.companySlug || null);
        } else {
          setCompanySlug(null);
        }
      } catch (error) {
        console.error("Error loading company data:", error);
      }
    };
    
    loadCompanySlug();
  }, [user, isEditing, formId]);

  useEffect(() => {
    const checkSlugAvailability = async () => {
      if (!formSlug || !user) return;
      
      try {
        setIsCheckingSlug(true);
        
        // Important: If we're editing and this is the current form's slug, it's valid
        if (formId) {
          const formDoc = await getDoc(doc(db, "forms", formId));
          if (formDoc.exists() && formDoc.data().formSlug === formSlug) {
            // Same slug for the same form is fine
            setIsSlugAvailable(true);
            setIsCheckingSlug(false);
            return;
          }
        }
        
        // Check for duplicates within user's own forms
        const userSlugsQuery = query(
          collection(db, "forms"),
          where("userId", "==", user.id),
          where("formSlug", "==", formSlug)
        );
        
        const userSlugsSnapshot = await getDocs(userSlugsQuery);
        
        // For newly created form that's been saved once, we need to handle the case 
        // where its formId is now set but we're still in the same "create" page
        const hasDuplicates = userSlugsSnapshot.docs.some(doc => 
          // Skip comparing to itself when editing or when we just created it
          !(doc.id === formId)
        );
        
        setIsSlugAvailable(!hasDuplicates);
      } catch (error) {
        console.error("Error checking slug availability:", error);
        // Default to available in case of error to avoid blocking users
        setIsSlugAvailable(true);
      } finally {
        setIsCheckingSlug(false);
      }
    };
    
    const timer = setTimeout(() => {
      if (formSlug) {
        checkSlugAvailability();
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [formSlug, formId, user]);

  const saveFormCore = async () => {
    try {
      if (!user) {
        alert("You must be logged in to save forms");
        return null; // Return null to indicate failure
      }
      
      if (!formTitle.trim()) {
        alert("Please add a form title before saving");
        return null; // Return null to indicate failure
      }
      
      if (!email.trim()) {
        alert("Please add a notification email address");
        return null; // Return null to indicate failure
      }
      
      if (!isValidMultipleEmails(email)) {
        alert("Please enter valid email address(es). For multiple emails, separate with commas.");
        return null; // Return null to indicate failure
      }
      
      // Skip this check if we're editing this form and the slug hasn't changed
      if (formSlug && !isSlugAvailable && 
          !(formId && originalFormSlug === formSlug)) {
        alert("The form URL slug is already in use. Please choose a different one.");
        return null; // Return null to indicate failure
      }
      
      const effectiveUserId = (isAdmin && assignedUserId) ? assignedUserId : user.id;
      setUserId(effectiveUserId);
      
      const effectiveEmail = (isAdmin && assignedUserId && assignedUserId !== user.id && assignedUserEmail) 
        ? assignedUserEmail 
        : email;
      
      let adminAssignmentData = {};
      if (isAdmin && assignedUserId && assignedUserId !== user.id) {
        adminAssignmentData = {
          assignedByAdmin: {
            adminId: user.id,
            adminEmail: user.emailAddresses[0]?.emailAddress || '',
            assignmentDate: new Date().toISOString()
          }
        };
      }
      
      // Save the form and get the ID back
      const savedFormId = await saveForm({ email: effectiveEmail, extraData: adminAssignmentData });
      
      // Always show the snackbar when saving (for both new and existing forms)
      setSnackbarOpen(true);
      
      // Transition to edit mode after first save
      if (savedFormId && !isEditing) {
        // We're now editing this form
        setIsEditing(true);
        setFormId(savedFormId);
        setOriginalFormSlug(formSlug); // Save the original slug value
        
        // Update the URL to reflect we're in edit mode
        window.history.replaceState(null, '', `/form-builder/${savedFormId}`);
      }
      
      return savedFormId;
    } catch (error) {
      console.error("Error saving form:", error);
      alert(`Failed to save form: ${error instanceof Error ? error.message : "Unknown error"}`);
      return null; // Return null to indicate failure
    }
  };

  const handleSaveForm = async () => {
    await saveFormCore();
  };

  const handleSaveAndReturn = async () => {
    const success = await saveFormCore();
    if (success) {
      // The snackbar is already shown by saveFormCore
      // Wait a moment for the user to see it before navigating
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
  };

  const handleSaveAndGoToLanding = async () => {
    const success = await saveFormCore();
    if (success) {
      // The snackbar is already shown by saveFormCore
      // Wait a moment for the user to see it before navigating
      setTimeout(() => {
        if (landingPageInfo.exists && landingPageInfo.slug) {
          window.open(`/${landingPageInfo.slug}`, '_blank');
        } else {
          navigate("/landing-page-builder");
        }
      }, 1000);
    }
  };

  const handleMoveQuestion = (index: number, direction: "up" | "down") => {
    const newQuestions = [...questions];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < newQuestions.length) {
      [newQuestions[index], newQuestions[targetIndex]] = [newQuestions[targetIndex], newQuestions[index]];
      setQuestions(newQuestions);
    }
  };

  const handleDuplicateQuestion = (index: number) => {
    const questionToDuplicate = questions[index];
    
    // Create a deep copy of the question
    const duplicatedQuestion = JSON.parse(JSON.stringify(questionToDuplicate));
    
    // Generate a new ID for the duplicated question
    duplicatedQuestion.id = `q${Date.now()}`;
    
    // Add "(copy)" to the label if it exists
    if (duplicatedQuestion.label) {
      duplicatedQuestion.label = `${duplicatedQuestion.label} (copy)`;
    }
    
    // Insert the duplicated question after the current one
    const newQuestions = [...questions];
    newQuestions.splice(index + 1, 0, duplicatedQuestion);
    
    // Update the questions in state
    setQuestions(newQuestions);
    
    // Highlight the new question
    setTimeout(() => {
      const duplicatedQuestionElement = document.getElementById(`question-${duplicatedQuestion.id}`);
      if (duplicatedQuestionElement) {
        duplicatedQuestionElement.classList.add('question-highlight-duplicate');
        duplicatedQuestionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        
        setTimeout(() => {
          duplicatedQuestionElement.classList.remove('question-highlight-duplicate');
        }, 2000);
      }
    }, 100);
  };

  const handleCopyUrl = () => {
    let url = '';
    if (companySlug && formSlug) {
      url = `${window.location.origin}/${companySlug}/${formSlug}`;
    } else if (formId) {
      url = `${window.location.origin}/fill/${formId}`;
    }
    
    if (url) {
      navigator.clipboard.writeText(url);
      alert("URL copied to clipboard!");
    }
  };

  const questionsContainerRef = useRef<HTMLDivElement | null>(null);
  const lastQuestionRef = useRef<HTMLDivElement | null>(null);

  const handleAddQuestion = useCallback((type: QuestionType) => {
    const newQuestionId = addQuestion(type);

    setTimeout(() => {
      if (lastQuestionRef.current) {
        lastQuestionRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'center' 
        });

        lastQuestionRef.current.classList.add('question-highlight');
        setTimeout(() => {
          if (lastQuestionRef.current) {
            lastQuestionRef.current.classList.remove('question-highlight');
          }
        }, 1500);
      }
    }, 100);

    return newQuestionId;
  }, [addQuestion]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        backgroundColor: theme.palette.background.default, 
        minHeight: '100vh',
        pb: 8 
      }}>
        <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />

        <div className="max-w-6xl mx-auto">
          <div className="p-6 bg-white shadow-lg rounded-lg mt-6 space-y-4">
            <FormHeader 
              formTitle={formTitle}
              email={email}
              formDescription={formDescription}
              formSlug={formSlug}
              companySlug={companySlug}
              onFormTitleChange={setFormTitle}
              onEmailChange={setEmail}
              onFormDescriptionChange={setFormDescription}
              onFormSlugChange={setFormSlug}
              allowPdfCopy={allowPdfCopy}
              onAllowPdfCopyChange={setAllowPdfCopy}
              requiresApproval={requiresApproval}  // New prop
              onRequiresApprovalChange={setRequiresApproval}  // New prop
              isSlugAvailable={isSlugAvailable}
              isCheckingSlug={isCheckingSlug}
            />

            {!landingPageInfo.hasThisForm && (
              <Box mb={4}>
                <MuiAlert 
                  severity="info"
                  icon={<InfoOutlined />}
                  action={
                    <Button 
                      color="inherit" 
                      size="small"
                      variant="outlined"
                      onClick={() => navigate("/landing-page-builder")}
                    >
                      Go to Landing Page Builder
                    </Button>
                  }
                >
                  Don't forget to add this form to your landing page and set up FAQs for your users!
                </MuiAlert>
              </Box>
            )}

            <Box ref={questionsContainerRef} sx={{ position: 'relative' }}>
              {questions.map((q, index) => (
                <div 
                  key={q.id}
                  ref={index === questions.length - 1 ? lastQuestionRef : null}
                  id={`question-${q.id}`}
                >
                  <QuestionWrapper
                    questionId={q.id}
                    index={index}
                    required={q.required || false}
                    canMoveUp={index > 0}
                    canMoveDown={index < questions.length - 1}
                    onMove={(direction) => handleMoveQuestion(index, direction)}
                    onDelete={() => removeQuestion(q.id)}
                    onToggleRequired={() => updateQuestion(q.id, { required: !q.required })}
                    onDuplicate={() => handleDuplicateQuestion(index)} // Add this new prop
                    showRequired={q.type !== "instructions"}
                  >
                    <QuestionFactory 
                      question={q}
                      onChange={(updates) => updateQuestion(q.id, updates)}
                      questionTypes={questionTypes}
                    />
                  </QuestionWrapper>
                </div>
              ))}
            </Box>

            <AddQuestionButton 
              onAddQuestion={(type: string) => handleAddQuestion(type as QuestionType)}
              questionTypes={questionTypes as string[]}
            />

            <Zoom in={true} style={{ transitionDelay: '300ms' }}>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 30,
                  right: 30,
                  zIndex: 1000,
                  display: { xs: 'block', sm: 'none' } // Only show on mobile
                }}
              >
                <SpeedDial
                  ariaLabel="Add question speed dial"
                  icon={<SpeedDialIcon />}
                  direction="up"
                  FabProps={{
                    color: 'primary',
                    size: 'large',
                    sx: {
                      boxShadow: '0 4px 10px rgba(0,0,0,0.3)', // Stronger shadow
                      width: 64,  // Larger size
                      height: 64,
                      '&:hover': { 
                        boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                        transform: 'scale(1.1)'
                      },
                      animation: 'pulsate 2s infinite',
                      transition: 'transform 0.2s, box-shadow 0.2s'
                    }
                  }}
                >
                  {[
                    { type: "text", icon: <TextFields />, name: "Text Question" },
                    { type: "select", icon: <List />, name: "Select/Dropdown" },
                    { type: "date", icon: <CalendarMonth />, name: "Date Field" },
                    { type: "section", icon: <Dashboard />, name: "Section Break" }
                  ].map((action) => (
                    <SpeedDialAction
                      key={action.type}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => handleAddQuestion(action.type as QuestionType)}
                      FabProps={{
                        sx: {
                          boxShadow: 2
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
              </Box>
            </Zoom>

            {userRole === "Admin" && (
              <Box 
                sx={{ 
                  mt: 4, 
                  p: 3, 
                  border: '1px dashed', 
                  borderColor: 'warning.main',
                  borderRadius: 2,
                  bgcolor: 'warning.light',
                  opacity: 0.8
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Admin Controls
                </Typography>
                
                <AdminUserSelect 
                  currentUserId={user?.id || ''}
                  onUserChange={(userId, userEmail) => {
                    setAssignedUserId(userId);
                    setAssignedUserEmail(userEmail);
                  }}
                />
              </Box>
            )}

            <FormActions
              isEditing={isEditing}
              formId={formId ?? undefined}
              formSlug={formSlug}
              companySlug={companySlug}
              onSave={handleSaveForm}
              onSaveAndReturn={handleSaveAndReturn}
              onSaveAndGoToLanding={handleSaveAndGoToLanding}
              onCopyUrl={handleCopyUrl}
              hasSelectedFormsInLanding={landingPageInfo.hasThisForm}
              requiresApproval={requiresApproval}  // Add this
            />

            <Snackbar 
              open={snackbarOpen} 
              autoHideDuration={4000} 
              onClose={() => setSnackbarOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert 
                onClose={() => setSnackbarOpen(false)}
                severity="success" 
                variant="filled"
                sx={{ width: '100%' }}
              >
                {isEditing ? "Form updated successfully!" : "Form saved successfully!"}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </Box>
      <style>{`
        .question-highlight {
          animation: highlight 1.5s ease-out;
        }
        
        .question-highlight-duplicate {
          animation: highlightDuplicate 2s ease-out;
        }
        
        @keyframes highlight {
          0% { 
            background-color: rgba(66, 165, 245, 0.2); /* light primary color */
          }
          100% { 
            background-color: transparent; 
          }
        }
        
        @keyframes highlightDuplicate {
          0% { 
            background-color: rgba(76, 175, 80, 0.2); /* light green color */
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
          }
          100% { 
            background-color: transparent;
            transform: translateY(0);
            box-shadow: none;
          }
        }
        
        @keyframes pulsate {
          0% {
            transform: scale(1);
            box-shadow: 0 4px 10px rgba(0,0,0,0.3);
          }
          50% {
            transform: scale(1.05);
            box-shadow: 0 6px 14px rgba(0,0,0,0.3);
          }
          100% {
            transform: scale(1);
            box-shadow: 0 4px 10px rgba(0,0,0,0.3);
          }
        }
      `}</style>
    </ThemeProvider>
  );
};

export default FormBuilder;