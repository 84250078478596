import React from 'react';
import { Box, Typography } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';

interface ImageResponseProps {
  question: any;
  submission: any;
}

const ImageResponse: React.FC<ImageResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const imagePreview = submission?.imagePreview || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  
  // Debug logging
  console.log(`Image response for ${question.id}:`, {
    imageInPreview: !!imagePreview[question.id],
    imageInResponses: !!responses[question.id],
    responseType: responses[question.id] ? typeof responses[question.id] : 'undefined'
  });
  
  // Check for image in imagePreview first (this is where they're typically stored)
  if (imagePreview && imagePreview[question.id]) {
    return (
      <>
        <Box sx={{ maxWidth: 300 }}>
          <img 
            src={imagePreview[question.id]} 
            alt="Uploaded image" 
            style={{ width: '100%', borderRadius: '4px' }} 
          />
        </Box>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // If not in imagePreview, check if URL is directly in responses
  const response = responses[question.id];
  if (response && typeof response === 'string' && 
      (response.startsWith('http') || response.startsWith('data:'))) {
    return (
      <>
        <Box sx={{ maxWidth: 300 }}>
          <img 
            src={response} 
            alt="Uploaded image" 
            style={{ width: '100%', borderRadius: '4px' }} 
          />
        </Box>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // No image found
  return (
    <>
      <Typography variant="body1">No image provided</Typography>
      <CommentDisplay comments={comment} />
    </>
  );
};

export default ImageResponse;