/**
 * Utility functions for PDF generation
 */

/**
 * Returns a list of response keys related to the given questionId and a given keyword.
 */
export const findRelatedResponseKeys = (
  responses: { [key: string]: any },
  questionId: string,
  keyword: string
): string[] => {
  const result: string[] = [];
  if (!responses || !questionId) return result;
  
  // Common variants of the question ID:
  const variants = [
    questionId,
    questionId.replace(/-/g, ''), // no dashes
    questionId.replace(/-/g, '_') // underscores instead of dashes
  ];
  
  Object.keys(responses).forEach(key => {
    // If any variant appears and the key includes the keyword, we take it
    if (variants.some(v => key.includes(v)) && key.toLowerCase().includes(keyword)) {
      result.push(key);
    }
  });
  
  return result;
};

/**
 * Extract all comment strings for the question as an object.
 */
export const extractComments = (
  responses: { [key: string]: any },
  questionId: string
): { [key: string]: string } => {
  const { comments } = getQuestionData(responses, questionId);
  const result: { [key: string]: string } = {};
  
  // Just for backward compatibility
  comments.forEach((value, index) => {
    result[`comment_${index}`] = value;
  });
  
  return result;
};

/**
 * Extract all follow-up strings for the question as an object.
 */
export const extractFollowups = (
  responses: { [key: string]: any },
  questionId: string
): { [key: string]: string } => {
  const { followups } = getQuestionData(responses, questionId);
  const result: { [key: string]: string } = {};
  
  // Just for backward compatibility
  followups.forEach((value, index) => {
    result[`followup_${index}`] = value;
  });
  
  return result;
};

/**
 * Returns true if "other" option is selected in a multi-checkbox response
 */
export const hasOtherOption = (
  response: any
): boolean => {
  if (!response || !Array.isArray(response)) return false;
  return response.some(item => item && typeof item === 'string' && item.startsWith('other:'));
};

/**
 * Extract "other" value from multi-checkbox response
 */
export const extractOtherValue = (
  response: any
): string | null => {
  if (!response || !Array.isArray(response)) return null;
  
  const otherOption = response.find(item => 
    item && typeof item === 'string' && item.startsWith('other:')
  );
  
  return otherOption ? otherOption.substring(6) : null; // Remove 'other:' prefix
};

/**
 * Get all related data for a question, including main response, comments, and follow-ups
 */
export const getQuestionData = (
  responses: { [key: string]: any },
  questionId: string
) => {
  if (!responses || !questionId) {
    return { response: null, comments: [], followups: [] };
  }
  
  // The main response for this question
  const response = responses[questionId];
  
  // Get the comments
  const comments: string[] = [];
  // Check for comments with both formats
  const commentKey1 = `${questionId}_comment`;
  const commentKey2 = `${questionId}-comment`;
  const commentKey3 = questionId.replace(/-/g, '') + '_comment';
  
  if (responses[commentKey1] && typeof responses[commentKey1] === 'string') {
    comments.push(responses[commentKey1]);
  }
  if (responses[commentKey2] && typeof responses[commentKey2] === 'string') {
    comments.push(responses[commentKey2]);
  }
  if (responses[commentKey3] && typeof responses[commentKey3] === 'string') {
    comments.push(responses[commentKey3]);
  }
  
  // Look for any keys that might be comments
  Object.keys(responses).forEach(key => {
    // Avoid duplicates
    if (key !== commentKey1 && key !== commentKey2 && key !== commentKey3) {
      // Check if it's a comment key with the question ID
      if (key.includes(questionId) && key.includes('comment')) {
        if (typeof responses[key] === 'string' && responses[key].trim()) {
          comments.push(responses[key]);
        }
      }
    }
  });
  
  // Get the followups
  const followups: string[] = [];
  // Check for followups with various formats
  const followupKey1 = `${questionId}_followup`;
  const followupKey2 = `${questionId}-followup`;
  const followupKey3 = questionId.replace(/-/g, '') + '_followup';
  const followupKey4 = questionId.replace(/-/g, '') + '-followup';
  
  if (responses[followupKey1] && typeof responses[followupKey1] === 'string') {
    followups.push(responses[followupKey1]);
  }
  if (responses[followupKey2] && typeof responses[followupKey2] === 'string') {
    followups.push(responses[followupKey2]);
  }
  if (responses[followupKey3] && typeof responses[followupKey3] === 'string') {
    followups.push(responses[followupKey3]);
  }
  if (responses[followupKey4] && typeof responses[followupKey4] === 'string') {
    followups.push(responses[followupKey4]);
  }
  
  // Also look for option-specific followups (for select/multi-select questions)
  Object.keys(responses).forEach(key => {
    // Avoid duplicates
    if (key !== followupKey1 && key !== followupKey2 && 
        key !== followupKey3 && key !== followupKey4) {
      // Check if it's a followup key for this question
      if (key.includes(questionId) && key.includes('followup')) {
        if (typeof responses[key] === 'string' && responses[key].trim()) {
          followups.push(responses[key]);
        }
      }
    }
  });
  
  return {
    response,
    comments,
    followups
  };
};

/**
 * Debug function to log all keys related to a questionId in the responses object
 */
export const debugRelatedKeys = (
  responses: { [key: string]: any },
  questionId: string
): void => {
  console.log(`Debugging keys related to question ${questionId}:`);
  
  Object.keys(responses).forEach(key => {
    if (key.includes(questionId)) {
      console.log(`Key: ${key}, Value: ${JSON.stringify(responses[key])}`);
    }
  });
  
  // Also check for simplified IDs (no dashes)
  const simplifiedId = questionId.replace(/-/g, '');
  Object.keys(responses).forEach(key => {
    if (key.includes(simplifiedId) && !key.includes(questionId)) {
      console.log(`Key with simplified ID: ${key}, Value: ${JSON.stringify(responses[key])}`);
    }
  });
};

/**
 * Format text safely for PDF rendering, preserving line breaks
 */
export const safeTextWithNewlines = (
  value: any
): string => {
  if (value === undefined || value === null) return '';
  
  // Convert to string
  const text = String(value);
  
  // Replace typical newline characters with a standard representation
  return text.replace(/\r\n|\r|\n/g, '\n');
};