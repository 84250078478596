import React, { useState } from "react";
import { 
  Box, 
  TextField, 
  Typography, 
  Button,
  Select,
  MenuItem,
  Card,
  CardContent,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  Chip
} from "@mui/material";
import { Add, Delete, Close } from "@mui/icons-material";
import { Question, SubField } from "../../../store/store";

interface RepeatableQuestionProps {
  question: Question;
  onChange: (updates: Partial<Question>) => void;
  questionTypes: string[];
}

const RepeatableQuestion: React.FC<RepeatableQuestionProps> = ({ 
  question, 
  onChange, 
  questionTypes 
}) => {
  const [newOptions, setNewOptions] = useState<{[key: string]: string}>({});

  const handleAddSubField = () => {
    const newSubFields = [
      ...(question.subFields || []),
      {
        id: `sub_${Date.now()}`,
        type: "text",
        label: `Field ${(question.subFields || []).length + 1}`,
        required: false
      }
    ];
    onChange({ subFields: newSubFields });
  };

  const handleUpdateSubField = (index: number, updates: Partial<SubField>) => {
    const newSubFields = [...(question.subFields || [])];
    newSubFields[index] = { ...newSubFields[index], ...updates };
    onChange({ subFields: newSubFields });
  };

  const handleRemoveSubField = (index: number) => {
    const newSubFields = (question.subFields || []).filter((_, i) => i !== index);
    onChange({ subFields: newSubFields });
  };

  const handleAddOption = (subFieldId: string) => {
    const optionKey = `sub_${subFieldId}`;
    if (!newOptions[optionKey]?.trim()) return;

    const subFieldIndex = (question.subFields || []).findIndex(sf => sf.id === subFieldId);
    if (subFieldIndex === -1) return;

    const newSubFields = [...(question.subFields || [])];
    newSubFields[subFieldIndex].options = [
      ...(newSubFields[subFieldIndex].options || []),
      newOptions[optionKey]
    ];
    
    onChange({ subFields: newSubFields });
    setNewOptions({...newOptions, [optionKey]: ""});
  };

  const handleRemoveOption = (subFieldId: string, option: string) => {
    const subFieldIndex = (question.subFields || []).findIndex(sf => sf.id === subFieldId);
    if (subFieldIndex === -1) return;

    const newSubFields = [...(question.subFields || [])];
    newSubFields[subFieldIndex].options = (newSubFields[subFieldIndex].options || [])
      .filter(opt => opt !== option);
    
    onChange({ subFields: newSubFields });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField 
          label="Question Label"
          fullWidth 
          value={question.label} 
          onChange={(e) => onChange({ label: e.target.value })} 
          placeholder="e.g., Add team members"
        />

        <Select
          value={question.type}
          onChange={(e) => onChange({ type: e.target.value as any })}
          className="ml-4"
          style={{ minWidth: '150px' }}
        >
          {questionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          Repeatable Fields Configuration
        </Typography>
        
        <TextField
          fullWidth
          label="Helper Text"
          placeholder="Enter instructions for this repeatable section (optional)"
          value={question.helperText || ""}
          onChange={(e) => onChange({ helperText: e.target.value })}
          sx={{ mb: 2 }}
        />
        
        <Box border={1} borderColor="divider" borderRadius={1} p={2} mb={2}>
          <Typography variant="subtitle2" mb={1} fontWeight="medium">
            Sub Fields
          </Typography>
          
          <Divider sx={{ mb: 2 }} />
          
          {(question.subFields || []).length === 0 ? (
            <Typography variant="body2" color="text.secondary" align="center" py={2}>
              No fields defined yet. Add a field using the button below.
            </Typography>
          ) : (
            (question.subFields || []).map((subField, subIndex) => (
              <Card key={subField.id} sx={{ mb: 2, border: '1px solid #e0e0e0' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={1}>
                    <TextField
                      label="Field Label"
                      value={subField.label || ""}
                      onChange={(e) => handleUpdateSubField(subIndex, { label: e.target.value })}
                      fullWidth
                      sx={{ mr: 2 }}
                      placeholder={`Field ${subIndex + 1}`}
                    />
                    
                    <Select
                      value={subField.type || "text"}
                      onChange={(e) => handleUpdateSubField(subIndex, { type: e.target.value as string })}
                      sx={{ width: '150px', mr: 1 }}
                    >
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="select">Select</MenuItem>
                      <MenuItem value="date">Date</MenuItem>
                    </Select>
                    
                    <IconButton 
                      color="error"
                      onClick={() => handleRemoveSubField(subIndex)}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                  
                  {/* Options for select type */}
                  {subField.type === "select" && (
                    <Box mt={1}>
                      <Typography variant="body2" gutterBottom>Options</Typography>
                      <Box display="flex" gap={1} mb={1}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Add Option"
                          value={newOptions[`sub_${subField.id}`] || ""}
                          onChange={(e) => 
                            setNewOptions({
                              ...newOptions, 
                              [`sub_${subField.id}`]: e.target.value
                            })
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleAddOption(subField.id);
                            }
                          }}
                        />
                        <Button 
                          variant="outlined" 
                          size="small"
                          onClick={() => handleAddOption(subField.id)}
                        >
                          Add
                        </Button>
                      </Box>
                      
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {Array.isArray(subField.options) && subField.options.map((option) => (
                          <Chip
                            key={option}
                            label={option}
                            onDelete={() => handleRemoveOption(subField.id, option)}
                            size="small"
                            deleteIcon={<Close />}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                  
                  <FormControlLabel
                    control={
                      <Switch
                        checked={subField.required || false}
                        onChange={(e) => handleUpdateSubField(subIndex, { required: e.target.checked })}
                      />
                    }
                    label="Required"
                  />
                </CardContent>
              </Card>
            ))
          )}
          
          <Button
            variant="outlined"
            fullWidth
            startIcon={<Add />}
            onClick={handleAddSubField}
          >
            Add Field
          </Button>
        </Box>
        
        {/* Max items configuration */}
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <TextField
            label="Minimum Entries"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.minEntries || ""}
            onChange={(e) => onChange({ minEntries: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
          <TextField
            label="Maximum Entries"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={question.maxEntries || ""}
            onChange={(e) => onChange({ maxEntries: parseInt(e.target.value) || 0 })}
            sx={{ mb: 2, width: '50%' }}
          />
        </Box>
      </Box>
    </>
  );
};

export default RepeatableQuestion;