import { db } from '../../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  orderBy,
  limit
} from 'firebase/firestore';
import { normalizeTextResponses } from './responseProcessing';

// Update the FormSubmission interface to match firebaseSubmissions.ts
export interface FormSubmission {
  id: string;
  formId: string;
  formTitle: string;
  email: string;
  submittedAt: any; // Timestamp
  userId?: string;
  // Add approval fields with specific types
  approvalRequired?: boolean;
  approvalStatus?: 'approved' | 'rejected' | 'pending';
  approvalComments?: string;
  approvalDate?: any; // Timestamp
  [key: string]: any;
}

export interface SubmissionDetails {
  id: string;
  formId: string;
  submissionId?: string;
  email?: string;
  submittedAt?: any;
  responses?: Record<string, any>;
  repeatableItems?: Record<string, any[]>;
  signatures?: Record<string, string>;
  imagePreview?: Record<string, string>;
  annotatedImages?: Record<string, string>;
  archived?: boolean;
  archivedAt?: any;
  approvalRequired?: boolean;
  approvalStatus?: string;
  approvalComments?: string;
  approvalDate?: any;
  metadata?: {
    submitDevice?: string;
    browser?: string;
    platform?: string;
    [key: string]: any;
  };
  [key: string]: any; // Allow for other fields
}

/**
 * Gets all submissions for a form with detailed responses
 */
export const getFormSubmissions = async (formId: string): Promise<FormSubmission[]> => {
  try {
    console.log(`Fetching submissions for form ${formId}`);
    
    // Query main submissions collection - we want ALL submissions for the history view
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId),
      orderBy("submittedAt", "desc") // Keep the ordering
    );
    
    const querySnapshot = await getDocs(submissionsQuery);
    
    if (querySnapshot.empty) {
      console.log(`No submissions found for form ${formId}`);
      return [];
    }
    
    console.log(`Found ${querySnapshot.size} submissions, now fetching details...`);
    
    // Get basic submission data
    const submissions = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        formId: data.formId || "",
        formTitle: data.formTitle || "Untitled Form",
        email: data.email || "",
        submittedAt: data.submittedAt,
        userId: data.userId || "",
        // Properly cast approval fields to specific types
        approvalRequired: data.approvalRequired || false,
        approvalStatus: (data.approvalStatus === 'approved' ? 'approved' :
                        data.approvalStatus === 'rejected' ? 'rejected' :
                        data.approvalStatus === 'pending' ? 'pending' : undefined) as 'approved' | 'rejected' | 'pending' | undefined,
        approvalComments: data.approvalComments || "",
        approvalDate: data.approvalDate || null,
        
      };
    }) as FormSubmission[];
    
    // Fetch detailed responses for each submission
    const submissionsWithDetails = await Promise.all(
      submissions.map(async (submission) => {
        try {
          // Try to get details for this submission
          const details = await getSubmissionDetails(submission.id!);
          
          if (details) {
            // Merge basic submission data with detailed response data
            return {
              ...submission,
              responses: details.responses || {},
              repeatableItems: details.repeatableItems || {},
              signatures: details.signatures || {},
              imagePreview: details.imagePreview || {},
              annotatedImages: details.annotatedImages || {},
              metadata: details.metadata || {},
              archived: details.archived || submission.archived || false,
              archivedAt: details.archivedAt || submission.archivedAt,
              // Ensure approval fields are merged
              approvalRequired: details.approvalRequired || submission.approvalRequired || false,
              approvalStatus: details.approvalStatus || submission.approvalStatus || 'approved',
              approvalComments: details.approvalComments || submission.approvalComments || '',
              approvalDate: details.approvalDate || submission.approvalDate || null
            } as FormSubmission;
          }
          
          return submission;
        } catch (error) {
          console.error(`Error fetching details for submission ${submission.id}:`, error);
          return submission;
        }
      })
    );
    
    console.log(`Successfully fetched ${submissionsWithDetails.length} submissions with details`);
    return submissionsWithDetails;
  } catch (error) {
    console.error("Error fetching form submissions:", error);
    throw error;
  }
};

/**
 * Gets detailed response data for a submission
 */
export const getSubmissionDetails = async (submissionId: string): Promise<SubmissionDetails | null> => {
  try {
    console.log(`Getting details for submission: ${submissionId}`);
    
    // First try direct document approach (if you use submissionId as doc ID)
    try {
      const directRef = doc(db, "submission_details", submissionId);
      const directSnap = await getDoc(directRef);
      
      if (directSnap.exists()) {
        console.log("Found submission details via direct document lookup");
        const data = directSnap.data();
        
        // Normalize text responses if they exist
        if (data.responses) {
          data.responses = normalizeTextResponses(data.responses);
        }
        
        return {
          id: directSnap.id,
          ...data,
          // Explicitly include approval fields
          approvalRequired: data.approvalRequired || false,
          approvalStatus: data.approvalStatus || 'approved',
          approvalComments: data.approvalComments || '',
          approvalDate: data.approvalDate || null
        } as SubmissionDetails;
      }
    } catch (directErr) {
      console.log("Direct document lookup failed:", directErr);
    }
    
    // If direct lookup fails, try query approach
    const detailsQuery = query(
      collection(db, "submission_details"),
      where("submissionId", "==", submissionId),
      limit(1)
    );
    
    console.log("Running query to find submission details");
    const querySnapshot = await getDocs(detailsQuery);
    
    if (querySnapshot.empty) {
      console.warn(`No details found for submission ${submissionId}`);
      return null;
    }
    
    console.log(`Found ${querySnapshot.size} detail documents`);
    
    // Process the data with normalization
    const data = querySnapshot.docs[0].data();
    
    // Normalize text responses if they exist
    if (data.responses) {
      data.responses = normalizeTextResponses(data.responses);
    }
    
    return {
      id: querySnapshot.docs[0].id,
      ...data,
      // Explicitly include approval fields
      approvalRequired: data.approvalRequired || false,
      approvalStatus: data.approvalStatus || 'approved',
      approvalComments: data.approvalComments || '',
      approvalDate: data.approvalDate || null
    } as SubmissionDetails;
  } catch (error) {
    console.error("Error fetching submission details:", error);
    throw error;
  }
};